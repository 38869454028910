import React, { memo, useRef, useCallback, useEffect, useState } from 'react';
import { ExpiredPassword } from '@web/web5';
import Logo from 'components/Logo';
import AbsoluteLoader from 'components/AbsoluteLoader';

import metadata from 'lib/metadata';
import { goLogin } from 'utils/routes';
import { parsePolicies } from 'utils/fm';

import Context from 'managers/Context';

import './styles.scss';

const Web4ExpiredPassword = memo(() => {
    const authData = useRef();

    const [policies, setPolicies] = useState([]);

    useEffect(() => {
        authData.current = window.sessionStorage.getItem('auth_expired');
        if (!authData.current) return goLogin();
        authData.current = JSON.parse(authData.current);
        Context.profileSettingsManager.getPasswordPolicyAuth(
            authData.current,
            (data) => {
                if (!data?.id) return goLogin();
                setPolicies(parsePolicies(data));
            },
            (error) => console.error(error),
        );
    }, []);

    const send = useCallback((password) => {
        return new Promise((resolve, reject) => {
            Context.domainManager
                .changeExpiredPassword({ ...authData.current, password })
                .then((result) => {
                    if (result?.ErrorDetail?.ErrorCode === '-28')
                        reject({
                            message: result.ErrorDetail.ErrorMessage,
                        });
                    else {
                        window.sessionStorage.removeItem('auth_expired');
                        resolve('helptext_password_changed');
                    }
                })
                .catch(reject);
        });
    }, []);

    return (
        <div className="fm-expired-password">
            {!policies.length && <AbsoluteLoader size="massive" />}
            {policies.length && (
                <ExpiredPassword
                    logo={<Logo width={metadata.mainLogoWidth} />}
                    onSend={send}
                    goLogin={goLogin}
                    iframeURL={metadata.iframeURL}
                    policies={policies}
                />
            )}
        </div>
    );
});

export default Web4ExpiredPassword;
