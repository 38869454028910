import { getLiteral } from 'utils/getLiteral';
import { DOCUMENTS } from 'constants/Entities';
import { PAGINATION_TABLE_DOCUMENTS } from 'constants/Environment';

export default (params) => ({
    pageSize: PAGINATION_TABLE_DOCUMENTS,
    entity: DOCUMENTS,
    withoutColumnSelector: true,
    withEntitySelection: true,
    selectionFor: DOCUMENTS.entity,
    isLocalSelection: true,
    cache: {
        order: false,
    },
    columnDefs: [
        {
            colId: 'name',
            headerName: getLiteral('label_name'),
            field: 'description',
            sortField: 'name',
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                showTotal: true,
                checkbox: {
                    onChange: params?.name?.onClickCheckbox || null,
                },
            },
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                bold: true,
                imageSize: 'small',
                isSquared: true,
                hoverLink: true,
                onlyIcon: true,
                onClick: params?.name?.onClick || null,
                innerMethods: {
                    getAvatarSvg: params?.name?.getSvg || null,
                },
                favorite: {
                    getShouldHide: params?.name?.getShouldHide || null,
                    onChange: params?.name?.onChangeFavorite || null,
                },
                checkbox: {
                    onChange: params?.name?.onClickCheckbox || null,
                    isDisabled: params?.name?.isDisabled || null,
                    isAlwaysVisible: true,
                    noCheckedWhenDisabled: true,
                },
            },
            rowDrag: params?.onRowDrag || null,
            width: 450,
            lockPosition: true,
            suppressMovable: true,
            suppressSizeToFit: true,
        },
        {
            colId: 'format',
            headerName: getLiteral('label_format'),
            field: 'format',
            sortField: 'extension',
            headerComponent: 'headerTextCell',
            cellRenderer: 'textCell',
            width: 80,
            suppressSizeToFit: true,
        },
        {
            colId: 'owner',
            headerName: getLiteral('label_owner'),
            field: 'owner',
            sortField: 'usercreado',
            headerComponent: 'headerTextCell',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl: params?.owner?.getUrl || null,
                otherFields: {
                    src: 'srcOwner',
                    placeholder: 'placeholderOwner',
                },
            },
            width: 150,
            suppressSizeToFit: true,
        },
        {
            colId: 'lastUpdate',
            headerName: getLiteral('label_modified_date'),
            field: 'lastUpdate',
            sortField: 'item_date',
            headerComponent: 'headerTextCell',
            cellRenderer: 'textCell',
            width: 250,
            suppressSizeToFit: true,
        },
        {
            colId: 'sizeFormatted',
            headerName: getLiteral('label_size'),
            field: 'sizeFormatted',
            sortField: 'size',
            headerComponent: 'headerTextCell',
            cellRenderer: 'textCell',
            width: 150,
            suppressSizeToFit: true,
        },
    ],
    help: [
        {
            label: 'label_link_help_what_doc_is',
            link: 'link_help_what_doc_is',
            permissions: ['permission.documentos_general'],
        },
        {
            label: 'label_link_help_menu_doc',
            link: 'link_help_menu_doc',
            permissions: ['permission.documentos_general'],
        },
        {
            label: 'label_link_help_create_doc',
            link: 'link_help_create_doc',
            permissions: ['permission.crud_permission.DOCUMENT.create'],
        },
        {
            label: 'label_link_help_add_delete_folder',
            link: 'link_help_add_delete_folder',
            permissions: ['permission.documentos_general'],
        },
    ],
});
