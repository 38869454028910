import React, { memo, useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { SALESORDERS, OPPORTUNITIES, COMPANIES, USERS } from 'constants/Entities';
import CONFIG from './TableConfig';
import { PATH_IMAGE } from 'constants/Environment';
import NewEntityList from 'containers/components/NewEntityList';
import NoData from 'components/SvgIcons/emptyScreen/NoData';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import EntityExport from 'containers/components/modals/EntityExport';
import Orders from 'components/SvgIcons/emptyScreen/Orders';

import {
    EntityActions,
    EntityListActions,
    EntityExtraFieldsActions,
    CrudActions,
    DetailActions,
    EntityDetailActions,
    EntityExportActions,
    EntityListSelectActions,
} from 'actions';
import LinkFromWeb3Decorator from 'decorators/LinkFromWeb3Decorator';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { groupFieldsForFieldSelector, processExtraFieldsForList, isBackendFalsy } from 'utils/fm';
import {
    getSalesOrdersDetailUrl,
    getOpportunityDetailUrl,
    getUserSfmUrl,
    getCompanyDetailUrl,
} from 'utils/getUrl';
import { logEvent } from 'utils/tracking';
import { ensureRoute } from 'utils/routes';
import metadata from 'lib/metadata';
import { getHasTabsEnabled } from 'containers/components/EntityDetail/hooks/useEntityDetail';
import { useTheme } from 'hoi-poi-ui';

const mapStateToProps = (state) => {
    const entityFilters = state.entityFilters[SALESORDERS.entity];
    const filters = entityFilters ? entityFilters.filters : null;
    const operators = entityFilters ? entityFilters.operators : null;

    const permission = state.config.permission;

    const total = state.entityList[SALESORDERS.entity]?.total;
    const isAdmin = state.config?.permission?.canConfigImplementation;

    return {
        canCreate: permission?.crud_permission?.[SALESORDERS.permission]?.create || false,
        canConfig: permission?.manageExtraFields && isAdmin,
        exportSalesOrders: permission?.exportSalesOrders || false,
        filters,
        locale: state?.config?.userData?.locale || '',
        visibleFields: state.config?.components?.salesOrders_list?.visibles || null,
        total,
        operators,
        enabledUserflow: state?.config?.userData?.userFlow,
        salesOrderTypeFilterValue:
            state.entityFilters[SALESORDERS.entity]?.filters?.salesOrderType?.value?.[0]?.value,
        salesordersBulkActions: state.config.permission.salesordersBulkActions,
        shouldClearWeb5Checkboxes:
            state?.entityListSelect?.[SALESORDERS.entity]?.shouldClearWeb5Checkboxes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getExtraFieldSchema: bindActionCreators(EntityExtraFieldsActions, dispatch)
            .getExtraFieldSchema,
        initExport: bindActionCreators(EntityExportActions, dispatch).initExport,
        setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
        standardFieldsConfiguration: bindActionCreators(EntityListActions, dispatch)
            .standardFieldsConfiguration,
        openDetail: bindActionCreators(DetailActions, dispatch).newDetailTab,
        openCrud: bindActionCreators(CrudActions, dispatch).initCrud,
        followEntity: bindActionCreators(EntityActions, dispatch).followEntity,
        open: bindActionCreators(EntityDetailActions, dispatch).open,
        getDataForChip: bindActionCreators(EntityActions, dispatch).getDataForChip,
        onEntitySelect: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelect,
        onEntitySelectAll: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelectAll,
        setShouldClearWeb5Checkboxes: bindActionCreators(EntityListSelectActions, dispatch)
            .setShouldClearWeb5Checkboxes,
    };
};

const Content = memo(
    ({
        getExtraFieldSchema,
        standardFieldsConfiguration,
        exportSalesOrders,
        filters,
        initExport,
        locale,
        canCreate,
        canConfig,
        setUseLazyLoad,
        visibleFields,
        total,
        operators,
        followEntity,
        enabledUserflow,
        open,
        getDataForChip,
        salesOrderTypeFilterValue,
        salesordersBulkActions,
        onEntitySelectAll,
        onEntitySelect,
        setShouldClearWeb5Checkboxes,
        shouldClearWeb5Checkboxes,
    }) => {
        const isFirstLoad = useRef(true);
        const tableRef = useRef(null);
        const [config, setConfig] = useState(null);
        const [entityExportAction, setEntityExportAction] = useState(null);
        const [showModalFields, setShowModalFields] = useState(false);
        const theme = useTheme();

        useEffect(() => {
            if (shouldClearWeb5Checkboxes) {
                if (tableRef?.current?.clearCheckboxes) tableRef.current.clearCheckboxes();
                setShouldClearWeb5Checkboxes(SALESORDERS, false);
            }
        }, [setShouldClearWeb5Checkboxes, shouldClearWeb5Checkboxes]);

        const handleOnRef = useCallback((ref) => {
            tableRef.current = ref;
        }, []);

        const getFavoriteRows = useCallback((data) => {
            const favoriteRows = data.reduce((obj, current) => {
                if (!obj?.['salesOrderNumber']) obj['salesOrderNumber'] = {};
                if (!obj?.['salesOrderNumber']?.rows) obj['salesOrderNumber'].rows = {};
                if (current) {
                    obj['salesOrderNumber'].rows[current.id] = current.followingItem;
                }
                return obj;
            }, {});
            return favoriteRows;
        }, []);

        const openTab = useCallback(
            (entity, id) => {
                if (getHasTabsEnabled()) {
                    getDataForChip(entity.redux, id).then((data) => {
                        !data?.deleted &&
                            open(
                                entity,
                                id,
                                true,
                                null,
                                false,
                                false,
                                null,
                                false, // toTab
                            );
                    });
                } else {
                    // We keep the standard link behaviour if nav tabs are not enabled
                    ensureRoute(`${entity.route}/${id}`);
                }
            },
            [getDataForChip, open],
        );

        const tableParams = useMemo(() => {
            let newTableParams = {
                getUrl: ({ idKey, entity }) => {
                    return (data) => {
                        if (!idKey || !entity) return;
                        const id = data[idKey];
                        if (!id || isBackendFalsy(id)) return;
                        let url = '';

                        switch (entity) {
                            case SALESORDERS:
                                url = getSalesOrdersDetailUrl(id) || '';
                                return url;
                            case COMPANIES:
                                url = getCompanyDetailUrl(id) || '';
                                return url;
                            case USERS:
                                url = getUserSfmUrl(id) || '';
                                return url;
                            case OPPORTUNITIES:
                                url = getOpportunityDetailUrl(id) || '';
                                return url;
                        }
                    };
                },
                orderNumber: {
                    onChangeFavorite: ({ id, value }) => {
                        followEntity({ entityType: SALESORDERS, entityId: id, follow: value })
                            .then((bool) => {
                                successToast({
                                    text: bool
                                        ? getLiteral('succes_following')
                                        : getLiteral('succes_unfollowing'),
                                });
                            })
                            .catch((err) => {
                                console.error(err);
                                errorToast();
                            });
                    },
                    getSvg: (data) => {
                        const svgDefault = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                <path d="M25.5 25.2c0 .2.2.4.4.4s.4-.2.4-.4-.2-.4-.4-.4c-.3.1-.4.2-.4.4zm-15-14.8l2.1 6.6 12.9-1.6v-5h-15zM7.4 23.6c-.4-.2-.7-.6-.7-1.1 0-1.8 1.4-4 3.3-4.9l-3.4-11H5.3C4.6 6.5 4 6 4 5.3 4 4.6 4.6 4 5.3 4h2.3c.6 0 1 .4 1.2.9l.9 3h17c.7 0 1.3.6 1.3 1.3v7.4c0 .6-.5 1.2-1.1 1.3l-15.1 1.8h-.1c-.8.1-1.6.8-2 1.5h17c.7 0 1.3.6 1.3 1.3 0 .5-.3.9-.7 1.1.4.4.7 1 .7 1.6 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-.6.2-1.1.6-1.4H10.5c.3.4.6.9.6 1.4 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-.6.3-1.2.7-1.6zm1.2 1.6c0 .2.2.4.4.4s.4-.2.4-.4-.2-.4-.4-.4c-.3.1-.4.2-.4.4z" fill="#CED4D9" fill-rule="evenodd"/>
                                            </svg>`;
                        const svgSalesOrdersWin = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                    <g fill="none" fill-rule="evenodd">
                                                        <path d="M10.5 10.4l2.1 6.6 12.9-1.6v-5h-15zM7.4 23.6c-.4-.2-.7-.6-.7-1.1 0-1.8 1.4-4 3.3-4.9l-3.4-11H5.3C4.6 6.5 4 6 4 5.3 4 4.6 4.6 4 5.3 4h2.3c.6 0 1 .4 1.2.9l.9 3h17c.7 0 1.3.6 1.3 1.3v7.4c0 .6-.5 1.2-1.1 1.3l-15.1 1.8h-.1c-.8.1-1.6.8-2 1.5h11.808c.795.675-.508 2.6-1.468 2.6H10.5c.3.4.6.9.6 1.4 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-.6.3-1.2.7-1.6zm1.2 1.6c0 .2.2.4.4.4s.4-.2.4-.4-.2-.4-.4-.4c-.3.1-.4.2-.4.4z" fill="#CED4D9"/>
                                                        <path d="M30.23 29.217c0 .541-.445.98-.993.98h-.661c.548 0 .992.44.992.98 0 .543-.444.981-.992.981 0 0-2.647.015-4.3 0-1.233-.01-2.57-1.274-4.922-1.307 0-.327-2.354-5.883-2.354-5.883h1.985c2.315 0 4.96-2.106 4.96-5.23 0-1.033 1.985-1.163 1.985.797 0 1.307-.662 3.78-.662 3.78h5.292c.548 0 .992.438.992.98 0 .541-.444.98-.992.98H29.9c.547 0 .992.439.992.98 0 .542-.445.981-.992.981h-.662c.548 0 .992.439.992.98z" fill="#86AD20"/>
                                                    </g>
                                                </svg>`;
                        const svgSalesOrdersLost = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                    <g fill="none" fill-rule="evenodd">
                                                        <path d="M10.5 10.4l2.1 6.6 12.9-1.6v-5h-15zM7.4 23.6c-.467-.4-.7-.767-.7-1.1 0-1.8 1.4-4 3.3-4.9l-3.4-11H5.3C4.6 6.5 4 6 4 5.3 4 4.6 4.6 4 5.3 4h2.3c.6 0 1 .4 1.2.9l.9 3h17c.7 0 1.3.6 1.3 1.3v7.4c0 .6-.5 1.2-1.1 1.3l-15.1 1.8h-.1c-.8.1-1.6.8-2 1.5h7.064l-1.111 2.6H10.5c.3.4.6.9.6 1.4 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-.4.233-.933.7-1.6zm1.2 1.6c0 .2.2.4.4.4s.4-.2.4-.4-.2-.4-.4-.4c-.3.1-.4.2-.4.4z" fill="#CED4D9"/>
                                                        <path d="M30.23 21.948a.986.986 0 00-.993-.98h-.661c.548 0 .992-.44.992-.981a.986.986 0 00-.992-.98s-2.647-.015-4.3 0c-1.233.01-2.57 1.274-4.922 1.307 0 .326-2.354 5.883-2.354 5.883h1.985c2.315 0 4.96 2.105 4.96 5.229 0 1.033 1.985 1.164 1.985-.796 0-1.308-.662-3.78-.662-3.78h5.292c.548 0 .992-.439.992-.98a.987.987 0 00-.992-.98H29.9a.986.986 0 00.992-.981.987.987 0 00-.992-.98h-.662a.986.986 0 00.992-.981z" fill="#F04540"/>
                                                    </g>
                                                </svg>`;

                        if (!data.win && !data.lost) return svgDefault;
                        else if (data.win) return svgSalesOrdersWin;
                        else return svgSalesOrdersLost;
                    },
                },
                opportunity: {
                    getSvg: () => {
                        const svgDefault = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                <path d="M23 18h2.5a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5H9v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18h12v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18zm-1-1H10v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17H5.5a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5h21a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H23v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17zm-9-8.987a.5.5 0 11-1 0C12 6.901 12.901 6 14.013 6h3.891c1.112 0 2.014.901 2.014 2.013a.5.5 0 11-1 0c0-.56-.454-1.013-1.014-1.013h-3.89C13.453 7 13 7.454 13 8.013z" fill="#CED4D9" fill-rule="evenodd"/>
                                            </svg>`;
                        return svgDefault;
                    },
                },
                locale: {
                    getLocale: () => locale,
                },
                onCompanyClick: ({ data }) => {
                    !isBackendFalsy(data?.idCompany) && openTab(COMPANIES, data.idCompany);
                },
                onOpportunityClick: ({ data }) => {
                    !isBackendFalsy(data?.idOpportunity) &&
                        openTab(OPPORTUNITIES, data.idOpportunity);
                },
            };

            if (salesordersBulkActions) {
                newTableParams.orderNumber.onClickCheckbox = (result) => {
                    const { id, isColumn, value, checked, data, rowIndex, total } = result;
                    if (isColumn) {
                        onEntitySelectAll(SALESORDERS, value, checked, total);
                    } else {
                        onEntitySelect(SALESORDERS, id, value, data, rowIndex, checked);
                    }
                };
            }

            return newTableParams;
        }, [
            followEntity,
            locale,
            onEntitySelect,
            onEntitySelectAll,
            openTab,
            salesordersBulkActions,
        ]);

        useEffect(() => {
            setUseLazyLoad(SALESORDERS, true);
            if (!isFirstLoad?.current) return;

            let newConfig = CONFIG(tableParams);
            newConfig = {
                ...newConfig,
                withEntitySelection: false,
            };

            if (salesordersBulkActions) {
                newConfig = {
                    ...newConfig,
                    withEntitySelection: true,
                    selectionFor: SALESORDERS.entity,
                    isLocalSelection: true,
                };
            }

            getExtraFieldSchema(newConfig.entity, (extraFieldTabs) => {
                let extraFields = [];
                const groupedExtraFields = extraFieldTabs.map((tab) => {
                    let group = {
                        label:
                            typeof tab.descripcion !== 'string'
                                ? 'label_customized_fields'
                                : tab.descripcion,
                        fields: [],
                    };

                    group.fields = processExtraFieldsForList(tab.tabFields);
                    extraFields = [...extraFields, ...group.fields];

                    return group;
                });

                standardFieldsConfiguration(SALESORDERS, newConfig.columnDefs)
                    .then((columnDefs) => {
                        const newColumnDefs = [...columnDefs, ...extraFields];
                        const groupedFields = [
                            ...groupFieldsForFieldSelector(newConfig, columnDefs, true),
                            ...groupedExtraFields,
                        ];

                        newConfig = {
                            ...newConfig,
                            columnDefs: newColumnDefs,
                            groupedFields,
                        };
                        setConfig(newConfig);
                    })
                    .catch(() => {
                        console.error('List configuration error');
                    });
            });

            isFirstLoad.current = false;
        }, [
            tableParams,
            config,
            setConfig,
            getExtraFieldSchema,
            standardFieldsConfiguration,
            setUseLazyLoad,
            salesordersBulkActions,
        ]);

        const onInitExport = useCallback(
            (action) => {
                initExport(SALESORDERS, filters, operators);
                setEntityExportAction(action);
            },
            [initExport, filters, operators],
        );

        const getFieldSelectorProps = useCallback(() => {
            if (!config.groupedFields) return null;
            let actions = [];

            actions.push({
                type: 'title',
                label: getLiteral('label_customize'),
            });

            actions.push({
                label: getLiteral('action_select_visible_columns'),
                icon: 'columns',
                id: 'columns',
                onClick: () => {
                    setShowModalFields(true);
                    logEvent({
                        event: SALESORDERS.trueName,
                        functionality: 'visibleFields',
                        checkDuplicate: true,
                    });
                },
            });

            if (canConfig) {
                actions.push({
                    label: getLiteral('action_add_custom_field'),
                    icon: 'plus',
                    id: 'add_custom_field',
                    color: theme.colors.actionMajor[500],
                    onClick: () => {
                        ensureRoute('/settings/fields', '?tab=tblSalesOrders&showCrud=true');
                        logEvent({
                            event: SALESORDERS.trueName,
                            submodule: 'threeDots',
                            functionality: 'createField',
                        });
                    },
                });
            }

            if (exportSalesOrders) {
                actions.push({
                    type: 'divider',
                });

                actions.push({
                    type: 'title',
                    label: getLiteral('title_excel_export_email'),
                });

                actions.push({
                    label: getLiteral('label_export_excel'),
                    icon: 'downloadAlt',
                    id: 'excel',
                    onClick: () => onInitExport('excel'),
                    disabled: !total || total === 0,
                });
            }

            return {
                actions,
                title: getLiteral('label_showHideColumns'),
                groupedColumns: config.groupedFields,
            };
        }, [config, canConfig, exportSalesOrders, onInitExport, total, theme]);

        const getCustomActiveRowId = useCallback((activeRowId) => {
            if (!activeRowId) return activeRowId;
            return parseInt(activeRowId, 10);
        }, []);

        const emptyIcon = useMemo(() => {
            if (locale.includes('es-'))
                return <img src={`${PATH_IMAGE}illustration_video_salesorders_ES.png`} />;
            return <img src={`${PATH_IMAGE}illustration_video_salesorders_EN.png`} />;
        }, [locale]);

        const emptyViewProps = useMemo(() => {
            if (!metadata.showEmptyScreenVideo || enabledUserflow === 'False')
                return {
                    canCreate,
                    icon: <NoData />,
                    iconFirstTime: <Orders />,
                    iconType: 'icon',
                    titleFirstTime: getLiteral('label_empty_screen_salesorders'),
                    subtitleFirstTime: getLiteral('label_empty_screen_salesorders_desc'),
                    titleSearch: getLiteral('error_notresultfound'),
                    subtitle: salesOrderTypeFilterValue
                        ? getLiteral('helptext_empty_salesorders_types')
                        : getLiteral('helptext_search_filter_text'),
                    subtitleSearch: getLiteral('helptext_search_filter_text'),
                    customButtonLiteral: getLiteral('action_add'),
                    hideClearButton: !!salesOrderTypeFilterValue,
                };

            return {
                canCreate,
                className: 'fm-empty-screen__container__sales-orders',
                icon: <NoData />,
                iconFirstTime: emptyIcon,
                iconType: 'video',
                onTracking: () => {
                    logEvent({
                        event: SALESORDERS.trueName,
                        submodule: 'emptyScreen',
                        functionality: 'video',
                    });
                },
                titleFirstTime: getLiteral('label_empty_screen_salesorders'),
                subtitleFirstTime: getLiteral('label_empty_screen_salesorders_desc'),
                titleSearch: getLiteral('error_notresultfound'),
                subtitle: salesOrderTypeFilterValue
                    ? getLiteral('helptext_empty_salesorders_types')
                    : getLiteral('helptext_search_filter_text'),
                subtitleSearch: getLiteral('helptext_search_filter_text'),
                customButtonLiteral: getLiteral('action_add'),
                hideClearButton: !!salesOrderTypeFilterValue,
            };
        }, [canCreate, emptyIcon, enabledUserflow, salesOrderTypeFilterValue]);

        const getRowNodeId = useCallback((data) => data.id, []);

        const modalOptionsProps = useCallback(
            () => ({
                advice: getLiteral('label_visible_fields_explanation'),
                isOpen: showModalFields,
                confirmText: getLiteral('action_save'),
                cancelText: getLiteral('action_cancel'),
                placeholderSearch: getLiteral('label_search_field'),
                title: getLiteral('label_visible_fields'),
                onCancel: setShowModalFields,
            }),
            [showModalFields],
        );

        const entityExportProps = useMemo(
            () => ({
                action: entityExportAction,
                config: config,
                fields: {
                    download: true,
                    emails: true,
                    freeEmails: false,
                    freeSingleEmail: true,
                    users: false,
                },
                visibleFields: visibleFields,
                entity: SALESORDERS,
            }),
            [config, entityExportAction, visibleFields],
        );

        return (
            <div className="salesorders-table">
                {config && (
                    <NewEntityList
                        id="fm-grid-salesorders"
                        entity={SALESORDERS}
                        config={config}
                        initOnReady={true}
                        useLazyLoad={true}
                        useCache={true}
                        useSort={true}
                        useDragColumns={true}
                        useFieldSelector={true}
                        fieldSelectorProps={getFieldSelectorProps()}
                        getRowNodeId={getRowNodeId}
                        emptyViewProps={emptyViewProps}
                        customEmptyViewComponent={CustomEmptyView}
                        useSelectColumns={true}
                        getFavoriteRows={getFavoriteRows}
                        getCustomActiveRowId={getCustomActiveRowId}
                        discardCount={true}
                        modalOptionsProps={modalOptionsProps()}
                        handleOnRef={handleOnRef}
                    />
                )}
                <EntityExport {...entityExportProps} />
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(LinkFromWeb3Decorator(Content));
