import { memo, useContext, useMemo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { getLiteral } from 'utils/getLiteral';

import { findNodeByType, AUTOMATION_ENTITIES, TRIGGER_ACTION_ICONS } from '../../../utils';
import { AutomationBuilderCanvasContext } from '../AutomationBuilderCanvasContext';
import AutomationBuilderBox from '../components/AutomationBuilderBox';

const ACTION_LITERALS = {
    create: 'label_automation_event_is_created',
    update: 'label_automation_event_is_modified',
    delete: 'label_automation_event_is_deleted',
};

const AutomationBuilderTriggerBox = memo((props) => {
    const { data } = useContext(AutomationBuilderCanvasContext);

    const nodeData = useMemo(() => findNodeByType(data?.scene, props.id), [data?.scene, props.id]);
    const textValue = useMemo(() => {
        const entity = AUTOMATION_ENTITIES.find(
            (entity) => entity.trueName === nodeData?.parameters?.entity?.value,
        );

        if (!entity || !nodeData?.parameters?.action) return;

        let text = `${getLiteral(entity?.labels?.singular)} ${getLiteral(ACTION_LITERALS[nodeData?.parameters?.action?.value])}`;

        if (nodeData?.parameters?.withCreation)
            text += ` (${getLiteral('label_automation_event_creation_included')})`;
        return text;
    }, [nodeData]);

    return (
        <>
            <AutomationBuilderBox
                {...props}
                className="fm-automation-builder__trigger-box"
                title={getLiteral('label_trigger')}
                icon={
                    nodeData?.parameters?.entity
                        ? nodeData?.parameters?.entity?.value
                        : 'arrowDropRightRaw'
                }
                secondIcon={TRIGGER_ACTION_ICONS[nodeData?.parameters?.action?.value]}
                textValue={textValue}
                emptyPlaceholder={getLiteral('action_automation_add_trigger')}
            ></AutomationBuilderBox>
            <Handle type="source" position={Position.Bottom} id="bottom" isConnectable={false} />
        </>
    );
});

export default AutomationBuilderTriggerBox;
