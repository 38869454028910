import { memo } from 'react';
import { Icon, Avatar, Text, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const AutomationBuilderHeader = memo(({ onClose, onEdit, title, description }) => {
    const theme = useTheme();
    return (
        <div className="fm-automation-builder__header">
            <div className="fm-automation-builder__header-content">
                <Avatar icon="automations" size="big" />
                <div className="fm-automation-builder__header-titles">
                    <div className="fm-automation-builder__header-name">
                        <Text type="h5">{title || getLiteral('label_automation_empty_name')}</Text>
                        <Icon name="edit" onClick={onEdit} size="large" />
                    </div>
                    <Text type="body" color={theme.colors.utility.textSecondary}>
                        {description}
                    </Text>
                </div>
            </div>
            <Icon
                className="fm-automation-builder__header-close"
                name="close"
                onClick={onClose}
                size="large"
            />
        </div>
    );
});

export default AutomationBuilderHeader;
