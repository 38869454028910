import { memo, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Link, Text, useTheme } from 'hoi-poi-ui';

import { getAutomationVariables } from 'services/AutomationsService';

import dot from 'utils/dot';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { isEmptyObject } from 'utils/objects';
import {
    buildPathTree,
    CONDITION_BOOL_LOGIC_LOCALES,
    CONDITION_TYPE_NO_INPUT,
} from '../../../../utils';
import DeleteModal from 'components/DeleteModal';
import AutomationBuilderConditionLogicSelector from './AutomationBuilderConditionLogicSelector';
import AutomationBuilderSidePanel from '../AutomationBuilderSidePanel';
import AutomationBuilderConditionGroup from './AutomationBuilderConditionGroup';

import './styles.scss';

const AutomationBuilderConditionsSidePanel = memo(
    ({ data, node, nodePath, onChange, onClose, isTouched, setTouched }) => {
        const theme = useTheme();
        const variables = useRef();
        const [fields, setFields] = useState([]);
        const [entityFields, setEntityFields] = useState([]);
        const deleteModalRef = useRef();
        const [conditionGroups, setConditionGroups] = useState(
            node?.parameters?.conditions || {
                type: 'and',
                parameters: [
                    {
                        type: 'and',
                        parameters: [{}],
                    },
                ],
            },
        );

        useEffect(() => {
            const nodeBranch = buildPathTree(data, nodePath);
            getAutomationVariables(nodeBranch?.scene)
                .then((result) => {
                    let newFields = [];
                    let newEntityFields = [
                        {
                            label: getLiteral('label_triggering_entity'),
                            value: 'event.data',
                            iconType: data?.scene?.parameters?.entity?.entity,
                        },
                    ];
                    if (result?.variables?.event?.data) {
                        newFields = result?.variables?.event?.data?.sections?.map((section) => ({
                            label: section.name,
                            key: 'event.data',
                            options:
                                section.fields.map?.((field) => ({
                                    ...field,
                                    label: getLiteral(field.literal),
                                    value: field.value,
                                })) || [],
                        }));
                    }

                    if (result?.variables?.related) {
                        result?.variables?.related?.map((related) => {
                            newEntityFields.push({
                                label: getLiteral(related?.description),
                                iconType: related.entity,
                                value: related.value,
                            });
                            newFields.push(
                                ...related.sections?.map((section) => ({
                                    label: section.name,
                                    key: related.value,
                                    options:
                                        section.fields.map?.((field) => ({
                                            ...field,
                                            label: getLiteral(field.literal),
                                            value: field.value,
                                        })) || [],
                                })),
                            );
                        });
                    }

                    variables.current = result;

                    setEntityFields(newEntityFields);
                    setFields(newFields);
                })
                .catch((e) => {
                    console.error(e);
                    errorToast({ text: getLiteral('error_generalerror') });
                });
        }, [data, nodePath]);

        const onDone = useCallback(() => {
            onChange(nodePath, {
                ...node,
                name: 'conditions_logic',
                parameters: {
                    conditions: {
                        type: conditionGroups.type,
                        parameters: conditionGroups.parameters.map((group) => ({
                            type: group.type,
                            parameters: group.parameters.map((condition) => {
                                let finalValue = condition.parameters?.value;
                                let finalValueFrom = condition.parameters?.valueFrom;
                                let finalValueTo = condition.parameters?.valueTo;
                                if (
                                    condition.type?.startsWith('number_') &&
                                    ![
                                        'number_equal_calculated',
                                        'number_not_equal_calculated',
                                    ].includes(condition.type)
                                ) {
                                    if (condition.parameters?.variable?.type === 'int') {
                                        finalValue =
                                            finalValue !== undefined
                                                ? parseInt(finalValue, 10)
                                                : finalValue;
                                        finalValueFrom =
                                            finalValueFrom !== undefined
                                                ? parseInt(finalValueFrom, 10)
                                                : finalValueFrom;
                                        finalValueTo =
                                            finalValueTo !== undefined
                                                ? parseInt(finalValueTo, 10)
                                                : finalValueTo;
                                    } else {
                                        finalValue =
                                            finalValue !== undefined
                                                ? parseFloat(finalValue)
                                                : finalValue;
                                        finalValueFrom =
                                            finalValueFrom !== undefined
                                                ? parseFloat(finalValueFrom)
                                                : finalValueFrom;
                                        finalValueTo =
                                            finalValueTo !== undefined
                                                ? parseFloat(finalValueTo)
                                                : finalValueTo;
                                    }
                                } else if (
                                    [
                                        'list_contains',
                                        'list_not_contains',
                                        'multilist_contains',
                                        'multilist_not_contains',
                                    ].includes(condition.type)
                                ) {
                                    finalValue = Array.isArray(finalValue)
                                        ? finalValue.map((item) => parseInt(item, 10))
                                        : finalValue;
                                }

                                return {
                                    ...condition,
                                    parameters: {
                                        ...condition.parameters,
                                        value: finalValue,
                                        valueFrom: finalValueFrom,
                                        valueTo: finalValueTo,
                                    },
                                };
                            }),
                        })),
                    },
                },
                type: 'conditions',
            });
            setTouched(false);
        }, [onChange, nodePath, node, conditionGroups, setTouched]);

        const addConditionGroup = useCallback(() => {
            setTouched(true);
            setConditionGroups((current) => {
                return dot.set(current, 'parameters', (groups) => [
                    ...groups,
                    { type: 'and', parameters: [{}] },
                ]);
            });
        }, [setTouched]);

        const onRemoveConditionGroup = useCallback(
            (index) => {
                setTouched(true);
                setConditionGroups((current) => {
                    return {
                        ...current,
                        parameters: dot.delete(current.parameters, index),
                    };
                });
            },
            [setTouched],
        );

        const onChangeGroup = useCallback(
            (index, value) => {
                setTouched(true);
                setConditionGroups((current) => {
                    return dot.set(current, `parameters.${index}`, value);
                });
            },
            [setTouched],
        );

        const onChangeBool = useCallback(
            (value) => {
                setTouched(true);
                setConditionGroups((current) => {
                    return {
                        ...current,
                        type: value,
                    };
                });
            },
            [setTouched],
        );

        const onDeleteRef = useCallback((ref) => {
            deleteModalRef.current = ref;
        }, []);

        const onDelete = useCallback(() => {
            deleteModalRef.current.open();
        }, []);

        const onDeleteConfirm = useCallback(() => {
            setTouched(false);
            setConditionGroups({ type: 'and', parameters: [{ type: 'and', parameters: [{}] }] });
            return Promise.resolve();
        }, [setTouched]);

        const conditionGroupsMap = useMemo(() => {
            const checkLast = (idx) => idx < conditionGroups.parameters.length - 1;
            return conditionGroups.parameters.map((conditionGroup, idx) => (
                <>
                    <AutomationBuilderConditionGroup
                        key={idx}
                        index={idx}
                        conditionGroup={conditionGroup}
                        onChange={(value) => onChangeGroup(idx, value)}
                        onRemove={() => onRemoveConditionGroup(idx)}
                        entityFields={entityFields}
                        fields={fields}
                        operators={variables.current?.operators}
                    />
                    {checkLast(idx) && (
                        <Text
                            className="fm-automation-builder__bool-logic-text"
                            medium
                            color={theme.colors.actionMinor[500]}
                        >
                            {conditionGroups.type === 'and'
                                ? getLiteral(CONDITION_BOOL_LOGIC_LOCALES.and)
                                : getLiteral(CONDITION_BOOL_LOGIC_LOCALES.or)}
                        </Text>
                    )}
                </>
            ));
        }, [
            conditionGroups.parameters,
            conditionGroups.type,
            entityFields,
            fields,
            onChangeGroup,
            onRemoveConditionGroup,
            theme.colors.actionMinor,
        ]);

        const hasEmptyConditions = useMemo(() => {
            if (!isTouched) return true;
            if (!conditionGroups?.parameters?.length) return true;
            return conditionGroups.parameters.some((group) => {
                if (!group?.parameters?.length) return true;
                return group.parameters.some((condition) => {
                    if (!condition || isEmptyObject(condition)) return true;
                    if (
                        condition?.type === 'number_between' ||
                        condition?.type === 'number_not_between' ||
                        condition?.type === 'datetime_in_range' ||
                        condition?.type === 'datetime_not_in_range'
                    ) {
                        return (
                            !condition?.parameters?.variable ||
                            !condition?.parameters?.valueFrom ||
                            !condition?.parameters?.valueTo
                        );
                    }
                    return (
                        !condition?.parameters?.variable ||
                        (!condition?.parameters?.value &&
                            !CONDITION_TYPE_NO_INPUT.includes(condition.type))
                    );
                });
            });
        }, [conditionGroups?.parameters, isTouched]);

        return (
            <AutomationBuilderSidePanel
                title={getLiteral('label_condition')}
                subtitle={getLiteral('label_conition_desc')}
                onClose={onClose}
                isDoneDisabled={hasEmptyConditions}
                onDone={onDone}
                onDelete={onDelete}
                deleteBtnText={getLiteral('action_delete_conditions')}
            >
                <div className="fm-automation-builder__condition-side-panel">
                    {conditionGroupsMap}
                    <div className="fm-automation-builder__add-condition-group">
                        <Link variation="primary" onClick={addConditionGroup} bold>
                            + {getLiteral('cfm_action_add_group')}
                        </Link>
                        {conditionGroupsMap?.length === 1 && <span></span>}
                        {conditionGroupsMap?.length > 1 && (
                            <AutomationBuilderConditionLogicSelector
                                onChange={onChangeBool}
                                value={conditionGroups.type}
                            />
                        )}
                    </div>
                </div>
                <DeleteModal
                    overlayClassName="fm-automation-builder__modal"
                    title={getLiteral('label_delete_all_conditions')}
                    body={getLiteral('label_delete_all_conditions_desc')}
                    confirmText={getLiteral('action_delete_all_conditions')}
                    onRef={onDeleteRef}
                    onDelete={onDeleteConfirm}
                />
            </AutomationBuilderSidePanel>
        );
    },
);

export default AutomationBuilderConditionsSidePanel;
