import React, { memo, useMemo, useState, useCallback, useRef, useEffect } from 'react';
import SelectHoi from 'components/SelectHoi';
import { Popover } from 'hoi-poi-ui';
import AdvancedSelectMenu from './AdvancedSelectMenu';
import './styles.scss';
import { forwardRef } from 'react';

const AdvancedSelect = forwardRef(
    (
        {
            key,
            menuTitle,
            filterOptions,
            defaultFilter,
            options,
            searchPlaceholder,
            children,
            onChange,
            onChangeVisible,
            value,
            ...props
        },
        ref,
    ) => {
        const [visible, setVisible] = useState(false);
        const contentRef = useRef(null);

        const onClickOutside = useCallback(
            (e) => {
                if (!contentRef.current?.contains(e.target)) {
                    onChangeVisible?.(false);
                    setVisible(false);
                }
            },
            [onChangeVisible],
        );

        const onClick = useCallback(() => {
            onChangeVisible?.(!visible);
            setVisible(!visible);
        }, [onChangeVisible, visible]);

        const onChangeField = useCallback(
            (option) => {
                onChange(option);
                onChangeVisible?.(false);
                setVisible(false);
            },
            [onChange, onChangeVisible],
        );

        useEffect(() => {
            document.addEventListener('mousedown', onClickOutside);
            return () => {
                document.removeEventListener('mousedown', onClickOutside);
            };
        }, [onClickOutside]);

        const popoverProps = useMemo(
            () => ({
                className: 'fm-advanced-select__popover',
                placement: 'bottomLeft',
                content: (
                    <div
                        ref={(cRef) => {
                            if (ref) ref.current = cRef;
                            contentRef.current = cRef;
                        }}
                    >
                        <AdvancedSelectMenu
                            menuTitle={menuTitle}
                            filterOptions={filterOptions}
                            defaultFilter={defaultFilter}
                            options={options}
                            searchPlaceholder={searchPlaceholder}
                            onChange={onChangeField}
                            value={value}
                        />
                    </div>
                ),
                overrides: {
                    root: {
                        visible,
                    },
                },
            }),
            [
                defaultFilter,
                filterOptions,
                menuTitle,
                onChangeField,
                options,
                ref,
                searchPlaceholder,
                value,
                visible,
            ],
        );

        const overrides = useMemo(
            () => ({
                'react-select': {
                    menuIsOpen: false,
                },
            }),
            [],
        );

        return (
            <Popover {...popoverProps}>
                <div className="fm-advanced-select" onClick={onClick}>
                    {!children && (
                        <SelectHoi
                            {...props}
                            onChange={onChangeField}
                            options={options}
                            value={value}
                            overrides={overrides}
                        />
                    )}
                    {children}
                </div>
            </Popover>
        );
    },
);

export default AdvancedSelect;
