import { memo } from 'react';
const CustomizationIllustration = memo((props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={144} height={144} fill="none" {...props}>
        <mask
            id="a"
            width={112}
            height={132}
            x={16}
            y={6}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: 'luminance',
            }}
        >
            <path fill="#fff" d="M127.622 6.776H16.375v130.73h111.247V6.776Z" />
        </mask>
        <g mask="url(#a)">
            <path
                fill="#C6C6C6"
                d="M34.874 47.743c-2.569 0-5.123-.45-7.505-1.332-5.998-2.22-9.584-5.126-10.66-8.636-.727-2.38-.259-4.909 1.32-7.123l5.613-7.871a4.292 4.292 0 0 1 6.003-.999 4.329 4.329 0 0 1 .995 6.026l-5.474 7.676c.569.575 2.08 1.676 5.181 2.824 1.89.7 4.063.95 6.116.703a4.308 4.308 0 0 1 4.783 3.776 4.315 4.315 0 0 1-3.763 4.8c-.867.104-1.739.156-2.609.156Z"
            />
            <path
                fill="#E64D4F"
                d="M27.99 19.016c-.097.058-.522.307-1.053.784a6.45 6.45 0 0 0-.843.919c-.283.38-.563.845-.761 1.418a2.418 2.418 0 0 0 1.486 3.074 2.405 2.405 0 0 0 3.062-1.492l-.035-.012.034.014v-.002l-.034-.012.034.014a.58.58 0 0 1 .078-.12 1.998 1.998 0 0 1 .455-.409l.013-.008.003-.003-.555-.897.527.914.028-.017-.555-.897.527.914a2.42 2.42 0 0 0 .878-3.301 2.402 2.402 0 0 0-3.289-.88Z"
            />
            <path
                fill="#919191"
                d="M67.203 48.528c-.508-.45-1.295-.601-1.946-.468a2.33 2.33 0 0 0-1.839 2.265c0 .19.026.375.07.552h4.467c.06 0 .07-.6.067-.66a2.53 2.53 0 0 0-.82-1.69Z"
            />
            <path
                fill="#919191"
                d="M53.504 63.413v8.075h9.982a2.31 2.31 0 0 1-.07-.552c0-.855.487-1.658 1.243-2.052.725-.378 1.664-.372 2.354.104.769.53 1.162 1.596.94 2.5h10.269v-8.075a2.23 2.23 0 0 1-.797.037 2.326 2.326 0 0 1-1.944-1.825c-.134-.654.017-1.444.465-1.953a2.516 2.516 0 0 1 1.684-.822c.047-.002.436.004.592.037v-8.055H53.504v13.057M102.364 79.482c.047-.002.436.004.592.037v-8.055H92.859c.036-.146.042-.56.04-.608a2.531 2.531 0 0 0-.819-1.69c-.507-.45-.918-1.325-1.58-1.396-2.527-.272-4.512 1.729-3.098 2.865.081.065-1.66.065-.18.278.17.025-.395-.39-.358-.229l-8.626.78v8.055a4.05 4.05 0 0 1 .594-.037c.62.032 1.273.356 1.684.822.448.51.599 1.3.465 1.953a2.327 2.327 0 0 1-1.944 1.825 2.25 2.25 0 0 1-.799-.037v8.075h9.974a2.295 2.295 0 0 0-.046.848c.13.96.873 1.758 1.818 1.952.652.134 1.439-.017 1.946-.467a2.531 2.531 0 0 0 .819-1.69c.002-.054-.006-.549-.051-.643h10.258v-8.075a2.25 2.25 0 0 1-.797.037 2.327 2.327 0 0 1-1.944-1.825c-.134-.654.017-1.444.465-1.953a2.515 2.515 0 0 1 1.684-.822Z"
            />
            <path
                fill="#474747"
                d="M77.429 42.796a2.328 2.328 0 0 1-1.945-1.824c-.133-.654.017-1.444.466-1.953a2.514 2.514 0 0 1 1.684-.822 4.02 4.02 0 0 1 .592.036V30.18H53.508v8.055c.154-.033.546-.04.594-.037.62.031 1.273.355 1.683.822.449.509.6 1.299.466 1.953a2.328 2.328 0 0 1-1.945 1.824 2.233 2.233 0 0 1-.798-.037v8.076h9.982a2.316 2.316 0 0 1-.07-.552c0-.856.487-1.659 1.243-2.053.725-.378 1.664-.371 2.354.104.769.53 1.162 1.596.94 2.501h10.269V42.76a2.24 2.24 0 0 1-.797.036Z"
            />
            <path
                fill="#919191"
                d="M77.704 17.67c.047-.002.436.004.592.037V9.652H68.2c.035-.146.042-.56.04-.608a2.532 2.532 0 0 0-.82-1.69c-.507-.45-1.294-.602-1.945-.468a2.329 2.329 0 0 0-1.839 2.265c0 .173.024.34.06.5H53.578v8.056c.155-.033.547-.04.594-.037.62.032 1.273.355 1.684.822.448.509.599 1.3.465 1.953a2.327 2.327 0 0 1-1.944 1.825 2.239 2.239 0 0 1-.799-.037v8.075h9.974a2.287 2.287 0 0 0-.046.849 2.33 2.33 0 0 0 1.818 1.951c.651.134 1.439-.017 1.946-.467a2.534 2.534 0 0 0 .819-1.69c.002-.054-.006-.548-.051-.643h10.258v-8.075a2.235 2.235 0 0 1-.797.037 2.328 2.328 0 0 1-1.944-1.825c-.134-.654.017-1.444.465-1.953a2.516 2.516 0 0 1 1.684-.822ZM51.164 59.644c-.448.51-.598 1.3-.465 1.953a2.328 2.328 0 0 0 1.945 1.825 2.278 2.278 0 0 0 .862-.05V58.89c0-.06-.598-.07-.657-.067a2.515 2.515 0 0 0-1.685.822ZM116.601 48.528c-.507-.45-1.294-.601-1.946-.468a2.328 2.328 0 0 0-1.839 2.265c0 .19.027.375.07.552h4.468c.059 0 .069-.6.066-.66a2.53 2.53 0 0 0-.819-1.69Z"
            />
            <path
                fill="#919191"
                d="M102.902 63.413v8.075h9.983a2.285 2.285 0 0 1-.07-.552c0-.855.486-1.658 1.243-2.052.725-.378 1.664-.372 2.354.104.768.53 1.162 1.596.94 2.5h10.268v-8.075a2.224 2.224 0 0 1-.797.037 2.326 2.326 0 0 1-1.944-1.825c-.134-.654.017-1.444.465-1.953a2.518 2.518 0 0 1 1.684-.822c.047-.002.437.004.592.037v-8.055h-24.718v13.057"
            />
            <path
                fill="#919191"
                d="M100.563 59.644c-.448.51-.599 1.3-.465 1.953a2.327 2.327 0 0 0 1.944 1.825 2.28 2.28 0 0 0 .862-.05V58.89c0-.06-.598-.07-.657-.067a2.515 2.515 0 0 0-1.684.822Z"
            />
            <path
                fill="#474747"
                d="M78.227 63.413v8.075h9.982a2.31 2.31 0 0 1-.07-.552c0-.855.486-1.658 1.243-2.052.725-.378 1.664-.372 2.353.104.77.53 1.163 1.596.94 2.5h10.27v-8.075a2.24 2.24 0 0 1-.797.037 2.327 2.327 0 0 1-1.945-1.825c-.133-.654.017-1.444.466-1.953a2.512 2.512 0 0 1 1.683-.822c.047-.002.437.004.593.037v-8.055H78.227v13.057"
            />
            <path
                fill="#474747"
                d="M75.887 59.644c-.448.51-.599 1.3-.465 1.953a2.328 2.328 0 0 0 1.944 1.825 2.28 2.28 0 0 0 .862-.05V58.89c0-.06-.598-.07-.657-.067a2.515 2.515 0 0 0-1.684.822Z"
            />
            <path
                fill="#C6C6C6"
                d="M102.339 38.172a4.09 4.09 0 0 1 .593.037v-8.055H92.835c.035-.146.042-.56.039-.608a2.532 2.532 0 0 0-.819-1.69c-.507-.45-1.294-.601-1.946-.467a2.328 2.328 0 0 0-1.838 2.264c0 .173.023.34.06.501H78.214v8.064c-.14-.037-.563-.044-.611-.041a2.515 2.515 0 0 0-1.685.821c-.448.51-.598 1.3-.465 1.954a2.328 2.328 0 0 0 1.944 1.824 2.256 2.256 0 0 0 .816-.04v8.075h9.975a2.293 2.293 0 0 0-.046.848c.13.96.872 1.757 1.818 1.951.651.134 1.438-.017 1.945-.467a2.532 2.532 0 0 0 .82-1.69c.002-.053-.006-.548-.052-.643h10.258v-8.075a2.223 2.223 0 0 1-.796.037 2.328 2.328 0 0 1-1.945-1.825c-.133-.654.017-1.444.465-1.953a2.517 2.517 0 0 1 1.684-.822Z"
            />
            <path
                fill="#4E4E4E"
                d="M31.362 137.506c-.06 0-.12-.002-.181-.004a4.021 4.021 0 0 1-3.83-4.202c.721-16.218 3.092-69.285 3.346-71.198l.04-.305c.258-2.018.69-5.397 3.204-7.335 1.293-.998 2.863-1.406 4.54-1.182 1.675.224 3.258.436 4.729 1.513 1.895 1.389 2.547 3.468 2.935 5.319.666 3.18 13.518 69.634 14.065 72.461a4.026 4.026 0 0 1-3.174 4.72 4.012 4.012 0 0 1-4.702-3.185c-.124-.642-11.513-59.53-13.704-70.65-.313 4.652-2.098 43.971-3.263 70.201a4.019 4.019 0 0 1-4.005 3.847Z"
            />
            <path
                fill="#4E4E4E"
                d="M32.615 136.354a.807.807 0 0 1-.246-1.572l3.613-1.17a.805.805 0 0 1 .493 1.532l-3.613 1.171a.815.815 0 0 1-.247.039ZM57.97 135.343a.805.805 0 0 1-.246-1.572l3.613-1.171a.803.803 0 0 1 1.01.519.807.807 0 0 1-.517 1.014l-3.613 1.17a.787.787 0 0 1-.247.04Z"
            />
            <path
                fill="#E64D4F"
                d="M38.77 37.203c1.2 0 2.172-.976 2.172-2.18 0-1.204-.972-2.18-2.172-2.18-1.2 0-2.172.976-2.172 2.18 0 1.204.972 2.18 2.172 2.18Z"
            />
            <path
                fill="#474747"
                d="M39.033 32.861a2.16 2.16 0 0 0-1.706.532 2.186 2.186 0 0 0-.322 2.901c1.31-.761 2.152-1.805 2.028-3.433Z"
            />
            <path
                fill="#474747"
                d="M36.83 34.713a1.6 1.6 0 1 0-3.14-.609c-.168.871.4 1.713 1.266 1.881a1.6 1.6 0 0 0 1.874-1.272Z"
            />
            <path
                fill="#E64D4F"
                d="M54.26 26.2c-.067-.253-.247-.597-.356-.683a2.42 2.42 0 0 1-.542-3.373 2.402 2.402 0 0 1 3.361-.543c1.606 1.163 2.126 3.08 2.214 3.452l-4.684 1.115.008.032Z"
            />
            <path
                fill="#C6C6C6"
                d="m29.629 44.682 1.89 9.8c.728 3.773 4.02 6.5 7.85 6.5a5.791 5.791 0 0 0 5.759-5.276l1.614-17.684-17.113 6.66Z"
            />
            <path
                fill="#00DB00"
                d="M56.15 18.427c-.54-.738-1.505-1.161-2.484-.882V9.653H43.413c0-.004.001-.009.003-.013.059 0 .069-.6.066-.66a2.53 2.53 0 0 0-.819-1.69c-.507-.45-1.294-.6-1.946-.467a2.33 2.33 0 0 0-1.839 2.265c0 .19.027.375.07.552l.003.013h-9.994v8.68c0-.235.636-.606.813-.698a2.328 2.328 0 0 1 1.158-.258c1.333.05 2.297 1.099 2.24 2.442-.052 1.223-1.075 2.223-2.295 2.242h-.037a2.325 2.325 0 0 1-1.879-.957v9.175h11.568a2.336 2.336 0 0 1-1.266-.893 2.351 2.351 0 0 1-.426-1.116c-.288.457-.79.812-1.6.843a.587.587 0 0 1-.182-.021c-2.034-.577-1.838-3.293.317-3.542a.57.57 0 0 1 .19.01c.874.2 1.363.772 1.502 1.402a2.342 2.342 0 0 1 2.398-1.3c1.101.135 2.144 1.162 2.132 2.305-.005.518-.206 1.024-.518 1.433-.25.326-.802.879-1.252.879h11.846V22c.2.058.411.09.63.09 1.816 0 2.926-2.194 1.854-3.663Z"
            />
            <path
                fill="#E64D4F"
                d="M55.069 24.453c-.068 0-.137-.007-.206-.02-.193-.04-1.918-.421-3.106-1.889a1.05 1.05 0 0 1 .152-1.472 1.04 1.04 0 0 1 1.467.152c.725.896 1.891 1.154 1.903 1.157.56.12.923.672.807 1.236a1.042 1.042 0 0 1-1.017.836Z"
            />
            <path
                fill="#C6C6C6"
                d="M36.97 47.618a4.309 4.309 0 0 1-4.267-3.807 4.315 4.315 0 0 1 3.761-4.8l14.016-1.68c2.844-.341 4.35-1.376 4.783-1.896l-2.345-5.025a4.325 4.325 0 0 1 2.071-5.742 4.296 4.296 0 0 1 5.722 2.079l2.548 5.46c.034.073.066.147.096.222.964 2.408.755 4.987-.589 7.263-1.964 3.327-6.07 5.592-11.266 6.215l-14.015 1.68c-.173.021-.345.031-.515.031Z"
            />
            <path
                fill="#E64D4F"
                d="M28.272 25.26c-.5 0-1.055-.065-1.624-.253a1.048 1.048 0 0 1-.666-1.32c.18-.55.768-.85 1.316-.669 1.093.36 2.213-.056 2.224-.06a1.045 1.045 0 0 1 .756 1.951 5.83 5.83 0 0 1-2.006.351Z"
            />
        </g>
    </svg>
));

CustomizationIllustration.displayName = 'CustomizationIllustration';

export default CustomizationIllustration;
