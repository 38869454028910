import React, { memo, useCallback, useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Text, FieldGroup, TimePicker, DatePicker } from 'hoi-poi-ui';
import SelectFieldV2 from 'containers/components/Fields/Select';
import { getLiteral } from 'utils/getLiteral';
import { getPreviousAndNextDate } from 'utils/dates';
import { getError } from 'utils/Errors';

import './styles.scss';

const DateTimeGroupV2 = memo(
    ({
        label,
        errors,
        readOnly,
        onChange,
        field,
        data,
        minDate,
        maxDate,
        minTime,
        maxTime,
        isMinTimeNow,
        isMaxTimeNow,
        defaultTime,
        version,
    }) => {
        const [isAllDay, setIsAllDay] = useState(data?.[field.fieldIds?.isAllDay] || false);

        const [timezoneSelected, setTimezoneSelected] = useState(
            data?.[field?.fieldIds?.idTimezone] || null,
        );
        const timeFieldsRef = useRef([]);
        const dateFieldRef = useRef(null);
        const isFirstRender = useRef(true);
        const [dateValue, setDateValue] = useState(null);
        const [timeFromValue, setTimeFromValue] = useState(null);
        const [timeToValue, setTimeToValue] = useState(null);
        const oldValues = useRef({});

        const updateValues = useCallback(() => {
            const fieldIds = field?.fieldIds || null;
            if (!fieldIds) return null;
            const newStartDate = data?.[fieldIds?.startDate] || null;
            const newStartTime = data?.[fieldIds?.startTime] || defaultTime || null;
            const newEndTime = data?.[fieldIds?.endTime] || defaultTime || null;
            if (newStartDate) {
                oldValues.current[fieldIds.startDate] = newStartDate;
                setDateValue(newStartDate);
            }
            if (newStartTime) {
                oldValues.current[fieldIds.startTime] = newStartTime;
                setTimeFromValue(newStartTime);
            }
            if (newEndTime) {
                oldValues.current[fieldIds.endTime] = newEndTime;
                setTimeToValue(newEndTime);
            }
        }, [field, data, defaultTime]);

        useEffect(() => {
            if (isFirstRender?.current && data) {
                isFirstRender.current = false;
                if (field?.inputs?.length) {
                    field.inputs.forEach((current) => {
                        if (current?.inputs?.length) {
                            if (timeFieldsRef?.current && !timeFieldsRef?.current?.length) {
                                timeFieldsRef.current = current.inputs;
                            }
                        } else if (current.type === 'date') {
                            dateFieldRef.current = current;
                        }
                    });
                }

                updateValues();
            } else {
                const fieldIds = field.fieldIds;
                if (!fieldIds?.startDate) return;
                let oldStartDateMs = null;
                let startDateMs = null;
                if (oldValues.current[fieldIds.startDate]) {
                    oldStartDateMs = oldValues.current[fieldIds.startDate].getTime();
                }
                if (data[fieldIds.startDate]) {
                    startDateMs = data[fieldIds.startDate].getTime();
                }

                if (oldStartDateMs && startDateMs && oldStartDateMs !== startDateMs) {
                    updateValues();
                }
            }
        }, [data, updateValues, dateValue, field]);

        const handleOnChangeAllDay = useCallback(
            (id) => {
                return () => {
                    onChange && onChange(id)(!isAllDay);
                    const newIsAllDay = !isAllDay;
                    setIsAllDay(newIsAllDay);
                    const intervalChange = field?.inputAttrs?.intervalChange || 30; // in minutes

                    const date = data[dateFieldRef?.current?.id] || new Date();

                    if (newIsAllDay && timeFieldsRef?.current?.length) {
                        timeFieldsRef.current.forEach((current, index) => {
                            if (index === 0) {
                                const previous = new Date(date);
                                previous.setHours(0, 0);

                                if (dateFieldRef?.current?.id) {
                                    onChange && onChange(dateFieldRef.current.id)(previous);
                                }

                                onChange && onChange(current.id)(previous);
                            }
                            if (index === 1) {
                                const next = new Date(date);

                                next.setHours(23, 59);
                                onChange && onChange(current.id)(next);
                            }
                        });
                    } else if (
                        !newIsAllDay &&
                        timeFieldsRef?.current?.length &&
                        dateFieldRef?.current?.id
                    ) {
                        if (!dateFieldRef?.current?.id || !data[dateFieldRef?.current?.id]) return;
                        const dateNow = new Date();
                        date.setHours(dateNow.getHours(), dateNow.getMinutes());

                        const { previous, next } = getPreviousAndNextDate(intervalChange, date);
                        onChange(dateFieldRef.current.id)(previous);
                        timeFieldsRef.current.forEach((current, index) => {
                            if (index === 0) onChange && onChange(current.id)(previous);
                            if (index === 1) onChange && onChange(current.id)(next);
                        });
                    }
                    const afterOnChange = field?.inputAttrs?.afterOnChange || null;
                    afterOnChange && afterOnChange('eventDate');
                };
            },
            [onChange, isAllDay, data, field],
        );

        const updateDate = useCallback((baseDate, dateToConvert) => {
            const hours = dateToConvert.getHours();
            const minutes = dateToConvert.getMinutes();
            let baseCorrected = new Date(baseDate);
            baseCorrected.setHours(hours);
            baseCorrected.setMinutes(minutes);
            const finalDate = new Date(baseCorrected.getTime());
            return finalDate;
        }, []);

        const updateHours = useCallback((baseDate, dateToConvert) => {
            const finalDate = new Date(dateToConvert);
            const hours = baseDate.getHours();
            const minutes = baseDate.getMinutes();
            finalDate.setHours(hours, minutes, 0, 0);
            return finalDate;
        }, []);

        const getAreDatesDifferent = useCallback((dateOne, dateTwo) => {
            if (!dateOne || !dateTwo) return true;
            const year1 = dateOne.getFullYear();
            const month1 = dateOne.getMonth();
            const date1 = dateOne.getDate();
            const one = `${date1}/${month1}/${year1}`;
            const year2 = dateTwo.getFullYear();
            const month2 = dateTwo.getMonth();
            const date2 = dateTwo.getDate();
            const two = `${date2}/${month2}/${year2}`;
            if (one !== two) return true;
            return false;
        }, []);

        const getValueHasChanged = useCallback((newValue, oldValue) => {
            if (!newValue && !oldValue) return false;
            if (newValue && oldValue && newValue.getTime() === oldValue.getTime()) return false;
            return true;
        }, []);

        const handleOnChange = useCallback(
            (id) => {
                return (value, action) => {
                    let newValue = null;
                    if (value) newValue = new Date(value);

                    const fieldIds = field.fieldIds;

                    let shouldUpdateStartDate = false;
                    let shouldUpdateStartTime = false;
                    let shouldUpdateEndTime = false;

                    let newStartDate = null;
                    let newStartTime = null;
                    let newEndTime = null;

                    const intervalChange = field?.inputAttrs?.intervalChange || 30; // in minutes

                    if (id === fieldIds?.startDate) {
                        if (!getValueHasChanged(newValue, dateValue)) return;
                        if (newValue) {
                            if (timeFromValue) {
                                // update the startDate with the startTime time
                                shouldUpdateStartDate = true;
                                newStartDate = updateHours(timeFromValue, newValue);

                                //update the startTime with the updated date
                                shouldUpdateStartTime = true;
                                newStartTime = updateDate(newValue, timeFromValue);
                            } else {
                                // update startDate with newValue
                                shouldUpdateStartDate = true;
                                newStartDate = newValue;
                            }
                            if (timeToValue) {
                                // update the endTime with the updated date
                                shouldUpdateEndTime = true;
                                newEndTime = updateDate(newValue, timeToValue);
                            }
                        } else {
                            shouldUpdateStartDate = true;
                            newStartDate = null;
                        }
                    }

                    if (id === fieldIds?.startTime) {
                        if (!getValueHasChanged(newValue, timeFromValue)) return;
                        if (newValue) {
                            if (dateValue && getAreDatesDifferent(dateValue, newValue)) {
                                // update startTime with the updated date
                                shouldUpdateStartTime = true;
                                newStartTime = updateDate(dateValue, newValue);
                                // update startDate with the updated startTime
                                shouldUpdateStartDate = true;
                                newStartDate = newStartTime;
                                if (
                                    timeToValue &&
                                    newStartTime.getTime() >=
                                        new Date(timeToValue.setSeconds(0, 0)).getTime()
                                ) {
                                    // update endTime with updated startTime as a base that already has
                                    // updated startDate plus interval
                                    shouldUpdateEndTime = true;
                                    const newTimeMs =
                                        newStartTime.getTime() + 1000 * 60 * intervalChange;
                                    newEndTime = new Date(newTimeMs);
                                } else {
                                    // update endTime with the updated startTime that already has updated startDate
                                    shouldUpdateEndTime = true;
                                    newEndTime = updateDate(newStartTime, timeToValue);
                                }
                            } else {
                                // update startTime
                                shouldUpdateStartTime = true;
                                newStartTime = newValue;

                                // update startDate
                                shouldUpdateStartDate = true;
                                newStartDate = newValue;

                                if (
                                    timeToValue &&
                                    (newStartTime.getTime() >=
                                        new Date(timeToValue.setSeconds(0, 0)).getTime() ||
                                        getAreDatesDifferent(newStartTime, timeToValue))
                                ) {
                                    // update endTime with startTime plus interval
                                    shouldUpdateEndTime = true;
                                    const newTimeMs =
                                        newStartTime.getTime() + 1000 * 60 * intervalChange;
                                    newEndTime = new Date(newTimeMs);
                                }
                            }
                        } else {
                            shouldUpdateStartTime = true;
                            newStartTime = null;
                        }
                    }

                    if (id === fieldIds?.endTime) {
                        if (!getValueHasChanged(newValue, timeToValue)) return;
                        if (newValue) {
                            if (dateValue && getAreDatesDifferent(dateValue, newValue)) {
                                // update endTime with updatedDate
                                shouldUpdateEndTime = true;
                                newEndTime = updateDate(dateValue, newValue);

                                if (
                                    timeFromValue &&
                                    newEndTime.getTime() <=
                                        new Date(timeFromValue.setSeconds(0, 0)).getTime()
                                ) {
                                    // update startTime with endTime less interval
                                    shouldUpdateStartTime = true;
                                    const newTimeMs =
                                        newEndTime.getTime() - 1000 * 60 * intervalChange;
                                    newStartTime = new Date(newTimeMs);

                                    // update startDate with startTime that contains startDate
                                    shouldUpdateStartDate = true;
                                    newStartDate = newStartTime;
                                }
                            } else {
                                // update endTime
                                shouldUpdateEndTime = true;
                                newEndTime = newValue;

                                if (
                                    timeFromValue &&
                                    newEndTime.getTime() <=
                                        new Date(timeFromValue.setSeconds(0, 0)).getTime()
                                ) {
                                    // update startTime less interval
                                    shouldUpdateStartTime = true;
                                    const newTimeMs =
                                        newEndTime.getTime() - 1000 * 60 * intervalChange;
                                    newStartTime = new Date(newTimeMs);

                                    // update startDate with startTime
                                    shouldUpdateStartDate = true;
                                    newStartDate = newStartTime;
                                }
                            }
                        } else {
                            shouldUpdateEndTime = true;
                            newEndTime = null;
                        }
                    }

                    if (shouldUpdateStartDate) {
                        setDateValue(newStartDate);
                        onChange &&
                            fieldIds?.startDate &&
                            onChange(fieldIds.startDate)(newStartDate);
                    }
                    if (shouldUpdateStartTime) {
                        setTimeFromValue(newStartTime);
                        onChange &&
                            fieldIds?.startTime &&
                            onChange(fieldIds.startTime)(newStartTime);
                    }
                    if (shouldUpdateEndTime) {
                        setTimeToValue(newEndTime);
                        onChange && fieldIds?.endTime && onChange(fieldIds.endTime)(newEndTime);
                    }
                };
            },
            [
                dateValue,
                field,
                onChange,
                timeFromValue,
                timeToValue,
                updateDate,
                updateHours,
                getAreDatesDifferent,
                getValueHasChanged,
            ],
        );

        const handleOnChangeTimezone = useCallback(
            (id) => {
                return (value) => {
                    setTimezoneSelected(value);
                    onChange && onChange(id)(value);
                };
            },
            [onChange],
        );

        const fieldsComp = useMemo(() => {
            if (!field?.inputs?.length) return null;

            return field.inputs.reduce((obj, current, index) => {
                if (!obj.fields) obj.fields = [];
                if (!obj.fieldsProps) obj.fieldsProps = [];
                if (current.type === 'checkbox') {
                    obj.push(
                        <div key={index} className="fm-field-date-time-group__all-day">
                            <Checkbox
                                color="actionMinor"
                                checked={isAllDay}
                                onChange={handleOnChangeAllDay(current.id)}
                            />
                            <div className="fm-field-date-group-new-all-day__label">
                                <Text>{getLiteral('label_calendarallday')}</Text>
                            </div>
                        </div>,
                    );
                } else if (current?.inputs?.length && !isAllDay) {
                    const timeFields = current.inputs.reduce((objTime, item, i) => {
                        if (!objTime?.inputs) objTime.inputs = [];
                        if (!objTime?.inputProps) objTime.inputProps = [];

                        const newTimeFromValue = timeFromValue ? new Date(timeFromValue) : null;
                        const newTimeToValue = timeToValue ? new Date(timeToValue) : null;
                        let timeValue = i === 0 ? newTimeFromValue : newTimeToValue;
                        let className = '';
                        if (current.inputs.length > 1 && i !== current.inputs.length - 1) {
                            className = 'fm-field-date-time__field-padding';
                        }

                        const interval = field?.inputAttrs?.interval || 30; // in minutes

                        const timeError = (errors && errors[item.id]) || null;

                        objTime.inputs.push(TimePicker);
                        objTime.inputProps.push({
                            // className: className,
                            // useBase: false,
                            value: timeValue,
                            onChange: handleOnChange(item.id),
                            interval: interval,
                            isClearable: false,
                            error: getError(timeError),
                            minTime: minTime,
                            maxTime: maxTime,
                            isMinTimeNow: isMinTimeNow,
                            isMaxTimeNow: isMaxTimeNow,
                        });

                        return objTime;
                    }, {});

                    obj.push(
                        <FieldGroup
                            label=" "
                            inputs={timeFields.inputs}
                            inputProps={timeFields.inputProps}
                            labelMode="horizontal"
                            isFullWidth
                        />,
                    );
                } else if (current.type === 'date') {
                    const newDateValue = dateValue ? new Date(dateValue) : null;
                    obj.push(
                        <DatePicker
                            key={index}
                            label={label}
                            labelMode="horizontal"
                            value={newDateValue}
                            error={getError(errors[current.id])}
                            disabled={readOnly}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={handleOnChange(current.id)}
                            isFullWidth
                        />,
                    );
                } else if (current.type === 'timezone') {
                    obj.push(
                        <SelectFieldV2
                            value={timezoneSelected}
                            list="fm_iana_time_zone"
                            isClearable={false}
                            onChange={handleOnChangeTimezone(current.id)}
                            label=" "
                            labelMode="horizontal"
                        />,
                    );
                }
                return obj;
            }, []);
        }, [
            field.inputs,
            field?.inputAttrs?.interval,
            isAllDay,
            handleOnChangeAllDay,
            timeFromValue,
            timeToValue,
            errors,
            handleOnChange,
            minTime,
            maxTime,
            isMinTimeNow,
            isMaxTimeNow,
            dateValue,
            readOnly,
            minDate,
            maxDate,
            timezoneSelected,
            handleOnChangeTimezone,
            label,
        ]);

        return fieldsComp;
    },
);

DateTimeGroupV2.propTypes = {
    label: PropTypes.string,
    mandatory: PropTypes.bool,
    errors: PropTypes.string,
    readOnly: PropTypes.string,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    isBulkAction: PropTypes.bool,
    onChange: PropTypes.func,
    field: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.oneOf(['dateTimeGroup']),
        label: PropTypes.string,
        fieldIds: PropTypes.shape({
            startDate: PropTypes.string,
            startTime: PropTypes.string,
            endTime: PropTypes.string,
            isAllDay: PropTypes.string,
        }),
        inputs: PropTypes.arrayOf(PropTypes.object),
    }),
    data: PropTypes.object,
};

export default DateTimeGroupV2;
