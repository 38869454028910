import mixpanel from 'mixpanel-browser';
import branch from 'branch-sdk';
import Context from 'managers/Context';
import { IS_PRODUCTION } from 'constants/Environment';
import { shallowEqual } from 'utils/objects';
import { EVENT_TYPES } from 'constants/Constants';

let LAST_EVENT;
let TRACKING_INIT = false;
let MIXPANEL_TRAITS = {};

function isEventDuplicated(event) {
    const isEqual = LAST_EVENT && shallowEqual(LAST_EVENT, event);
    LAST_EVENT = event;
    return isEqual;
}

function getToken() {
    const token = IS_PRODUCTION
        ? 'f70da51df62912ad4b8614931a549bea'
        : '0ab711bd68e25faaf3a687f02a76fd15';
    return token;
}

export function init(traits = {}) {
    const token = getToken();

    mixpanel.init(token);

    mixpanel.identify(
        `${Context.config?.userData?.idUsuario}_${Context?.config?.userData?.idImplementacion}`,
    );

    let fullName = Context?.config?.userData?.nombre || '';
    if (Context?.config?.userData?.apellidos) {
        fullName = `${fullName} ${Context?.config?.userData?.apellidos}`;
    }

    mixpanel.people.set({
        implementation: Context?.config?.userData?.idImplementacion,
        plan: Context?.config?.billingPlatformPlanCode,
        $email: Context?.config?.userData?.email || '',
        $name: fullName || '',
    });

    TRACKING_INIT = true;
    MIXPANEL_TRAITS = traits;
}

export function logEvent({ event, submodule, functionality, checkDuplicate = false }) {
    if (!IS_PRODUCTION) {
        console.info('LOGEVENT', { event, submodule, functionality });
    }

    if (checkDuplicate && isEventDuplicated({ event, submodule, functionality })) return;

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    if (TRACKING_INIT) {
        mixpanel.track(event, {
            ...MIXPANEL_TRAITS,
            submodule,
            functionality,
            version: process.env.VERSION,
            platform: 'web',
            screenWidth,
            screenHeight,
            viewportWidth,
            viewportHeight,
        });
    }
}

export function logBranchEvent(event) {
    if (!IS_PRODUCTION) {
        console.info('LOG_BRANCH_EVENT', event);
    }

    branch.init(
        'key_live_nvfEMpH1gX0IR5x4ESCQ2bdjBCkzyFYh',
        {
            identity: `${Context.config?.userData?.idUsuario}_${Context?.config?.userData?.idImplementacion}`,
        },
        (err) => {
            if (err) return;
            branch.logEvent(event);
        },
    );
}

export function trackEvent({ entity, entityId, idEventType }) {
    if (!entity || !entity.objectTypeId || !idEventType) {
        if (!entityId && idEventType !== EVENT_TYPES.create) {
            if (!entityId) console.error('The parameter "entityId" should be provided');
            return;
        }
        if (!entity) console.error('The parameter "entity" should be provided');
        if (!entity.objectTypeId)
            console.error('The parameter "entity" should contain "objectTypeId"');
        if (!idEventType) console.error('The parameter "idEventType" should be provided');
        return;
    }

    // if ever needed:
    // -> idObjectType is related with tblTiposObjetos
    // -> idEventType is related with tblTiposEventos
    const params = {
        idObjectType: entity.objectTypeId,
        entityId: entityId || '',
        idEventType,
    };
    Context.domainManager.setTrackingEvents(params);
}

// New methods for Onboarding tracking
export function initWithoutId() {
    const token = getToken();
    mixpanel.init(token);
    mixpanel.identify();
    TRACKING_INIT = true;
}

export function identify(idUsuario, idImplementacion) {
    if (!idUsuario && !idImplementacion) return;
    const token = getToken();
    mixpanel.init(token);
    mixpanel.identify(`${idUsuario}_${idImplementacion}`);
    TRACKING_INIT = true;
}
