import React, { Fragment, useCallback, memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import { COMPANIES } from 'constants/Entities';
import { ensureRoute } from 'utils/routes';
import { getSrcCompanyChipAlternative } from 'utils/getSrcAvatar';
import CompanyInfoWindowRow from './CompanyInfoWindowRow';
import { UserChip } from 'containers/components/chips';

import './styles.scss';

const propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.object,
    standardFieldsConfiguration: PropTypes.object,
};

const mapStateToProps = (state) => {
    const mapState = state.entityMap;
    const entityMapState = (mapState && mapState[COMPANIES.entity]) || {};
    const infoWindowState = entityMapState.infoWindow || null;

    return {
        standardFieldsConfiguration:
            state.config.standardFieldsSchemaMap[COMPANIES.extraFieldName] || null,
        loading: infoWindowState.loading || false,
        data: infoWindowState.data || null,
    };
};

const CompanyInfoWindow = ({ loading, data, standardFieldsConfiguration }) => {
    const openCompany = useCallback(() => {
        if (!data?.Id) return;
        ensureRoute(`/companies/map/${data.Id}`);
    }, [data]);

    if (!loading && !data) return null;

    const showName =
        standardFieldsConfiguration && standardFieldsConfiguration.nombre
            ? !standardFieldsConfiguration.nombre.isHidden
            : true;
    const showEnvironment =
        standardFieldsConfiguration && standardFieldsConfiguration.idsucursal
            ? !standardFieldsConfiguration.idsucursal.isHidden
            : true;
    const showCalificacion =
        standardFieldsConfiguration && standardFieldsConfiguration.idcalificacion
            ? !standardFieldsConfiguration.idcalificacion.isHidden
            : true;
    const showType =
        (standardFieldsConfiguration && standardFieldsConfiguration.idtipo
            ? !standardFieldsConfiguration.idtipo.isHidden
            : true) &&
        data &&
        data.CompanyType;
    const showStatus =
        (standardFieldsConfiguration && standardFieldsConfiguration.idestadoempresa
            ? !standardFieldsConfiguration.idestadoempresa.isHidden
            : true) &&
        data &&
        data.CompanyState;
    const showResponsible =
        (standardFieldsConfiguration && standardFieldsConfiguration.idComercial
            ? !standardFieldsConfiguration.idComercial.isHidden
            : true) &&
        data &&
        data.IdResponsable;

    const logoSrc = data ? getSrcCompanyChipAlternative(data.idLogo) : null;

    return (
        <div className="company-info-window">
            {loading && (
                <div className="company-info-window__loader">
                    <Loader type="small" />
                </div>
            )}
            {!loading && (
                <Fragment>
                    <div className="company-info-window-block company-info-window__header">
                        <div className="company-info-window__header-column">
                            <div className="company-info-window__photo">
                                <img
                                    src={logoSrc.src || logoSrc.fallbackSrc}
                                    onError={(e) => {
                                        e.target.src = logoSrc.fallbackSrc;
                                    }}
                                />
                            </div>
                            <div className="company-info-window__basic-info">
                                {showName && (
                                    <div
                                        className="company-info-window__name"
                                        onClick={openCompany}
                                    >
                                        {data.Name}
                                    </div>
                                )}
                                {showEnvironment && (
                                    <div className="company-info-window__environment">
                                        {data.Environment}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="company-info-window__header-column">
                            {showCalificacion && (
                                <div className="company-info-window__calificacion">
                                    {data.Calificacion}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="company-info-window-block company-info-window__content">
                        {showType && (
                            <CompanyInfoWindowRow
                                className="company-info-window__type"
                                label="label_account_type"
                            >
                                {data.CompanyType}
                            </CompanyInfoWindowRow>
                        )}
                        {showStatus && (
                            <CompanyInfoWindowRow
                                className="company-info-window__state"
                                label="label_status"
                            >
                                {data.CompanyState}
                            </CompanyInfoWindowRow>
                        )}
                        {showResponsible && (
                            <CompanyInfoWindowRow
                                className="company-info-window__responsible"
                                label="label_responsible"
                            >
                                <UserChip
                                    idUser={data.IdResponsable}
                                    username={data.Responsible}
                                    remotePopover={true}
                                />
                            </CompanyInfoWindowRow>
                        )}
                    </div>
                </Fragment>
            )}
        </div>
    );
};

CompanyInfoWindow.propTypes = propTypes;

export default memo(connect(mapStateToProps)(CompanyInfoWindow));
