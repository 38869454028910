import { memo, useContext, useMemo, useState, useEffect } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Text, Divider, Spacer, useTheme } from 'hoi-poi-ui';

import { getLiteral } from 'utils/getLiteral';
import { isEmptyObject } from 'utils/objects';
import { errorToast } from 'utils/toast';

import {
    findNodeByType,
    AUTOMATION_ENTITIES,
    ACTION_ICONS,
    ACTION_OPERATORS,
    ACTION_OPERATORS_NO_INPUT,
} from '../../../../utils';

import { AutomationBuilderCanvasContext } from '../../AutomationBuilderCanvasContext';
import AutomationBuilderBox from '../../components/AutomationBuilderBox';
import ActionBoxValue from './ActionBoxValue';

const AutomationBuilderActionBox = memo((props) => {
    const theme = useTheme();
    const { data, getActions } = useContext(AutomationBuilderCanvasContext);
    const [actions, setActions] = useState([]);
    const nodeData = useMemo(() => findNodeByType(data?.scene, props.id), [data?.scene, props.id]);

    useEffect(() => {
        getActions()
            .then((result) => {
                setActions(result);
            })
            .catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_generalerror') });
            });
    }, [getActions]);

    const content = useMemo(() => {
        if (!actions?.length) return;
        const entity = AUTOMATION_ENTITIES.find(
            (entity) => entity.trueName === nodeData?.parameters?.entity?.entity,
        );

        if (!entity) return;

        const action = actions?.find((a) => a.value === nodeData?.name);

        return (
            <>
                <Text type="subtitle" bold>
                    {`${action.label} ${getLiteral(entity?.labels?.singular)?.toLowerCase()}: ${getLiteral(nodeData?.parameters.entity.literal)}`}
                </Text>
                <Spacer y={2} />
                <Divider />
                <Spacer y={2} />
                {nodeData?.parameters?.values.map((value) => (
                    <div className="fm-automation-builder__action-box__field">
                        <Text type="subtitle" bold>
                            {getLiteral(value.field.literal)}
                        </Text>
                        <Spacer y={2} />
                        <div className="fm-automation-builder__action-box__field-row">
                            <Text color={theme.colors.textLight.secondary}>
                                {getLiteral(ACTION_OPERATORS[value?.value?.type])}
                                {ACTION_OPERATORS_NO_INPUT.includes(value?.value?.type) ? '' : ':'}
                            </Text>
                            <ActionBoxValue item={value} />
                        </div>
                        <Spacer y={2} />
                    </div>
                ))}
            </>
        );
    }, [actions, nodeData, theme]);

    return (
        <>
            <Handle type="target" position={Position.Top} id="top" isConnectable={false} />
            <AutomationBuilderBox
                {...props}
                className="fm-automation-builder__action-box"
                title={getLiteral('label_action')}
                icon={
                    nodeData?.parameters?.entity?.entity
                        ? nodeData?.parameters?.entity?.entity
                        : 'automations'
                }
                secondIcon={
                    !isEmptyObject(nodeData?.parameters) ? ACTION_ICONS[nodeData?.name] : ''
                }
                emptyPlaceholder={getLiteral('action_automation_add_action')}
            >
                {content}
            </AutomationBuilderBox>
        </>
    );
});

export default AutomationBuilderActionBox;
