import React, { memo } from 'react';
import { LottieGif } from '@web/web5';
import metadata from 'lib/metadata';

const styles = {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
};

const bottomLogoStyles = {
    position: 'fixed',
    bottom: '10%',
};

const stylesImg = {
    width: 50,
    height: 'auto',
};

const TabLoader = () => {
    return (
        <div style={styles}>
            {metadata.spinnerGif && (
                <img style={stylesImg} src={metadata.spinnerGif} alt="Loading" />
            )}
            {metadata.loaderLottie && (
                <LottieGif width="200px" height="200px" src={metadata.loaderLottie} />
            )}
            {metadata.bottomLoaderLogo && (
                <div style={bottomLogoStyles}>
                    <img alt="Logo" src={metadata.bottomLoaderLogo} width={300} />
                </div>
            )}
        </div>
    );
};

export default memo(TabLoader);
