import { axios } from './axios';
import { handleErrors } from './utils';
import sha1 from 'sha1';

export function getAutomations() {
    return new Promise((resolve, reject) => {
        axios
            .get(`automations/v1`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getAutomationsLog(body) {
    return new Promise((resolve, reject) => {
        axios
            .post(`automations/v1/logs`, body)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getAutomationsLogCount(body) {
    return new Promise((resolve, reject) => {
        axios
            .post(`automations/v1/logs/count`, body)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getAutomationLog(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`automations/v1/logs/${id}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getAutomation(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`automations/v1/${id}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function enableAutomation(id, enabled) {
    return new Promise((resolve, reject) => {
        axios
            .put(`automations/v1/${id}/enabled`, { enabled })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function putAutomation(id, data) {
    return new Promise((resolve, reject) => {
        axios
            .put(`automations/v1/${id}`, data)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function createAutomation(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(`automations/v1`, data)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function deleteAutomation(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`automations/v1/${id}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

const AUTOMATION_VARIABLES_CACHE = {};
export function getAutomationVariables(body) {
    // Implementing simple previous value caching
    const cacheKey = sha1(JSON.stringify(body));
    if (AUTOMATION_VARIABLES_CACHE[cacheKey]) {
        return Promise.resolve(AUTOMATION_VARIABLES_CACHE[cacheKey]);
    }
    return new Promise((resolve, reject) => {
        axios
            .post(`automations/v1/variables`, body)
            .then(({ data }) => {
                AUTOMATION_VARIABLES_CACHE = { cacheKey: data };
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getAutomationActions() {
    return new Promise((resolve, reject) => {
        axios
            .get(`automations/v1/actions`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}
