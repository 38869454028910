import React, { PureComponent, Fragment } from 'react';
import { getLiteral } from 'utils/getLiteral';
import { Button } from 'hoi-poi-ui';
import classNames from 'classnames';

import EntityCrudDeleteConfirmModal from './EntityCrudDeleteConfirmModal';

class EntityCrudDelete extends PureComponent {
    state = {
        isConfirmModalOpen: false,
    };

    onClickDelete = () => {
        this.setState({ isConfirmModalOpen: true });
    };

    onCloseModal = () => {
        this.setState({ isConfirmModalOpen: false });
    };

    render() {
        const {
            className,
            canDelete,
            buttonText = 'action_delete',
            getDeleteEntityLiteral,
            children,
            ...props
        } = this.props;
        const { isConfirmModalOpen } = this.state;

        if (!canDelete) return null;

        return (
            <Fragment>
                <Button
                    className={classNames('entity-crud-delete', className)}
                    size="big"
                    type="secondary-error"
                    onClick={this.onClickDelete}
                    isFullWidth
                >
                    {getLiteral(getDeleteEntityLiteral ? getDeleteEntityLiteral() : buttonText)}
                </Button>
                <EntityCrudDeleteConfirmModal
                    isOpen={isConfirmModalOpen}
                    onClose={this.onCloseModal}
                    {...props}
                >
                    {children}
                </EntityCrudDeleteConfirmModal>
            </Fragment>
        );
    }
}

export default EntityCrudDelete;
