import React, { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfigActions, ErrorActions } from 'actions';
import { InvitationForm } from '@web/web5';
import Logo from 'components/Logo';
import AbsoluteLoader from 'components/AbsoluteLoader';
import metadata from 'lib/metadata';

import { createUser, getPasswordPolicies } from 'services/Onboarding';
import { parsePolicies } from 'utils/fm';

import './styles.scss';

const mapDispatchToProps = (dispatch) => ({
    login: bindActionCreators(ConfigActions, dispatch).login,
    showError: bindActionCreators(ErrorActions, dispatch).showError,
});

const getParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { token, email, license } = Object.fromEntries(urlSearchParams.entries());
    return { token, email, license };
};

const AcceptInvite = memo(({ login, showError }) => {
    const [policies, setPolicies] = useState([]);

    const handleError = useCallback(() => {
        showError({
            title: 'label_general_error_screen_title',
            description: 'label_general_error_screen_explanation',
        });
    }, [showError]);

    useEffect(() => {
        const { token } = getParams();
        if (!token) return handleError();

        getPasswordPolicies(token)
            .then((data) => {
                if (!data?.id) return showError();
                setPolicies(parsePolicies(data));
            })
            .catch((error) => console.error(error));
    }, [handleError, showError]);

    const formProps = useMemo(() => {
        const { email, license } = getParams();
        if (!email) return handleError();
        return {
            logo: <Logo width={metadata.mainLogoWidth} />,
            policies,
            email,
            settings: metadata.settings.inviteForm,
            onSend: createUser,
            onLogin: login,
            license,
        };
    }, [handleError, login, policies]);

    return (
        <div className="fm-accept-invite">
            {!policies.length ? (
                <AbsoluteLoader size="massive" />
            ) : (
                <InvitationForm {...formProps} />
            )}
        </div>
    );
});

export default connect(null, mapDispatchToProps)(AcceptInvite);
