import React, { memo } from 'react';
import classnames from 'classnames';
import { Text, Spacer, Divider, useTheme } from 'hoi-poi-ui';
import './styles.scss';

const SettingsLayout = memo(({ title, description, className, withoutDivider, children }) => {
    const theme = useTheme();
    return (
        <div className={classnames('fm-settings-layout', className)}>
            <div className="fm-settings-layout__header">
                <Text type="h5" className="fm-settings-layout__title">
                    {title}
                </Text>
                <Spacer y={2} />
                {description && (
                    <Text
                        className="fm-settings-layout__description"
                        color={theme.colors.utility.textSecondary}
                    >
                        {description}
                    </Text>
                )}
            </div>
            <Spacer y={4} />
            {!withoutDivider && <Divider />}
            <Spacer y={4} />
            <div className="fm-settings-layout__content">{children}</div>
        </div>
    );
});

export default SettingsLayout;
