import React, { memo } from 'react';
import { Text } from 'hoi-poi-ui';

import metadata from 'lib/metadata';

import Logo from 'components/Logo';
import useLoginLiterals from 'utils/literals/useLoginLiterals';

import './styles.scss';
const SignatureConfirm = memo(() => {
    const { loading, getLiteral } = useLoginLiterals();

    if (loading) return null;

    return (
        <div className="signature-confirm__container">
            <div className="signature-confirm__logo">
                <Logo width={metadata.mainLogoWidth} />
            </div>
            <Text className="signature-confirm__title" type="h6">
                {getLiteral('label_thank_you')}
            </Text>
            <Text type="subtitle1">{getLiteral('success_signature_process_completed')}</Text>
        </div>
    );
});

export default SignatureConfirm;
