import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { Menus, Icon, useTheme } from 'hoi-poi-ui';
import {
    ACTIVITIES,
    AGENDA,
    CONTACTS,
    COMPANIES,
    OPPORTUNITIES,
    SALESORDERS,
} from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import useCrudModal from 'containers/components/EntityCrud/hooks/useCrudModal';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';

import './styles.scss';

const mapStateToProps = (state) => {
    const crudPermissions = state.config.permission.crud_permission;
    return {
        crudPermissions,
        isKpisPremium: state?.config?.permission?.kpisPremium || false,
        allowSalesOrders: state?.config?.permission?.salesOrders,
    };
};

const CreateGlobal = memo(({ crudPermissions, isKpisPremium, allowSalesOrders }) => {
    const {
        crudAccount,
        crudContact,
        crudActivity,
        crudActivityWhatsApp,
        crudEvent,
        crudTask,
        crudOpportunity,
        crudSalesOrder,
        crudGoal,
    } = useCrudModal();
    const { canUseEmail, showEditor } = useEmailEditor();
    const theme = useTheme();

    const items = useMemo(() => {
        let activeItems = [];

        if (crudPermissions?.[COMPANIES.permission]?.create) {
            activeItems.push({
                title: getLiteral('label_account'),
                icon: <Icon name="accounts" color="currentColor" />,
                onClick: crudAccount,
            });
        }

        if (crudPermissions?.[CONTACTS.permission]?.create) {
            activeItems.push({
                title: getLiteral('label_contact'),
                icon: <Icon name="contacts" color="currentColor" />,
                onClick: crudContact,
            });
        }

        if (crudPermissions?.[ACTIVITIES.permission]?.create) {
            activeItems.push({
                title: getLiteral('label_activity'),
                icon: <Icon name="activities" color="currentColor" />,
                onClick: crudActivity,
            });
            activeItems.push({
                title: getLiteral('title_whatsapp_activity'),
                icon: <Icon name="whatsappGreen" />,
                onClick: crudActivityWhatsApp,
            });
        }

        if (crudPermissions?.[AGENDA.permission]?.create) {
            activeItems.push({
                title: getLiteral('label_event'),
                icon: <Icon name="calendar" color="currentColor" />,
                onClick: crudEvent,
            });
            activeItems.push({
                title: getLiteral('label_task'),
                icon: <Icon name="tasks" color="currentColor" />,
                onClick: crudTask,
            });
        }

        if (crudPermissions?.[OPPORTUNITIES.permission]?.create) {
            activeItems.push({
                title: getLiteral('title_opportunity'),
                icon: <Icon name="opportunities" color="currentColor" />,
                onClick: crudOpportunity,
            });
        }

        if (allowSalesOrders && crudPermissions?.[SALESORDERS.permission]?.create) {
            activeItems.push({
                title: getLiteral('title_salesorders'),
                icon: <Icon name="salesOrders" color="currentColor" />,
                onClick: crudSalesOrder,
            });
        }

        if (isKpisPremium) {
            activeItems.push({
                title: getLiteral('label_goal'),
                icon: <Icon name="goals" color="currentColor" />,
                onClick: crudGoal,
            });
        }

        if (canUseEmail) {
            activeItems.push({
                title: getLiteral('placeholder_send_email'),
                icon: <Icon name="email" color="currentColor" />,
                onClick: () => showEditor({ entityForTracking: 'emails' }),
            });
        }

        return activeItems;
    }, [
        crudPermissions,
        allowSalesOrders,
        isKpisPremium,
        canUseEmail,
        crudAccount,
        crudContact,
        crudActivity,
        crudActivityWhatsApp,
        crudEvent,
        crudTask,
        crudOpportunity,
        crudSalesOrder,
        crudGoal,
        showEditor,
    ]);

    if (!items.length) return null;

    return (
        <div className="fm-create-global-container">
            <Menus
                className="fm-create-global"
                classNameMenu="fm-create-global-submenu"
                icon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
                        <circle cx="20" cy="20" r="12" fill={theme.colors.actionMajor[500]} />
                        <path
                            fill={theme.colors.primary.white}
                            fillRule="evenodd"
                            d="M18.8 21.2V26h2.462v-4.8H26v-2.462h-4.738V14H18.8v4.738H14V21.2h4.8Z"
                            clipRule="evenodd"
                        />
                    </svg>
                }
                items={items}
                size="medium"
            />
        </div>
    );
});

export default connect(mapStateToProps)(CreateGlobal);
