import { useCallback, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getEntityTrueNameFromString } from 'utils/getEntityFromString';
import { getDetailRoute } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { formatLargeNumber } from 'utils/amount';
import { getNumberAsLocaleNumber } from 'utils/numbers';
import { getVar } from 'utils/color';
import colors from 'constants/colors';

const CHART_REGEX = /\[fm_report=(['"])(.*?)\1\]/;
const CHART_REGEX_GLOBAL = /\[fm_report=(['"])(.*?)\1\]/g;
const DETAIL_REGEX = /\[fm_detail=(['"])(.*?)\1\]/;
const DETAIL_REGEX_GLOBAL = /\[fm_detail=(['"])(.*?)\1\]/g;
const TABLE_REGEX = /\[fm_table=(['"])(.*?)\1\]/;
const TABLE_REGEX_GLOBAL = /\[fm_table=(['"])(.*?)\1\]/g;

const SVG_DOWNLOAD = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="#7D8A96" fill-rule="evenodd" d="M19 9h-4V3H9v6H5l7 7 7-7ZM5 18v2h14v-2H5Z" clip-rule="evenodd"/></svg>`;

const IDENTIFIERS = {
    detail: 'data-fm_detail',
    chart: 'data-fm_action_chart',
    table: 'data-fm_action_table',
    detailShort: 'fm_detail',
    chartShort: 'fm_action_chart',
    tableShort: 'fm_action_table',
};

let chartImages = {};

const useBuildAnswer = () => {
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender?.current) {
            chartImages = {};
            isFirstRender.current = false;
        }
    }, []);

    const tableToCSV = useCallback(
        (table) => {
            if (!table) return;
            let csvData = [];

            const rows = table.querySelectorAll('tr');
            for (let i = 0; i < rows.length; i++) {
                const cols = rows[i].querySelectorAll('td,th');

                let csvRow = [];
                for (let j = 0; j < cols.length; j++) {
                    const text = cols[j].innerHTML || '';
                    csvRow.push(removeTags(text));
                }

                csvData.push(csvRow.join(','));
            }

            csvData = csvData.join('\n');
            return csvData;
        },
        [removeTags],
    );

    const redirectToDetail = useCallback((redirectTarget) => {
        if (redirectTarget.hasAttribute('data-clicking')) return;
        redirectTarget.setAttribute('data-clicking', true);

        const [entityName, id] = redirectTarget.dataset[IDENTIFIERS.detailShort].split('-');
        const entity = getEntityTrueNameFromString(entityName);
        window.open(getDetailRoute({ entity, id }), '_blank');

        setTimeout(() => {
            redirectTarget.removeAttribute('data-clicking');
        }, 300);
    }, []);

    const downloadTable = useCallback(
        (downloadTarget) => {
            if (downloadTarget.hasAttribute('data-clicking')) return;
            downloadTarget.setAttribute('data-clicking', true);

            const idTable = downloadTarget.getAttribute(IDENTIFIERS.table);
            const table = document.querySelector(`[data-fm_table="${idTable}"]`);

            if (!table) return;

            const csvData = tableToCSV(table);

            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = `table_${idTable}.csv`;
            downloadLink.click();

            setTimeout(() => {
                downloadTarget.removeAttribute('data-clicking');
            }, 300);
        },
        [tableToCSV],
    );

    const downloadChart = useCallback((downloadTarget) => {
        if (downloadTarget.hasAttribute('data-clicking')) return;
        downloadTarget.setAttribute('data-clicking', true);

        const idChart = downloadTarget.getAttribute(IDENTIFIERS.chart);
        const imageBase64 = chartImages[idChart] || null;
        if (!imageBase64) return;

        const a = document.createElement('a');
        a.href = imageBase64;
        a.download = `chart_${idChart}.jpeg`;
        a.click();

        setTimeout(() => {
            downloadTarget.removeAttribute('data-clicking');
        }, 300);
    }, []);

    useEffect(() => {
        const handleEvent = (e) => {
            let downloadTarget = undefined;
            if (e.target.classList.contains('dana-chat-info__header__action-icon')) {
                downloadTarget = e.target;
            } else {
                downloadTarget =
                    e.target.closest('.dana-chat-info__header__action-icon') || undefined;
            }

            let redirectTarget = undefined;
            if (e.target.hasAttribute(IDENTIFIERS.detail)) redirectTarget = e.target;

            if (redirectTarget) {
                redirectToDetail(redirectTarget);
            } else if (downloadTarget && downloadTarget.hasAttribute(IDENTIFIERS.table)) {
                downloadTable(downloadTarget);
            } else if (downloadTarget && downloadTarget.hasAttribute(IDENTIFIERS.chart)) {
                downloadChart(downloadTarget);
            }
        };

        window.addEventListener('click', handleEvent);

        return () => window.removeEventListener('click', handleEvent);
    }, [redirectToDetail, downloadTable, downloadChart]);

    const getRegexMatch = useCallback((text, regex) => {
        const matchArr = text.match(regex);
        if (!matchArr) return '';

        return matchArr?.[2] || '';
    }, []);

    const getRegexExec = useCallback((text, regex) => {
        const matchArr = regex.exec(text) || [];

        if (!matchArr.length) return {};

        const match = matchArr?.[0] || '';
        const info = matchArr?.[2] || '';

        return { match, info };
    }, []);

    const replaceForDetail = useCallback(
        (avoidLink) => (text) => {
            const info = getRegexMatch(text, DETAIL_REGEX);

            const [entityName, id, name] = info.split(',');

            if (!entityName || !id || !name) return text;

            let element = '';

            if (avoidLink) {
                element = `<b>${name}</b>`;
            } else element = `<a data-fm_detail="${entityName}-${id}">${name}</a>`;
            return element;
        },
        [getRegexMatch],
    );

    const replaceForChart = useCallback(
        (text) => {
            const info = getRegexMatch(text, CHART_REGEX);
            const id = info;

            if (!id) return text;

            const div = `<div data-fm_report="${id}"></div>`;
            return div;
        },
        [getRegexMatch],
    );

    const replaceForTable = useCallback(
        (text) => {
            const info = getRegexMatch(text, TABLE_REGEX);
            const id = info;

            if (!id) return text;

            const div = `<div data-fm_table="${id}"></div>`;
            return div;
        },
        [getRegexMatch],
    );

    const processStaticRedirect = useCallback(
        (textBox, avoidLink) => {
            if (!textBox) {
                return;
            }

            const htmlText = textBox.innerHTML;
            const newText = htmlText.replace(DETAIL_REGEX_GLOBAL, replaceForDetail(avoidLink));
            textBox.innerHTML = newText;
        },
        [replaceForDetail],
    );

    const processLiveRedirect = useCallback(
        (textBox) => {
            if (!textBox) {
                return;
            }

            const htmlText = textBox.innerHTML;

            let { match, info } = getRegexExec(htmlText, DETAIL_REGEX);

            if (!match || !info) {
                return;
            }

            info = info.split(',');
            if (!info.length) return;

            const [entity, id, name] = info;
            if (!entity || !name || !id) return;

            // Remove identifier from the text
            const newText = htmlText.replace(match, '');
            textBox.innerHTML = newText;

            const link = document.createElement('a');
            link.innerText = name;
            link.setAttribute('data-fm_detail', `${entity}-${id}`);
            link.setAttribute('id', id);
            textBox.appendChild(link);
        },
        [getRegexExec],
    );

    const createAction = useCallback((container, actionsArray) => {
        if (!actionsArray || !actionsArray.length) return;

        actionsArray.forEach((obj) => {
            const { svg, isChart, isTable, id } = obj;
            if (container && svg && (isChart || isTable)) {
                const divAction = document.createElement('div');
                divAction.classList.add('dana-chat-info__header__actions__action');

                let identifier = '';
                if (isChart) identifier = IDENTIFIERS.chart;
                else if (isTable) identifier = IDENTIFIERS.table;

                const parser = new DOMParser();
                const svgDocument = parser.parseFromString(svg, 'image/svg+xml');
                const svgElement = svgDocument.documentElement;

                if (identifier) svgElement.setAttribute(identifier, id);
                svgElement.classList.add('dana-chat-info__header__action-icon');

                divAction.appendChild(svgElement);
                container.appendChild(divAction);
            }
        });
    }, []);

    const buildInfoBox = useCallback(
        ({ container, isChart, isTable, id }) => {
            container.classList.add('dana-chat-info');

            const header = document.createElement('div');
            header.classList.add('dana-chat-info__header');

            const title = document.createElement('div');
            title.classList.add('dana-chat-info__header__title');
            let textTitle = '';
            if (isChart) textTitle = getLiteral('label_report');
            else if (isTable) textTitle = getLiteral('label_table');

            title.innerHTML = textTitle;

            const actionsContainer = document.createElement('div');
            actionsContainer.classList.add('dana-chat-info__header__actions');
            createAction(actionsContainer, [{ svg: SVG_DOWNLOAD, isChart, isTable, id }]);

            header.appendChild(title);
            header.appendChild(actionsContainer);

            const body = document.createElement('div');
            body.classList.add('dana-chat-info__body');
            if (isChart) body.classList.add('dana-chat-info__body__chart');
            if (isTable) body.classList.add('dana-chat-info__body__table');

            container.appendChild(header);
            container.appendChild(body);
            return body;
        },
        [createAction],
    );

    const isColorDark = (rgbColor) => {
        const grayscaleValue = 0.299 * rgbColor[0] + 0.587 * rgbColor[1] + 0.114 * rgbColor[2];
        const threshold = 170; // # of 255

        if (grayscaleValue < threshold) return true;
        else return false;
    };

    const buildChart = useCallback((id, container, data) => {
        if (!data || !Object.keys(data)?.length) return;

        const chartConfig = { ...data };

        chartConfig.options = {
            ...(chartConfig?.options || {}),
            devicePixelRatio: 2.5,
            responsive: true,
            layout: { ...(chartConfig?.options?.layout || {}), padding: 12 },
            animation: {
                onComplete: () => {
                    const imageBase64 = newChart.toBase64Image();
                    chartImages[id] = imageBase64;
                },
            },
            plugins: {
                customCanvasBackgroundColor: {
                    color: getVar(colors.actionMinor[50]),
                },
                tooltip: {
                    enabled: true,
                    callbacks: {
                        title: function (tooltipItem) {
                            const label = tooltipItem.label;
                            return label;
                        },
                        label: function (tooltipItem) {
                            let value = tooltipItem.dataset.data?.[tooltipItem.dataIndex];
                            if (isNaN(value)) return '';
                            value = getNumberAsLocaleNumber(value.toString());
                            const label = tooltipItem?.dataset?.label || '';
                            if (label) return `${label}: ${value}`;
                            return value;
                        },
                    },
                    boxPadding: 4,
                },
                datalabels: {
                    color: (context) => {
                        const backgroundColors = context?.dataset?.backgroundColor || [];
                        const backgroundColor = backgroundColors?.[context.dataIndex];
                        const isChartPie = data.type === 'pie' || data.type === 'doughnut' || false;
                        if (!backgroundColor?.length || !isChartPie)
                            return getVar(colors.grey[700]);

                        const darks = backgroundColors.filter((current) => {
                            const rgbString = current;
                            let rgbArray = rgbString.match(/\d+/g);
                            rgbArray = rgbArray.map(function (value) {
                                return parseInt(value, 10);
                            });
                            return isColorDark(rgbArray);
                        });

                        return darks.length >= Math.ceil(backgroundColors?.length / 2)
                            ? getVar(colors.primary.white)
                            : getVar(colors.grey[700]);
                    },
                    font: {
                        weight: data.type === 'pie' || data.type === 'doughnut' ? 'bold' : 'normal',
                    },
                    anchor: 'center',
                    formatter: function (value, context) {
                        if (!value) return null;
                        const hasMultipleDatasets = data.data.datasets.length > 1;
                        return hasMultipleDatasets
                            ? `${context.dataset.label}\n${formatLargeNumber(value)}`
                            : formatLargeNumber(value);
                    },
                    textAlign: 'center',
                    display: 'auto',
                },
            },
        };

        chartConfig.plugins = [
            ChartDataLabels,
            {
                id: 'customCanvasBackgroundColor',
                beforeDraw: (chart, args, options) => {
                    const { ctx } = chart;
                    ctx.save();
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = options.color || getVar(colors.actionMinor[50]);
                    ctx.fillRect(0, 0, chart.width, chart.height);
                    ctx.restore();
                },
            },
        ];

        const canvas = document.createElement('canvas');
        canvas.width = 533;
        canvas.height = 300;

        container.appendChild(canvas);
        const ctx = canvas.getContext('2d');

        const newChart = new Chart(ctx, chartConfig);
    }, []);

    const processStaticChart = useCallback(
        (textBox, data) => {
            if (!textBox || !data || !Object.keys(data)?.length) {
                return;
            }

            const htmlText = textBox.innerHTML;

            // Remove identifier from the text
            const newText = htmlText.replace(CHART_REGEX_GLOBAL, replaceForChart);
            textBox.innerHTML = newText;

            for (const [key, value] of Object.entries(data)) {
                const id = key;
                // select chart div container and insert the chart
                const container = document.querySelector(`[data-fm_report="${id}"]`);

                if (container && value?.content && Object.keys(value.content)?.length) {
                    const chartBody = buildInfoBox({ container, isChart: true, id });

                    buildChart(id, chartBody, value.content);
                }
            }
        },
        [replaceForChart, buildInfoBox, buildChart],
    );

    const processLiveChart = useCallback(
        (textBox, data) => {
            if (!textBox) {
                return;
            }

            const htmlText = textBox.innerHTML;

            const { match, info } = getRegexExec(htmlText, CHART_REGEX);

            const id = info;

            // if there is no match or data of the report doesn't exist, return
            if (!match || !id || !data?.[id]) {
                return;
            }

            const reportData = data[id]?.content;

            // Remove identifier from the text
            const newText = htmlText.replace(match, replaceForChart);
            textBox.innerHTML = newText;

            // select chart div container and insert the chart
            const container = document.querySelector(`[data-fm_report="${id}"]`);

            if (container && reportData && Object.keys(reportData)?.length) {
                const chartBody = buildInfoBox({ container, isChart: true, id });

                buildChart(id, chartBody, reportData);
            }
        },
        [replaceForChart, buildInfoBox, buildChart, getRegexExec],
    );

    const createTable = useCallback(
        (tableData) => {
            if (!tableData?.length) return;

            // Create a table element
            const table = document.createElement('table');
            table.classList.add('dana-chat-info__table');
            table.style.width = '100%';
            table.style.borderCollapse = 'collapse';
            table.style.tableLayout = 'auto';

            // Create a header row
            const headerRow = document.createElement('tr');

            // Extract headers dynamically
            const headers = Object.keys(tableData[0]);

            const columnMaxWidths = {};
            tableData.forEach((data) => {
                Object.entries(data).forEach(([key, value]) => {
                    const currentWidth = value ? value.toString().length * 20 : 20;
                    if (!columnMaxWidths[key] || currentWidth > columnMaxWidths[key]) {
                        columnMaxWidths[key] = currentWidth;
                    }
                });
            });

            // Create headers
            headers.forEach((headerText) => {
                const headerCell = document.createElement('th');
                headerCell.classList.add('dana-chat-info__table__header-cell');
                headerCell.textContent = headerText;

                headerCell.style.width = `${columnMaxWidths[headerText]}px`;

                headerRow.appendChild(headerCell);
            });

            table.appendChild(headerRow);

            // Create rows
            tableData.forEach((data) => {
                const row = document.createElement('tr');
                headers.forEach((header) => {
                    const cell = document.createElement('td');
                    cell.classList.add('dana-chat-info__table__row-cell');
                    // Tables can also have identifiers that need to be converted to links to Detail
                    cell.innerHTML = data?.[header]
                        ? data[header].replace(DETAIL_REGEX_GLOBAL, replaceForDetail())
                        : '';
                    cell.style.width = `${columnMaxWidths[header]}px`;
                    row.appendChild(cell);
                });
                table.appendChild(row);
            });

            return table;
        },
        [replaceForDetail],
    );

    const processStaticTable = useCallback(
        (textBox, data) => {
            if (!textBox || !data || !Object.keys(data)?.length) {
                return;
            }

            const htmlText = textBox.innerHTML;

            // Remove identifier from the text
            const newText = htmlText.replace(TABLE_REGEX_GLOBAL, replaceForTable);
            textBox.innerHTML = newText;

            for (const [key, value] of Object.entries(data)) {
                const id = key;
                // select chart div container and insert the table
                const container = document.querySelector(`[data-fm_table="${id}"]`);

                if (container && value?.content?.length) {
                    const tableBody = buildInfoBox({ container, isTable: true, id });
                    const table = createTable(value.content);
                    tableBody.appendChild(table);
                }
            }
        },
        [replaceForTable, buildInfoBox, createTable],
    );

    const processLiveTable = useCallback(
        (textBox, data) => {
            if (!textBox) {
                return;
            }

            const htmlText = textBox.innerHTML;

            const { match, info } = getRegexExec(htmlText, TABLE_REGEX);

            const id = info;

            // if there is no match or data of the report doesn't exist, return
            if (!match || !id || !data?.[id]) {
                return;
            }

            const tableData = data[id]?.content;

            // Remove identifier from the text
            const newText = htmlText.replace(match, replaceForTable);
            textBox.innerHTML = newText;

            // select chart div container and insert the chart
            const container = document.querySelector(`[data-fm_table="${id}"]`);

            if (container && tableData?.length) {
                const tableBody = buildInfoBox({ container, isTable: true, id });

                const table = createTable(tableData);
                tableBody.appendChild(table);
            }
        },
        [buildInfoBox, replaceForTable, createTable, getRegexExec],
    );

    const getDifference = useCallback((str1, str2) => {
        if (!str1) return str2;
        const arr1 = str1.toLowerCase().split('');
        const arr2 = str2.toLowerCase().split('');

        const index = arr2.findIndex((char, i) => char !== arr1[i]);

        return str2.slice(index);
    }, []);

    const processLiveMarkdown = useCallback((text) => {
        return text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
    }, []);

    const insertTextInDOM = useCallback(
        ({ text, progress, chatBox, prevTextRef, reports, tables }) => {
            if (!chatBox || (!text && !progress)) return;

            const textBox = chatBox.querySelector('.dana-chat-comment-text__inner');
            // if (progress) textBox.classList.add('dana-chat-comment-text__progress');
            // else textBox.classList.remove('dana-chat-comment-text__progress');

            const prevText = prevTextRef || '';

            if (progress) {
                if (!textBox.classList.contains('dana-chat-comment-text__progress')) {
                    textBox.classList.add('dana-chat-comment-text__progress');
                }
                textBox.innerHTML = progress;
            } else if (text) {
                if (textBox.classList.contains('dana-chat-comment-text__progress')) {
                    textBox.classList.remove('dana-chat-comment-text__progress');
                }

                if (!prevText) textBox.innerHTML = '';
                let textPortion = getDifference(prevText, text);
                // IMPORTANT: Backend returns all blocks of relevant info (link, table and report identifiers,
                // <b>bold text</b>, **bold text**) all together so we can treat them correctly at once and
                // insert in the DOM together for a correct visual result.
                textPortion = processLiveMarkdown(textPortion);
                textBox.insertAdjacentHTML('beforeend', textPortion);
                processLiveRedirect(textBox);
                processLiveTable(textBox, tables);
                // Always processLiveChart has to be the last method otherwise the chart may not display
                processLiveChart(textBox, reports);
            }
        },
        [
            getDifference,
            processLiveChart,
            processLiveRedirect,
            processLiveTable,
            processLiveMarkdown,
        ],
    );

    const removeTags = useCallback((text) => {
        const tagWrapperRegEx = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
        return text.replace(tagWrapperRegEx, '');
    }, []);

    const removeMarkdown = useCallback((text) => {
        return text.replace(/\*\*(.*?)\*\*/g, '$1');
    }, []);

    const replaceDetailToCopy = useCallback(
        (text) => {
            const info = getRegexMatch(text, DETAIL_REGEX);

            const [entityName, id, name] = info.split(',');

            if (!name) return text;
            return text.replace(DETAIL_REGEX, `"${name}"`);
        },
        [getRegexMatch],
    );

    const generateMarkdownTable = useCallback(
        (data) => {
            // Calculate column widths
            const columnWidths = {};
            for (let header of Object.keys(data[0])) {
                columnWidths[header] = header.length;
            }

            for (let row of data) {
                for (let [key, value] of Object.entries(row)) {
                    columnWidths[key] = value
                        ? Math.max(columnWidths[key], value.toString().length)
                        : columnWidths[key] || 0;
                }
            }

            // Generate Markdown table
            let markdownTable = '|';
            for (let header of Object.keys(columnWidths)) {
                markdownTable += ' ' + header.padEnd(columnWidths[header]) + ' |';
            }
            markdownTable += '\n|';

            for (let header of Object.keys(columnWidths)) {
                markdownTable += ' ' + '-'.repeat(columnWidths[header]) + ' |';
            }
            markdownTable += '\n';

            for (let row of data) {
                markdownTable += '|';
                for (let [key, value] of Object.entries(row)) {
                    let cleanValue = '';
                    if (value && typeof value === 'string') {
                        cleanValue = value.replace(DETAIL_REGEX_GLOBAL, replaceDetailToCopy);
                    } else cleanValue = value;

                    if (cleanValue) {
                        markdownTable +=
                            ' ' + cleanValue.toString().padEnd(columnWidths[key]) + ' |';
                    } else {
                        markdownTable += ' ' + ''.padEnd(columnWidths[key]) + ' |';
                    }
                }
                markdownTable += '\n';
            }

            return `\n${markdownTable}`;
        },
        [replaceDetailToCopy],
    );

    const replaceTableToCopy = useCallback(
        (tables) => (text) => {
            const { match, info } = getRegexExec(text, TABLE_REGEX);

            const id = info;

            if (!match || !id) {
                return text;
            }
            let markdownTable = '';

            if (tables?.[id]?.content?.length) {
                // Convert de table data to markdown
                markdownTable = generateMarkdownTable(tables?.[id]?.content);
            }

            return text.replace(TABLE_REGEX, markdownTable);
        },
        [generateMarkdownTable, getRegexExec],
    );

    const replaceChartToCopy = useCallback(
        ({ reports, tables }) =>
            (text) => {
                const { match, info } = getRegexExec(text, CHART_REGEX);

                const id = info;

                if (!match || !id) {
                    return text;
                }
                let table = null;

                if (tables?.[id]) table = tables?.[id];

                let markdownTable = '';

                if (table?.content?.length) {
                    // Convert de table data to markdown
                    markdownTable = generateMarkdownTable(table?.content);
                }

                return text.replace(CHART_REGEX, markdownTable);
            },
        [generateMarkdownTable, getRegexExec],
    );

    const cleanChatTextForClipboard = useCallback(
        ({ value, tables, reports }) => {
            let finalText = value;
            finalText = removeTags(finalText);
            finalText = removeMarkdown(finalText);
            finalText = finalText.replace(DETAIL_REGEX_GLOBAL, replaceDetailToCopy);
            finalText = finalText.replace(TABLE_REGEX_GLOBAL, replaceTableToCopy(tables));
            finalText = finalText.replace(
                CHART_REGEX_GLOBAL,
                replaceChartToCopy({ reports, tables }),
            );
            return finalText;
        },
        [removeTags, removeMarkdown, replaceDetailToCopy, replaceTableToCopy, replaceChartToCopy],
    );

    const processStaticMarkdown = useCallback((textBox) => {
        if (!textBox) {
            return;
        }

        const htmlText = textBox.innerHTML;
        const newText = htmlText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
        textBox.innerHTML = newText;
    }, []);

    const processStaticContent = useCallback(
        ({ textBox, isWidget, tables, reports }) => {
            processStaticMarkdown(textBox);
            processStaticRedirect(textBox, isWidget);
            tables && processStaticTable(textBox, tables);
            // Always processStaticChart has to be the last method otherwise the chart won't display
            reports && processStaticChart(textBox, reports);
        },
        [processStaticRedirect, processStaticTable, processStaticChart, processStaticMarkdown],
    );

    return {
        insertTextInDOM,
        processStaticContent,
        cleanChatTextForClipboard,
    };
};

export default useBuildAnswer;
