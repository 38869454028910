import { REFRESH_USER_KEY } from 'constants/ActionTypes';
import Context from 'managers/Context';
import { getNewToken, persistTokens } from 'services';
import Cookies from 'js-cookie';
import { getCookieDomainName } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';

const profileSettingsSchema = [
    {
        description: '_INFORMATION',
        show: true,
        tabFields: [
            {
                id: 'nombre',
                description: 'label_name',
                dataType: 'text',
                mandatory: true,
            },
            {
                id: 'apellidos',
                description: 'label_surname',
                dataType: 'text',
                mandatory: true,
            },
            {
                id: 'langISOInterface',
                description: 'label_idioma',
                dataType: 'singleValueList',
                valueListName: 'tblTiposIdioma',
                mandatory: true,
            },
            {
                id: 'idPrefix',
                description: 'label_mobile_country_code',
                dataType: 'singleValueList',
                valueListName: 'tblCountries',
                mandatory: true,
            },
            {
                id: 'areaCode',
                description: 'label_mobile_area_code',
                dataType: 'text',
                disabled: true,
                hint: '  ',
            },
            {
                id: 'movil',
                description: 'label_mobilephone',
                dataType: 'text',
            },
            {
                id: 'email',
                description: 'label_email',
                disabled: true,
            },
            {
                id: 'password',
                description: 'label_password',
                dataType: 'password',
            },
        ],
    },
];

const parsePolicy = (data) => ({
    id: data.id,
    digitsMin: parseInt(data.digitsminnumber, 10),
    lowercaseLettersMin: parseInt(data.lowercaselettersminnumber, 10),
    maxLength: parseInt(data.maxlength, 10),
    minLength: parseInt(data.minlength, 10),
    regexDigits: data.regexDigits,
    regexLowerCase: data.regexLowerCase,
    regexSpecial: data.regexSpecial,
    regexUpperCase: data.regexUpperCase,
    requireDigits: data.requiredigits === 'True',
    requireLowerCase: data.requirelowercaseletters === 'True',
    requireUpperCase: data.requireuppercaseletters === 'True',
    requireSpecialLetters: data.requirespecialletters === 'True',
    specialLettersMin: parseInt(data.speciallettersminnumber, 10),
    uppercaseLettersMin: parseInt(data.uppercaselettersminnumber, 10),
});

export default class ProfileSettingsManager {
    schema = profileSettingsSchema;

    get() {
        const { userData } = this.context.store.getState().config;
        return {
            idUsuario: userData.idUsuario,
            nombre: userData.nombre,
            apellidos: userData.apellidos,
            idPrefix: userData.idPrefix,
            areaCode: null,
            movil: userData.movil,
            email: userData.email,
            langISOInterface: userData.langISOInterface,
            locale: userData.locale,
        };
    }

    getTblTiposIdioma(success, error) {
        this.context.domainManager.getValueList(
            'tblTiposIdioma',
            (response) => {
                let resultValue = response.data.tblTiposIdioma;
                resultValue.constructor === Object ? (resultValue = [resultValue]) : resultValue;
                success(resultValue);
            },
            error,
        );
    }

    getCountryCodes(success, error) {
        this.context.domainManager.getValueList(
            'tblCountries',
            (response) => {
                success(
                    Array.isArray(response.data.tblCountries)
                        ? response.data.tblCountries
                        : [response.data.tblCountries],
                );
            },
            error,
        );
    }

    getPasswordPolicy(success, error, hash) {
        this.context.domainManager.getPasswordPolicy(
            (data) => success(parsePolicy(data)),
            error,
            hash,
        );
    }

    getPasswordUserPolicy(success, error, hash) {
        this.context.domainManager.getPasswordUserPolicy(
            (data) => success(parsePolicy(data)),
            error,
            hash,
        );
    }

    getPasswordPolicyAuth(authData, success, error) {
        this.context.domainManager.getPasswordPolicyAuth(
            authData,
            (data) => success(parsePolicy(data)),
            error,
        );
    }

    getErrors(entity) {
        // always async for the future
        return new Promise((resolve) => {
            const errors = {};
            let firstErrorField = null;
            profileSettingsSchema.forEach((current) => {
                current.tabFields.forEach((field) => {
                    const value = entity[field.id];
                    if (field.mandatory && !value) {
                        errors[field.id] = getLiteral('helptext_required_field');
                        if (!firstErrorField) firstErrorField = field;
                    }
                });
            });
            return resolve({ errors, firstErrorField });
        });
    }

    savePassword(data, success, error) {
        this.context.domainManager.savePassword(
            data,
            (newToken) => {
                // Updating token & userKey
                getNewToken(newToken)
                    .then((data) => {
                        // Sesstion storage
                        const sessionData = this.context.cacheManager.getConfigStore();
                        sessionData.token = data.token;
                        sessionData.refreshToken = data.refreshToken;
                        sessionData.userData.userKey = data.userKey;
                        this.context.cacheManager.setConfigStore(sessionData);

                        // Current api config
                        persistTokens(data);

                        // Store
                        this.context.store.dispatch({
                            type: REFRESH_USER_KEY,
                            userKey: data.userKey,
                        });
                        success();
                    })
                    .catch(error);
            },
            error,
        );
    }

    save(entity, success, error) {
        const data = {
            Nombre: entity.nombre,
            Apellidos: entity.apellidos,
            foto: entity.photo,
            language: entity.langISOInterface,
            idPrefix: entity.idPrefix,
            strCellPhone: entity.movil,
            Email: entity.email,
        };
        this.context.domainManager.setProfileSettings(
            entity.idUsuario,
            data,
            () => {
                const sessionData = this.context.cacheManager.getConfigStore();
                getNewToken(sessionData.token)
                    .then((data) => {
                        // Sesstion storage
                        sessionData.token = data.token;
                        sessionData.refreshToken = data.refreshToken;

                        sessionData.userData.nombre = entity.nombre;
                        sessionData.userData.apellidos = entity.apellidos;
                        sessionData.userData.nic = `${entity.nombre} ${entity.apellidos}`;
                        sessionData.userData.idPrefix = entity.idPrefix;
                        sessionData.userData.movil = entity.movil;
                        sessionData.userData.email = entity.email;

                        // Only if new photo is uploaded
                        if (entity.photo && sessionData.userData.foto !== entity.photo) {
                            //sessionData.userData.foto = entity.photo; // Avoiding quota exceeded
                            Cookies.set('imageTimestamp', new Date().getTime(), {
                                domain: getCookieDomainName(window.location.hostname),
                                secure: true,
                                expires: 31,
                            });
                        }

                        // Refreshing literals...
                        if (entity.langISOInterface !== sessionData.userData.langISOInterface) {
                            sessionData.userData.langISOInterface = entity.langISOInterface;
                            sessionData.userData.langISODDBB = entity.langISOInterface;
                            Context.refreshLiterals(entity.langISOInterface);
                            Context.clearValueLists(entity.langISOInterface);
                            sessionStorage.removeItem('config_web4');
                            Cookies.set('INVALIDATE_CONFIG', true, {
                                domain: getCookieDomainName(window.location.hostname),
                                secure: true,
                            });
                            Cookies.set('INVALIDATE_LITERALS', entity.langISOInterface, {
                                domain: getCookieDomainName(window.location.hostname),
                                secure: true,
                            });
                            window.location.reload();
                        }

                        this.context.cacheManager.setConfigStore(sessionData);
                        // Current api config
                        persistTokens(data);
                        success();
                    })
                    .catch(error);
            },
            error,
        );
    }
}
