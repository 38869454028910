import React, { memo, useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'hoi-poi-ui';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const propTypes = {
    height: PropTypes.node,
    headerToolbar: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onGetDisplayedDateRange: PropTypes.func,
    onEventRender: PropTypes.func,
    onEventClick: PropTypes.func,
    onDateClick: PropTypes.func,
    onEventMouseEnter: PropTypes.func,
    onEventMouseLeave: PropTypes.func,
    weekends: PropTypes.bool,
    firstDay: PropTypes.number,
    className: PropTypes.string,
    events: PropTypes.array,
    showNonCurrentDates: PropTypes.bool,
    getCalendarRef: PropTypes.func,
    isEditable: PropTypes.bool,
};

const Calendar = memo(
    ({
        height = '100%',
        contentHeight = 'auto',
        headerToolbar,
        firstDay = 1,
        dayMaxEvents = 2,
        className: classNameProp,
        onGetDisplayedDateRange,
        renderEventContent,
        onEventClick,
        onCheckTask,
        onDateClick,
        events,
        locale,
        getCalendarRef,
        isEditable = true,
        showNonCurrentDates = false,
        scrollTime,
        ...props
    }) => {
        const calendarRef = useRef(null);
        const hasApiRef = useRef(false);

        useEffect(() => {
            if (getCalendarRef && calendarRef.current && !hasApiRef.current) {
                hasApiRef.current = true;
                getCalendarRef(calendarRef.current);
            }
        }, [getCalendarRef]);

        const handleGetDisplayedDateRange = useCallback(
            (info) => {
                const startDate = info.view.currentStart;
                const endDate = info.view.currentEnd;
                onGetDisplayedDateRange && onGetDisplayedDateRange(startDate, endDate);
            },
            [onGetDisplayedDateRange],
        );

        const eventContent = useCallback((item) => {
            const event = item.event;
            return <div className="fm-calendar__item">{event.title}</div>;
        }, []);

        const allDayContent = useMemo(() => {
            return (
                <Tooltip
                    placement="top"
                    content={<span>{getLiteral('label_calendarallday')}</span>}
                >
                    <span className="fc-custom-all-day">
                        <Icon name="update" color="currentColor" />
                    </span>
                </Tooltip>
            );
        }, []);

        const renderCalendar = useMemo(() => {
            return (
                <FullCalendar
                    ref={calendarRef}
                    height={height}
                    expandRows={false}
                    headerToolbar={headerToolbar}
                    firstDay={firstDay}
                    dayMaxEvents={dayMaxEvents}
                    datesSet={handleGetDisplayedDateRange}
                    dateClick={onDateClick}
                    events={events}
                    eventContent={renderEventContent || eventContent}
                    eventClick={onEventClick}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    locale={locale}
                    locales={allLocales}
                    nowIndicator
                    selectable
                    selectMirror
                    editable={isEditable}
                    eventStartEditable={false}
                    eventResizableFromStart={false}
                    showNonCurrentDates={showNonCurrentDates}
                    slotEventOverlap={false}
                    moreLinkContent={(args) => args.shortText}
                    allDayContent={allDayContent}
                    slotLabelFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        omitZeroMinute: false,
                        meridiem: 'short',
                        hour12: false,
                    }}
                    eventTimeFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: 'short',
                        omitZeroMinute: false,
                        hour12: false,
                    }}
                    dayPopoverFormat={{ month: 'short', day: 'numeric' }}
                    views={{
                        dayGridMonth: {
                            dayHeaderFormat: { weekday: 'long' },
                        },
                        timeGridWeek: {
                            dayHeaderFormat: {
                                weekday: 'short',
                                day: 'numeric',
                                omitCommas: true,
                            },
                            eventMinHeight: 30,
                            scrollTime: scrollTime || '08:00:00',
                        },
                        timeGridDay: {
                            dayHeaderFormat: { weekday: 'long' },
                            eventMinHeight: 30,
                            scrollTime: scrollTime || '08:00:00',
                        },
                        workweek: {
                            type: 'timeGrid',
                            dayHeaderFormat: {
                                weekday: 'short',
                                day: 'numeric',
                                omitCommas: true,
                            },
                            eventMinHeight: 30,
                            scrollTime: scrollTime || '08:00:00',
                            duration: { days: 5 },
                        },
                    }}
                    {...props}
                />
            );
        }, [
            height,
            headerToolbar,
            firstDay,
            dayMaxEvents,
            handleGetDisplayedDateRange,
            onDateClick,
            events,
            renderEventContent,
            eventContent,
            onEventClick,
            locale,
            isEditable,
            showNonCurrentDates,
            allDayContent,
            scrollTime,
            props,
        ]);

        const className = ['fm-calendar-container', classNameProp].join(' ');

        return <div className={className}>{renderCalendar}</div>;
    },
);

Calendar.propTypes = propTypes;

export default Calendar;
