import React, { memo, useMemo, useCallback, Fragment, useState, useRef } from 'react';
import { Modal, Text, useTheme } from 'hoi-poi-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v4 as uuid } from 'uuid';
import Chat from './components/Chat';
import ChatToolbar from './components/ChatToolbar';
import DanaLoader from './components/DanaLoader';
import BetaBadge from 'components/BetaBadge';
import SageCopilot from 'components/icons/SageCopilot';
import UserAvatar from 'containers/components/avatars/UserAvatar';
import { DanaActions } from 'actions';
import { ChatGPTService } from 'services';
import useBuildAnswer from './hooks/useBuildAnswer';
import { getLiteral } from 'utils/getLiteral';
import copyToClipboard from 'utils/copy';
import { logEvent } from 'utils/tracking';
import metadata from 'lib/metadata';

import './styles.scss';

const mapStateToProps = (state, ownProps) => {
    let chatId = '';
    if (ownProps?.entity?.entity && ownProps?.id) {
        if (ownProps.subEntity) {
            chatId = `${ownProps.entity.entity}-${ownProps.id}-${ownProps.subEntity.entity}`;
        } else chatId = `${ownProps.entity.entity}-${ownProps.id}`;
    }

    return {
        chatId,
        conversation:
            chatId && state.dana.chat?.[chatId]?.conversation
                ? state.dana.chat?.[chatId]?.conversation
                : {},
        conversationId: chatId ? state.dana.chat?.[chatId]?.conversationId : '',
        lastError: chatId ? state.dana.chat?.[chatId]?.lastError : null,
        idUser: state?.config?.userData?.idUsuario || '',
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        storeDanaChat: bindActionCreators(DanaActions, dispatch).storeDanaChat,
        setDanaChatFinished: bindActionCreators(DanaActions, dispatch).setDanaChatFinished,
        clearDanaChat: bindActionCreators(DanaActions, dispatch).clearDanaChat,
    };
};

const DanaChatModal = memo(
    ({
        children,
        isOpen,
        entity,
        subEntity,
        id,
        isError,
        isLoadingModal,
        isChildrenWriting,
        storeDanaChat,
        setDanaChatFinished,
        clearDanaChat,
        idUser,
        lastError,
        chatId,
        conversation,
        conversationId,
        previousInteractions,
        onRequestClose,
        name,
        isDebugMode,
    }) => {
        const [isLoadingAnswer, setIsLoadingAnswer] = useState(false);
        const answerInteractionIdRef = useRef(null);
        const contentRef = useRef(null);
        const questionRef = useRef('');
        const answerRef = useRef(''); // to keep a track of the response
        const chatBoxRef = useRef({}); // refs of all the chatBoxes rendered in the DOM
        const tempReportsRef = useRef({});
        const tempTablesRef = useRef({});
        const theme = useTheme();
        const { insertTextInDOM, cleanChatTextForClipboard } = useBuildAnswer();

        const handleClearChat = useCallback(() => {
            if (!entity || !id) return null;
            clearDanaChat({ entity, id, subEntity });
        }, [clearDanaChat, entity, id, subEntity]);

        const getChatBoxRef = useCallback(({ ref, interactionId }) => {
            if (!ref || !interactionId || chatBoxRef?.current?.[interactionId]) return;
            chatBoxRef.current[interactionId] = ref;
        }, []);

        const onChatAnswer = useCallback(
            ({ text, conversationId, progress, reports, tables, debug }) => {
                if (reports) {
                    tempReportsRef.current = reports;
                    if (isDebugMode)
                        console.info(
                            `%cDANA_REPORTS:\n${JSON.stringify(reports, null, 2)}`,
                            'color: olivedrab',
                        );
                }
                if (tables) {
                    tempTablesRef.current = tables;
                    if (isDebugMode)
                        console.info(
                            `%cDANA_TABLES:\n${JSON.stringify(tables, null, 2)}`,
                            'color: olivedrab',
                        );
                }
                if (debug && isDebugMode) {
                    console.info(
                        `DANA_DEBUG\nfunction: ${debug.function}\narguments: ${debug.args}`,
                    );
                    return;
                }

                if (text || progress) {
                    // Execute only when there is text to display
                    insertTextInDOM({
                        text,
                        progress: progress ? `${progress}...` : '',
                        chatBox: chatBoxRef.current[answerInteractionIdRef.current],
                        prevTextRef: answerRef.current,
                        reports: tempReportsRef.current,
                        tables: tempTablesRef.current,
                    });
                }

                storeDanaChat({
                    entity,
                    subEntity,
                    id,
                    text,
                    loading: false,
                    error: false,
                    conversationId,
                    interactionId: answerInteractionIdRef.current,
                    progress: progress ? `${progress}...` : '',
                    reports,
                    tables,
                });

                // To keep track of the previous chunk of text
                if (text) answerRef.current = text;
            },
            [entity, subEntity, id, insertTextInDOM, storeDanaChat, isDebugMode],
        );

        const onChatError = useCallback(
            (parentId) => {
                return () => {
                    answerRef.current = '';
                    setIsLoadingAnswer(false);
                    storeDanaChat({
                        entity,
                        subEntity,
                        id,
                        text: '',
                        loading: false,
                        error: true,
                        interactionId: answerInteractionIdRef.current,
                        parentId,
                    });
                };
            },
            [entity, subEntity, id, storeDanaChat],
        );

        const onFinishChatAnswer = useCallback(() => {
            if (isDebugMode) {
                console.info(`%cDANA_TEXT:\n${answerRef.current}`, 'color: olivedrab');
            }

            setDanaChatFinished({ chatId, interactionId: answerInteractionIdRef.current });

            answerRef.current = '';
            answerInteractionIdRef.current = null;
            tempReportsRef.current = {};
            tempTablesRef.current = {};
            setIsLoadingAnswer(false);
        }, [isDebugMode, setDanaChatFinished, chatId]);

        const onSubmitChat = useCallback(
            (suggestion) => {
                const question = suggestion || questionRef.current || '';
                setIsLoadingAnswer(true);
                const questionId = uuid();
                storeDanaChat({
                    entity,
                    subEntity,
                    id,
                    text: question,
                    interactionId: questionId,
                });
                answerInteractionIdRef.current = uuid();
                storeDanaChat({
                    entity,
                    subEntity,
                    id,
                    text: '',
                    loading: true,
                    interactionId: answerInteractionIdRef.current,
                    parentId: questionId,
                });

                logEvent({
                    event: entity.trueName,
                    submodule: 'danaAssistant',
                    functionality: 'sendQuestionDana ',
                });

                return ChatGPTService.getDanaChatStream({
                    entity,
                    id,
                    question,
                    setText: onChatAnswer,
                    conversationId,
                    isDebugMode,
                    onFinish: onFinishChatAnswer,
                    onError: onChatError(questionId),
                });

                // Only for testing
                // ChatGPTService.getFakeChat({
                //     entity,
                //     id,
                //     question,
                //     setText: onChatAnswer,
                //     conversationId,
                //     onFinish: onFinishChatAnswer,
                //     onError: onChatError(questionId),
                // });
            },
            [
                conversationId,
                entity,
                subEntity,
                id,
                onChatAnswer,
                storeDanaChat,
                isDebugMode,
                onChatError,
                onFinishChatAnswer,
            ],
        );

        const onResendQuestion = useCallback(
            ({ interactionId, parentId }) => {
                if (!interactionId || !parentId) return;
                const oldQuestion = conversation?.[parentId]?.content || '';
                if (!oldQuestion) return;
                setIsLoadingAnswer(true);
                answerInteractionIdRef.current = interactionId;
                storeDanaChat({
                    entity,
                    subEntity,
                    id,
                    text: '',
                    loading: true,
                    interactionId: answerInteractionIdRef.current,
                    parentId,
                });

                logEvent({
                    event: entity.trueName,
                    submodule: 'danaAssistant',
                    functionality: 'retryQuestionDana ',
                });

                ChatGPTService.getDanaChatStream({
                    entity,
                    id,
                    question: oldQuestion,
                    setText: onChatAnswer,
                    conversationId,
                    isDebugMode,
                    onFinish: onFinishChatAnswer,
                    onError: onChatError(parentId),
                });
            },
            [
                entity,
                subEntity,
                id,
                conversationId,
                conversation,
                isDebugMode,
                onChatAnswer,
                onChatError,
                storeDanaChat,
                onFinishChatAnswer,
            ],
        );

        const onCopyChat = useCallback(
            (value) => {
                if (!value.content) return;

                const text = cleanChatTextForClipboard({
                    value: value.content,
                    tables: value.tables,
                    reports: value.reports,
                });
                copyToClipboard(text);
            },
            [cleanChatTextForClipboard],
        );

        const getContentRef = (ref) => {
            if (ref) {
                contentRef.current = ref;
                const el = contentRef.current;
                el.scrollTop = el.scrollHeight;
            }
        };

        const getQuestionRef = useCallback((question) => {
            questionRef.current = question;
        }, []);

        const isDanaWritting = useMemo(() => {
            return isLoadingAnswer || isChildrenWriting;
        }, [isLoadingAnswer, isChildrenWriting]);

        const assistantFor = useMemo(() => {
            if (!name) return null;
            const label = getLiteral('label_assistant_for');
            return (
                <Text
                    color={theme.colors.utility.textSecondary}
                    className="dana-chat-modal__assistantFor"
                    isTruncated
                >{`${label} ${name}`}</Text>
            );
        }, [name, theme]);

        return (
            <Modal
                className="dana-chat-modal"
                isOpen={isOpen}
                width="690px"
                height="90%"
                overrides={{
                    content: {
                        style: {
                            height: '100%',
                            position: 'relative',
                            overflow: 'auto',
                            ...theme.utils.scrollbar,
                        },
                        ref: (ref) => getContentRef(ref),
                    },
                }}
                title={
                    <div className="dana-chat-modal__title-container">
                        <div className="dana-chat-modal__logo">{metadata.SageCopilotIcon}</div>
                        <div className="dana-chat-modal__copy">
                            <div className="dana-chat-modal__title">
                                <Text type="h6" bold={true}>
                                    {getLiteral('label_danai_golderminute_title')}
                                </Text>
                                <BetaBadge />
                            </div>
                            {assistantFor}
                        </div>
                    </div>
                }
                onRequestClose={onRequestClose}
                onAfterOpen={() => {
                    const element = document.querySelector('.dana-chat-modal__content');
                    if (!element) return;
                    const container = element.parentNode;
                    container.scrollTop = container.scrollHeight;
                }}
                postComponent={
                    !isError && (
                        <ChatToolbar
                            getQuestionRef={getQuestionRef}
                            onSubmit={onSubmitChat}
                            onClear={handleClearChat}
                            conversation={conversation}
                            isLoadingAnswer={!isLoadingAnswer}
                            isDanaWritting={isDanaWritting}
                        />
                    )
                }
            >
                <div className="dana-chat-modal__content">
                    {isLoadingModal && <DanaLoader />}
                    {!isLoadingModal && (
                        <Fragment>
                            {children && children({ onSubmitChat })}
                            {Object.keys(conversation)?.length > 0 && (
                                <Chat
                                    getChatBoxRef={getChatBoxRef}
                                    chatId={chatId}
                                    conversationId={conversationId}
                                    conversation={conversation}
                                    previousInteractions={previousInteractions}
                                    lastError={lastError}
                                    avatar={<UserAvatar id={idUser} />}
                                    onResend={onResendQuestion}
                                    onCopy={onCopyChat}
                                />
                            )}
                        </Fragment>
                    )}
                </div>
            </Modal>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(DanaChatModal);
