export const RECIPIENT_TYPE_ACCOUNT = 1;
export const RECIPIENT_TYPE_CONTACT = 2;
export const RECIPIENT_TYPE_USER = 3;
export const RECIPIENT_TYPE_EMAIL = 4;

export const MAX_ATTACHMENT_SIZE = 25000000; // 25MB

export const TRACKING_EMAIL_DISCLAIMER = {
    ACTIVE: 'active',
    NOT_ACTIVE: 'notactive',
    ACCEPTED: 'accepted',
    NOT_ACCEPTED: 'notaccepted',
};

export const TRACKING_EMAIL_ACTIONS_BEHAVIOUR = {
    ACTIVE: 'active',
    NOT_ACTIVE: 'notactive',
    DISABLED: 'disabled',
};
