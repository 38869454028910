import React, { memo, useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { Popover, Button, Icon, Text, Tooltip, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { trackEvent } from 'utils/tracking';
import { EVENT_TYPES } from 'constants/Constants';

import './styles.scss';

const classes = {
    root: {
        '& .hoi-poi-popover-inner': {
            padding: '0px',
        },
    },
};

const EntityDetailPopover = memo(
    ({
        children,
        content,
        className,
        onDelete,
        headerPreComponent = null,
        headerPostComponent = null,
        onExpand,
        onEdit,
        onClose,
        labelCantEdit,
        titleDelete,
        subtitleDelete,
        onPopupVisibleChange,
        setIsDetailVisible,
        entity,
        entityId,
        ...props
    }) => {
        const [showConfirmDelete, setShowConfirmDelete] = useState(false);
        const [isDeleting, setIsDeleting] = useState(false);
        const theme = useTheme();
        const headerRef = useRef(null);
        const contentRef = useRef(null);
        const footerRef = useRef(null);

        const [isVisible, setIsVisible] = useState(false);

        const handleOnClose = useCallback(() => {
            if (isDeleting || showConfirmDelete) return;
            setIsVisible(false);
            onClose && onClose();
        }, [isDeleting, onClose, showConfirmDelete]);

        const onClickOutside = useCallback(
            (e) => {
                if (
                    !headerRef.current?.contains(e.target) &&
                    !footerRef.current?.contains(e.target) &&
                    !contentRef.current?.contains(e.target)
                ) {
                    handleOnClose();
                }
            },
            [handleOnClose],
        );

        useEffect(() => {
            document.addEventListener('mousedown', onClickOutside);
            return () => {
                document.removeEventListener('mousedown', onClickOutside);
            };
        }, [isDeleting, onClickOutside]);

        const classNames = useMemo(() => {
            let classes = ['fm-entity-detail-popover'];
            if (className) classes.push(className);
            return classes;
        }, [className]);

        const handleOnPopupVisibleChange = useCallback(
            (visible) => {
                setIsVisible(visible);
                if (!visible && showConfirmDelete) {
                    // timeout to prevent undesired visual effect
                    setTimeout(() => {
                        setShowConfirmDelete(false);
                    }, 250);
                }
                onPopupVisibleChange && onPopupVisibleChange(visible);

                if (visible) {
                    trackEvent({ entity, entityId, idEventType: EVENT_TYPES.read });
                }
            },
            [showConfirmDelete, onPopupVisibleChange, entity, entityId],
        );

        const handleOnDelete = useCallback(() => {
            if (onDelete) {
                setIsDeleting(true);
                onDelete()
                    .then(() => {
                        successToast({ text: getLiteral('succes_entitydeletedsuccessfully') });
                        handleOnClose();
                    })
                    .catch(() => {
                        setIsDeleting(false);
                        errorToast({ text: getLiteral('error_generalerror') });
                        handleOnClose();
                    });
            }
        }, [handleOnClose, onDelete]);

        const handleEdit = useCallback(() => {
            setIsVisible(false);
            onEdit && onEdit();
        }, [onEdit]);

        const header = useMemo(() => {
            return (
                <>
                    <div
                        className="fm-entity-detail-popover__header"
                        ref={(ref) => (headerRef.current = ref)}
                    >
                        {onExpand && <Icon name="openInFull" onClick={onExpand} />}
                        {onEdit && (
                            <Icon
                                className="fm-entity-detail-popover__edit-icon"
                                name="edit"
                                onClick={handleEdit}
                            />
                        )}
                        {!onEdit && (
                            <Tooltip content={<span>{labelCantEdit}</span>}>
                                <span className="fm-entity-detail-popover__edit-icon">
                                    <Icon
                                        className="fm-entity-detail-popover__header__edit--disabled"
                                        name="edit"
                                        color={theme.colors.grey[500]}
                                    />
                                </span>
                            </Tooltip>
                        )}
                        <Icon
                            className="fm-entity-detail-popover__close-icon"
                            name="close"
                            size="large"
                            onClick={handleOnClose}
                        />
                    </div>
                    <div className="fm-entity-detail-popover__divider" />
                </>
            );
        }, [onExpand, onEdit, handleEdit, labelCantEdit, theme.colors.grey, handleOnClose]);

        const onClickDelete = useCallback(() => {
            setShowConfirmDelete(true);
        }, []);

        const onClickCancel = useCallback(() => {
            setShowConfirmDelete(false);
        }, []);

        const footer = useMemo(() => {
            if (!onDelete) return null;
            return (
                <>
                    <div className="fm-entity-detail-popover__divider" />
                    <div
                        ref={(ref) => (footerRef.current = ref)}
                        className="fm-entity-detail-popover__footer"
                    >
                        <Button onClick={onClickDelete} type="secondary-error">
                            {getLiteral('action_delete')}
                        </Button>
                    </div>
                </>
            );
        }, [onDelete, onClickDelete]);

        const confirmDelete = useMemo(() => {
            return (
                <div className="fm-entity-detail-popover__confirm-delete">
                    {titleDelete && (
                        <div className="fm-entity-detail-popover__confirm-delete__title">
                            <Text type="body" bold>
                                {titleDelete}
                            </Text>
                            <Icon
                                name="close"
                                size="large"
                                onClick={handleOnClose}
                                color="currentColor"
                            />
                        </div>
                    )}
                    <div className="fm-entity-detail-popover__confirm-delete__subtitle">
                        <Text type="body">
                            {subtitleDelete ? subtitleDelete : getLiteral('confirm_delete_sure')}
                        </Text>
                    </div>
                    <div className="fm-entity-detail-popover__confirm-delete__buttons">
                        <Button type="terciary" onClick={onClickCancel} isDisabled={isDeleting}>
                            {getLiteral('action_cancel')}
                        </Button>
                        <Button
                            type="primary-error"
                            onClick={handleOnDelete}
                            isLoading={isDeleting}
                        >
                            {getLiteral('action_delete')}
                        </Button>
                    </div>
                </div>
            );
        }, [onClickCancel, handleOnDelete, titleDelete, handleOnClose, isDeleting, subtitleDelete]);

        const renderContent = useMemo(() => {
            return (
                <>
                    {!showConfirmDelete && header}
                    <div
                        ref={(ref) => (contentRef.current = ref)}
                        className="fm-entity-detail-popover__content"
                    >
                        {!showConfirmDelete ? content : confirmDelete}
                    </div>
                    {!showConfirmDelete && footer}
                </>
            );
        }, [header, footer, content, showConfirmDelete, confirmDelete]);

        return (
            <Popover
                placement="right"
                {...props}
                className={classNames.join(' ')}
                classes={classes}
                content={renderContent}
                onPopupVisibleChange={handleOnPopupVisibleChange}
                visible={isVisible}
            >
                {children}
            </Popover>
        );
    },
);

export default EntityDetailPopover;
