import React, { memo, useEffect, useMemo, useCallback, useState, useRef, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ensureRoute } from 'utils/routes';
import { PATH_IMAGE } from 'constants/Environment';
import { CONTACTS, COMPANIES } from 'constants/Entities';
import {
    EntityExtraFieldsActions,
    EntityListActions,
    EntityExportActions,
    EntityListSelectActions,
    EntityDetailActions,
    EntityActions,
} from 'actions';
import { logEvent } from 'utils/tracking';
import {
    groupFieldsForFieldSelector,
    processExtraFieldsForList,
    isBackendFalsy,
    canImport,
} from 'utils/fm';
import { getContactDetailUrl, getCompanyDetailUrl } from 'utils/getUrl';
import CONFIG from './TableConfig';
import NewEntityList from 'containers/components/NewEntityList';
import CustomEmptyView from '../components/CustomEmptyView';
import UsageLimitWarning from 'containers/components/UsageLimitWarning';
import NoData from 'components/SvgIcons/emptyScreen/NoData';
import Contacts from 'components/SvgIcons/emptyScreen/Contacts';
import metadata from 'lib/metadata';
import { getLiteral } from 'utils/getLiteral';
import EntityExport from 'containers/components/modals/EntityExport';
import LinkFromWeb3Decorator from 'decorators/LinkFromWeb3Decorator';
import { Button, useTheme } from 'hoi-poi-ui';
import { getHasTabsEnabled } from 'containers/components/EntityDetail/hooks/useEntityDetail';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';

const mapStateToProps = (state) => {
    const entityFilters = state.entityFilters[CONTACTS.entity];
    const filters = entityFilters ? entityFilters.filters : {};
    const operators = entityFilters ? entityFilters.operators : {};

    const permission = state.config.permission;

    let contactsBulkActions = permission.contactsBulkActions;
    const shouldClearWeb5Checkboxes =
        state?.entityListSelect?.[CONTACTS.entity]?.shouldClearWeb5Checkboxes;

    const total = state.entityList[CONTACTS.entity]?.total;
    const isAdmin = state.config?.permission?.canConfigImplementation;

    return {
        enableBulkOperations: contactsBulkActions || state.config?.permission?.allowSendEmails,
        exportContactos: permission.exportContactos,
        canCreate: permission?.crud_permission?.[CONTACTS.permission]?.create || false,
        canConfig: permission?.manageExtraFields && isAdmin,
        filters,
        locale: state?.config?.userData?.locale || '',
        shouldClearWeb5Checkboxes,
        visibleFields: state.config?.components?.contacts_list?.visibles || null,
        total,
        operators,
        enabledUserflow: state?.config?.userData?.userFlow,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getExtraFieldSchema: bindActionCreators(EntityExtraFieldsActions, dispatch)
            .getExtraFieldSchema,
        initExport: bindActionCreators(EntityExportActions, dispatch).initExport,
        setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
        standardFieldsConfiguration: bindActionCreators(EntityListActions, dispatch)
            .standardFieldsConfiguration,
        onEntitySelect: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelect,
        onEntitySelectAll: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelectAll,
        setShouldClearWeb5Checkboxes: bindActionCreators(EntityListSelectActions, dispatch)
            .setShouldClearWeb5Checkboxes,
        open: bindActionCreators(EntityDetailActions, dispatch).open,
        getDataForChip: bindActionCreators(EntityActions, dispatch).getDataForChip,
    };
};

const Content = memo(
    ({
        enableBulkOperations,
        exportContactos,
        canCreate,
        canConfig,
        filters,
        locale,
        getExtraFieldSchema,
        initExport,
        setUseLazyLoad,
        standardFieldsConfiguration,
        onEntitySelect,
        onEntitySelectAll,
        shouldClearWeb5Checkboxes,
        setShouldClearWeb5Checkboxes,
        visibleFields,
        total,
        operators,
        enabledUserflow,
        open,
        getDataForChip,
    }) => {
        const { canUseEmail, mailToFromTable } = useEmailEditor();
        const tableRef = useRef(null);
        const theme = useTheme();

        const openTab = useCallback(
            (entity, id) => {
                if (getHasTabsEnabled()) {
                    getDataForChip(entity.redux, id).then((data) => {
                        !data?.deleted &&
                            open(
                                entity,
                                id,
                                true,
                                null,
                                false,
                                false,
                                null,
                                false, // toTab
                            );
                    });
                } else {
                    // We keep the standard link behaviour if nav tabs are not enabled
                    ensureRoute(`${entity.route}/${id}`);
                }
            },
            [getDataForChip, open],
        );

        const tableParams = useMemo(() => {
            let newTableParams = {
                getUrl: ({ idKey, entity }) => {
                    return (data) => {
                        if (!idKey || !entity) return;
                        const id = data[idKey];
                        if (!id) return;
                        let url = '';

                        switch (entity) {
                            case CONTACTS:
                                url = getContactDetailUrl(id) || '';
                                return url;
                            case COMPANIES:
                                url = getCompanyDetailUrl(id) || '';
                                return url;
                        }
                    };
                },
                date: {
                    getLocale: () => locale,
                },
                onCompanyClick: ({ data }) => {
                    !isBackendFalsy(data?.IdCompany) && openTab(COMPANIES, data.IdCompany);
                },
            };

            if (enableBulkOperations) {
                if (!newTableParams.contact) newTableParams.contact = {};
                newTableParams.contact.onClickCheckbox = (result) => {
                    const { id, isColumn, value, checked, data, rowIndex, total } = result;
                    if (isColumn) {
                        onEntitySelectAll(CONTACTS, value, checked, total);
                    } else {
                        onEntitySelect(CONTACTS, id, value, data, rowIndex, checked);
                    }
                };
            }

            if (canUseEmail) {
                newTableParams.email = {
                    forceUrl: true,
                    onClick: (data) => mailToFromTable(data, CONTACTS),
                };
            }

            return newTableParams;
        }, [
            enableBulkOperations,
            canUseEmail,
            locale,
            openTab,
            onEntitySelectAll,
            onEntitySelect,
            mailToFromTable,
        ]);

        const [config, setConfig] = useState(null);
        const [entityExportAction, setEntityExportAction] = useState(null);
        const [showModalFields, setShowModalFields] = useState(false);
        const isFirstLoad = useRef(true);

        useEffect(() => {
            setUseLazyLoad(CONTACTS, true);
            if (!isFirstLoad?.current) return;

            let newConfig = CONFIG(tableParams);
            newConfig = {
                ...newConfig,
                withEntitySelection: false,
            };

            if (enableBulkOperations) {
                newConfig = {
                    ...newConfig,
                    withEntitySelection: true,
                    selectionFor: CONTACTS.entity,
                    isLocalSelection: true,
                };
            }

            getExtraFieldSchema(newConfig.entity, (extraFieldTabs) => {
                let extraFields = [];
                const groupedExtraFields = extraFieldTabs.map((tab) => {
                    let group = {
                        label:
                            typeof tab.descripcion !== 'string'
                                ? 'label_customized_fields'
                                : tab.descripcion,
                        fields: [],
                    };

                    group.fields = processExtraFieldsForList(tab.tabFields);
                    extraFields = [...extraFields, ...group.fields];

                    return group;
                });

                standardFieldsConfiguration(CONTACTS, newConfig.columnDefs)
                    .then((columnDefs) => {
                        const newColumnDefs = [...columnDefs, ...extraFields];
                        const groupedFields = [
                            ...groupFieldsForFieldSelector(newConfig, columnDefs, true),
                            ...groupedExtraFields,
                        ];

                        newConfig = {
                            ...newConfig,
                            columnDefs: newColumnDefs,
                            groupedFields,
                        };
                        setConfig(newConfig);
                    })
                    .catch(() => {
                        console.error('List configuration error');
                    });
            });
            isFirstLoad.current = false;
        }, [
            tableParams,
            enableBulkOperations,
            config,
            setConfig,
            getExtraFieldSchema,
            standardFieldsConfiguration,
            setUseLazyLoad,
        ]);

        useEffect(() => {
            if (shouldClearWeb5Checkboxes) {
                if (tableRef?.current?.clearCheckboxes) tableRef.current.clearCheckboxes();
                setShouldClearWeb5Checkboxes(CONTACTS, false);
            }
        }, [setShouldClearWeb5Checkboxes, shouldClearWeb5Checkboxes]);

        const handleOnRef = useCallback((ref) => {
            tableRef.current = ref;
        }, []);

        const onInitExport = useCallback(
            (action) => {
                initExport(CONTACTS, filters, operators);
                setEntityExportAction(action);
            },
            [initExport, filters, operators],
        );

        const onClickImport = useCallback(() => {
            logEvent({
                event: CONTACTS.trueName,
                functionality: 'import',
                submodule: 'emptyScreen',
            });
            ensureRoute('/settings/import', `?tab=${CONTACTS.importExport}`);
        }, []);

        const customImportButton = useMemo(() => {
            if ((filters && filters.matchingName && filters.matchingName.value) || !canImport())
                return;
            return (
                <Button type="secondary" onClick={onClickImport}>
                    {getLiteral('action_import_contacts')}
                </Button>
            );
        }, [onClickImport, filters]);

        const emptyIcon = useMemo(() => {
            if (locale.includes('es-'))
                return <img src={`${PATH_IMAGE}illustration_video_contacts_ES.png`} />;
            return <img src={`${PATH_IMAGE}illustration_video_contacts_EN.png`} />;
        }, [locale]);

        const emptyViewProps = useMemo(() => {
            if (!metadata.showEmptyScreenVideo || enabledUserflow === 'False')
                return {
                    canCreate,
                    icon: <NoData />,
                    iconType: 'icon',
                    titleFirstTime: getLiteral('label_empty_screen_contacts'),
                    subtitleFirstTime: getLiteral('label_empty_screen_contacts_desc'),
                    emptyTitleSearch: getLiteral('error_notresultfound'),
                    emptySubtitle: getLiteral('helptext_search_filter_text'),
                    iconFirstTime: <Contacts />,
                    others: { callsToAction: [customImportButton] },
                    customButtonLiteral: getLiteral('action_add_contact'),
                };
            return {
                className: 'fm-empty-screen__container-contacts',
                canCreate,
                icon: <NoData />,
                iconType: 'video',
                onTracking: () => {
                    logEvent({
                        event: CONTACTS.trueName,
                        submodule: 'emptyScreen',
                        functionality: 'video',
                    });
                },
                titleFirstTime: getLiteral('label_empty_screen_contacts'),
                subtitleFirstTime: getLiteral('label_empty_screen_contacts_desc'),
                emptyTitleSearch: getLiteral('error_notresultfound'),
                emptySubtitle: getLiteral('helptext_search_filter_text'),
                iconFirstTime: emptyIcon,
                others: { callsToAction: [customImportButton] },
                customButtonLiteral: getLiteral('action_add_contact'),
            };
        }, [canCreate, customImportButton, emptyIcon, enabledUserflow]);

        const getFieldSelectorProps = useCallback(() => {
            if (!config.groupedFields) return null;
            let actions = [];

            actions.push({
                type: 'title',
                label: getLiteral('label_customize'),
            });

            actions.push({
                label: getLiteral('action_select_visible_columns'),
                icon: 'columns',
                id: 'columns',
                onClick: () => {
                    setShowModalFields(true);
                    logEvent({
                        event: CONTACTS.trueName,
                        functionality: 'visibleFields',
                        checkDuplicate: true,
                    });
                },
            });

            if (canConfig) {
                actions.push({
                    label: getLiteral('action_add_custom_field'),
                    icon: 'plus',
                    id: 'add_custom_field',
                    color: theme.colors.actionMajor[500],
                    onClick: () => {
                        ensureRoute('/settings/fields', '?tab=Contactos&showCrud=true');
                        logEvent({
                            event: CONTACTS.trueName,
                            submodule: 'threeDots',
                            functionality: 'createField',
                        });
                    },
                });
            }

            if (exportContactos) {
                actions.push({
                    type: 'divider',
                });

                actions.push({
                    type: 'title',
                    label: getLiteral('title_excel_export_email'),
                });

                actions.push({
                    label: getLiteral('label_export_excel'),
                    icon: 'downloadAlt',
                    id: 'excel',
                    onClick: () => onInitExport('excel'),
                    disabled: !total || total === 0,
                });
            }

            return {
                actions,
                title: getLiteral('label_showHideColumns'),
                groupedColumns: config.groupedFields,
            };
        }, [config, canConfig, exportContactos, onInitExport, total, theme]);

        const getRowNodeId = useCallback((data) => data.Id, []);

        const modalOptionsProps = useCallback(
            () => ({
                advice: getLiteral('label_visible_fields_explanation'),
                isOpen: showModalFields,
                confirmText: getLiteral('action_save'),
                cancelText: getLiteral('action_cancel'),
                placeholderSearch: getLiteral('label_search_field'),
                title: getLiteral('label_visible_fields'),
                onCancel: setShowModalFields,
            }),
            [showModalFields],
        );

        const entityExportProps = useMemo(
            () => ({
                action: entityExportAction,
                config: config,
                fields: {
                    download: true,
                    emails: true,
                    freeEmails: false,
                    freeSingleEmail: true,
                    users: false,
                },
                visibleFields: visibleFields,
                entity: CONTACTS,
            }),
            [config, entityExportAction, visibleFields],
        );

        return (
            <Fragment>
                <UsageLimitWarning entity={CONTACTS} />
                <div className="contacts-table">
                    {config && (
                        <NewEntityList
                            id="fm-grid-contacts"
                            entity={CONTACTS}
                            config={config}
                            initOnReady={true}
                            useLazyLoad={true}
                            useCache={true}
                            useWeb3Cache={true}
                            useSort={true}
                            useDragColumns={true}
                            useFieldSelector={true}
                            fieldSelectorProps={getFieldSelectorProps()}
                            getRowNodeId={getRowNodeId}
                            emptyViewProps={emptyViewProps}
                            customEmptyViewComponent={CustomEmptyView}
                            useSelectColumns={true}
                            handleOnRef={handleOnRef}
                            discardCount={true}
                            modalOptionsProps={modalOptionsProps()}
                        />
                    )}
                    <EntityExport {...entityExportProps} />
                </div>
            </Fragment>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(LinkFromWeb3Decorator(Content));
