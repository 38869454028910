import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Markdown, Modal } from 'hoi-poi-ui';
import { ConfigActions } from 'actions';
import { DANA_GOLDEN_MINUTE_DEBUG_TOOLS } from 'constants/Features';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';

const danaActivatedDisclaimerPermission = {
    key: 'danaActivatedDisclaimer',
    value: true,
};

const useDana = ({ onConfirmDisclaimerSuccess, overrideModalOverlayClassName } = {}) => {
    const dispatch = useDispatch();
    const [danaModalAfterDisclaimer, setDanaModalAfterDisclaimer] = useState(null);
    const shouldUpdateDanaPermisionRef = useRef(false);

    // Destructure separate values and memoize to avoid object re-creation and prevent re-renders
    const danaActivated = useSelector((state) => state.config.permission?.danaActivated || false);
    const danaActivatedDisclaimer = useSelector(
        (state) => state.config.permission?.danaActivatedDisclaimer || false,
    );
    const hasDanaDebugTools = useSelector(
        (state) => state.config?.flagsmith?.[DANA_GOLDEN_MINUTE_DEBUG_TOOLS] || false,
    );
    const danaState = useMemo(
        () => ({ danaActivated, danaActivatedDisclaimer, hasDanaDebugTools }),
        [danaActivated, danaActivatedDisclaimer, hasDanaDebugTools],
    );

    const onCancelDisclaimer = useCallback(() => {
        setDanaModalAfterDisclaimer(null);
    }, []);

    const onConfirmDisclaimer = useCallback(() => {
        dispatch(ConfigActions.setDisclaimer(danaActivatedDisclaimerPermission, false))
            .then((response) => {
                if (response.State === '1') {
                    shouldUpdateDanaPermisionRef.current = true;
                    onConfirmDisclaimerSuccess &&
                        onConfirmDisclaimerSuccess({ danaModalAfterDisclaimer });
                } else {
                    errorToast({ text: getLiteral('label_dana_disclaimer_error') });
                }
                setDanaModalAfterDisclaimer(null);
            })
            .catch(() => {
                errorToast({ text: getLiteral('label_dana_disclaimer_error') });
                setDanaModalAfterDisclaimer(null);
            });
    }, [danaModalAfterDisclaimer, dispatch, onConfirmDisclaimerSuccess]);

    const danaDisclaimerOnClose = useCallback(() => {
        if (shouldUpdateDanaPermisionRef.current) {
            shouldUpdateDanaPermisionRef.current = false;
            dispatch(ConfigActions.updateSpecificPermissions([danaActivatedDisclaimerPermission]));
        }
    }, [dispatch]);

    const renderDanaDisclaimer = useMemo(
        () => (
            <Modal
                title={getLiteral('label_danai_title_accept_tos')}
                className="fm-entity-modal__dana_disclaimer"
                isOpen={!!danaModalAfterDisclaimer}
                onRequestClose={onCancelDisclaimer}
                onCancel={onCancelDisclaimer}
                onConfirm={onConfirmDisclaimer}
                cancelText={getLiteral('label_danai_button_deny_tos')}
                confirmText={getLiteral('label_danai_button_accept_tos')}
                overlayClassName={overrideModalOverlayClassName}
            >
                <Markdown
                    content={getLiteral('label_danai_text_accept_tos')}
                    overrides={{ a: { target: '_blank' } }}
                />
            </Modal>
        ),
        [
            danaModalAfterDisclaimer,
            onCancelDisclaimer,
            onConfirmDisclaimer,
            overrideModalOverlayClassName,
        ],
    );

    return {
        ...danaState,
        danaDisclaimerOnClose,
        setDanaModalAfterDisclaimer,
        renderDanaDisclaimer,
    };
};

export default useDana;
