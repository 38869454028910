import { memo, useMemo, useRef, useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Context from 'managers/Context';
import { LookerService } from 'services';
import { getDocumentToBlob } from 'services/DocumentsService';

import { EntityModalActions } from 'actions';
import { ANALYTICS_REPORT, REPORTS } from 'constants/Entities';
import { METABASE_REPORT } from 'constants/Constants';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { getUserSfmUrl } from 'utils/getUrl';
import { hashHistory } from 'utils/routes';
import { isBackendFalsy } from 'utils/fm';
import AnalyticsReportModel from 'models/AnalyticsReportModel';

import { FolderSvg, FolderKeySvg, DashboardSvg, ReportSvg } from 'components/SvgString';
import { ShareFilled } from 'components/SvgIcons';

import ViewerModal from 'components/ViewerModal';
import { DeleteIcon, EditIcon, DuplicateIcon } from 'containers/components/NewEntityList/icons';
import NewEntityList from 'containers/components/NewEntityList';
import ShareModal from 'containers/components/ShareModal';
import ErrorReportDialog from 'containers/components/dialog/ErrorReportDialog';
import AnalyticsDragPreview from './DashboardDragPreview';
import DashboardDeleteModal from './DashboardDeleteModal';
import ReportsParametersDialog from './ReportsParametersDialog';
import ReportsTableModal from './ReportsTableModal';

import CONFIG from './TableConfig';

const SharedList = ({
    entity = ANALYTICS_REPORT,
    onDrop,
    dragInfo,
    refreshList,
    refreshFavorites,
    onChange,
    onEditFolder,
    innerData,
    emptyViewProps,
    customEmptyViewComponent,
    columnsToInclude,
}) => {
    const dispatch = useDispatch();
    const [viewerData, setViewerData] = useState(null);

    const reportsModal = useSelector((state) => state[REPORTS.entity]?.modals);
    const report = useSelector((state) => state[REPORTS.entity]?.report);

    const canDeleteAnalyticsProFolder = useSelector(
        (state) =>
            state.config?.permission?.analyticsPro &&
            state.config?.permission?.analyticsProDeleteFolder,
    );
    const canEditAnalyticsProFolder = useSelector(
        (state) =>
            state.config?.permission?.analyticsPro &&
            state.config?.permission?.analyticsProEditFolder,
    );
    const canCreateAnalyticsProDashboard = useSelector(
        (state) =>
            state.config?.permission?.analyticsPro &&
            state.config?.permission?.analyticsProCreateDashboard,
    );

    const [rowToDelete, setRowToDelete] = useState(null);
    const favoriteChanges = useRef({});

    const getRowNodeId = useCallback((data) => {
        if (data.isDashboardFolder) return `dashboard-folder-${data.id}`;
        if (data.isDashboard) return `dashboard-${data.id}`;
        if (data.isReportFolder) return `report-folder-${data.id}`;
        if (data.isReport) return `report-${data.id}`;
        return data.id;
    }, []);

    // Drag and drops handlers
    const canDrop = useCallback(
        (row) =>
            dragInfo && row.isDashboardFolder && getRowNodeId(row) !== dragInfo.current.dragged,
        [dragInfo, getRowNodeId],
    );

    const canDrag = useCallback(
        (row) => {
            if (!dragInfo) return;
            if (row?.isDashboardFolder && canEditAnalyticsProFolder) return true;
            if (row?.isDashboard && canCreateAnalyticsProDashboard) return true;
        },
        [canCreateAnalyticsProDashboard, canEditAnalyticsProFolder, dragInfo],
    );

    // CRUD Handlers
    const onEdit = useCallback(
        (item) => {
            if (item.isDashboard) {
                LookerService.getDashboard(item.id)
                    .then((result) => {
                        dispatch(
                            EntityModalActions.init({
                                entity: ANALYTICS_REPORT,
                                data: AnalyticsReportModel.toCrud({ data: result }),
                                labels: {
                                    title: getLiteral('label_analytics_pro_edit_report'),
                                    success: getLiteral('succes_entityupdatedsuccessfully'),
                                    error: getLiteral('label_failed_update'),
                                },
                                version: 2,
                            }),
                        );
                    })
                    .catch(() => {
                        errorToast({ text: getLiteral('error_tryitagain') });
                    });
            } else if (item.isDashboardFolder) {
                onEditFolder(item);
            }
        },
        [dispatch, onEditFolder],
    );

    const onDelete = useCallback((item) => {
        setRowToDelete(item);
    }, []);

    const onDuplicate = useCallback(
        (item) => {
            if (!item.isDashboard) return;
            LookerService.duplicateDashboard(item.id)
                .then((data) => {
                    dispatch(
                        Context.actions.EntityCrudActions.init({
                            entity: ANALYTICS_REPORT,
                            id: data.code,
                        }),
                    );
                    refreshList();
                })
                .catch((e) => {
                    console.error(e);
                    errorToast({
                        text: getLiteral('label_analyticspro_toast_ko'),
                    });
                });
        },
        [dispatch, refreshList],
    );

    const onConfirm = useCallback(() => {
        refreshList();
        dispatch(Context.actions.ConfigActions.refreshWebTemplates());
        setRowToDelete(null);
    }, [dispatch, refreshList]);

    const onCancel = useCallback(() => {
        setRowToDelete(null);
    }, []);

    const onCloseViewer = useCallback(() => {
        setViewerData(null);
    }, []);

    const handleDownloadPreview = useCallback(() => {
        dispatch(
            Context.actions.ReportActions.downloadReport(viewerData.data, viewerData.parameters),
        );
        onCloseViewer();
    }, [viewerData?.data, viewerData?.parameters, onCloseViewer, dispatch]);

    const getFileUrl = useCallback(() => {
        return new Promise((resolve, reject) => {
            dispatch(
                Context.actions.ReportActions.getReportLink(viewerData.data, viewerData.parameters),
            )
                .then((url) => getDocumentToBlob(url, viewerData?.format))
                .then((blob) => {
                    resolve(blob);
                })
                .catch((error) => {
                    console.error(`Couldn't get the file url`);
                    reject(error);
                });
        });
    }, [dispatch, viewerData?.data, viewerData?.format, viewerData?.parameters]);

    const config = useMemo(() => {
        let preConfig = CONFIG({
            getUrlUser: (data) => {
                if (!data.salesRepIdCreated) return;
                const id = data.salesRepIdCreated.id;
                return getUserSfmUrl(id) || '';
            },
            name: {
                getSvg: (data) => {
                    let finalSvg;
                    if (data?.isDashboardFolder || data?.isReportFolder) finalSvg = FolderSvg;
                    if (data?.isDashboardFolder && !data?.shared) finalSvg = FolderKeySvg;
                    if (data?.isDashboard) finalSvg = DashboardSvg;
                    if (data?.isReport) finalSvg = ReportSvg;
                    return finalSvg;
                },
                onClick: ({ data }) => {
                    if (data.isDashboard) {
                        hashHistory.push(`/metrics/dashboards/view/${data.id}`);
                    } else if (data.isReport) {
                        dispatch(Context.actions.ReportActions.openShareReport(data, 'download'));
                    } else {
                        onChange(data);
                    }
                },
                getShouldHide: (row) => row.isDashboardFolder || row.isReportFolder,
                onChangeFavorite: (row) => {
                    const id = getRowNodeId(row);
                    favoriteChanges.current[id] = favoriteChanges.current.hasOwnProperty(id)
                        ? !favoriteChanges.current[id]
                        : !row.data.favorite;

                    if (row.data.isReport) {
                        dispatch(
                            Context.actions.EntityActions.followEntity({
                                entityType: REPORTS,
                                entityId: row.data.id,
                                follow: row.value,
                                skipUpdate: false,
                                isShared: row.data.isShared,
                            }),
                        ).then(() => refreshFavorites(true));
                    } else {
                        let call = row.data.favorite
                            ? LookerService.unmarkFavorite
                            : LookerService.markFavorite;
                        call(row.data.id, true).then(() => refreshFavorites(true));
                    }
                },
            },
        });

        return preConfig;
    }, [dispatch, getRowNodeId, onChange, refreshFavorites]);

    // Metabase dashboards should not be editable, duplicable or deletable by any user.
    // This is something that is controlled exclusively by technical department.
    const actions = useMemo(() => {
        return [
            (row) => {
                if (!row.isDashboard || (row.isDashboard && !canCreateAnalyticsProDashboard))
                    return;
                if (parseInt(row.providerId, 10) === parseInt(METABASE_REPORT, 10)) return;
                return {
                    icon: <div onClick={() => onDuplicate(row)}>{DuplicateIcon}</div>,
                    tooltip: {
                        content: getLiteral('action_duplicate'),
                    },
                };
            },
            (row) => {
                if (row.isReport || row.isReportFolder) return;
                if (row.isDashboardFolder && !canEditAnalyticsProFolder) return;
                if (row.isDashboard && !canCreateAnalyticsProDashboard) return;
                if (parseInt(row.providerId, 10) === parseInt(METABASE_REPORT, 10)) return;
                return {
                    icon: <div onClick={() => onEdit(row)}>{EditIcon}</div>,
                    tooltip: {
                        content: getLiteral('action_edit'),
                    },
                };
            },
            (row) => {
                if (row.isReport || row.isReportFolder) return;
                if (row.isDashboardFolder && !canDeleteAnalyticsProFolder) return;
                if (row.isDashboard && !canCreateAnalyticsProDashboard) return;
                if (parseInt(row.providerId, 10) === parseInt(METABASE_REPORT, 10)) return;
                return {
                    icon: <div onClick={() => onDelete(row)}>{DeleteIcon}</div>,
                    tooltip: {
                        content: getLiteral('action_delete'),
                    },
                };
            },
            (row) => {
                if (row.isReportFolder || row.isTable || row.isDashboard || row.isDashboardFolder)
                    return;
                return {
                    icon: (
                        <div
                            className="table-reports__share-action"
                            onClick={() => {
                                dispatch(
                                    Context.actions.ReportActions.openShareReport(
                                        row,
                                        'share',
                                        false,
                                    ),
                                );
                            }}
                        >
                            <ShareFilled />
                        </div>
                    ),
                    tooltip: { content: getLiteral('action_share') },
                };
            },
        ];
    }, [
        canCreateAnalyticsProDashboard,
        canDeleteAnalyticsProFolder,
        canEditAnalyticsProFolder,
        dispatch,
        onDelete,
        onDuplicate,
        onEdit,
    ]);

    const getFavoriteRows = useCallback(
        (rowData) => {
            if (rowData?.length === 0) return;

            const newFavoriteRows = rowData.reduce((obj, current) => {
                if (!obj?.name) obj.name = {};
                if (!obj?.name?.rows) obj.name.rows = {};
                if (current) {
                    const id = getRowNodeId(current);
                    const isFolder = current.isDashboardFolder || current.isReportFolder;
                    let favorite = favoriteChanges.current.hasOwnProperty(id)
                        ? favoriteChanges.current[id]
                        : current.favorite;

                    if (!isBackendFalsy(current.followingItem)) favorite = true;

                    obj.name.rows[id] = !isFolder && favorite ? favorite : false;
                }
                return obj;
            }, {});
            return newFavoriteRows;
        },
        [getRowNodeId],
    );

    const renderParametersDialog = useMemo(() => {
        const closeReportParametersDialog = (...args) =>
            dispatch(Context.actions.ReportActions.closeReportParametersDialog(...args));

        const closeDownloadReport = (...args) =>
            dispatch(Context.actions.ReportActions.closeDownloadReport(...args));

        const handleOpenPreview = (data, parameters) => {
            if (parameters?.documentFormat?.toLowerCase() !== 'pdf') {
                dispatch(Context.actions.ReportActions.downloadReport(data, parameters));
                return;
            }
            dispatch(Context.actions.ReportActions.closeReportParametersDialog());
            setViewerData({ data, parameters });
        };

        const shareReport = (...args) =>
            dispatch(Context.actions.ReportActions.shareReport(...args));

        const openDialogTableReports = (...args) =>
            dispatch(Context.actions.ReportActions.openDialogTableReports(...args));

        if (reportsModal?.showParametersDialog) {
            if (!reportsModal?.errorLink) {
                let completeMethod =
                    reportsModal.action === 'share' ? shareReport : handleOpenPreview;
                if (report.isCrystal !== '1') completeMethod = openDialogTableReports;
                return (
                    <ReportsParametersDialog
                        show={reportsModal.showParametersDialog}
                        onClose={closeReportParametersDialog}
                        schema={reportsModal.parametersSchema}
                        onCompleteReport={completeMethod}
                        report={report}
                        loading={reportsModal.loadingParametersDialog}
                        action={reportsModal.action}
                    />
                );
            } else {
                const closeAction =
                    reportsModal.action === 'share'
                        ? closeReportParametersDialog
                        : closeDownloadReport;
                return <ErrorReportDialog show={true} onClose={closeAction} />;
            }
        }
    }, [
        reportsModal?.showParametersDialog,
        reportsModal?.errorLink,
        reportsModal?.parametersSchema,
        reportsModal?.loadingParametersDialog,
        reportsModal?.action,
        dispatch,
        report,
    ]);

    const renderShareDialog = useMemo(() => {
        const closeShareReport = (...args) =>
            dispatch(Context.actions.ReportActions.closeShareReport(...args));

        if (reportsModal?.showShareDialog) {
            if (!reportsModal.errorLink) {
                return (
                    <ShareModal
                        subject={getLiteral('label_share_report')}
                        link={reportsModal.link}
                        isOpen={reportsModal.showShareDialog}
                        isLoading={reportsModal.loadingLink}
                        onClose={closeShareReport}
                    />
                );
            } else {
                return <ErrorReportDialog show={true} onClose={closeShareReport} />;
            }
        }
    }, [
        dispatch,
        reportsModal?.errorLink,
        reportsModal?.link,
        reportsModal?.loadingLink,
        reportsModal?.showShareDialog,
    ]);

    const renderReportsTableModal = useMemo(() => {
        const exportTableReport = (...args) =>
            dispatch(Context.actions.ReportActions.exportTableReport(...args));

        const closeReportTable = (...args) =>
            dispatch(Context.actions.ReportActions.closeReportTable(...args));

        const hideGetTableReportError = (...args) =>
            dispatch(Context.actions.ReportActions.hideGetTableReportError(...args));

        if (reportsModal?.showTable) {
            if (!reportsModal?.reportData.error) {
                return (
                    <ReportsTableModal
                        showTable={reportsModal?.showTable}
                        reportData={reportsModal?.reportData}
                        report={report}
                        reportParams={reportsModal?.reportParams}
                        exportTableReport={exportTableReport}
                        closeReportTable={closeReportTable}
                    />
                );
            } else {
                return (
                    <ErrorReportDialog
                        show={true}
                        onClose={closeReportTable}
                        error={reportsModal?.reportData.errorMessage}
                    />
                );
            }
        } else if (reportsModal?.showGetTableReportError) {
            return (
                <ErrorReportDialog
                    show={reportsModal?.showGetTableReportError}
                    onClose={hideGetTableReportError}
                    error={reportsModal?.downloadError}
                />
            );
        }
    }, [
        reportsModal?.showTable,
        reportsModal?.reportData,
        reportsModal?.reportParams,
        reportsModal?.showGetTableReportError,
        reportsModal?.downloadError,
        dispatch,
        report,
    ]);

    const fileDate = useMemo(() => {
        const data = viewerData?.data || null;
        if (data?.fmodificado) return data?.fmodificado.split(' ')[0];
        return data?.fcreado?.split(' ')[0] || '';
    }, [viewerData]);

    return (
        <>
            <NewEntityList
                id="fm-grid-metrics"
                entity={entity}
                config={config}
                getRowNodeId={getRowNodeId}
                innerData={innerData}
                actions={actions}
                emptyViewProps={emptyViewProps}
                customEmptyViewComponent={customEmptyViewComponent}
                getFavoriteRows={getFavoriteRows}
                useDragCustom={canEditAnalyticsProFolder}
                canDrag={canDrag}
                customDragPreview={AnalyticsDragPreview}
                previewKey="name"
                onDragDrop={onDrop}
                canDrop={canDrop}
                columnsToInclude={columnsToInclude}
                useSort
                useCache
            />

            <DashboardDeleteModal data={rowToDelete} onConfirm={onConfirm} onCancel={onCancel} />
            {renderShareDialog}
            {renderParametersDialog}
            {renderReportsTableModal}
            {viewerData?.data && (
                <ViewerModal
                    useHeader={true}
                    isOpen={!!viewerData?.data}
                    onRequestClose={onCloseViewer}
                    size="large"
                    fileFormat={viewerData?.parameters?.documentFormat}
                    fileName={viewerData?.data?.name}
                    fileDate={fileDate}
                    getFileUrl={getFileUrl}
                    onConfirm={handleDownloadPreview}
                    onConfirmError={onCloseViewer}
                    confirmText={getLiteral('action_download')}
                    confirmErrorText={getLiteral('label_accept')}
                    isReport
                />
            )}
        </>
    );
};

export default memo(SharedList);
