import { memo, useCallback, useMemo } from 'react';
import { SectionForm, Link, Text, useTheme, Spacer, Divider } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import dot from 'utils/dot';

import { CONDITION_BOOL_LOGIC_LOCALES } from '../../../../utils';
import AutomationBuilderConditionLogicSelector from './AutomationBuilderConditionLogicSelector';
import AutomationBuilderCondition from './AutomationBuilderCondition';

const AutomationBuilderConditionGroup = memo(
    ({ index, conditionGroup, onChange, onRemove, entityFields, fields, operators }) => {
        const theme = useTheme();
        const onAddCondition = useCallback(() => {
            const newConditionGroup = {
                ...conditionGroup,
                parameters: [...conditionGroup?.parameters, {}],
            };
            onChange(newConditionGroup);
        }, [conditionGroup, onChange]);

        const onRemoveCondition = useCallback(
            (index) => {
                const newConditionGroup = {
                    ...conditionGroup,
                    parameters: dot.delete(conditionGroup?.parameters, index),
                };
                onChange(newConditionGroup);
            },
            [conditionGroup, onChange],
        );

        const onChangeBool = useCallback(
            (value) => {
                onChange({
                    ...conditionGroup,
                    type: value,
                });
            },
            [conditionGroup, onChange],
        );

        const onInnerChange = useCallback(
            (idx, value) => {
                onChange({
                    ...conditionGroup,
                    parameters: dot.set(conditionGroup?.parameters, idx, value),
                });
            },
            [conditionGroup, onChange],
        );

        const conditions = useMemo(() => {
            const checkLast = (idx) => idx < conditionGroup?.parameters?.length - 1;
            return conditionGroup?.parameters?.map((condition, idx) => (
                <>
                    <AutomationBuilderCondition
                        key={idx}
                        condition={condition}
                        onChange={(value) => onInnerChange(idx, value)}
                        onRemove={() => onRemoveCondition(idx)}
                        entityFields={entityFields}
                        fields={fields}
                        operators={operators}
                    />
                    {checkLast(idx) && (
                        <Text medium color={theme.colors.actionMinor[500]}>
                            {conditionGroup?.type === 'and'
                                ? getLiteral(CONDITION_BOOL_LOGIC_LOCALES.and)
                                : getLiteral(CONDITION_BOOL_LOGIC_LOCALES.or)}
                        </Text>
                    )}
                </>
            ));
        }, [
            conditionGroup,
            entityFields,
            fields,
            onInnerChange,
            onRemoveCondition,
            operators,
            theme.colors.actionMinor,
        ]);

        return (
            <div className="fm-automation-builder__condition-group">
                <SectionForm
                    title={`${getLiteral('label_conditions_group')} ${index + 1}`}
                    isExpandable={false}
                    onRemove={onRemove}
                >
                    {conditions}
                    <Spacer y={2} />
                    {conditions?.length > 0 && <Divider />}
                    <div className="fm-automation-builder__add-condition-group">
                        {conditions?.length > 1 && (
                            <AutomationBuilderConditionLogicSelector
                                onChange={onChangeBool}
                                value={conditionGroup.type}
                            />
                        )}
                        <span></span>
                        <Link variation="primary" onClick={onAddCondition} bold>
                            + {getLiteral('cfm_action_add_condition')}
                        </Link>
                    </div>
                </SectionForm>
            </div>
        );
    },
);

export default AutomationBuilderConditionGroup;
