import { useCallback, useMemo } from 'react';
import Context from 'managers/Context';
import { COMPANIES } from 'constants/Entities';
import CompanyDetailWidget from '../CompaniesDetail/widgets/CompanyDetailWidget';
import DanaGoldenMinuteWidget from '../CompaniesDetail/widgets/DanaGoldenMinuteWidget';
import CommentsWidget from 'containers/components/widgets/CommentsWidget';
import EntityLocationWidget from 'containers/components/widgets/EntityLocation';
import { getLiteral } from 'utils/getLiteral';
import { getBackendBoolean } from 'utils/fm';
import { successToast, errorToast } from 'utils/toast';
import useDana from 'hooks/useDana';

const useCompaniesDetail = () => {
    const state = Context.store.getState();
    const permission = state.config.permission;
    const crudPermissions = state.config.permission.crud_permission[COMPANIES.permission] || null;
    const lookerAnalyticsViewer = state.config?.userData?.lookerAnalyticsViewer || false;
    const standardFieldsSchemaMap = state.config?.standardFieldsSchemaMap || null;
    const standardFieldsConfiguration = standardFieldsSchemaMap?.[COMPANIES.extraFieldName] || null;
    const commentFieldReadOnly = standardFieldsConfiguration?.observaciones?.readOnly;

    const { danaActivated } = useDana();

    const { options, optionsParams } = useMemo(() => {
        const options = ['follow'];
        const optionsParams = ['isFollowing'];
        if (permission.merge_companies) options.push('merge');
        if (crudPermissions && crudPermissions.update) options.push('edit');
        if (crudPermissions && crudPermissions.delete) options.push('delete');
        return { options, optionsParams };
    }, [crudPermissions, permission.merge_companies]);

    const getWidgetsProps = useCallback((data) => {
        const activities = {
            addParams: {
                ic: data.id,
            },
            allParams: {
                Company: { Id: data.id, Value: data.name },
            },
        };
        return {
            33: activities,
            1: activities,
            2: {
                accountId: data.id,
                accountName: data.name,
            },
            3: activities,
        };
    }, []);

    const getInitialStateWidgets = useCallback(() => {
        let obj = {
            CompanyDetailWidget: {
                height: 260,
                maxHeight: 1000,
                isEmitted: false,
            },
        };

        if (danaActivated) {
            obj.DanaGoldenMinuteWidget = {
                height: 240,
                maxHeight: 240,
            };
        }

        obj = {
            ...obj,
            EntityLocationWidget: {
                height: 120,
                maxHeight: 300,
                isEmitted: true,
            },
            CompanyCommentsWidget: {
                height: 176,
                maxHeight: 512,
                isEmitted: false,
            },
        };
        return obj;
    }, [danaActivated]);

    const getWidgets = useCallback(
        (data, widgets, setSize, loading) => {
            let arr = [
                {
                    id: 'CompanyDetailWidget',
                    widget: (
                        <CompanyDetailWidget
                            type="generalInfo"
                            company={data}
                            setSize={setSize('CompanyDetailWidget')}
                        />
                    ),
                    x: 0,
                    y: 0,
                    width: 2,
                    height: 296,
                    fillColumn: false,
                },
            ];

            if (danaActivated) {
                arr.push({
                    id: 'DanaGoldenMinuteWidget',
                    widget: (
                        <DanaGoldenMinuteWidget
                            data={data}
                            loading={loading}
                            entity={COMPANIES}
                            id={data.id}
                            setSize={setSize('DanaGoldenMinuteWidget')}
                        />
                    ),
                    x: 2,
                    y: 0,
                    width: 2,
                    height: 240,
                });
            }

            arr = [
                ...arr,
                {
                    id: 'EntityLocationWidget',
                    widget: (
                        <EntityLocationWidget
                            type="location"
                            entityData={data}
                            entity={COMPANIES}
                            allowCheckIn={permission.checkinweb}
                            allowCheckOut={permission.blnAllowCheckOut}
                            notGeoLocalizedEntityLiteral="error_companynotgeolocalized"
                        />
                    ),
                    x: 2,
                    y: 0,
                    width: 2,
                    height: 120,
                },
                {
                    id: 'CompanyCommentsWidget',
                    widget: (
                        <CommentsWidget
                            title={getLiteral('label_comment_companies')}
                            data={data}
                            comments={data?.comments}
                            setSize={setSize('CompanyCommentsWidget')}
                            entity={COMPANIES}
                            field="comments"
                            isReadOnly={
                                !crudPermissions?.update ||
                                getBackendBoolean(data?.isReadOnly) ||
                                commentFieldReadOnly
                            }
                        />
                    ),
                    x: 2,
                    y: 0,
                    width: 2,
                    height: 176,
                },
                ...widgets,
            ];

            return arr;
        },
        [
            danaActivated,
            permission.checkinweb,
            permission.blnAllowCheckOut,
            crudPermissions?.update,
            commentFieldReadOnly,
        ],
    );

    const getWidgetsPermissions = useCallback(
        (data, permissions) => {
            return {
                CompanyCommentsWidget: !standardFieldsConfiguration?.observaciones?.isHidden,
                9: (permissions?.documentManagement && permissions?.documentos_empresas) || false,
                28: (permissions?.documentManagement && permissions?.documentos_empresas) || false,
                10: (permissions?.informes && permissions?.reports_empresas) || false,
                29: (permissions?.informes && permissions?.reports_empresas) || false,
                102: (permissions?.analyticsPro && lookerAnalyticsViewer) || false,
            };
        },
        [lookerAnalyticsViewer, standardFieldsConfiguration],
    );

    const onDelete = useCallback(() => {
        successToast({
            text: getLiteral('succes_entitydeletedsuccessfully'),
        });
    }, []);

    const onErrorDelete = useCallback(() => {
        errorToast({
            text: getLiteral('label_failed_delete_opportunity_salesforce'),
        });
    }, []);

    const onFollow = useCallback((bool) => {
        successToast({
            text: bool ? getLiteral('succes_following') : getLiteral('succes_unfollowing'),
        });
    }, []);

    const onErrorFollow = useCallback(() => {
        errorToast({
            text: getLiteral('error_following_account'),
        });
    }, []);

    const getWarning = useCallback(
        (data) => {
            if (!data?.warningText) return null;
            if (!permission.warnings) return null;
            if (!permission.editWarnings && !permission.viewWarningsInEmpresas) return null;
            return data.warningText;
        },
        [permission.editWarnings, permission.viewWarningsInEmpresas, permission.warnings],
    );

    const getOptions = useCallback(
        (data) => {
            if (!data || !Object.keys(data)?.length) return [];
            return options.filter((option) => {
                switch (option) {
                    case 'merge':
                    case 'delete':
                    case 'edit':
                        return !getBackendBoolean(data?.readOnlyCompany);
                    default:
                        return true;
                }
            });
        },
        [options],
    );

    return {
        className: 'fm-companies-detail',
        entity: COMPANIES,
        getWidgetsProps,
        getInitialStateWidgets,
        getWidgets,
        getWidgetsPermissions,
        getOptions,
        optionsParams,
        onDelete,
        onErrorDelete,
        onFollow,
        onErrorFollow,
        getWarning,
    };
};

export default useCompaniesDetail;
