import { memo, useState, useEffect, useCallback } from 'react';
import { Text, Chip } from 'hoi-poi-ui';
import { getEntityValue } from 'services/Fuzzy';
import { formatDate } from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';
import { getDayWeekOptions, getMonthOptions } from '../../../../utils';

const ConditionBoxValue = memo(({ field, condition, operator }) => {
    const getValueType = useCallback(() => {
        const value = condition?.parameters?.value;
        switch (field?.type) {
            case 'percentage':
                switch (operator) {
                    case 'number_between':
                    case 'number_not_between':
                        return `${condition?.parameters?.valueFrom}% - ${condition?.parameters?.valueTo}%`;
                    default:
                        return `${value}%`;
                }
            case 'datetime':
                switch (operator) {
                    case 'datetime_in_range':
                    case 'datetime_not_in_range':
                        return `${formatDate(condition?.parameters?.valueFrom)} - ${formatDate(condition?.parameters?.valueTo)}`;
                    case 'datetime_last_n_days':
                    case 'datetime_next_n_days':
                        return value;
                    case 'datetime_day_of_week':
                        const dayOptions = getDayWeekOptions();
                        return dayOptions.find((option) => option.value === value)?.label;
                    case 'datetime_month':
                        const monthOptions = getMonthOptions();
                        return monthOptions.find((option) => option.value === value)?.label;
                    default:
                        return value ? formatDate(value) : '';
                }
            case 'bit':
                let valueLiteral;
                switch (value) {
                    case true:
                        valueLiteral = getLiteral('cfm_label_yes');
                        break;
                    case false:
                        valueLiteral = getLiteral('cfm_label_no');
                        break;
                }
                return valueLiteral;
            default:
                switch (operator) {
                    case 'number_between':
                    case 'number_not_between':
                        return `${condition?.parameters?.valueFrom} - ${condition?.parameters?.valueTo}`;
                    case 'text_equal_calculated':
                    case 'text_not_equal_calculated':
                    case 'bool_equal_calculated':
                    case 'bool_not_equal_calculated':
                    case 'number_equal_calculated':
                    case 'number_not_equal_calculated':
                    case 'list_equal_calculated':
                    case 'list_not_equal_calculated':
                    case 'multilist_equal_calculated':
                    case 'multilist_not_equal_calculated':
                    case 'datetime_equal_calculated':
                    case 'datetime_not_equal_calculated':
                        return value?.label;
                    default:
                        return value;
                }
        }
    }, [condition, field?.type, operator]);

    const [innerValue, setInnerValue] = useState(
        ['list', 'multivalue'].includes(field?.type) ? (
            ''
        ) : (
            <Text type="subtitle" isTruncated useTooltip>
                {getValueType(condition?.parameters?.value)}
            </Text>
        ),
    );

    useEffect(() => {
        const value = condition?.parameters?.value;

        if (value?.label) {
            setInnerValue([
                <div className="fm-automation-builder__condition-box__chips">
                    <Chip isFilled>{value.label}</Chip>
                </div>,
            ]);
            return;
        }

        if (!['list', 'multivalue'].includes(field?.type)) {
            setInnerValue(
                <Text type="subtitle" isTruncated useTooltip>
                    {getValueType()}
                </Text>,
            );
            return;
        }

        const list =
            FuzzyMap[field?.tableRelated?.table?.toLowerCase()]?.list || field?.tableRelated?.table;
        getEntityValue(list, Array.isArray(value) ? value : [value])
            .then((result) => {
                if (!result?.data) return;
                const resultValue = value?.map((v) => ({
                    label: result?.data?.[v]?.description,
                    value: result?.data?.[v]?.id,
                }));

                setInnerValue(
                    <div className="fm-automation-builder__condition-box__chips">
                        {resultValue.map((v) => (
                            <Chip isFilled>{v.label}</Chip>
                        ))}
                    </div>,
                );
            })
            .catch((e) => {
                console.error(e);
                setInnerValue();
            });
    }, [condition, field?.tableRelated?.table, field?.type, getValueType, operator]);

    return innerValue;
});

export default ConditionBoxValue;
