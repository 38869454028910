import { memo, useMemo, useCallback, useState } from 'react';
import { Icon, Input, Slider, DatePicker, Text } from 'hoi-poi-ui';
import { useSelector } from 'react-redux';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';
import { formatDate } from 'utils/dates';
import {
    CONDITION_OPERATOR_LOCALES,
    CONDITION_TYPE_DEFAULTS,
    CONDITION_TYPE_NO_INPUT,
    getDayWeekOptions,
    getMonthOptions,
} from '../../../../utils';
import SelectHoi from 'components/SelectHoi';
import AdvancedSelect from 'components/AdvancedSelect';
import MultiSelect from 'containers/components/Fields/MultiSelect';
import MultiSelectFuzzy from 'containers/components/Fields/MultiSelectFuzzy';
import Select from 'containers/components/Fields/Select';
import SelectFuzzy from 'containers/components/Fields/SelectFuzzy';

const AutomationBuilderCondition = memo(
    ({ condition, onChange, onRemove, entityFields, fields, operators }) => {
        const lang = useSelector((state) => state.config?.userData?.langISOInterface);

        const boolOptions = useMemo(
            () => [
                {
                    label: getLiteral('cfm_label_yes'),
                    value: true,
                },
                {
                    label: getLiteral('cfm_label_no'),
                    value: false,
                },
            ],
            [],
        );

        const operatorOptions = useMemo(() => {
            if (!operators) return [];

            const type = condition?.parameters?.variable?.type;
            if (!type) return [];

            return (
                operators?.[type]
                    ?.filter((operator) => CONDITION_OPERATOR_LOCALES[operator])
                    ?.map((operator) => ({
                        label: getLiteral(CONDITION_OPERATOR_LOCALES[operator]),
                        value: operator,
                    })) || []
            );
        }, [condition?.parameters?.variable?.type, operators]);

        const getOtherFieldInput = useCallback(
            (type, condition) => {
                const options = fields
                    ?.map((field) => {
                        return {
                            label: field.label,
                            key: field.key,
                            options: field.options.filter(
                                (option) =>
                                    option.type === type &&
                                    option?.tableRelated?.table ===
                                        condition?.parameters?.variable?.tableRelated?.table,
                            ),
                        };
                    })
                    .filter((option) => option.options.length > 0);

                const filterOptions = options.map((option) =>
                    entityFields?.find((entity) => entity.value === option.key),
                );

                const entityKey = condition?.parameters?.value?.value
                    ?.split?.('.')
                    ?.slice?.(0, -1)
                    ?.join?.('.');

                let valueLabel =
                    entityKey !== 'event.data'
                        ? condition?.parameters?.value?.label +
                          ` (${entityFields?.find((entity) => entity.value === entityKey)?.label})`
                        : condition?.parameters?.value?.label;

                return (
                    <AdvancedSelect
                        searchPlaceholder={getLiteral('placeholder_search_field')}
                        menuTitle={getLiteral('cfm_action_add_field')}
                        placeholder={getLiteral('label_placeholder_field')}
                        onChange={onChangeValue('value', condition)}
                        options={options}
                        defaultFilter={filterOptions[0]?.value}
                        filterOptions={filterOptions}
                        value={
                            condition?.parameters?.value
                                ? {
                                      label: valueLabel,
                                      value: condition?.parameters?.value?.value,
                                  }
                                : undefined
                        }
                        isFullWidth
                        useMenuPortal={false}
                    />
                );
            },
            [entityFields, fields, onChangeValue],
        );

        const onChangeField = useCallback(
            (value) => {
                if (!value) {
                    onChange({});
                } else {
                    let operator = condition?.type;
                    operator = CONDITION_TYPE_DEFAULTS[value?.type] || operators[value?.type]?.[0];
                    onChange({
                        type: operator,
                        parameters: {
                            variable: value,
                            value: undefined,
                            valueFrom: undefined,
                            valueTo: undefined,
                        },
                    });
                }
            },
            [condition, onChange, operators],
        );

        const onChangeOperator = useCallback(
            (value) => {
                onChange({
                    ...condition,
                    type: value,
                    parameters: {
                        ...condition.parameters,
                        value: undefined,
                        valueFrom: undefined,
                        valueTo: undefined,
                    },
                });
            },
            [condition, onChange],
        );

        const onChangeValue = useCallback(
            (field = 'value', condition) =>
                (value) => {
                    onChange({
                        ...condition,
                        parameters: {
                            ...condition.parameters,
                            [field]: value,
                        },
                    });
                },
            [onChange],
        );

        const typeInputEl = useMemo(() => {
            const type = condition?.parameters?.variable?.type;
            if (!type) return null;
            const value = condition?.parameters?.value ?? '';
            const operator = condition?.type;
            const key = condition?.parameters?.variable?.key;
            let list, listProps, Component;

            // Check if operator has an input component
            if (CONDITION_TYPE_NO_INPUT.includes(operator)) return null;

            let commonProps;
            switch (type) {
                case 'text':
                    switch (operator) {
                        case 'text_equal_calculated':
                        case 'text_not_equal_calculated':
                            return getOtherFieldInput(type, condition);
                        default:
                            return (
                                <Input
                                    key={condition?.parameters?.variable?.key}
                                    onChange={onChangeValue('value', condition)}
                                    value={value}
                                    placeholder={getLiteral('placeholder_text_field')}
                                    isFullWidth
                                />
                            );
                    }
                case 'int':
                    commonProps = {
                        key,
                        onChange: onChangeValue('value', condition),
                        value: value,
                        type: 'integer',
                        placeholder: getLiteral('placeholder_numeric'),
                        isFullWidth: true,
                    };
                    switch (operator) {
                        case 'number_between':
                        case 'number_not_between':
                            return (
                                <div className="fm-automation-builder__condition__range">
                                    <Input
                                        {...commonProps}
                                        onChange={onChangeValue('valueFrom', condition)}
                                        value={condition?.parameters?.valueFrom}
                                    />
                                    <span>-</span>
                                    <Input
                                        {...commonProps}
                                        onChange={onChangeValue('valueTo', condition)}
                                        value={condition?.parameters?.valueTo}
                                    />
                                </div>
                            );
                        case 'number_equal_calculated':
                        case 'number_not_equal_calculated':
                            return getOtherFieldInput(type, condition);
                        default:
                            return <Input {...commonProps} />;
                    }
                case 'currency':
                case 'decimal':
                    commonProps = {
                        key,
                        onChange: onChangeValue('value', condition),
                        value: value,
                        type: 'decimal',
                        placeholder: getLiteral('placeholder_numeric'),
                        isFullWidth: true,
                    };
                    switch (operator) {
                        case 'number_between':
                        case 'number_not_between':
                            return (
                                <div className="fm-automation-builder__condition__range">
                                    <Input
                                        {...commonProps}
                                        key={`${key}-from`}
                                        onChange={onChangeValue('valueFrom', condition)}
                                        value={condition?.parameters?.valueFrom}
                                    />
                                    <Text>-</Text>
                                    <Input
                                        {...commonProps}
                                        key={`${key}-to`}
                                        onChange={onChangeValue('valueTo', condition)}
                                        value={condition?.parameters?.valueTo}
                                    />
                                </div>
                            );
                        case 'number_equal_calculated':
                        case 'number_not_equal_calculated':
                            return getOtherFieldInput(type, condition);
                        default:
                            return <Input {...commonProps} />;
                    }
                case 'percentage':
                    commonProps = {
                        key,
                        onChange: onChangeValue('value', condition),
                        value: value,
                        isPercentage: true,
                        isFullWidth: true,
                    };
                    switch (operator) {
                        case 'number_between':
                        case 'number_not_between':
                            return (
                                <div className="fm-automation-builder__condition__range">
                                    <Slider
                                        {...commonProps}
                                        key={`${key}-from`}
                                        onChange={onChangeValue('valueFrom', condition)}
                                        value={condition?.parameters?.valueFrom}
                                    />
                                    <Text>-</Text>
                                    <Slider
                                        {...commonProps}
                                        key={`${key}-to`}
                                        onChange={onChangeValue('valueTo', condition)}
                                        value={condition?.parameters?.valueTo}
                                    />
                                </div>
                            );
                        case 'number_equal_calculated':
                        case 'number_not_equal_calculated':
                            return getOtherFieldInput(type, condition);
                        default:
                            return <Slider {...commonProps} />;
                    }
                case 'bit':
                    switch (operator) {
                        case 'bool_equal_calculated':
                        case 'bool_not_equal_calculated':
                            return getOtherFieldInput(type, condition);
                        default:
                            return (
                                <SelectHoi
                                    key={key}
                                    onChange={onChangeValue('value', condition)}
                                    options={boolOptions}
                                    value={value}
                                    usePlainValue
                                    isFullWidth
                                    useMenuPortal={false}
                                />
                            );
                    }
                case 'datetime':
                    switch (operator) {
                        case 'datetime_in_range':
                        case 'datetime_not_in_range':
                            return (
                                <div className="fm-automation-builder__condition__range">
                                    <DatePicker
                                        key={`${key}-from`}
                                        onChange={onChangeValue('valueFrom', condition)}
                                        value={condition?.parameters?.valueFrom}
                                        lang={lang}
                                        calendarButtonLabel={getLiteral('label_today')}
                                        placeholder={getLiteral('label_selectone')}
                                        formatDate={(date) => formatDate(date, 'L')}
                                        isFullWidth
                                    />
                                    <Text>-</Text>
                                    <DatePicker
                                        key={`${key}-to`}
                                        onChange={onChangeValue('valueTo', condition)}
                                        value={condition?.parameters?.valueTo}
                                        lang={lang}
                                        calendarButtonLabel={getLiteral('label_today')}
                                        placeholder={getLiteral('label_selectone')}
                                        formatDate={(date) => formatDate(date, 'L')}
                                        isFullWidth
                                    />
                                </div>
                            );
                        case 'datetime_last_n_days':
                        case 'datetime_next_n_days':
                            return (
                                <Input
                                    key={key}
                                    onChange={onChangeValue('value', condition)}
                                    value={value}
                                    type="integer"
                                    placeholder={getLiteral('placeholder_numeric')}
                                    isFullWidth
                                />
                            );
                        case 'datetime_day_of_week':
                            return (
                                <SelectHoi
                                    key={key}
                                    onChange={onChangeValue('value', condition)}
                                    options={getDayWeekOptions()}
                                    value={value}
                                    usePlainValue
                                    isFullWidth
                                    useMenuPortal={false}
                                />
                            );
                        case 'datetime_month':
                            return (
                                <SelectHoi
                                    key={key}
                                    onChange={onChangeValue('value', condition)}
                                    options={getMonthOptions()}
                                    value={value}
                                    usePlainValue
                                    isFullWidth
                                    useMenuPortal={false}
                                />
                            );
                        case 'datetime_equal_calculated':
                        case 'datetime_not_equal_calculated':
                            return getOtherFieldInput(type, condition);
                        default:
                            return (
                                <DatePicker
                                    key={key}
                                    onChange={onChangeValue()}
                                    value={value}
                                    lang={lang}
                                    calendarButtonLabel={getLiteral('label_today')}
                                    placeholder={getLiteral('label_selectone')}
                                    formatDate={(date) => formatDate(date, 'L')}
                                    isFullWidth
                                />
                            );
                    }
                case 'list':
                    list = condition?.parameters?.variable?.tableRelated?.table;
                    listProps = FuzzyMap[list?.toLowerCase()] || list || {};
                    switch (operator) {
                        case 'list_equal_calculated':
                        case 'list_not_equal_calculated':
                            Component = listProps?.list ? SelectFuzzy : Select;
                            break;
                        default:
                            Component = listProps?.list ? MultiSelectFuzzy : MultiSelect;
                            break;
                    }

                    switch (operator) {
                        case 'list_equal_calculated':
                        case 'list_not_equal_calculated':
                            return getOtherFieldInput(type, condition);
                        default:
                            return (
                                <Component
                                    key={key}
                                    onChange={onChangeValue('value', condition)}
                                    list={condition?.parameters?.variable?.tableRelated?.table}
                                    {...listProps}
                                    value={value}
                                    useMenuPortal={false}
                                    usePlainValue
                                />
                            );
                    }
                case 'multivalue':
                    list = condition?.parameters?.variable?.tableRelated?.table;
                    listProps = FuzzyMap[list?.toLowerCase()] || list || {};
                    Component = listProps?.list ? MultiSelectFuzzy : MultiSelect;

                    switch (operator) {
                        case 'multilist_equal_calculated':
                        case 'multilist_not_equal_calculated':
                            return getOtherFieldInput(type, condition);
                        default:
                            return (
                                <Component
                                    key={key}
                                    onChange={onChangeValue('value', condition)}
                                    list={condition?.parameters?.variable?.tableRelated?.table}
                                    {...listProps}
                                    value={value}
                                    useMenuPortal={false}
                                    usePlainValue
                                />
                            );
                    }
            }
        }, [boolOptions, condition, getOtherFieldInput, lang, onChangeValue]);

        const fieldValue = useMemo(() => {
            if (!condition?.parameters?.variable) return undefined;
            const entityKey = condition?.parameters?.variable?.value
                ?.split?.('.')
                ?.slice?.(0, -1)
                ?.join?.('.');

            return {
                ...condition?.parameters?.variable,
                label:
                    entityKey !== 'event.data'
                        ? condition?.parameters?.variable?.label +
                          ` (${entityFields?.find((entity) => entity.value === entityKey)?.label})`
                        : condition?.parameters?.variable?.label,
            };
        }, [condition?.parameters?.variable, entityFields]);

        return (
            <div className="fm-automation-builder__condition">
                <div className="fm-automation-builder__condition-form">
                    <AdvancedSelect
                        searchPlaceholder={getLiteral('placeholder_search_field')}
                        menuTitle={getLiteral('cfm_action_add_field')}
                        placeholder={getLiteral('label_placeholder_field')}
                        onChange={onChangeField}
                        options={fields}
                        defaultFilter={entityFields?.[0]?.value}
                        filterOptions={entityFields}
                        value={fieldValue}
                        isFullWidth
                        useMenuPortal={false}
                    />
                    {condition?.parameters?.variable && (
                        <SelectHoi
                            options={operatorOptions}
                            onChange={onChangeOperator}
                            value={condition.type}
                            isFullWidth
                            useMenuPortal={false}
                            isReadOnly={!operatorOptions?.length}
                            usePlainValue
                            isClearable={false}
                        />
                    )}
                    {typeInputEl}
                </div>
                <Icon
                    className="fm-automation-builder__condition-form-icon"
                    name="less"
                    size="large"
                    onClick={onRemove}
                    options={operatorOptions}
                />
            </div>
        );
    },
);

export default AutomationBuilderCondition;
