import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    SalesRep,
    Outbox,
    Environment,
    MyTeam,
    WholeCompany,
    CustomGroups,
    Inbox,
} from 'components/SvgIcons';
import { Icon } from 'hoi-poi-ui';
import TruncatedTooltip from 'components/TruncatedTooltip';

const propTypes = {
    node_id: PropTypes.string,
    childLength: PropTypes.number,
    selected: PropTypes.bool,
    currentNode: PropTypes.object,
    dataSource: PropTypes.object,
    entityType: PropTypes.object,
    isOpen: PropTypes.bool,
    depth: PropTypes.number,
    onClick: PropTypes.func,
    onArrowClick: PropTypes.func,
};

class TreeRow extends PureComponent {
    state = {};

    handleArrowClick = (event) => {
        const { onArrowClick } = this.props;
        event.stopPropagation();
        event.preventDefault();
        onArrowClick();
    };

    renderIcon = () => {
        const { treeRowIconType, isOpen } = this.props;

        const types = {
            folder: (
                <Icon name={isOpen ? 'folderOpen' : 'folder'} size="large" color="currentColor" />
            ),
            user: <SalesRep color="currentColor" />,
            outbox: <Outbox color="currentColor" />,
            environment: <Environment color="currentColor" />,
            myTeam: <MyTeam color="currentColor" />,
            wholeCompany: <WholeCompany color="currentColor" />,
            customGroups: <CustomGroups color="currentColor" />,
            inbox: <Inbox color="currentColor" />,
            //ADD CATEGORIES IF NECESSARY
        };

        return <span>{types[treeRowIconType]}</span>;
    };

    render() {
        const {
            node_id,
            childLength,
            selected,
            colorIconSelected,
            dataSource,
            isOpen,
            depth,
            onClick,
        } = this.props;

        let node = dataSource[node_id].folder
            ? dataSource[node_id].folder
            : dataSource[node_id].node;

        let colorSelected = '';
        if (selected && colorIconSelected) colorSelected = colorIconSelected;

        let classNameItem = ['fm-tree-navigator-item'];
        if (selected) classNameItem.push('selected');
        if (colorSelected) classNameItem.push(colorSelected);
        if (node_id === '-1' || node_id === 'root') classNameItem.push('is-root');
        if (node_id !== '-1' && node_id !== 'root') classNameItem.push('not-root');
        if (childLength === 0) classNameItem.push('no-children');
        if (isOpen) classNameItem.push('is-open');

        return (
            <div
                className={classNameItem.join(' ')}
                style={{ marginLeft: depth * (node_id === '-1' ? 10 : 20) }}
                onClick={onClick}
            >
                <div
                    className="fm-tree-navigator-arrow-container"
                    onClick={(event) => this.handleArrowClick(event)}
                >
                    <div className="fm-tree-navigator-arrow">
                        <Icon name="arrowDropDownRaw" size="raw" color="currentColor" />
                    </div>
                </div>
                <div className="fm-tree-navigator-icon">{this.renderIcon()}</div>
                <div className="fm-tree-navigator-text">
                    <TruncatedTooltip content={node.name}>{node.name}</TruncatedTooltip>
                </div>
            </div>
        );
    }
}

TreeRow.propTypes = propTypes;

export default TreeRow;
