import { PATH_IMAGE } from 'constants/Environment';
import lottieSageLoader from './lottieFiles/sage_crm_loader.json';
import lottieSageGenerating from './lottieFiles/sage_generating.json';
import SageCopilot from './icons/SageCopilot';

const fontHeadline = '"Sage Headline", sans-serif';
const fontText = '"Sage Text", sans-serif';

export const DEFAULT_THEME = {
    colors: {
        primary: {
            brilliantGreen: 'var(--primary-brilliantGreen)',
            black: 'var(--primary-black)',
            white: 'var(--primary-white)',
        },
        accessible: {
            jadeAccessible: 'var(--accessible-jadeAccessible)',
            jadeUniversal: 'var(--accessible-jadeUniversal)',
        },
        textLight: {
            primary: 'var(--textLight-primary)',
            secondary: 'var(--textLight-secondary)',
            disabled: 'var(--textLight-disabled)',
            negative: 'var(--textLight-negative)',
            jadeAccessible: 'var(--accessible-jadeAccessible)',
            jadeUniversal: 'var(--accessible-jadeUniversal)',
        },
        textDark: {
            primary: 'var(--textDark-primary)',
            secondary: 'var(--textDark-secondary)',
            disabled: 'var(--textDark-disabled)',
            negative: 'var(--textDark-negative)',
            brilliantGreen: 'var(--primary-brilliantGreen)',
        },
        background: {
            black: 'var(--background-black)',
            gray900: 'var(--background-gray900)',
            black095: 'var(--background-black095)',
            white: 'var(--background-white)',
            black005: 'var(--background-black005)',
            utility025: 'var(--background-utility025)',
            jadeT7: 'var(--background-jadeT7)',
        },
        grey: {
            50: 'var(--grey-50)',
            100: 'var(--grey-100)',
            200: 'var(--grey-200)',
            300: 'var(--grey-300)',
            400: 'var(--grey-400)',
            500: 'var(--grey-500)',
            600: 'var(--grey-600)',
            700: 'var(--grey-700)',
            800: 'var(--grey-800)',
            900: 'var(--grey-900)',
        },
        actionMajor: {
            25: 'var(--actionMajor-25)',
            150: 'var(--actionMajor-150)',
            300: 'var(--actionMajor-300)',
            450: 'var(--actionMajor-450)',
            500: 'var(--actionMajor-500)',
            600: 'var(--actionMajor-600)',
            700: 'var(--actionMajor-700)',
        },
        actionMinor: {
            25: 'var(--actionMinor-25)',
            50: 'var(--actionMinor-50)',
            100: 'var(--actionMinor-100)',
            150: 'var(--actionMinor-150)',
            200: 'var(--actionMinor-200)',
            250: 'var(--actionMinor-250)',
            300: 'var(--actionMinor-300)',
            400: 'var(--actionMinor-400)',
            500: 'var(--actionMinor-500)',
            550: 'var(--actionMinor-550)',
            600: 'var(--actionMinor-600)',
            700: 'var(--actionMinor-700)',
            800: 'var(--actionMinor-800)',
            900: 'var(--actionMinor-900)',
        },
        semantic: {
            focusCustom100: 'var(--semantic-focusCustom100)',
            focusCustom200: 'var(--semantic-focusCustom200)',
            focus250: 'var(--semantic-focus250)',
            focusCustom400: 'var(--semantic-focusCustom400)',
            focus500: 'var(--semantic-focus500)',
            focusCustom600: 'var(--semantic-focusCustom600)',
            positiveCustom100: 'var(--semantic-positiveCustom100)',
            positiveCustom200: 'var(--semantic-positiveCustom200)',
            positiveCustom300: 'var(--semantic-positiveCustom300)',
            positive400: 'var(--semantic-positive400)',
            positive500: 'var(--semantic-positive500)',
            positive600: 'var(--semantic-positive600)',
            negativeCustom100: 'var(--semantic-negativeCustom100)',
            negativeCustom200: 'var(--semantic-negativeCustom200)',
            negative350: 'var(--semantic-negative350)',
            negative450: 'var(--semantic-negative450)',
            negative500: 'var(--semantic-negative500)',
            negative600: 'var(--semantic-negative600)',
            caution400: 'var(--semantic-caution400)',
            caution500: 'var(--semantic-caution500)',
            caution600: 'var(--semantic-caution600)',
            caution650: 'var(--semantic-caution650)',
            infoCustom50: 'var(--semantic-infoCustom50)',
            infoCustom100: 'var(--semantic-infoCustom100)',
            info150: 'var(--semantic-info150)',
            info400: 'var(--semantic-info400)',
            info500: 'var(--semantic-info500)',
            info600: 'var(--semantic-info600)',
            notification600: 'var(--semantic-notification600)',
        },
        temp: {
            purple100: 'var(--temp-purple100)',
            purple200: 'var(--temp-purple200)',
            purple300: 'var(--temp-purple300)',
            purple400: 'var(--temp-purple400)',
            purple500: 'var(--temp-purple500)',
            purple600: 'var(--temp-purple600)',
            purple700: 'var(--temp-purple700)',
            purple900: 'var(--temp-purple900)',
        },
        utility: {
            bg: 'var(--ssm-bg)',
            textPrimary: 'var(--ssm-textPrimary)',
            textSecondary: 'var(--ssm-textSecondary)',
            textDisabled: 'var(--ssm-textDisabled)',
            textNegative: 'var(--ssm-textNegative)',
            textAccessible: 'var(--ssm-textAccessible)',
            textUniversal: 'var(--ssm-textUniversal)',
        },
    },
    typography: {
        fontFamily: fontText,
        fontFamilyHeadline: fontHeadline,
        defaultText: {
            fontFamily: fontText,
        },
        smallText: {
            fontFamily: fontText,
        },
        largeText: {
            fontFamily: fontText,
        },
        bigText: {
            fontFamily: fontText,
        },
        h1: {
            fontFamily: fontHeadline,
        },
        h2: {
            fontFamily: fontHeadline,
        },
        h3: {
            fontFamily: fontHeadline,
        },
        h4: {
            fontFamily: fontText,
        },
        h5: {
            fontFamily: fontText,
        },
        h6: {
            fontFamily: fontText,
        },
        subtitle1: {
            fontFamily: fontText,
        },
        subtitle: {
            fontFamily: fontText,
        },
        body1: {
            fontFamily: fontText,
        },
        body: {
            fontFamily: fontText,
        },
        button: {
            fontFamily: fontText,
        },
        caption: {
            fontFamily: fontText,
        },
        captionMedium: {
            fontFamily: fontText,
        },
        badges: {
            fontFamily: fontText,
        },
        overline: {
            fontFamily: fontText,
        },
    },
};

export default {
    id: 'forcemanager',
    urlIdentifier: 'forcemanager',
    title: 'Sage Sales Management',
    favicon: `${PATH_IMAGE}sage_dark_favicon.png`,
    loaderLottie: lottieSageLoader,
    danaLoaderLottie: lottieSageGenerating,
    logo: `${PATH_IMAGE}Sage-Sales-Management-dark.svg`,
    bottomLoaderLogo: `${PATH_IMAGE}Sage-Sales-Management-dark.svg`,
    iframeURL: 'login_page_marketing_iframe_link',
    signupURL: 'https://www.forcemanager.com',
    appURL: 'https://onelink.to/qupted',
    showEmptyScreenVideo: true, // This was `false` for `sage_crm`
    downloadBannerLogo: `${PATH_IMAGE}DownloadApp.svg`,
    theme: {
        ...DEFAULT_THEME,
        danabox: {
            boxShadow: '0px 4px 14px 0px rgba(255, 149, 0, 0.10)',
            background:
                'linear-gradient(white, white) padding-box, linear-gradient(to right, #F76F73, #FE9C3B) border-box',
        },
    },
    settings: {
        // This was only for `sage_crm`
        inviteForm: {
            title: 'label_accept_invitation_title_sagesales',
            privacyPolicyUrl: 'label_privacy_policy_url_sagesales',
            termsAndConditionsUrl: 'label_terms_and_conditions_url_sagesales',
            accountUrl: 'label_account_url_sagesales',
            hasNewsletter: false,
        },
        // This was only for `sage_crm`
        onboardingWizard: {
            summary: {
                users: {
                    text: 'label_sage_summary_1',
                    text2: 'label_sage_summary_1_variable',
                },
                accountTypes: {
                    text: 'label_sage_summary_2',
                    text2: 'label_sage_summary_2_variable',
                },
                orderStatus: {
                    text: 'label_sage_summary_3',
                    text2: 'label_sage_summary_3_variable',
                },
                opportunityStatus: {
                    text: 'label_sage_summary_4',
                    text2: 'label_sage_summary_4_variable',
                },
            },
        },
        showComparePlans: true, // This was `false` for `sage_crm`
    },
    SageCopilotIcon: <SageCopilot />,
};
