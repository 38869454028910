import React, { memo, useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs } from 'hoi-poi-ui';
import { PRODUCTS } from 'constants/Entities';
import { ProductsActions } from 'actions';

import { logEvent } from 'utils/tracking';
import { getLiteral } from 'utils/getLiteral';
import { getWithLanguage } from 'utils/fm';

import ExtraFieldsList from 'containers/components/EntityDetail/components/ExtraFieldsList';
import DetailFiles from 'containers/components/EntityDetail/components/DetailFiles';
import RelatedProducts from '../RelatedProducts';

import './styles.scss';
import ReportsWidget from '../../../../components/widgets/Reports';

const ProductDetailTabs = memo(({ data }) => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => ({
        relatedProducts: state.config?.permission?.productos_relacionados,
        reports: state.config?.permission?.informes && state.config?.permission?.reports_productos,
        canCreateDocuments: state?.config?.permission?.crud_permission?.DOCUMENT?.create,
        canDeleteDocuments: state?.config?.permission?.crud_permission?.DOCUMENT?.delete,
        documents:
            state.config?.permission?.documentManagement &&
            state.config?.permission?.documentos_productos,
    }));
    const [currentTab, setCurrentTab] = useState('extraInfo');

    const getFiles = useCallback(
        (id) => {
            if (!id) return;
            return dispatch(ProductsActions.getFiles(id));
        },
        [dispatch],
    );

    const removeProductFile = useCallback(
        (id, fileId) => dispatch(ProductsActions.removeProductFile(id, fileId)),
        [dispatch],
    );

    const onChangeTab = useCallback(
        (tab) => {
            const tabNames = {
                extraInfo: 'extraFieldsWidget',
                documents: 'documentsWidget',
                relatedProducts: 'relatedProductsWidget',
                reports: 'reportsWidget',
            };

            if (tabNames[tab]) {
                logEvent({ event: PRODUCTS.trueName, submodule: tabNames[tab] });
            }

            if (!data) return;
            setCurrentTab(tab);
        },
        [data],
    );

    const extraFields = useMemo(() => {
        if (!data?.extraFields) return;
        let fields = JSON.parse(JSON.stringify(data?.extraFields));
        const name = getWithLanguage(data, 'ProductModel');
        const description = getWithLanguage(data, 'ProductDescription');
        const nameDescFields = [
            {
                dataType: 'text',
                id: 'name',
                descripcion: getLiteral('label_name_product'),
                value: name,
                withEmptyPlaceholder: true,
            },
            {
                dataType: 'text',
                id: 'description',
                descripcion: getLiteral('label_description'),
                value: description,
            },
        ];
        if (fields?.[0]?.data?.length && fields?.[0]?.id === '-1')
            fields[0].data = [...nameDescFields, ...fields?.[0]?.data];
        else if (name || description) {
            fields = [
                {
                    descripcion: 'General',
                    id: '-1',
                    data: nameDescFields,
                },
                ...fields,
            ];
        }
        return fields;
    }, [data]);

    const tabs = useMemo(() => {
        let finalTabs = [];

        if (data) {
            if (extraFields?.length)
                finalTabs.push({ key: 'extraInfo', title: getLiteral('label_extra_info') });
            if (permissions?.documents)
                finalTabs.push({ key: 'documents', title: getLiteral('label_documents') });
            if (permissions?.relatedProducts)
                finalTabs.push({
                    key: 'relatedProducts',
                    title: getLiteral('label_products_related'),
                });
            if (permissions?.reports)
                finalTabs.push({ key: 'reports', title: getLiteral('label_reports') });
        }

        return finalTabs;
    }, [
        data,
        extraFields?.length,
        permissions?.documents,
        permissions?.relatedProducts,
        permissions?.reports,
    ]);

    return (
        <div className="fm-products-detail__tabs">
            <Tabs
                className="fm-products__detail-tabs"
                onChange={onChangeTab}
                activeKey={currentTab}
                tabs={tabs}
            />
            {currentTab === 'extraInfo' && <ExtraFieldsList fields={extraFields} />}
            {currentTab === 'documents' && (
                <DetailFiles
                    entity={PRODUCTS}
                    entityId={data.Id}
                    getFiles={getFiles}
                    removeFile={removeProductFile}
                    canUploadImages={permissions?.canCreateDocuments}
                    canDeleteImages={permissions?.canDeleteDocuments}
                    canUploadDocuments={permissions?.canCreateDocuments}
                    showImages
                    showDocuments
                    imagesFileGroupLabel="label_other_images_with_total"
                    emptyImagesTitle="label_empty_attachments_products_title"
                    emptyImagesDesc="label_empty_attachments_products_desc"
                    emptyDocumentsTitle="label_empty_documents_products_title"
                    emptyDocumentsDesc="label_empty_documents_products_desc"
                    emptyImagesTitlePermission="label_empty_attachments_products_not_permission_title"
                    emptyImagesDescPermission="label_empty_attachments_products_not_permission_desc"
                    emptyDocumentsTitlePermission="label_empty_documents_products_not_permission_title"
                    emptyDocumentsDescPermission="label_empty_documents_products_not_permission_desc"
                />
            )}
            {currentTab === 'relatedProducts' && <RelatedProducts id={data.Id} />}
            {currentTab === 'reports' && (
                <ReportsWidget
                    type="bottomTabs"
                    entityType={PRODUCTS}
                    entity={data}
                    entityId={data.Id}
                />
            )}
        </div>
    );
});

export default ProductDetailTabs;
