import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import { getLiteral } from 'utils/getLiteral';
import Select from 'components/Select';
import { EntityChip } from 'containers/components/chips';
import { CheckboxRow } from './rows';
import { TextChip } from 'components/chips';

import './style.scss';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onRemove: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.array,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    list: PropTypes.string,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    firstErrorField: PropTypes.bool,
};

class SelectMultiple extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    onRemove = (id) => {
        const { onChange, options, value = [] } = this.props;
        const result = value.filter((v) => id !== v && id !== v?.value);
        onChange && onChange(result, options);
    };

    //Method created specifically to remove grouped chip
    onRemoveOpen = () => {
        const { onChange, options, value = [] } = this.props;

        let values = options
            .filter((option) => option.groupedKey === 'open')
            .map((option) => option.value);
        const result = value.filter((v) => !values.includes(v));

        onChange && onChange(result, options);
    };

    //Method created specifically to remove grouped chip
    onRemoveClose = () => {
        const { onChange, options, value = [] } = this.props;
        let values = options
            .filter((option) => option.groupedKey === 'closed')
            .map((option) => option.value);
        const result = value.filter((v) => !values.includes(v));
        onChange && onChange(result, options);
    };

    onChange = ([data]) => {
        const { onChange, value = [] } = this.props;
        if (!data) return;
        if (value.includes(data.value) || value?.find?.((v) => v.value === data.value)) {
            this.onRemove(data.value);
        } else onChange && onChange([...value, data.value], this.props.options);
    };

    renderSelected = () => {
        const {
            value = [],
            entityName,
            options = [],
            groupedOptions = [],
            withNoOptionValue,
        } = this.props;

        if (!Array.isArray(value)) return null;
        const mappedValues = options.reduce((obj, item) => {
            obj[item.value] = item;
            return obj;
        }, {});

        let openLength = 0;
        let closedLength = 0;

        if (groupedOptions.length !== 2) {
            return value.reduce((obj, v) => {
                let item = mappedValues[v];
                if (withNoOptionValue && !item) item = v;
                if (item)
                    obj.push(
                        <EntityChip
                            key={item?.value}
                            entity={entityName}
                            id={Number(item?.value)}
                            name={item.label}
                            remotePopover={true}
                            isDismissible={true}
                            onClickDismissible={() => this.onRemove(item.value)}
                            isClearable={false}
                        />,
                    );
                return obj;
            }, []);
        }

        if (groupedOptions.length === 2) {
            // Adding  groupedKey prop to the object in order to be able to diff open/close values
            // Open
            groupedOptions[0].options.forEach((option) => {
                mappedValues[option.value].groupedKey = 'open';
            });

            // Closed
            groupedOptions[1].options.forEach((option) => {
                mappedValues[option.value].groupedKey = 'closed';
            });
        }

        let countOpen = 0;
        let countClosed = 0;
        let openChips = [];
        let closedChips = [];

        value.forEach((v) => {
            openLength = groupedOptions[0].options.length;
            closedLength = groupedOptions[1].options.length;
            let item = mappedValues[v];
            if (!item) return null;

            if (item.groupedKey === 'open') {
                openChips.push(
                    <EntityChip
                        key={item.value}
                        entity={entityName}
                        id={Number(item.value)}
                        name={item.label}
                        remotePopover={true}
                        isDismissible={true}
                        onClickDismissible={() => this.onRemove(item.value)}
                        isClearable={false}
                    />,
                );
                countOpen++;
            } else if (item.groupedKey === 'closed') {
                closedChips.push(
                    <EntityChip
                        key={item.value}
                        entity={entityName}
                        id={Number(item.value)}
                        name={item.label}
                        remotePopover={true}
                        isDismissible={true}
                        onClickDismissible={() => this.onRemove(item.value)}
                        isClearable={false}
                    />,
                );
                countClosed++;
            }

            if (openLength === countOpen) {
                openChips = [];
                openChips.push(
                    <TextChip
                        key="openChip"
                        className="multiple-value-list-chip"
                        text={getLiteral('label_account_open')}
                        isDismissible={true}
                        onClickDismissible={this.onRemoveOpen}
                    />,
                );
            }

            if (closedLength === countClosed) {
                closedChips = [];
                closedChips.push(
                    <TextChip
                        key="closeChip"
                        className="multiple-value-list-chip"
                        text={getLiteral('label_account_closed')}
                        isDismissible={true}
                        onClickDismissible={this.onRemoveClose}
                    />,
                );
            }
        });

        return [openChips, closedChips];
    };

    renderError = () => {
        let { error } = this.props;

        if (!error || typeof error === 'object') return null;
        return <div className="fm-field-error">{error}</div>;
    };

    formatOptionLabel = (item) => {
        const { value = [], getExtraClassNameValue, withNoOptionValue } = this.props;
        let className = getExtraClassNameValue ? getExtraClassNameValue(item) : '';
        let isChecked = value?.includes?.(item.value);
        if (withNoOptionValue && !isChecked)
            isChecked = !!value?.find?.((v) => v.value === item.value);
        return <CheckboxRow isChecked={isChecked} label={item.label} className={className} />;
    };

    render() {
        let {
            list,
            hidden,
            loading,
            options = [],
            groupedOptions,
            value,
            label,
            hint,
            mandatory,
            description,
            error,
            labelMode,
            disabled,
            readOnly,
            isBulkAction,
            getExtraClassNameValue,
            ...props
        } = this.props;

        if (hidden) return null;

        let classes = ['fm-field-select'];
        if (error) classes.push('fm-field__error');

        let finalOptions = options;
        if (groupedOptions) finalOptions = groupedOptions;

        return (
            <div className={classes.join(' ')}>
                <Base
                    label={label}
                    mandatory={mandatory}
                    description={description}
                    labelMode={labelMode}
                    isBulkAction={isBulkAction}
                    onReset={this.props.onChange}
                >
                    <Select
                        {...props}
                        value={[]}
                        onChange={this.onChange}
                        options={finalOptions}
                        placeholder={hint || getLiteral('label_selectone')}
                        isClearable={false}
                        isDisabled={disabled || readOnly}
                        isMulti={true}
                        hideSelectedOptions={true}
                        formatOptionLabel={this.formatOptionLabel}
                        closeMenuOnSelect={false}
                    />
                    {this.renderError()}
                    <div className={`fm-field-container-selected fm-field-multi-selected`}>
                        {this.renderSelected()}
                    </div>
                </Base>
            </div>
        );
    }
}

SelectMultiple.propTypes = propTypes;

export default SelectMultiple;
