import { memo, useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { SectionForm } from 'hoi-poi-ui';

import { getAutomationVariables } from 'services/AutomationsService';

import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';

import SelectHoi from 'components/SelectHoi';
import { AutomationBuilderCanvasContext } from '../../AutomationBuilderCanvasContext';
import AutomationBuilderSidePanel from '../AutomationBuilderSidePanel';

import UpdateEntityRelatedAction from './actions//UpdateEntityRelatedAction';

const ACTION_MAP = {
    update_entity_related: UpdateEntityRelatedAction,
};

const AutomationBuilderActionSidePanel = memo(
    ({ node, data, nodePath, onChange, onClose, isTouched, setTouched }) => {
        const [entities, setEntities] = useState([]);
        const { getActions } = useContext(AutomationBuilderCanvasContext);
        const [actions, setActions] = useState();
        const [form, setForm] = useState(() => {
            let newNode = JSON.parse(JSON.stringify(node));
            if (newNode.name) newNode = ACTION_MAP[newNode.name].getInitialData(newNode);
            return newNode;
        });
        const [variables, setVariables] = useState();

        const getVariablesData = useCallback(() => {
            getAutomationVariables(data?.scene)
                .then((result) => {
                    setEntities(
                        result.entity.map((entity) => ({
                            label: getLiteral(entity.name),
                            options: entity.fields.map((field) => ({
                                label: getLiteral(field.literal),
                                value: field.value,
                                iconType: field?.entity,
                                original: field,
                            })),
                        })),
                    );

                    setVariables(result);
                })
                .catch((e) => {
                    console.error(e);
                    errorToast({ text: getLiteral('error_generalerror') });
                });
        }, [data?.scene]);

        useEffect(() => {
            // Getting actions and select lists when updating
            if (actions?.length > 0) return;

            getActions().then((result) => {
                setActions(result);
            });

            if (variables) return;
            getVariablesData();
        }, [actions?.length, getActions, getVariablesData, variables]);

        const changeEvent = useCallback(
            (value) => {
                setTouched(true);
                setForm(() => {
                    const newObj = {
                        name: value,
                        parameters: {},
                    };
                    return newObj;
                });

                getVariablesData();
            },
            [getVariablesData, setTouched],
        );

        const changeEntity = useCallback(
            (value) => {
                setTouched(true);
                setForm((current) => {
                    const newObj = {
                        ...current,
                        parameters: {
                            entity: value,
                        },
                        fields: [],
                        values: {},
                        operators: {},
                    };
                    return newObj;
                });

                if (!value) return;
            },
            [setTouched],
        );

        const onChangeAction = useCallback(
            (cb) => {
                setTouched(true);
                setForm(cb);
            },
            [setTouched],
        );

        const onDone = useCallback(() => {
            onChange(nodePath, {
                name: form.name,
                parameters: ACTION_MAP[form?.name].getSaveData(form),
                type: 'action',
            });
            setTouched(false);
        }, [setTouched, onChange, nodePath, form]);

        const isDoneDisabled = useMemo(() => {
            if (!form?.name) return true;
            if (!isTouched) return true;
            if (!ACTION_MAP[form?.name]) return true;
            return ACTION_MAP[form?.name].hasEmptyFields?.(form);
        }, [form, isTouched]);

        const ActionComponent = useMemo(() => ACTION_MAP[form?.name] || null, [form.name]);

        return (
            <AutomationBuilderSidePanel
                title={getLiteral('label_action')}
                subtitle={getLiteral('label_action_desc')}
                onClose={onClose}
                isDoneDisabled={isDoneDisabled}
                onDone={onDone}
            >
                <SectionForm title={getLiteral('label_action')} isExpandable={false}>
                    <SelectHoi
                        label={getLiteral('label_action_event')}
                        placeholder={getLiteral('label_placeholder_event')}
                        options={actions}
                        onChange={changeEvent}
                        value={form.name}
                        useMenuPortal={false}
                        isFullWidth
                        usePlainValue
                    />
                    <SelectHoi
                        label={getLiteral('label_action_entity')}
                        placeholder={getLiteral('label_placeholder_entity')}
                        options={entities}
                        onChange={changeEntity}
                        value={form.parameters?.entity}
                        useMenuPortal={false}
                        isReadOnly={!form.name}
                        isFullWidth
                    />
                </SectionForm>
                {ActionComponent && (
                    <ActionComponent
                        onChange={onChangeAction}
                        data={data}
                        form={form}
                        variables={variables}
                        action={actions?.find((a) => a.value === form.name)}
                    />
                )}
            </AutomationBuilderSidePanel>
        );
    },
);

export default AutomationBuilderActionSidePanel;
