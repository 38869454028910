import { memo, useMemo, useState, useCallback } from 'react';
import { SectionList } from '@web/web5';
import { getLiteral } from 'utils/getLiteral';
import { SFMSvg } from 'components/SvgString';

const SFMList = ({}) => {
    const [rows, _] = useState([
        {
            name: getLiteral('label_intensity'),
            id: 'intensity',
            description: getLiteral('label_intensity_description'),
        },
        {
            name: getLiteral('label_distribution'),
            id: 'distribution',
            description: getLiteral('label_distribution_description'),
        },
        {
            name: getLiteral('label_conversion'),
            id: 'conversion',
            description: getLiteral('label_conversion_description'),
        },
    ]);

    const tableProps = useMemo(
        () => ({
            columnDefs: [
                {
                    colId: 'name',
                    field: 'name',
                    headerName: getLiteral('label_name'),
                    headerComponent: 'headerTextCell',
                    cellRenderer: 'avatarCell',
                    cellRendererParams: {
                        innerMethods: {
                            getAvatarSvg: () => SFMSvg,
                        },
                        getUrl: (data) => `#/metrics/sfm/${data.id}`,
                        imageSize: 'small',
                        isSquared: true,
                        onlyIcon: true,
                        hoverLink: true,
                        bold: true,
                    },
                    resizable: false,
                    width: 250,
                    suppressSizeToFit: true,
                    resizable: true,
                },
                {
                    colId: 'description',
                    field: 'description',
                    headerName: getLiteral('label_description'),
                    headerComponent: 'headerTextCell',
                    cellRenderer: 'textCell',
                    resizable: true,
                },
            ],
        }),
        [],
    );

    const getRowNodeId = useCallback((item) => item.id, []);

    return (
        <SectionList
            id="fm-metrics__sfm-list"
            {...tableProps}
            getRowNodeId={getRowNodeId}
            rows={rows}
            totalRowData={rows?.length}
            rowBuffer={20}
            useSort
        />
    );
};

export default memo(SFMList);
