import { memo, useCallback } from 'react';
import { Text, Button } from 'hoi-poi-ui';

import metadata from 'lib/metadata';
import { getLiteral } from 'utils/getLiteral';
import './styles.scss';

const MobileBanner = memo(() => {
    const onDownloadApp = useCallback(() => {
        window.location = metadata.appURL;
    }, []);

    return (
        <div className="fm-mobile-banner__overlay">
            <div className="fm-mobile-banner">
                <img alt="bannerLogo" src={metadata.downloadBannerLogo} />
                <Text className="fm-mobile-banner__text">
                    {getLiteral('label_download_mobile_apps')}
                </Text>
                <Button className="fm-mobile-banner__button" onClick={onDownloadApp}>
                    {getLiteral('label_downloadApp')}
                </Button>
            </div>
        </div>
    );
});

export default MobileBanner;
