import { memo } from 'react';
import { LottieGif } from '@web/web5';
import metadata from 'lib/metadata';

import './styles.scss';

const DanaLoader = memo(() => {
    return (
        <div className="dana-chat-loader">
            <LottieGif src={metadata.danaLoaderLottie} />
        </div>
    );
});

export default DanaLoader;
