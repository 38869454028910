const PermissionsPic = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={332} height={219} fill="none" {...props}>
        <path
            fill="#F1F1F1"
            stroke="#F2F5F6"
            strokeWidth={0.5}
            d="M1 24.65H.75v181.286c0 6.765 5.485 12.25 12.25 12.25h306c6.766 0 12.25-5.485 12.25-12.25V24.65H1Z"
        />
        <mask
            id="a"
            width={132}
            height={102}
            x={100}
            y={59}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: 'luminance',
            }}
        >
            <path fill="#fff" d="M231.506 59.176H100.494v101.648h131.012V59.176Z" />
        </mask>
        <g mask="url(#a)">
            <path
                fill="#00D041"
                d="M141.371 129.796a3.854 3.854 0 0 1-2.929-1.343l-10.683-12.422a3.846 3.846 0 0 1 .414-5.43 3.86 3.86 0 0 1 5.438.414l8.268 9.615 43.514-34.413a3.861 3.861 0 0 1 5.418.628 3.845 3.845 0 0 1-.629 5.409l-46.418 36.709a3.85 3.85 0 0 1-2.393.831v.002Z"
            />
            <path
                fill="#C6C6C6"
                d="M207.67 90.758a4.29 4.29 0 0 1-4.291-4.169 4.289 4.289 0 0 1 4.174-4.404l1.697-.046 1.929-8.674c1.012-4.55 4.978-7.726 9.646-7.726a4.29 4.29 0 0 1 4.294 4.288 4.29 4.29 0 0 1-4.294 4.287c-.61 0-1.129.415-1.261 1.01l-2.654 11.94a4.292 4.292 0 0 1-4.073 3.355l-5.045.14h-.122Z"
            />
            <path
                fill="#919191"
                d="M115.006 127.493h53.518c3.008 0 5.451 2.439 5.451 5.441v3.108h-64.419v-3.108a5.449 5.449 0 0 1 5.45-5.441Z"
            />
            <path
                fill="#474747"
                d="M179.979 132.989h-76.427a3.055 3.055 0 0 0-3.058 3.052 3.055 3.055 0 0 0 3.058 3.052h76.427a3.054 3.054 0 0 0 3.057-3.052 3.054 3.054 0 0 0-3.057-3.052Z"
            />
            <path
                fill="#5E5E5E"
                d="M225.835 158.707a5.664 5.664 0 0 1-5.665-5.53l-1.209-53.154-13.511 52.337a5.668 5.668 0 0 1-6.904 4.068 5.657 5.657 0 0 1-4.074-6.892l14.417-55.847.019-.067a10.88 10.88 0 0 1 4.168-5.886 10.901 10.901 0 0 1 6.941-1.977 10.842 10.842 0 0 1 7.158 3.294 10.81 10.81 0 0 1 3.042 7.26l1.289 56.606a5.664 5.664 0 0 1-5.538 5.787h-.131l-.002.001Z"
            />
            <path
                fill="#5E5E5E"
                d="M219.516 160.824a1.076 1.076 0 0 1-.311-2.103l7.787-2.353a1.075 1.075 0 0 1 .621 2.057l-7.786 2.352a1.085 1.085 0 0 1-.313.047h.002ZM199.376 156.542h-7.507a1.074 1.074 0 1 1 0-2.148h7.507a1.075 1.075 0 1 1 0 2.148Z"
            />
            <path
                fill="#E84D4F"
                d="M209.27 108.908a2.143 2.143 0 0 1-1.605-3.567l10.726-12.065a2.151 2.151 0 0 1 3.032-.18c.886.785.967 2.14.18 3.026l-10.725 12.066a2.145 2.145 0 0 1-1.607.72h-.001Z"
            />
            <path
                fill="#C6C6C6"
                d="M226.424 88.604s-6.832 2.859-13.525-1.356l5.295-4.04 8.051 4.04.179 1.356Z"
            />
            <path
                fill="#C6C6C6"
                d="M219.994 98.986a4.293 4.293 0 0 1-1.971-.48 4.285 4.285 0 0 1-1.841-5.777c2.243-4.33 4.817-9.88 5.105-11.31l.56-4.174c.217-1.609-.148-2.514-.371-2.768-.036-.041-.144-.165-.653-.165a4.29 4.29 0 0 1-4.294-4.287 4.29 4.29 0 0 1 4.294-4.287c2.842 0 5.37 1.099 7.119 3.093 2.07 2.362 2.929 5.755 2.419 9.552l-.572 4.264a3.211 3.211 0 0 0-.016.11c-.507 3.153-4.337 10.78-5.961 13.912a4.294 4.294 0 0 1-3.817 2.319l-.001-.002Z"
            />
            <path fill="#C6C6C6" d="M225.837 73.09h-9.999v12.067h9.999V73.09Z" />
            <path
                fill="#E84D4F"
                d="M222.505 60.614a2.149 2.149 0 0 1-1.319 2.738 2.153 2.153 0 0 1-2.742-1.318 2.14 2.14 0 0 1 .25-1.913 2.16 2.16 0 0 1 3.346-.273c.203.214.362.47.467.766h-.002Z"
            />
            <path
                fill="#474747"
                d="M220.309 59.742a4.7 4.7 0 0 0 .907-.432 2.144 2.144 0 0 0-2.436.693c.507 0 1.024-.082 1.529-.26Z"
            />
            <path
                fill="#E84D4F"
                d="M189.757 89.45a2.146 2.146 0 0 1-2.143-2.03 2.146 2.146 0 0 1 2.029-2.254l15.395-.825a2.148 2.148 0 0 1 2.259 2.025 2.145 2.145 0 0 1-2.029 2.255l-15.395.825a2.404 2.404 0 0 1-.116.004Z"
            />
            <path
                fill="#E84D4F"
                d="M191.934 87.409h-2.482a1.03 1.03 0 0 1-1.031-1.03 1.03 1.03 0 0 1 1.031-1.028h2.482a1.03 1.03 0 0 1 1.031 1.029 1.03 1.03 0 0 1-1.031 1.029Z"
            />
        </g>
        <path
            fill="#FEFFFF"
            d="M1 9.962C1 5.012 4.966 1 9.86 1H322.14C327.034 1 331 5.013 331 9.962V24.9H1V9.962Z"
        />
        <path
            fill="#E1242F"
            d="M17.242 12.576c0 1.444-1.157 2.614-2.584 2.614s-2.584-1.17-2.584-2.614c0-1.443 1.157-2.614 2.584-2.614s2.584 1.17 2.584 2.614Z"
        />
        <path
            fill="#EE7B2A"
            d="M24.624 12.576c0 1.444-1.157 2.614-2.584 2.614s-2.584-1.17-2.584-2.614c0-1.443 1.157-2.614 2.584-2.614s2.584 1.17 2.584 2.614Z"
        />
        <path
            fill="#43DD33"
            d="M32.007 12.576c0 1.444-1.157 2.614-2.584 2.614s-2.584-1.17-2.584-2.614c0-1.443 1.157-2.614 2.584-2.614s2.584 1.17 2.584 2.614Z"
        />
        <path
            fill="#9D9D9D"
            d="M38.651 11.99v4.694h2.842v-1.707h2.436v1.707h2.843V11.99l-4.06-4.268-4.061 4.268ZM310.329 9.962c0-.412.33-.746.738-.746h8.121c.408 0 .738.334.738.746 0 .413-.33.747-.738.747h-8.121a.742.742 0 0 1-.738-.747ZM310.329 12.203c0-.412.33-.747.738-.747h8.121c.408 0 .738.335.738.747 0 .413-.33.747-.738.747h-8.121a.742.742 0 0 1-.738-.747ZM310.329 14.444c0-.413.33-.747.738-.747h8.121c.408 0 .738.334.738.747 0 .412-.33.746-.738.746h-8.121a.742.742 0 0 1-.738-.746Z"
        />
        <path
            fill="#F1F1F1"
            d="M52.678 12.576c0-3.093 2.479-5.601 5.537-5.601h240.671c3.058 0 5.537 2.508 5.537 5.601 0 3.094-2.479 5.602-5.537 5.602H58.215c-3.058 0-5.537-2.508-5.537-5.602Z"
        />
        <path
            fill="#000"
            fillRule="evenodd"
            d="M61.25 13.672h-.251l-.089-.086c.31-.366.497-.84.497-1.355 0-1.15-.92-2.082-2.057-2.082s-2.058.932-2.058 2.082.921 2.082 2.058 2.082c.51 0 .978-.19 1.339-.503l.085.09v.252l1.583 1.599.472-.478-1.58-1.6Zm-1.9 0a1.431 1.431 0 0 1-1.425-1.441c0-.798.636-1.441 1.425-1.441.788 0 1.424.643 1.424 1.44 0 .798-.636 1.442-1.424 1.442Z"
            clipRule="evenodd"
        />
        <path
            fill="#000"
            d="M291.134 9.962v5.432h2.461v-.453h-2.013v-3.62h4.698v.905h.447V9.962h-5.593Z"
        />
        <path
            fill="#E1242F"
            fillRule="evenodd"
            d="m297.305 12.892-.342-.346-1.355 1.37-1.354-1.37-.342.346 1.355 1.37-1.355 1.371.342.346 1.354-1.37 1.355 1.37.342-.346-1.355-1.37 1.355-1.371Z"
            clipRule="evenodd"
        />
        <path
            stroke="#F2F5F6"
            strokeWidth={0.5}
            d="M331 25.15h.25V9.962c0-5.085-4.076-9.212-9.109-9.212H9.859C4.826.75.75 4.877.75 9.962V25.15H331Z"
        />
        <path
            stroke="#00D639"
            strokeDasharray="4 6"
            strokeWidth={2}
            d="M178 168.5s26.5 42 80 15.5 39-159.5 39-159.5"
        />
        <circle cx={294.5} cy={12.5} r={10.5} stroke="#00D639" strokeWidth={2} />
    </svg>
);

export default PermissionsPic;
