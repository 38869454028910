import { Map } from 'immutable';
import { ACTIVITIES, COMPANIES, OPPORTUNITIES, CONVERSATIONS, CONTACTS } from 'constants/Entities';
import { Link } from 'hoi-poi-ui';
import { getOffsetTimeZoneValues } from 'utils/dates';
import { getEntityFromString } from 'utils/getEntityFromString';
import { getGroupByParentValue } from 'utils/getGroupByParentValue';
import moment from 'moment';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { infoToast } from 'utils/toast';
import { FuzzyMap } from 'utils/fuzzy';
import { getBackendBoolean } from 'utils/fm';
import {
    beforeRenderCheckinValueList,
    beforeRenderVideoCallValueList,
    beforeRenderVideoCheckinValueList,
    beforeRenderFastCheckinValueList,
    beforeRenderOppCheckinValueList,
    beforeRenderActivityTypeValueList,
    beforeRenderCustomActivityList,
    beforeRenderWhatsAppValueList,
    moveFilters,
    addActivityTypeIdToFilters,
} from 'containers/Activities/utils/filtersFunctions';
import { getCustomizeCrudAction } from 'utils/crud';

import {
    default as ActivityModel,
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_VIDEO_CALL,
    ACTIVITY_TYPE_WORKFLOW,
    ACTIVITY_TYPE_WHATSAPP,
    ACTIVITY_ID_TYPE_CHECKIN_COMPANY,
    ACTIVITY_ID_TYPE_CHECKIN_OPPORTUNITY,
    mappedActivityTypeIdsReverse,
    activityTypeSubFilters,
} from 'models/ActivityModel';
import { PAGINATION_TABLE_ACTIVITIES } from 'constants/Environment';
import { ensureRoute } from 'utils/routes';
import { getCrudFieldConfig } from 'utils/fm/fields';
import { logEvent } from 'utils/tracking';
import { getActiveCrud } from 'utils/crud';
import { translateFollowingItemForBackend } from 'utils/filters';
import { FilesService } from 'services';
import MaxDistanceFilter from 'containers/Activities/components/components/MaxDistanceFilter';
import { getIsActivityTypeWithExtraFields } from 'containers/Activities/utils/activities';
import { getEnableTimeLine } from 'containers/Activities/utils/timeline';
import { inConversations } from 'containers/Activities/utils/conversations';
import { MAX_FILES, IMAGES_FILES_VALID_FORMATS } from 'constants/Constants';
import { getLiteralWithParametersHtml } from '../../utils/getLiteral';

const inputAttrsContacts = {
    forceDefaultSearch: '',
    parentFieldNoRemove: true,
};

let mappedListNameByFilter = null;
let gestionTimeInitial = null;

const onClickIsSuspiciousCheckIn = (entity) => {
    const state = Context.store.getState();
    const isCrossEntity = entity?.entity !== ACTIVITIES.entity;
    const filters = isCrossEntity
        ? state?.entityFilters?.[entity.entity]?.crossFilters?.[ACTIVITIES.entity] || {}
        : state?.entityFilters?.[ACTIVITIES.entity]?.filters || {};
    const isSuspiciousCheckIn = filters?.isSuspiciousCheckIn?.value || false;

    if (!isSuspiciousCheckIn) {
        logEvent({
            event: ACTIVITIES.trueName,
            submodule: 'filterBySuspiciousCheckin',
            functionality: 'true',
        });
    }

    const relatedEntity = inConversations() ? CONVERSATIONS : null;

    Context.store.dispatch(
        Context.actions.EntityFiltersActions.changeFilter({
            entity: isCrossEntity ? entity : ACTIVITIES,
            filter: { id: 'isSuspiciousCheckIn', dataType: 'bool' },
            value: !isSuspiciousCheckIn,
            refresh: true,
            completeValues: !isSuspiciousCheckIn,
            isEntityList: null,
            info: null,
            isPreload: false,
            relatedEntity,
            crossEntity: isCrossEntity ? ACTIVITIES.entity : null,
        }),
    );
};
export default class ActivitiesManager {
    getListCheckInTypeFilter = () => {
        return {
            id: 'listCheckInType',
            dataType: 'singleValueList',
            description: getLiteral('label_checkin'),
            activityTypeId: ACTIVITY_TYPE_CHECKIN.toString(),
            beforeRenderValueList: beforeRenderCheckinValueList,
            customFormatCompleteValuesToSave: this.customFormatCheckinCompleteValues,
            customFormatStandard: this.formatStandardFilters,
            isSubFilter: true,
            inputAttrs: {
                list: 'tblTiposGestion',
                actions: [
                    {
                        id: 'isSuspiciousCheckIn',
                        onClick: onClickIsSuspiciousCheckIn,
                        component: MaxDistanceFilter,
                        hint: {
                            title: getLiteral('label_suspicious_checkin_what_is'),
                            description: getLiteralWithParametersHtml(
                                'label_suspicious_checkin_what_is_desc',
                                [getLiteral('action_cross_filters_info_link')],
                                (text) => (
                                    <Link
                                        href={getLiteral(
                                            'label_distance_checkin_account_explanation_url',
                                        )}
                                        target="_blank"
                                    >
                                        {text}
                                    </Link>
                                ),
                            ),
                        },
                    },
                ],
            },
        };
    };

    getTypeFilter = () => {
        return {
            id: 'type',
            dataType: 'singleValueList',
            description: getLiteral('title_activities'),
            activityTypeId: ACTIVITY_TYPE_ANNOTATION.toString(),
            beforeRenderValueList: beforeRenderActivityTypeValueList,
            customFormatStandard: this.formatStandardFilters,
            isSubFilter: true,
            inputAttrs: {
                list: 'tblTiposGestion',
            },
        };
    };

    /*
        Activity Filters have a very custom behavior.
        -------------------------------------------
        ### activityType
        -------------------------------------------
        Any interaction with activityType will activate the subfilters: listCheckInType, fastCheckIn,
        opportunityCheckIn, callType, videoCallCheckInType, videoCallType or whatsappType.

        This is why all those filters have their "activityTypeId".
        None of those subfilters work well if the activityType counterpart is missing.

        Email is also in the group from above but with the difference that doesn't has subfilters.

        To make them work as subFilters these filters in the schema have the isSubFilter property.
        -------------------------------------------
        ### fastCheckIn and opportunityCheckIn (are related to listCheckInType)
        -------------------------------------------
        All filters with "idRelated" are double agents.
        >> fastCheckIn and opportunityCheckIn << have "idRelated" listCheckInType
        >> whatsappType << has "idRelated" type
        In frontEnd they will be stored in redux and cache under their "id" identifier.
        When sending it to backend, they'll be sent under their "idRelated" identifier.

        To achieve this we need to make different conversions and formatting in order to
        build the object to send to backend. Those convertions are made just before sending
        them to backend without affecting the frontEnd flow.
    */

    getActivityTypePermissions = () => {
        const state = Context.store.getState();
        const permission = state.config?.permission || {};
        return {
            [ACTIVITY_TYPE_CALL]: permission?.getPhoneCalls,
            [ACTIVITY_TYPE_CHECKIN]: permission?.gestiones,
            [ACTIVITY_TYPE_VIDEO_CHECKIN]: permission?.enableVideoCalls,
            [ACTIVITY_TYPE_FAST_CHECKIN]: permission?.blnAllowFastCheckin,
            [ACTIVITY_TYPE_OPPORTUNITY_CHECKIN]: permission?.opportunitiesGeolocation,
            [ACTIVITY_TYPE_EMAIL]: permission?.getEmails,
            [ACTIVITY_TYPE_ANNOTATION]: permission?.gestiones,
            [ACTIVITY_TYPE_VIDEO_CALL]: permission?.enableVideoCalls,
            [ACTIVITY_TYPE_WHATSAPP]: permission?.gestiones,
        };
    };

    getFilterSchema = (success, error) => {
        const state = Context.store.getState();
        const currentUser = `${state.config.userData.nombre} ${state.config.userData.apellidos}`;

        let filterSchema = [
            {
                id: 'activityType',
                avoidListAsObject: true,
                dataType: 'singleValueList',
                description: getLiteral('label_activities_types'),
                inputProps: {
                    isMulti: true,
                },
                generateOptions: () => {
                    const optionPermissions = this.getActivityTypePermissions();

                    const options = [
                        {
                            label: getLiteral('label_phoneCalls'),
                            value: ACTIVITY_TYPE_CALL.toString(),
                            iconType: 'call',
                            iconColor: 'var(--grey-500)',
                        },
                        {
                            label: getLiteral('label_checkin'),
                            value: ACTIVITY_TYPE_CHECKIN.toString(),
                            iconType: 'accountCheckin',
                            iconColor: 'var(--grey-500)',
                        },
                        {
                            label: getLiteral('label_videocheckin_meeting'),
                            value: ACTIVITY_TYPE_VIDEO_CHECKIN.toString(),
                            iconType: 'videoCheckin',
                            iconColor: 'var(--grey-500)',
                        },
                        {
                            label: getLiteral('title_fast_checkin'),
                            value: ACTIVITY_TYPE_FAST_CHECKIN.toString(),
                            iconType: 'fastCheckin',
                            iconColor: 'var(--grey-500)',
                        },
                        {
                            label: getLiteral('title_opportunity_checkin'),
                            value: ACTIVITY_TYPE_OPPORTUNITY_CHECKIN.toString(),
                            iconType: 'opportunityCheckin',
                            iconColor: 'var(--grey-500)',
                        },
                        {
                            label: getLiteral('title_emails'),
                            value: ACTIVITY_TYPE_EMAIL.toString(),
                            iconType: 'email',
                            iconColor: 'var(--grey-500)',
                        },
                        {
                            label: getLiteral('title_activities'),
                            value: ACTIVITY_TYPE_ANNOTATION.toString(),
                            iconType: 'activities',
                            iconColor: 'var(--grey-500)',
                        },
                        {
                            label: getLiteral('label_online_meeting'),
                            value: ACTIVITY_TYPE_VIDEO_CALL.toString(),
                            iconType: 'videoCamera',
                            iconColor: 'var(--grey-500)',
                        },
                        {
                            label: getLiteral('title_whatsapp_activity'),
                            value: ACTIVITY_TYPE_WHATSAPP.toString(),
                            iconType: 'whatsappGreen',
                        },
                    ];

                    const finalOptions = options.filter(
                        (current) => optionPermissions[current.value],
                    );

                    return finalOptions;
                },
                subfilterKey: 'activityTypeId',
            },
            {
                id: 'callType',
                dataType: 'singleValueList',
                description: getLiteral('label_phoneCalls'),
                activityTypeId: ACTIVITY_TYPE_CALL.toString(),
                isSubFilter: true,
                generateOptions: () => [
                    {
                        label: getLiteral('placeholder_external'),
                        value: '1',
                    },
                    {
                        label: getLiteral('label_internal'),
                        value: '0',
                    },
                    {
                        label: getLiteral('placeholder_personal'),
                        value: '2',
                    },
                ],
            },
            this.getListCheckInTypeFilter(),
            {
                id: 'videoCallCheckInType',
                dataType: 'singleValueList',
                description: getLiteral('label_videocheckin_meeting'),
                activityTypeId: ACTIVITY_TYPE_VIDEO_CHECKIN.toString(),
                beforeRenderValueList: beforeRenderVideoCheckinValueList,
                customFormatStandard: this.formatStandardFilters,
                isSubFilter: true,
                inputAttrs: {
                    list: 'tblTiposGestion',
                },
            },
            {
                id: 'fastCheckIn',
                idRelated: 'listCheckInType',
                dataType: 'singleValueList',
                description: getLiteral('title_fast_checkin'),
                activityTypeId: ACTIVITY_TYPE_FAST_CHECKIN.toString(),
                beforeRenderValueList: beforeRenderFastCheckinValueList,
                customFormatCompleteValuesToSave: this.customFormatCheckinCompleteValues,
                isSubFilter: true,
                inputAttrs: {
                    list: 'tblTiposGestion',
                },
            },
            { id: 'isSuspiciousCheckIn', dataType: 'bool' },
            {
                id: 'opportunityCheckIn',
                idRelated: 'listCheckInType',
                dataType: 'singleValueList',
                description: getLiteral('title_opportunity_checkin'),
                activityTypeId: ACTIVITY_TYPE_OPPORTUNITY_CHECKIN.toString(),
                beforeRenderValueList: beforeRenderOppCheckinValueList,
                customFormatCompleteValuesToSave: this.customFormatCheckinCompleteValues,
                isSubFilter: true,
                inputAttrs: {
                    list: 'tblTiposGestion',
                    actions: [
                        {
                            id: 'isSuspiciousCheckIn',
                            onClick: onClickIsSuspiciousCheckIn,
                            component: MaxDistanceFilter,
                        },
                    ],
                },
            },
            {
                id: 'email',
                dataType: 'singleValueList',
                description: getLiteral('title_emails'),
                activityTypeId: ACTIVITY_TYPE_EMAIL.toString(),
                isSubFilter: true,
                generateOptions: () => [
                    {
                        label: getLiteral('label_email_received'),
                        value: '0',
                        iconType: 'emailReceive',
                    },
                    {
                        label: getLiteral('label_email_sent'),
                        value: '1',
                        iconType: 'emailSend',
                    },
                ],
            },
            {
                id: 'type',
                dataType: 'singleValueList',
                description: getLiteral('title_activities'),
                activityTypeId: ACTIVITY_TYPE_ANNOTATION.toString(),
                beforeRenderValueList: beforeRenderActivityTypeValueList,
                customFormatStandard: this.formatStandardFilters,
                isSubFilter: true,
                inputAttrs: {
                    list: 'tblTiposGestion',
                },
            },
            {
                id: 'videoCallType',
                dataType: 'singleValueList',
                description: getLiteral('label_online_meeting'),
                activityTypeId: ACTIVITY_TYPE_VIDEO_CALL.toString(),
                beforeRenderValueList: beforeRenderVideoCallValueList,
                customFormatStandard: this.formatStandardFilters,
                isSubFilter: true,
                inputAttrs: {
                    list: 'tblTiposGestion',
                },
            },
            {
                id: 'whatsappType',
                idRelated: 'type',
                dataType: 'singleValueList',
                description: getLiteral('title_whatsapp_activity'),
                activityTypeId: ACTIVITY_TYPE_WHATSAPP.toString(),
                beforeRenderValueList: beforeRenderWhatsAppValueList,
                customFormatCompleteValuesToSave: this.customFormatCheckinCompleteValues,
                isSubFilter: true,
                inputAttrs: {
                    list: 'tblTiposGestion',
                },
            },
            {
                id: 'customActivities',
                dataType: 'singleValueList',
                description: getLiteral('label_filter_by_custom_activities'),
                beforeRenderValueList: beforeRenderCustomActivityList,
                inputAttrs: {
                    list: 'tblTiposGestionGeneral',
                },
            },
            {
                id: 'users',
                dataType: 'singleValueList',
                description: getLiteral('label_owners'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
            },
            {
                id: 'date',
                dataType: 'date',
                serverKeys: { from: 'dateMin', to: 'dateMax' },
                description: getLiteral('label_date'),
                customDateFormatToParams: 'YYYY-MM-DD',
            },
            {
                id: 'companies',
                dataType: 'singleValueList',
                description: getLiteral('label_account'),
                inputAttrs: {
                    ...FuzzyMap.empresas,
                },
                hint: getLiteral('action_searchcompany'),
            },
            {
                id: 'opportunities',
                dataType: 'singleValueList',
                description: getLiteral('title_opportunity'),
                inputAttrs: {
                    ...FuzzyMap.expedientes,
                },
            },
            {
                id: 'contacts',
                dataType: 'singleValueList',
                description: getLiteral('label_contacts'),
                inputAttrs: {
                    ...FuzzyMap.contactos,
                },
            },
            {
                id: 'environment',
                dataType: 'singleValueList',
                description: getLiteral('label_environment'),
                inputAttrs: {
                    list: 'tblSucursales',
                },
            },
            // {
            //     id: 'fcreado',
            //     dataType: 'date',
            //     description: getLiteral('label_created'),
            //     asExtra: true,
            //     isAudit: true,
            //     locators: {
            //         first: 'activities-filter__creation-date-start',
            //         last: 'activities-filter__creation-date-end',
            //     },
            // },
            {
                id: 'fmodificado',
                dataType: 'date',
                description: getLiteral('label_modified'),
                asExtra: true,
                isAudit: true,
                locators: {
                    first: 'activities-filter__modified-date-start',
                    last: 'activities-filter__modified-date-end',
                },
            },
            {
                id: 'FollowingItem',
                dataType: 'singleValueList',
                description: getLiteral('label_following'),
                generateOptions: () => {
                    return [
                        { label: getLiteral('label_following'), value: '1' },
                        { label: getLiteral('label_not_following'), value: '2' },
                    ];
                },
            },
            {
                id: 'subject',
                dataType: 'text',
                description: getLiteral('cfm_label_subject_email'),
            },
            {
                id: 'hasTracking',
                dataType: 'bool',
                description: getLiteral('label_email_send_with_tracking'),
            },
            {
                id: 'hasAttachment',
                dataType: 'bool',
                description: getLiteral('label_email_with_attachments'),
            },
            {
                id: 'isMarketingEmail',
                dataType: 'bool',
                description: getLiteral('label_email_send_massive'),
            },
            // Below are the filters related to the cross filters functionality of this entity
            // the one tagged as "isFakeCrossFilter" is to be shown as cross filter in the allowed entities
            // the ones tagged as "invisible" are standard filters of this entity that will be set from the
            // cross filters section of the matching entity (because UX & BE)
            {
                id: 'hasActivity',
                description: getLiteral('label_filters_cross_filters_has_activity'),
                dataType: 'bool',
                isFakeCrossFilter: true,
            },
            {
                id: 'hasAccount',
                dataType: 'bool',
                invisible: true,
            },
            {
                id: 'hasContact',
                dataType: 'bool',
                invisible: true,
            },
            {
                id: 'hasOpportunity',
                dataType: 'bool',
                invisible: true,
            },
        ];

        if (getEnableTimeLine())
            filterSchema.push({
                id: 'pendingConversations',
                dataType: 'bool',
                description: getLiteral('label_filter_pending_conversations'),
            });

        if (state.config?.permission?.allowSendEmails) {
            filterSchema.push(
                {
                    id: 'hasTracking',
                    dataType: 'bool',
                    description: getLiteral('label_email_send_with_tracking'),
                },
                {
                    id: 'hasAttachment',
                    dataType: 'bool',
                    description: getLiteral('label_email_with_attachments'),
                },
                {
                    id: 'isMarketingEmail',
                    dataType: 'bool',
                    description: getLiteral('label_email_send_massive'),
                    inputAttrs: {
                        options: [
                            { label: getLiteral('common_yes'), value: '1' },
                            { label: getLiteral('common_no'), value: '0' },
                        ],
                    },
                },
            );
        }

        mappedListNameByFilter = filterSchema.reduce((obj, current) => {
            let filter = {};

            if (current?.inputAttrs?.list) filter.listName = current?.inputAttrs.list;
            if (current?.activityTypeId && current?.beforeRenderValueList)
                filter.beforeRenderValueList = current.beforeRenderValueList;
            if (current?.idRelated) filter.idRelated = current.idRelated;
            if (Object.keys(filter).length) obj[current.id] = filter;

            return obj;
        }, {});

        return filterSchema;
    };

    getActivities(init, count, filter, success, error) {
        this.context.domainManager.getEntityList(
            ACTIVITIES.entity,
            init,
            count,
            filter,
            '',
            '',
            '',
            false,
            (activities) => {
                activities = activities || [];
                const last = activities.length < count;
                activities = activities.slice(0, count);
                const finalActivities = ActivityModel.toWidget({ data: activities });
                success(finalActivities, last);
            },
            error,
            null,
            true, // isWidget
        );
    }

    getActivitiesList(init, count, filter, success, error) {
        this.context.domainManager.getEntityList(
            ACTIVITIES.entity,
            init,
            count,
            filter,
            '',
            '',
            '',
            false,
            (activities) => {
                activities = activities || [];
                const last = activities.length < count;
                activities = activities.slice(0, count);
                const finalActivities = ActivityModel.toList(activities);
                success(finalActivities, last);
            },
            error,
        );
    }

    customInitDetail(entity, id, info) {
        let filter = {
            params: {
                id,
            },
            filter: {},
        };

        // Id's of activities are not unique :( so we need also to specify the activityType to make sure
        // we are getting the desired activity when opening the detail
        const activityType = info?.activityType || '';

        if (activityType) {
            filter.filter.activityType = {
                value: [activityType],
                completeValues: [
                    {
                        value: activityType,
                        label: '',
                    },
                ],
                id: 'activityType',
                dataType: 'singleValueList',
            };
        }

        const activityPromise = new Promise((resolve, reject) => {
            return Context.domainManager.getEntityList(
                ACTIVITIES.entity,
                0,
                1,
                filter,
                null,
                null,
                null,
                null,
                (result) => {
                    if (!result?.[0]) {
                        ensureRoute(`${ACTIVITIES.route}`);
                        console.error('There is no activity with this id');
                        resolve({});
                        return;
                    }
                    resolve({ entityData: result[0], rawData: result[0] });
                },
                (error) => {
                    reject(error);
                },
            );
        });

        const extraFieldsPromise = new Promise((resolve, reject) => {
            if (!getIsActivityTypeWithExtraFields(activityType || ACTIVITY_TYPE_ANNOTATION)) {
                resolve();
            } else {
                return Context.store
                    .dispatch(Context.actions.ActivitiesActions.getActivityExtraFields(id))
                    .then(resolve)
                    .catch(resolve);
            }
        });

        return new Promise((resolve, reject) => {
            Promise.all([activityPromise, extraFieldsPromise]).then(([activityProps, result]) => {
                resolve(activityProps);
            });
        });
    }

    hasCustomCrud(extraInfo) {
        let activityType;
        if (!extraInfo) {
            const state = Context.store.getState();
            const activeCrud = getActiveCrud(state);
            activityType = activeCrud?.extraInfo?.activityType?.toString();
        } else {
            activityType = extraInfo.activityType?.toString();
        }
        return [ACTIVITY_TYPE_CALL.toString(), ACTIVITY_TYPE_EMAIL.toString()].includes(
            activityType,
        );
    }

    customInitCrud(entity, id) {
        const state = Context.store.getState();
        const activeCrud = getActiveCrud(state);
        const activityType = activeCrud?.extraInfo?.activityType;

        let filter = {
            params: {
                id,
            },
            filter: {},
        };

        if (activityType) {
            filter.filter.activityType = {
                value: [activityType],
                completeValues: [
                    {
                        value: activityType,
                        label: '',
                    },
                ],
                id: 'activityType',
                dataType: 'singleValueList',
            };
        }

        return new Promise((resolve, reject) => {
            return Context.domainManager.getEntityList(
                ACTIVITIES.entity,
                0,
                1,
                filter,
                null,
                null,
                null,
                null,
                (result) => {
                    if (!result?.[0]) {
                        ensureRoute(`${ACTIVITIES.route}`);
                        console.error('There is no activity with this id');
                        resolve({});
                        return;
                    }
                    const finalActivity = ActivityModel.toCrudFromCustomInit(result[0]);
                    resolve(finalActivity);
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }

    avoidGetExtraFields(info) {
        return true;
    }

    getSchemaExtraField(success, error) {
        this.context.extraFieldManager.getExtraFieldSchema(
            ACTIVITIES,
            (schema) => {
                let resultSchema = Map();
                schema.forEach((value) => {
                    resultSchema = resultSchema.set(value.id, value);
                });
                success(resultSchema);
            },
            error,
        );
    }

    getSchema(defaultInputSchema) {
        let schema;
        const state = Context.store.getState();
        const activeCrud = getActiveCrud(state);
        const activityType = activeCrud?.extraInfo?.activityType?.toString();

        if (
            [ACTIVITY_TYPE_CALL.toString(), ACTIVITY_TYPE_EMAIL.toString()].includes(activityType)
        ) {
            schema = [
                {
                    title: getLiteral('label_related_entities'),
                    show: true,
                    fields: [
                        {
                            ...defaultInputSchema,
                            ...getCrudFieldConfig(ACTIVITIES, 'idEmpresa'),
                            type: 'fuzzySearchSingle',
                            label: getLiteral('label_account'),
                            hint: getLiteral('label_account'),
                            readOnly: true,
                            inputAttrs: {
                                ...FuzzyMap.empresas,
                            },
                        },
                        {
                            ...defaultInputSchema,
                            ...getCrudFieldConfig(ACTIVITIES, 'idContactMultiple'),
                            type: 'multipleFuzzySearchSingle',
                            keyIds: 'contactIdList',
                            customProcessField: this.customProcessField,
                            inputAttrs: {
                                max: 1,
                                labelButton: getLiteral('label_add_contact'),
                                parentField: 'idempresa',
                                parentFieldForBackend: 'idempresa',
                                parentFieldForContext: 'idEmpresa',
                            },
                            inputs: [
                                {
                                    ...defaultInputSchema,
                                    ...getCrudFieldConfig(ACTIVITIES, 'idContacto'),
                                    label: getLiteral('label_contact'),
                                    hint: getLiteral('label_contact'),
                                    readOnly: true,
                                    inputAttrs: {
                                        ...FuzzyMap.contactos,
                                        ...inputAttrsContacts,
                                    },
                                },
                            ],
                        },
                        {
                            ...defaultInputSchema,
                            ...getCrudFieldConfig(ACTIVITIES, 'idExpediente'),
                            type: 'fuzzySearchSingle',
                            label: getLiteral('title_opportunity'),
                            hint: getLiteral('title_opportunity'),
                            inputAttrs: {
                                groupFunction: getGroupByParentValue,
                                ...FuzzyMap.expedientes,
                                parentField: 'idempresa',
                                parentFieldForBackend: 'idinstalador',
                                parentFieldForContext: 'idEmpresa',
                                parentFieldForContext2: 'idContacto',
                                parentFieldNoRemove: true,
                                mainGroupLabel: getLiteral('label_related_opportunities'),
                                otherGroupLabel: getLiteral('label_other_opportunities'),
                            },
                        },
                    ],
                },
            ];
        } else {
            schema = [
                {
                    title: getLiteral('label_info'),
                    show: true,
                    fields: [
                        {
                            ...defaultInputSchema,
                            ...getCrudFieldConfig(ACTIVITIES, '_idComercial'),
                            type: 'fuzzySearchSingle',
                            label: getLiteral('label_owner'),
                            hint: getLiteral('label_owner'),
                            inputAttrs: {
                                ...FuzzyMap.users,
                            },
                        },
                        {
                            ...defaultInputSchema,
                            ...getCrudFieldConfig(ACTIVITIES, 'idTipoGestion'),
                            type: 'singleValueList',
                            label: getLiteral('label_activity_type'),
                            hint: getLiteral('label_activity_type'),
                            inputAttrs: {
                                list: 'tbltiposgestion',
                                customFilterOptions: this.filterActivityTypeOptions,
                                actions: getCustomizeCrudAction(
                                    ACTIVITIES,
                                    '/settings/values-list',
                                    `?entity=${ACTIVITIES.trueName}&list=tblTiposGestion`,
                                ),
                            },
                        },
                        {
                            id: 'gestionTime',
                            type: 'dateTimeGroup',
                            label: getLiteral('common_date_and_time'),
                            customProcessField: this.customProcessField,
                            mandatory: true,
                            description: getLiteral('helptext_date_activities'),
                            fieldIds: {
                                startDate: 'gestionTime',
                                startTime: 'gestionTimeHour',
                            },
                            inlineLayout: true,
                            inputAttrs: {
                                interval: 30,
                                intervalChange: 60,
                                maxDate: new Date(),
                                isMaxTimeNow: true,
                                defaultTime: new Date(),
                            },
                            inputs: [
                                {
                                    ...defaultInputSchema,
                                    ...getCrudFieldConfig(ACTIVITIES, 'gestionTime'),
                                    type: 'date',
                                    mandatory: true,
                                    defaultValue: new Date(),
                                },
                                {
                                    id: 'times',
                                    inputs: [
                                        {
                                            ...defaultInputSchema,
                                            ...getCrudFieldConfig(ACTIVITIES, 'gestionTimeHour'),
                                            type: 'time',
                                            mandatory: true,
                                            inputAttrs: {
                                                dateType: 'time',
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            ...defaultInputSchema,
                            ...getCrudFieldConfig(ACTIVITIES, 'idEmpresa'),
                            type: 'fuzzySearchSingle',
                            label: getLiteral('label_account'),
                            hint: getLiteral('label_account'),
                            inputAttrs: {
                                ...FuzzyMap.empresas,
                            },
                        },
                        {
                            ...defaultInputSchema,
                            ...getCrudFieldConfig(ACTIVITIES, 'idExpediente'),
                            type: 'fuzzySearchSingle',
                            label: getLiteral('title_opportunity'),
                            hint: getLiteral('title_opportunity'),
                            inputAttrs: {
                                groupFunction: getGroupByParentValue,
                                ...FuzzyMap.expedientes,
                                parentField: 'idempresa',
                                parentFieldForBackend: 'idinstalador',
                                parentFieldForContext: 'idEmpresa',
                                parentFieldForContext2: 'idContacto',
                                parentFieldNoRemove: true,
                                mainGroupLabel: getLiteral('label_related_opportunities'),
                                otherGroupLabel: getLiteral('label_other_opportunities'),
                            },
                        },
                        {
                            ...defaultInputSchema,
                            ...getCrudFieldConfig(ACTIVITIES, 'idContactMultiple'),
                            type: 'multipleFuzzySearchSingle',
                            keyIds: 'contactIdList',
                            customProcessField: this.customProcessField,
                            inputAttrs: {
                                max: 5,
                                labelButton: getLiteral('label_add_contact'),
                                parentField: 'idempresa',
                                parentFieldForBackend: 'idempresa',
                                parentFieldForContext: 'idEmpresa',
                            },

                            inputs: [
                                {
                                    ...defaultInputSchema,
                                    ...getCrudFieldConfig(ACTIVITIES, 'idContacto'),
                                    label: getLiteral('label_contact'),
                                    hint: getLiteral('label_contact'),
                                    inputAttrs: {
                                        ...FuzzyMap.contactos,
                                        ...inputAttrsContacts,
                                    },
                                },
                                {
                                    ...defaultInputSchema,
                                    ...getCrudFieldConfig(ACTIVITIES, 'idContacto2'),
                                    label: getLiteral('label_contact_2'),
                                    hint: getLiteral('label_contact_2'),
                                    inputAttrs: {
                                        ...FuzzyMap.contactos,
                                        ...inputAttrsContacts,
                                    },
                                },
                                {
                                    ...defaultInputSchema,
                                    ...getCrudFieldConfig(ACTIVITIES, 'idContacto3'),
                                    label: getLiteral('label_contact_3'),
                                    hint: getLiteral('label_contact_3'),
                                    inputAttrs: {
                                        ...FuzzyMap.contactos,
                                        ...inputAttrsContacts,
                                    },
                                },
                                {
                                    ...defaultInputSchema,
                                    ...getCrudFieldConfig(ACTIVITIES, 'idContacto4'),
                                    label: getLiteral('label_contact_4'),
                                    hint: getLiteral('label_contact_4'),
                                    inputAttrs: {
                                        ...FuzzyMap.contactos,
                                        ...inputAttrsContacts,
                                    },
                                },
                                {
                                    ...defaultInputSchema,
                                    ...getCrudFieldConfig(ACTIVITIES, 'idContacto5'),
                                    label: getLiteral('label_contact_5'),
                                    hint: getLiteral('label_contact_5'),
                                    inputAttrs: {
                                        ...FuzzyMap.contactos,
                                        ...inputAttrsContacts,
                                    },
                                },
                            ],
                        },
                        {
                            ...defaultInputSchema,
                            ...getCrudFieldConfig(ACTIVITIES, 'WhatsappMessage'),
                            type: 'textarea',
                            label: getLiteral('label_whatsapp_message'),
                            hint: getLiteral('label_whatsapp_message'),
                            inputAttrs: {
                                rows: 4,
                                rowsMax: 10,
                                maxLength: 8000,
                            },
                        },
                        {
                            ...defaultInputSchema,
                            ...getCrudFieldConfig(ACTIVITIES, 'text'),
                            type: 'textarea',
                            label: getLiteral('label_notes'),
                            hint: getLiteral('label_notes'),
                            inputAttrs: {
                                rows: 4,
                                rowsMax: 10,
                                maxLength: 8000,
                            },
                        },
                    ],
                },
            ];
        }

        if (
            state.config?.permission?.upload_activity_image &&
            state.config?.permission?.delete_activity_image &&
            ACTIVITY_TYPE_EMAIL.toString() !== activityType &&
            ACTIVITY_TYPE_CALL.toString() !== activityType
        ) {
            schema.push({
                title: getLiteral('label_selectfilestoupload'),
                show: true,
                fields: [
                    {
                        id: 'images',
                        type: 'uploadFiles',
                        inputAttrs: {
                            shouldRenderField: true,
                        },
                        isCustom: true,
                        getCustomValidFiles: this.getCustomValidFiles,
                        canUploadDocuments: true,
                        showDocuments: true,
                    },
                ],
            });
        }

        return schema;
    }

    getCustomValidFiles(files, dropped) {
        const { images, others } = files?.reduce((obj, current) => {
            if (!obj.images) obj.images = [];
            if (!obj.others) obj.others = [];
            const type = current?.file ? current.file.type : current.type;
            if (IMAGES_FILES_VALID_FORMATS.includes(type)) obj.images.push(current);
            else obj.others.push(current);
            return obj;
        }, {}) || { image: [], others: [] };

        const { droppedImages, droppedOthers } = dropped?.reduce((obj, current) => {
            if (!obj.droppedImages) obj.droppedImages = [];
            if (!obj.droppedOthers) obj.droppedOthers = [];
            const type = current?.file ? current.file.type : current.type;

            if (IMAGES_FILES_VALID_FORMATS.includes(type)) obj.droppedImages.push(current);
            else obj.droppedOthers.push(current);
            return obj;
        }, {});

        let validFiles = [...droppedOthers];
        const totalImages = (images?.length || 0) + droppedImages.length;

        if (totalImages <= MAX_FILES[`${ACTIVITIES.entity}_images`])
            validFiles = [...validFiles, ...droppedImages];

        return validFiles;
    }

    checkCustomErrors(errors, schema, data) {
        const gestionTimeHour = data.gestionTimeHour || null;
        const errorName = 'mandatory';

        let firstError = '';

        if (data.gestionTime && data.gestionTime?.getTime() > new Date().getTime()) {
            errors.gestionTime = getLiteral('error_future_date_not_allowed');
            firstError = 'gestionTime';
        }

        if (!gestionTimeHour) {
            errors.gestionTimeHour = errorName;
            firstError = 'gestionTimeHour';
        }

        if (!errors._idComercial && !errors.idTipoGestion && !errors.gestionTime) {
            errors.firstErrorField = firstError;
        }

        return errors;
    }

    getFilesForCrud(id) {
        return new Promise((resolve, reject) => {
            this.context.entityManager.getEntityDocuments(
                id,
                ACTIVITIES,
                (response) => resolve(response),
                (error) => reject(error),
            );
        });
    }

    getFiles(id) {
        Context.store.dispatch(Context.actions.ActivitiesActions.getActivityFiles(id));
    }

    setFollow(id, follow, isShared = false, success, error) {
        this.context.domainManager.setFollow(ACTIVITIES.entity, id, follow, false, success, error);
    }

    getDownloadDocumentLink(id, success, error) {
        this.context.domainManager.getDocumentLink(
            ACTIVITIES.entity,
            id,
            (result) => {
                success(`${this.context.constants.getUrlDownloadDocument()}?token=${result.token}`);
            },
            error,
        );
    }

    getLastCheckIn(entity) {
        return new Promise((resolve, reject) => {
            const userInfo = this.context.store.getState().config.userData;
            const today = moment.utc().format('YYYY-MM-DD');

            let activityType = ACTIVITY_TYPE_CHECKIN.toString();
            if (entity === OPPORTUNITIES) {
                activityType = ACTIVITY_TYPE_OPPORTUNITY_CHECKIN.toString();
            }

            const filter = {
                activityType: { value: activityType },
                callType: { value: -1 },
                users: {
                    value: [
                        {
                            id: userInfo.idUsuario,
                            value: userInfo.idUsuario,
                        },
                    ],
                },
                from: { value: today },
                to: { value: today },
                FollowingItem: { value: '0' },
            };

            this.context.domainManager.getEntityList(
                ACTIVITIES.entity,
                0,
                1,
                filter,
                null,
                null,
                null,
                false,
                (data) => {
                    if (data && data.length > 0) {
                        resolve(data[0]);
                    } else {
                        resolve();
                    }
                },
                reject,
            );
        });
    }

    createCheckIn(dataEntity) {
        return new Promise((resolve, reject) => {
            const state = Context.store.getState();
            const idTiposGestionList = state.serverList?.tbltiposgestion?.data || [];

            let IDTipocheckin = ACTIVITY_ID_TYPE_CHECKIN_OPPORTUNITY;
            let activityType = ACTIVITY_TYPE_OPPORTUNITY_CHECKIN;
            if (dataEntity.idEmpresa) {
                IDTipocheckin = ACTIVITY_ID_TYPE_CHECKIN_COMPANY;
                activityType = ACTIVITY_TYPE_CHECKIN;
            } else if (dataEntity.idExpediente) {
                IDTipocheckin = ACTIVITY_ID_TYPE_CHECKIN_OPPORTUNITY;
                activityType = ACTIVITY_TYPE_OPPORTUNITY_CHECKIN;
            }

            let idTipoGestion = '2';
            if (idTiposGestionList?.length > 0) {
                const options =
                    this.filterActivityTypeOptions(idTiposGestionList, null, null, {
                        activityType,
                    }) || [];
                if (options.length > 0) idTipoGestion = options[0].value;
            }

            const data = {
                ...dataEntity,
                gestionTime: moment.utc().format(),
                idTipoGestion,
                IDTipocheckin,
                isCheckin: '1',
                ...getOffsetTimeZoneValues(),
            };

            Context.domainManager.createEntity(
                ACTIVITIES.entity,
                data,
                (result) => {
                    resolve(result);
                },
                reject,
            );
        });
    }

    makeCheckOutOfCheckIn(idType, idActivity, success, error) {
        const data = {
            IDTipocheckin: idType,
            dtCheckOutDate: moment.utc().format(),
            ...getOffsetTimeZoneValues(),
        };

        Context.domainManager.updateEntity(
            ACTIVITIES.entity,
            idActivity,
            data,
            (result) => {
                success();
            },
            error,
        );
    }

    shouldOpenDetail = () => {
        return true;
    };

    backRoute = (entityCrud, activityId) => {
        const { id, activityType } = entityCrud.data;
        const state = Context.store.getState();

        if (entityCrud.isFromTab && Object.entries(state.entityDetail.tabs).length > 1) return;

        const activeEntityDetail = state.entityDetail?.active || null;
        let entityDetail = null;
        if (activeEntityDetail) entityDetail = state.entityDetail[activeEntityDetail];

        const isInConversations = inConversations();
        const baseRoute = isInConversations
            ? `${CONVERSATIONS.route}${ACTIVITIES.route}`
            : `${ACTIVITIES.route}`;

        if (!id && !activityId && !entityDetail?.id) return ensureRoute(baseRoute);
        const finalId = id || activityId || entityDetail?.id;

        if (!activityType) ensureRoute(baseRoute);
        else
            ensureRoute(
                isInConversations
                    ? `${baseRoute}/${finalId}/${activityType}/timeline`
                    : `${baseRoute}/${finalId}/${activityType}`,
            );
    };

    getCustomFieldProps = () => {
        return {
            modalCrud: {
                create: {
                    [COMPANIES.entity]: {
                        checkIn: { idEmpresa: { readOnly: true } },
                    },
                    [OPPORTUNITIES.entity]: {
                        checkIn: {
                            idExpediente: { readOnly: true },
                        },
                    },
                },
                edit: {
                    [COMPANIES.entity]: {
                        [ACTIVITY_TYPE_CHECKIN]: {
                            gestionTime: { hidden: true },
                        },
                        [ACTIVITY_TYPE_FAST_CHECKIN]: {},
                        [ACTIVITY_TYPE_VIDEO_CHECKIN]: {
                            gestionTime: { hidden: true },
                        },
                        checkOut: {
                            idEmpresa: { readOnly: true },
                        },
                        [ACTIVITY_TYPE_EMAIL]: {
                            idEmpresa: { hidden: true },
                            idContactMultiple: { hidden: true },
                        },
                    },
                    [OPPORTUNITIES.entity]: {
                        [ACTIVITY_TYPE_CHECKIN]: {
                            gestionTime: { hidden: true },
                        },
                        [ACTIVITY_TYPE_FAST_CHECKIN]: {},
                        [ACTIVITY_TYPE_VIDEO_CHECKIN]: {
                            gestionTime: { hidden: true },
                        },
                        checkOut: {
                            idExpediente: { readOnly: true },
                        },
                        [ACTIVITY_TYPE_EMAIL]: {
                            idEmpresa: { hidden: true },
                            idContactMultiple: { hidden: true },
                        },
                    },
                    [CONTACTS.entity]: {
                        [ACTIVITY_TYPE_CHECKIN]: {
                            gestionTime: { hidden: true },
                        },
                        [ACTIVITY_TYPE_FAST_CHECKIN]: {},
                        [ACTIVITY_TYPE_VIDEO_CHECKIN]: {
                            gestionTime: { hidden: true },
                        },
                        checkOut: {
                            idContactMultiple: { readOnly: true },
                        },
                        [ACTIVITY_TYPE_EMAIL]: {
                            idEmpresa: { hidden: true },
                            idContactMultiple: { hidden: true },
                        },
                    },
                },
            },
            entityCrud: {
                create: {
                    checkIn: { idEmpresa: { readOnly: true } },
                },
                edit: {
                    [ACTIVITY_TYPE_CHECKIN]: {
                        gestionTime: { hidden: true },
                    },
                    [ACTIVITY_TYPE_FAST_CHECKIN]: {
                        gestionTime: { hidden: true },
                    },
                    [ACTIVITY_TYPE_VIDEO_CHECKIN]: {
                        gestionTime: { hidden: true },
                    },
                    [ACTIVITY_TYPE_OPPORTUNITY_CHECKIN]: {
                        gestionTime: { hidden: true },
                    },
                    checkOut: {
                        idEmpresa: { readOnly: true },
                        idExpediente: { readOnly: true },
                    },
                    [ACTIVITY_TYPE_EMAIL]: {
                        idEmpresa: { hidden: true },
                        idContactMultiple: { hidden: true },
                    },
                },
            },
        };
    };

    afterGetSchema = ({ schema, dependencyMap, dynamicMap }) =>
        new Promise((resolve, reject) => {
            let newSchema;

            if (schema?.length > 0) {
                newSchema = schema.reduce((arr, section) => {
                    let newSection = {
                        ...section,
                    };
                    let fields = [];
                    if (newSection?.fields?.length > 0) {
                        fields = newSection.fields.reduce((arr2, field) => {
                            // Activities crud web3 seems to use only dateTime field instead of date field
                            if (field.type === 'date') {
                                field.type = 'dateTime';
                            }
                            arr2.push(field);
                            return arr2;
                        }, []);
                    }
                    newSection.fields = fields;
                    arr.push(newSection);
                    return arr;
                }, []);
            }

            resolve({ schema: newSchema, dependencyMap, dynamicMap });
        });

    dataAffectsSchema({ data, schema, isModal, entity }) {
        if (!data || Object.keys(data).length === 0) return schema;
        const state = Context.store.getState();
        const activeEntityDetail = state.entityDetail?.active || null;
        let entityDetail = null;
        if (activeEntityDetail) entityDetail = state.entityDetail[activeEntityDetail];
        // We need currentEntity because the prop entity refers to ACTIVITES but the cases we
        // are contemplating refer to the checkin in COMPANIES or OPPORTUNITIES
        // In case we are accessing from a place that has no detail open in the store, we take entity.
        const currentEntity = entityDetail ? entityDetail.entity : entity.entity;
        const activeEntityType = currentEntity && getEntityFromString(currentEntity);

        const activeCrud = getActiveCrud(state);
        const isWhatsAppActivity =
            activeCrud?.extraInfo?.activityType === ACTIVITY_TYPE_WHATSAPP.toString() ||
            data?.activityType === ACTIVITY_TYPE_WHATSAPP.toString();

        const customFieldProps = this.getCustomFieldProps();
        const isCheckOut = !!data.dtCheckOutDate;
        const isCheckIn = getBackendBoolean(data.isCheckin) || false;
        let checkOutProps = {};
        let checkInProps = {};

        if (isCheckIn && !data.id) {
            if (isModal) {
                checkInProps = customFieldProps.modalCrud.create[activeEntityType.entity]?.checkIn;
            } else {
                checkInProps = customFieldProps.entityCrud.create?.checkIn;
            }
        }

        if (isCheckOut) {
            if (isModal) {
                checkOutProps = customFieldProps.modalCrud.edit[activeEntityType.entity]?.checkOut;
            }
        }

        const finalSchema = schema.map((current, index) => {
            current.fields = current.fields.reduce((arr, field) => {
                let finalField = field;
                const activityType = parseInt(data.activityType, 10);

                if (data.id) {
                    if (isModal) {
                        finalField = {
                            ...finalField,
                            ...(customFieldProps.modalCrud.edit[activeEntityType.entity]?.[
                                activityType
                            ]?.[field.id] || {}),
                            ...(checkOutProps[field.id] || {}),
                        };
                    } else {
                        finalField = {
                            ...finalField,
                            ...(customFieldProps.entityCrud.edit?.[activityType]?.[field.id] || {}),
                        };
                    }

                    if (field.id === '_idComercial') {
                        if (!data._idComercial) {
                            finalField.hidden = true;
                        } else {
                            if (
                                data.activityType === ACTIVITY_TYPE_CHECKIN.toString() ||
                                data.activityType === ACTIVITY_TYPE_FAST_CHECKIN.toString() ||
                                data.activityType === ACTIVITY_TYPE_VIDEO_CHECKIN.toString() ||
                                data.activityType === ACTIVITY_TYPE_OPPORTUNITY_CHECKIN.toString()
                            ) {
                                finalField.readOnly = true;
                            }
                        }
                    }

                    if (field.id === 'WhatsappMessage') {
                        if (!data.WhatsappMessage || !isWhatsAppActivity) return arr;
                        finalField.readOnly = true;
                    }
                } else {
                    if (isModal) {
                        finalField = {
                            ...finalField,
                            ...(customFieldProps.modalCrud.create[activeEntityType.entity]?.[
                                field.id
                            ] || {}),
                            ...(checkInProps[field.id] || {}),
                        };
                    } else {
                        finalField = {
                            ...finalField,
                            ...(customFieldProps.entityCrud.create?.[field.id] || {}),
                            ...(checkInProps[field.id] || {}),
                        };
                    }

                    if (field.id === 'WhatsappMessage') {
                        return arr;
                    }
                }

                return [...arr, finalField];
            }, []);
            return current;
        });
        return finalSchema;
    }

    filterActivityTypeOptions(finalOptions, list, idFieldTable, defaultData) {
        const state = Context.store.getState();
        const crud = getActiveCrud(state);
        const crudData = defaultData || crud?.data || null;

        const isWhatsAppActivity =
            crud?.extraInfo?.activityType === ACTIVITY_TYPE_WHATSAPP.toString() ||
            crudData?.activityType === ACTIVITY_TYPE_WHATSAPP.toString();

        let filterForActivity = (option) => {
            return isWhatsAppActivity
                ? option.hasOwnProperty('blniswhatsapp') && getBackendBoolean(option.blniswhatsapp)
                : option.hasOwnProperty('blnisvisibleinactivity') &&
                      getBackendBoolean(option.blnisvisibleinactivity);
        };

        let result = finalOptions.filter(filterForActivity);

        if (crudData) {
            if (crudData.outOfRangeCheckin) {
                result = result.filter((current) => {
                    return (
                        current.hasOwnProperty('blnisvisita') &&
                        getBackendBoolean(current.blnisvisita) &&
                        current.hasOwnProperty('blnhide') &&
                        !getBackendBoolean(current.blnhide)
                    );
                });
            } else {
                switch (parseInt(crudData.activityType, 10)) {
                    case ACTIVITY_TYPE_VIDEO_CHECKIN:
                        result = result.filter((current) => {
                            return (
                                current.hasOwnProperty('blnischeckin') &&
                                getBackendBoolean(current.blnischeckin) &&
                                current.hasOwnProperty('blnisvideocall') &&
                                getBackendBoolean(current.blnisvideocall) &&
                                current.hasOwnProperty('blnhide') &&
                                !getBackendBoolean(current.blnhide)
                            );
                        });
                        break;
                    case ACTIVITY_TYPE_VIDEO_CALL:
                        result = result.filter((current) => {
                            return (
                                current.hasOwnProperty('blnisvideocall') &&
                                getBackendBoolean(current.blnisvideocall) &&
                                current.hasOwnProperty('blnhide') &&
                                !getBackendBoolean(current.blnhide)
                            );
                        });
                        break;
                    case ACTIVITY_TYPE_CHECKIN:
                    case ACTIVITY_TYPE_OPPORTUNITY_CHECKIN:
                        result = result.filter((current) => {
                            return (
                                current.hasOwnProperty('blnischeckin') &&
                                getBackendBoolean(current.blnischeckin) &&
                                current.hasOwnProperty('blnhide') &&
                                !getBackendBoolean(current.blnhide)
                            );
                        });
                        break;
                    case ACTIVITY_TYPE_FAST_CHECKIN:
                        result = result.filter((current) => {
                            return (
                                current.hasOwnProperty('blnisfastcheckin') &&
                                getBackendBoolean(current.blnischeckin) &&
                                current.hasOwnProperty('blnhide') &&
                                !getBackendBoolean(current.blnhide)
                            );
                        });
                        break;
                    case ACTIVITY_TYPE_WORKFLOW:
                        result = result.filter((current) => {
                            return (
                                current.hasOwnProperty('blnisworkflow') &&
                                getBackendBoolean(current.blnisworkflow) &&
                                current.hasOwnProperty('blnhide') &&
                                !getBackendBoolean(current.blnhide)
                            );
                        });
                        break;
                    case ACTIVITY_TYPE_WHATSAPP:
                        result = result.filter((current) => {
                            return (
                                current.hasOwnProperty('blniswhatsapp') &&
                                getBackendBoolean(current.blniswhatsapp) &&
                                current.hasOwnProperty('blnhide') &&
                                !getBackendBoolean(current.blnhide)
                            );
                        });
                        break;
                    default:
                        result = result.filter((current) => {
                            return isWhatsAppActivity
                                ? current.hasOwnProperty('blnhide') &&
                                      !getBackendBoolean(current.blnhide)
                                : current.hasOwnProperty('blnhide') &&
                                      !getBackendBoolean(current.blnhide) &&
                                      current.hasOwnProperty('blnisgeneral') &&
                                      getBackendBoolean(current.blnisgeneral);
                        });
                        break;
                }
            }
        }

        return result;
    }

    customProcessField(field, data) {
        if (field.id === 'idContactMultiple') {
            let itemsIdsArr = [];
            let itemsIds;
            if (field.inputs) {
                field.inputs.forEach((input) => {
                    if (data[input.id]) {
                        if (input.id === 'idContacto') {
                            data.idContacto = data[input.id].value;
                        } else {
                            itemsIdsArr = [...itemsIdsArr, data[input.id].value];
                            delete data[input.id];
                        }
                    }
                });
                // Backend doesn't want the string with a final ';' :|
                // When is empty they expect a ';'
                itemsIds = itemsIdsArr.length > 0 ? itemsIdsArr.join(';') : ';';
                if (field.keyIds) data[field.keyIds] = itemsIds || '';
            }
        }

        if (field.id === 'gestionTime' && data.gestionTime) {
            const [date, time] = data.gestionTime.split(' ');
            const hoursToMinutes = moment.duration(time).asMinutes();
            const utcOffset = moment(date, 'DD/MM/YYYY').add(hoursToMinutes, 'minutes').format('Z');
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            data.gestionTime = moment(data.gestionTime, 'DD/MM/YYYY hh:mm:ss').utc().format();
            data.utcOffset = utcOffset;
            data.timeZone = timeZone;
            data.useUTCDates = true;
        }

        if (data.gestionTimeHour) delete data.gestionTimeHour;

        return data;
    }

    beforeSave = (schema, data) => {
        // Getting initial seconds for gestionTime
        if (data.gestionTime) {
            let gestionTimeMoment = moment(data.gestionTime);
            data.gestionTime = gestionTimeInitial
                ? gestionTimeMoment.add(gestionTimeInitial.getSeconds(), 'seconds').utc().format()
                : gestionTimeMoment;
        }

        return ActivityModel.toSave(data);
    };

    saveFilesPostSave = (id, files) => {
        if (!files.length || !id) return Promise.resolve();

        const filesToCreate = files.filter(
            (current) => !current.edit && !current.delete && !current.file,
        );
        const filesToEdit = files.filter((current) => current.file && current.edit);
        const filesToDelete = files.filter((current) => current.file && current.delete);

        let promises = [];

        filesToCreate.map((current) => {
            let file = current;
            let name = current.name;

            const uploadPromise = new Promise((resolve, reject) => {
                FilesService.uploadFile({
                    entity: ACTIVITIES.entity,
                    entityId: id,
                    name,
                    file,
                }).then((response) => {
                    let counter = 0;
                    const syncro = setInterval(() => {
                        FilesService.getFile(response.data.id).then((data) => {
                            if (counter > 10) {
                                clearInterval(syncro);
                                reject('notSync');
                            }
                            counter++;
                            if (data.data.synchronized) {
                                clearInterval(syncro);
                                resolve();
                            }
                        });
                    }, 300);
                });
            });

            promises.push(uploadPromise);
        });

        filesToEdit.forEach((current) => {
            if (!current.id || !current.file) return Promise.resolve();
            promises.push(FilesService.updateFile(current.id, current.file));
        });

        filesToDelete.forEach((current) => {
            if (!current.id || !current.file) return Promise.resolve();
            promises.push(FilesService.deleteFile(current.id));
        });

        return Promise.all(promises)
            .then(() => {
                this.getFiles(id);

                logEvent({
                    event: ACTIVITIES.trueName,
                    submodule: 'crud',
                    functionality: 'attachFiles',
                });
            })
            .catch((error) => {
                if (error === 'notSync') {
                    this.getFiles(id);

                    infoToast({ text: getLiteral('label_attachment_waiting_synchronized') });
                }
            });
    };

    afterSave({ schema, data, result, isModal }) {
        const state = Context.store.getState();
        const getCount = inConversations()
            ? Context.actions.ConversationsActions.getConversationsCount
            : Context.actions.ActivitiesActions.getActivitiesCount;

        Context.store.dispatch(getCount());

        const activeEntityDetail = state.entityDetail?.active || null;
        let entityDetail = null;
        if (activeEntityDetail) entityDetail = state.entityDetail[activeEntityDetail];
        // We need currentEntity because the prop entity refers to ACTIVITES but the cases we
        // are contemplating refer to the checkin in COMPANIES or OPPORTUNITIES
        let currentEntity = entityDetail ? entityDetail.entity : null;
        const entityId = entityDetail ? entityDetail.id : null;
        const allowCheckOut = state.config.permission?.blnAllowCheckOut || false;
        let activeEntityType = currentEntity && getEntityFromString(currentEntity);

        if (
            isModal &&
            activeEntityType &&
            (activeEntityType === COMPANIES || activeEntityType === OPPORTUNITIES)
        ) {
            // Refresh widget files list
            if (entityId) {
                Context.store.dispatch(
                    Context.actions.ActivitiesActions.getActivitiesWidgetFiles(
                        entityId,
                        activeEntityType.entity,
                    ),
                );
            }

            if (data.id && data.dtCheckOutDate) {
                Context.store.dispatch(
                    Context.actions.ActivitiesActions.removePendingCheckOut(activeEntityType),
                );
                Context.store.dispatch(
                    Context.actions.ActivitiesActions.changeLastCheckIn(activeEntityType),
                );
            } else if (result?.Insert?.id) {
                Context.store.dispatch(
                    Context.actions.ActivitiesActions.getLastCheckInForLocationWidget({
                        entityData: entityDetail?.data ? entityDetail.data : null,
                        allowCheckOut,
                        entity: activeEntityType,
                    }),
                );
            }
        }
    }

    afterDelete({ schema, data, isModal }) {
        const state = Context.store.getState();
        const getCount = inConversations()
            ? Context.actions.ConversationsActions.getConversationsCount
            : Context.actions.ActivitiesActions.getActivitiesCount;

        Context.store.dispatch(getCount());

        const activeEntityDetail = state.entityDetail?.active || null;
        let entityDetail = null;
        if (activeEntityDetail) entityDetail = state.entityDetail[activeEntityDetail];
        // We need currentEntity because the prop entity refers to ACTIVITES but the cases we
        // are contemplating refer to the checkin in COMPANIES or OPPORTUNITIES
        let currentEntity = entityDetail ? entityDetail.entity : null;
        let activeEntityType = currentEntity && getEntityFromString(currentEntity);
        const pendingCheckOut =
            state.activities.checkIn?.[activeEntityType.entity]?.pendingCheckOut || '';

        if (
            data &&
            isModal &&
            activeEntityType &&
            (activeEntityType === COMPANIES || activeEntityType === OPPORTUNITIES)
        ) {
            if (data.id && pendingCheckOut && data.id === pendingCheckOut) {
                Context.store.dispatch(
                    Context.actions.ActivitiesActions.removePendingCheckOut(activeEntityType),
                );
                Context.store.dispatch(
                    Context.actions.ActivitiesActions.changeLastCheckIn(activeEntityType),
                );
            }
        }
    }

    getEntity(id, success, error) {
        this.context.domainManager.getEntity(
            id,
            ACTIVITIES.entity,
            true,
            (data) => {
                success(data);
            },
            error,
        );
    }

    transformDataForEntityList = (backendData) => {
        if (!backendData || (backendData && !Array.isArray(backendData))) return null;
        return {
            Data: backendData,
            MetaData: { totalRows: backendData.length },
        };
    };

    whileInitCrud = (data, mappedSchema) => {
        gestionTimeInitial = data.gestionTime;
        return new Promise((resolve, reject) => {
            let finalData = data;
            const id = data.id || data.Id || '';

            if (!id) {
                const state = Context.store.getState();
                const userData = state.config.userData;
                const owner = {
                    label: `${userData.nombre} ${userData.apellidos}`,
                    value: userData.idUsuario,
                };

                finalData['_idComercial'] = owner;
            }

            if (!data.gestionTimeHour) {
                finalData.gestionTimeHour = data.gestionTime;
            }

            if (id) {
                this.getFilesForCrud(id)
                    .then((files) => {
                        if (files.length) {
                            finalData.files = files.map((current) => {
                                current.size = Number(current.size);
                                return current;
                            });
                        }
                        resolve(finalData);
                    })
                    .catch((error) => {
                        resolve(finalData);
                    });
            } else {
                resolve(finalData);
            }
        });
    };

    customInitList = (data, id) => {
        if ((id && data) || (!id && data.id)) {
            Context.store.dispatch(
                Context.actions.EntityListActions.init(
                    ACTIVITIES,
                    true,
                    PAGINATION_TABLE_ACTIVITIES,
                    0,
                    null,
                    true,
                ),
            );
        }
    };

    getListOfOptions = (filter) => {
        const state = Context.store.getState();
        const serverList = state.serverList;
        const filterId = filter.filter.id;
        const filterProps = mappedListNameByFilter?.[filterId];
        if (filterProps?.listName) {
            const list = serverList?.[filterProps.listName]?.data || [];

            if (!list?.length) return [];

            const options = filterProps?.beforeRenderValueList?.(list) || list || [];
            return options;
        }
        return [];
    };

    getCustomViewsValues = () => {
        return;
    };

    getDiscartableCustomActivitiesValues = (customActivitiesFilter, options) => {
        const values = customActivitiesFilter?.value || [];
        const discartableValues = options.reduce((arr, current) => {
            if (!values.includes(current.value)) arr.push(current.value);
            return arr;
        }, []);

        return discartableValues;
    };

    beforeOnChangeFilter = ({ relatedEntity, refresh }) => {
        if (!refresh) return;
        const route = relatedEntity
            ? `${relatedEntity.route}${ACTIVITIES.route}`
            : ACTIVITIES.route;
        ensureRoute(route);
    };

    afterOnChangeFilter = ({ refresh, filter, isClear, entity }) => {
        if (!filter?.filter?.id) return;
        const state = Context.store.getState();
        const filterId = filter.filter.id;
        const isCrossFilter = entity?.entity !== ACTIVITIES.entity;

        // These filters activate or deactivate also another filter "activityType"
        const subFilters = activityTypeSubFilters;

        if (subFilters.includes(filterId)) return null;

        if (filterId === 'activityType') {
            const filters = isCrossFilter
                ? state?.entityFilters?.[entity.entity]?.crossFilters?.[ACTIVITIES.entity]
                : state?.entityFilters?.[ACTIVITIES.entity]?.filters;
            const activityFilters = filters || {};
            const activeSubFilters = Object.keys(activityFilters).reduce((arr, key) => {
                if (subFilters.includes(key)) arr.push(key);
                return arr;
            }, []);

            const finalEntity = isCrossFilter ? entity : ACTIVITIES;
            const crossEntity = isCrossFilter ? ACTIVITIES.entity : null;

            if (
                activityFilters?.isSuspiciousCheckIn &&
                (!activityFilters?.activityType ||
                    (!activityFilters.activityType?.value?.includes(
                        ACTIVITY_TYPE_CHECKIN.toString(),
                    ) &&
                        !activityFilters.activityType?.value?.includes(
                            ACTIVITY_TYPE_OPPORTUNITY_CHECKIN.toString(),
                        )))
            ) {
                // When there is isSuspiciousCheckIn filter and there are no CheckIn and
                // OpportunityCheckIn filters, remove isSuspiciousCheckIn filter
                const relatedEntity = inConversations() ? CONVERSATIONS : null;

                Context.store.dispatch(
                    Context.actions.EntityFiltersActions.changeFilter({
                        entity: finalEntity,
                        filter: { id: 'isSuspiciousCheckIn', dataType: 'bool' },
                        value: false,
                        refresh: true,
                        completeValues: false,
                        isEntityList: null,
                        info: null,
                        isPreload: false,
                        relatedEntity,
                        crossEntity,
                    }),
                );
            }

            if (activeSubFilters.length) {
                if (!filter.value || !filter?.value?.length) {
                    //Remove all existing subFilters
                    Context.store.dispatch(
                        Context.actions.EntityFiltersActions.deleteMultipleFilters({
                            entity: finalEntity,
                            filters: isCrossFilter
                                ? { [ACTIVITIES.entity]: activeSubFilters }
                                : activeSubFilters,
                        }),
                    );
                } else {
                    //Remove selective subFilters
                    const activityTypeValues = filter.value || [];
                    const subFiltersToRemove = activeSubFilters.reduce((arr, current) => {
                        const filterId = mappedActivityTypeIdsReverse[current];
                        if (!activityTypeValues.includes(filterId.toString())) {
                            arr.push(current);
                        }
                        return arr;
                    }, []);
                    Context.store.dispatch(
                        Context.actions.EntityFiltersActions.deleteMultipleFilters({
                            entity: finalEntity,
                            filters: isCrossFilter
                                ? { [ACTIVITIES.entity]: subFiltersToRemove }
                                : subFiltersToRemove,
                        }),
                    );
                }
            }
        }

        if (inConversations() && filterId === 'pendingConversations' && filter.value) {
            logEvent({
                event: ACTIVITIES.trueName,
                submodule: 'timelineMyConversations',
                functionality: 'pendingConversationsFilter',
            });
        }
    };

    customFormatCheckinCompleteValues = (completeValues, filter) => {
        if (filter?.activityTypeId && completeValues?.length > 0) {
            completeValues.forEach((current) => {
                current.id = filter.activityTypeId.toString();
            });
        }
        return completeValues;
    };

    getFilterOptionState = (filter) => {
        const state = Context.store.getState();
        const serverList = state.serverList;
        const filterId = filter.id;
        const filterProps = mappedListNameByFilter?.[filterId];
        let options = [];

        if (!filterProps?.listName) return false;

        const list = serverList?.[filterProps.listName]?.data || [];

        if (!list?.length) return false;

        options = filterProps?.beforeRenderValueList(list) || [];

        const optionValues = options.reduce((arr, current) => {
            arr.push(current.value);
            return arr;
        }, []);
        const selectedOptions = options.filter((current) => filter.value.includes(current.value));
        const areAllSelected = selectedOptions.length === options.length;

        return { optionValues, areAllSelected };
    };

    removeFiltersWhenAllSelected = (filter, optionValues, activityTypeId) => {
        let newValues = [];
        let newCompleteValues = [];
        const newFilter = { ...filter };
        newValues = newFilter.value.filter((current) => !optionValues.includes(current));
        newCompleteValues = newFilter.completeValues.filter((current) => {
            if (!optionValues.includes(current.value) || current.id !== activityTypeId) {
                return true;
            }
            return false;
        });
        newFilter.value = newValues;
        newFilter.completeValues = newCompleteValues;
        return newFilter;
    };

    formatFiltersToSend = (filters, forCrossFilters = false) => {
        const state = Context.store.getState();
        const isExportingActivities = state.entityExport.entityName === ACTIVITIES.entity;
        const exportOverrides = state.entityExport.overrides;

        if (filters?.fastCheckIn || (filters?.opportunityCheckIn && !filters?.listCheckInType)) {
            filters.listCheckInType = this.getListCheckInTypeFilter();
        }

        if (filters?.customActivities) {
            filters = moveFilters('activityType', 'customActivities', filters);
        }

        if (filters?.fastCheckIn) {
            filters = moveFilters('listCheckInType', 'fastCheckIn', filters);
        }

        if (filters?.opportunityCheckIn) {
            filters = moveFilters('listCheckInType', 'opportunityCheckIn', filters);
        }

        if (filters?.whatsappType) {
            if (!filters?.type) filters.type = this.getTypeFilter();
            filters = moveFilters('type', 'whatsappType', filters);
        }

        if (filters?.videoCallCheckInType) {
            filters = addActivityTypeIdToFilters(filters.videoCallCheckInType.id, filters);
        }

        if (filters?.videoCallType) {
            filters = addActivityTypeIdToFilters(filters.videoCallType.id, filters);
        }

        if (filters?.type) {
            filters = addActivityTypeIdToFilters(filters.type.id, filters);
        }

        // When we format it for cross filters looks like we don't need the activityType's default value (because BE)
        if (!filters.activityType && !forCrossFilters) {
            filters.activityType = {
                id: 'activityType',
                dataType: 'singleValueList',
                value: ['-1'],
            };
        }

        if (isExportingActivities && exportOverrides?.activityType) {
            filters.activityType = {
                id: 'activityType',
                dataType: 'singleValueList',
                value: exportOverrides.activityType.split(','),
            };
        }

        const newFilters = translateFollowingItemForBackend(filters, ACTIVITIES);

        return newFilters;
    };

    shouldSendStandardValues = (filter) => {
        const state = Context.store.getState();
        const serverList = state.serverList;
        const filterId = filter.id;
        const filterProps = mappedListNameByFilter?.[filterId];
        let selectedOptions = [];
        let list = [];
        if (filterProps?.listName) {
            if (!serverList[filterProps.listName]) return true;
            list = serverList?.[filterProps.listName]?.data || [];
            if (!list?.length) return true;
            const options = filterProps?.beforeRenderValueList?.(list) || list || [];
            const filterValues = filter?.value || [];
            selectedOptions = options.filter((current) => filterValues.includes(current.value));

            if (selectedOptions.length === options.length) return false;
            else return true;
        }
        return true;
    };

    formatFiltersForCount = (filters) => {
        const state = Context.store.getState();
        const isExportingActivities = state.entityExport.entityName === ACTIVITIES.entity;
        const exportOverrides = state.entityExport.overrides;

        const newFiltersRef = JSON.parse(JSON.stringify(filters));
        // We need to not alterate filters object so, we use a new Object ref
        // with all its content, arrays included, brand new without reference to
        // old filters object.
        // We will use it only to alterate value, because we need all the existing
        // methods within filters object.

        const newFilters = Object.entries(newFiltersRef).reduce((obj, [key, value]) => {
            switch (key) {
                case 'activityType':
                    obj[key] = { ...filters[key] };
                    if (obj[key]?.value && Array.isArray(filters[key]?.value)) {
                        obj[key].value = value.value.join(',');
                    }
                    break;
                case 'callType':
                case 'email':
                    obj[key] = { ...filters[key] };
                    obj[key].value = parseInt(obj[key].value, 10);
                    break;
                case 'date':
                    obj[key] = { ...filters[key] };
                    const oldValue = obj[key]?.value;
                    if (oldValue) {
                        const keysToMap = { from: 'dateMin', to: 'dateMax' };
                        const newValue = Object.keys(oldValue).reduce(
                            (acc, key) => ({
                                ...acc,
                                ...{ [keysToMap[key] || key]: oldValue[key] },
                            }),
                            {},
                        );
                        obj[key].completeValues = newValue;
                        obj[key].value = newValue;
                    }
                    break;
                default:
                    obj[key] = { ...filters[key] };
                    break;
            }

            return obj;
        }, {});

        if (!newFilters.activityType) {
            newFilters.activityType = {
                dataType: 'singleValueList',
                value: '-1',
            };
        }

        if (isExportingActivities && exportOverrides?.activityType) {
            newFilters.activityType = {
                dataType: 'singleValueList',
                value: exportOverrides.activityType,
            };
        }

        if (!newFilters.email) {
            newFilters.email = {
                dataType: 'singleValueList',
                value: -1,
            };
        }

        newFilters.useUTCDates = {
            dataType: 'bool',
            value: true,
        };

        return newFilters;
    };

    formatStandardFilters = (filter, completeValues, skipSwapingIdValue) => {
        const filterId = filter.idRelated || filter.id;
        if (
            filterId !== 'listCheckInType' &&
            filterId !== 'videoCallCheckInType' &&
            filterId !== 'videoCallType' &&
            filterId !== 'type'
        )
            return null;

        const shouldSendFilter = this.shouldSendStandardValues(filter);

        if (!shouldSendFilter) return null;

        let result = {};
        result['field'] = filterId;
        result['values'] = completeValues.map((current) => {
            return skipSwapingIdValue && filterId !== 'type' // Because BE
                ? { value: current.value, id: current.id }
                : { value: current.id, id: current.value };
        });

        return result;
    };
}
