import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { useTheme, Text } from 'hoi-poi-ui';
import ErrorImage from './ErrorImage';

import './styles.scss';

const propTypes = {
    img: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

const AbsoluteError = memo(
    ({
        title = getLiteral('label_page_error_title'),
        subtitle = getLiteral('error_generalerror'),
        img,
        svg,
        size,
    }) => {
        const theme = useTheme();
        const className = classnames('fm-absolute-error', {
            'fm-absolute-error--small': size === 'small',
            'fm-absolute-error--popover': size === 'popover',
            'fm-absolute-error--widget': size === 'widget',
        });

        let titleType = size === 'popover' ? 'subtitle1' : 'h6';
        let subtitleType = size === 'popover' ? 'caption' : 'body';

        if (size === 'widget') {
            titleType = 'subtitle1';
            subtitleType = 'caption';
        }

        if (size === 'small') {
            titleType = 'captionMedium';
            subtitleType = 'caption';
        }

        const showDefaultSvg = !svg && !img;

        return (
            <div className={className}>
                {!svg && <img src={img} />}
                {svg}
                {showDefaultSvg && <ErrorImage />}
                <Text type={titleType} color={theme.colors.utility.textPrimary}>
                    {title}
                </Text>
                <Text type={subtitleType} color={theme.colors.utility.textSecondary}>
                    {subtitle}
                </Text>
            </div>
        );
    },
);

AbsoluteError.propTypes = propTypes;

export default AbsoluteError;
