import React, { memo, useCallback, useMemo, useReducer, useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import CONFIG from '../../../components/SalesOrdersProductLinesTable/TableConfig';
import { SectionListCrud } from '@web/web5';
import { defineBaseExtraField, groupFieldsForFieldSelector } from 'utils/fm';
import {
    ConfigActions,
    EntityExtraFieldsActions,
    EntityListActions,
    EntityCrudActions,
    ServerListActions,
} from 'actions';
import { SALESORDERSLINES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { getSmartFieldProps } from 'utils/fm/fields';
import { isEqual } from 'utils/objects';
import Context from 'managers/Context';
import CompaniesRow from 'components/selectRows/CompaniesRow';
import DatePicker from 'components/DatePicker';
import ProductLinesEmptyView from '../../../components/ProductLinesEmptyView';
import { getDefaultColumnsWidth } from '../../../utils';

const mapStateToProps = (state, props) => {
    const oldCache = state?.config?.components?.[`${SALESORDERSLINES.entity}_list`] || null;
    const newCache = (props.cacheId && state?.config?.components?.[props.cacheId]) || null;
    const permission = state.config.permission;
    const canDeleteOrderLines = permission.crud_permission[SALESORDERSLINES.permission].delete;

    return { cache: newCache || oldCache, canDeleteOrderLines };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getExtraFieldSchema: bindActionCreators(EntityExtraFieldsActions, dispatch)
            .getExtraFieldSchema,
        standardFieldsConfiguration: bindActionCreators(EntityListActions, dispatch)
            .standardFieldsConfiguration,
        setConfigWeb: bindActionCreators(ConfigActions, dispatch).setConfigWeb,
        updateTableErrors: bindActionCreators(EntityCrudActions, dispatch).updateTableErrors,
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
};

const initialState = { groupedFields: null, columnsSizes: null, columnsVisibility: null };

const reducer = (state, action) => {
    switch (action.type) {
        case 'setColumnsSizes':
            return { ...state, columnsSizes: action.columnsSizes };
        case 'setColumnsVisibility':
            return { ...state, columnsVisibility: action.columnsVisibility };
        case 'setState':
            return { ...action.state };
    }
};

const ProductLinesTable = memo(
    ({
        data,
        cache,
        getExtraFieldSchema,
        standardFieldsConfiguration,
        setConfigWeb,
        changeField,
        errors,
        crudSchema,
        handleOnRef,
        handleSetCheckedProducts,
        updateTableErrors,
        canDeleteOrderLines,
        getList,
        parentData,
        parentConfigMap,
        isNewEntity,
    }) => {
        const [refreshColumnDefs, setRefreshColumnDefs] = useState(false);
        const [state, dispatch] = useReducer(reducer, initialState);
        const [showModalFields, setShowModalFields] = useState(false);
        const errorsRef = useRef(errors);
        const mappedFields = useRef(null);
        const parentDataRef = useRef(parentData);

        const { finalData, finalCrudSchema } = useMemo(() => {
            const updatedData = data?.map((row) => ({ ...row })) || null;

            const finalCrudSchema = crudSchema.map((field) => {
                const smartFieldOverrides = field.smartField
                    ? getSmartFieldProps({
                          field,
                          configMap: parentConfigMap,
                          data: parentData,
                          isNewEntity,
                      })
                    : {};

                const { defaultValue, ...otherOverrides } = smartFieldOverrides;

                if (!!Object.entries(otherOverrides).length && !!defaultValue) {
                    const fieldId = field.isExtra ? field.id.toLowerCase() : field.id;
                    updatedData?.forEach((row) => (row[fieldId] = defaultValue));
                }

                return { ...field, ...otherOverrides };
            });

            return { finalData: updatedData, finalCrudSchema };
        }, [data, parentData, parentConfigMap, isNewEntity, crudSchema]);

        useEffect(() => {
            errorsRef.current = errors;
        }, [errors]);

        useEffect(() => {
            if (!isEqual(parentDataRef.current, parentData)) {
                setRefreshColumnDefs(true);
                parentDataRef.current = parentData;
            } else {
                setRefreshColumnDefs(false);
            }
        }, [parentData]);

        const tableParams = useMemo(() => {
            let newTableParams = { numLine: { isCrud: true } };

            if (!canDeleteOrderLines) return newTableParams;

            newTableParams.numLine.onClickCheckbox = (result) => {
                const { checked } = result;
                const checkedRows = checked.numLine.rows;
                const newCheckedRows = Object.keys(checkedRows).filter((key) => {
                    return checkedRows[key];
                });
                handleSetCheckedProducts(newCheckedRows);
            };

            return newTableParams;
        }, [canDeleteOrderLines, handleSetCheckedProducts]);

        const saveCache = useCallback(
            (newObject) => {
                const fieldsKeys = ['visibles', 'sizes'];
                const columnsConfig = fieldsKeys.reduce((obj, field) => {
                    obj[field] = newObject[field];
                    return obj;
                }, {});
                setConfigWeb(`${SALESORDERSLINES.entity}_list`, columnsConfig);
            },
            [setConfigWeb],
        );

        const getCacheConfiguration = useCallback(
            (columnsDef) => {
                return columnsDef.map((column) => {
                    if (cache?.visibles?.hasOwnProperty(column.colId)) {
                        column.hide = !cache.visibles[column.colId];
                    }

                    if (column?.cellRendererParams?.inputParams?.isMandatory) column.hide = false;

                    if (cache?.sizes?.[column.colId]) column.width = cache?.sizes[column.colId];

                    return column;
                });
            },
            [cache],
        );

        const entityValueListName = useMemo(
            () => ({
                Productos: 'products',
                coferta: 'offers',
                empresas: 'companies',
                Expedientes: 'opportunities',
                viewUsuariosFullName: 'users',
                viewContactosFullName: 'contacts',
            }),
            [],
        );

        const selectCompanyRow = useCallback((props) => {
            if (!props.data) return null;

            return (
                <CompaniesRow
                    text={props.data.MatchingInfo}
                    info2={props.data.ExtraInfo}
                    info3={props.data.ExtraInfo3}
                    {...props}
                />
            );
        }, []);

        const selectComponents = useCallback(
            ({ list }) => {
                const finalEntity = entityValueListName[list] || list;
                const components = { companies: selectCompanyRow };

                return { Option: components[finalEntity] || null };
            },
            [selectCompanyRow, entityValueListName],
        );

        const saveColumnSize = useCallback(
            (colId, size) => {
                const newColumnsSizes = { ...state.columnsSizes, [colId]: size };
                const newColumnsConfigObject = {
                    sizes: newColumnsSizes,
                    visibles: state.columnsVisibility,
                };
                saveCache(newColumnsConfigObject);
                dispatch({ type: 'setColumnsSizes', columnsVisibility: newColumnsSizes });
            },
            [state.columnsSizes, state.columnsVisibility, saveCache],
        );

        const saveVisibleColumns = useCallback(
            (newColumns) => {
                const newColumnsVisibility = { ...state.columnsVisibility, ...newColumns };

                const newColumnsConfigObject = {
                    sizes: state.columnsSizes,
                    visibles: newColumnsVisibility,
                };

                saveCache(newColumnsConfigObject);
                dispatch({ type: 'setColumnsVisibility', columnsVisibility: newColumnsVisibility });
            },
            [state.columnsSizes, state.columnsVisibility, saveCache],
        );

        const loadOptionsFuzzy = useCallback(
            ({ list }) => {
                return () => {
                    return new Promise((resolve, reject) => {
                        const finalList = entityValueListName[list] || list;

                        Context.domainManager.getFuzzySearch(
                            finalList,
                            finalList,
                            'a',
                            null,
                            null,
                            null,
                            (data) => {
                                const options = data.map((current) => {
                                    return {
                                        ...current,
                                        label: current.MatchingInfo,
                                        value: current.Id,
                                    };
                                });
                                resolve(options);
                            },
                            (error) => {
                                console.error('Error while looking for results');
                                reject(error);
                            },
                        );
                    });
                };
            },
            [entityValueListName],
        );

        const loadOptions = useCallback(
            ({ list }) => {
                return () => {
                    return getList(list);
                };
            },
            [getList],
        );

        const getMappedFields = useCallback((columnDefs) => {
            mappedFields.current = columnDefs.reduce((obj, current) => {
                obj[current.field] = current;
                return obj;
            }, {});
        }, []);

        const getColumnDefs = useCallback(
            ({ transformTableToCrud }) => {
                return new Promise((resolve, reject) => {
                    const newTableParams = { ...tableParams, data: finalData };
                    let newConfig = CONFIG(newTableParams);

                    getExtraFieldSchema(newConfig.entity, (extraFieldsTabs) => {
                        let extraFields = [];
                        const groupedExtraFields = extraFieldsTabs.map((tab) => {
                            let group = {
                                label:
                                    typeof tab.descripcion !== 'string'
                                        ? 'label_customized_fields'
                                        : tab.descripcion,
                                fields: [],
                            };

                            group.fields = tab.tabFields.map((extraField) => {
                                const extraParams = {
                                    decimalAndIntegerParams: { emptyIfNull: true },
                                };
                                let newExtraField = defineBaseExtraField(extraField, extraParams);
                                if (newExtraField.cellRenderer === 'dateCell') {
                                    newExtraField.cellRendererParams.CustomCell = DatePicker;
                                }

                                extraFields.push(newExtraField);
                                return newExtraField;
                            });

                            return group;
                        });

                        standardFieldsConfiguration(SALESORDERSLINES, newConfig.columnDefs)
                            .then((columnDefs) => {
                                let newColumnDefs = [...columnDefs, ...extraFields];

                                const groupedFields = [
                                    ...groupFieldsForFieldSelector(newConfig, columnDefs, true),
                                    ...groupedExtraFields,
                                ];

                                if (finalCrudSchema) {
                                    newColumnDefs = transformTableToCrud({
                                        columnDefs: newColumnDefs,
                                        crudSchema: finalCrudSchema,
                                        inputProps: {
                                            onChange: onChangeCell,
                                            loadOptions,
                                            loadOptionsFuzzy,
                                            selectComponents,
                                        },
                                    });
                                }

                                newColumnDefs = getDefaultColumnsWidth(newColumnDefs);

                                newColumnDefs = getCacheConfiguration(newColumnDefs);

                                dispatch({
                                    type: 'setState',
                                    state: {
                                        groupedFields: groupedFields,
                                        columnsSizes: cache?.sizes || [],
                                        columnsVisibility: cache?.visibles || [],
                                    },
                                });
                                getMappedFields(newColumnDefs);
                                resolve(newColumnDefs);
                            })
                            .catch((error) => {
                                console.error('List configuration error:', error);
                                reject(error);
                            });
                    });
                });
            },
            [
                tableParams,
                getExtraFieldSchema,
                standardFieldsConfiguration,
                getCacheConfiguration,
                finalCrudSchema,
                cache?.sizes,
                cache?.visibles,
                getMappedFields,
                onChangeCell,
                loadOptions,
                loadOptionsFuzzy,
                selectComponents,
                finalData,
            ],
        );

        const fieldSelectorProps = useMemo(() => {
            if (!state.groupedFields) return null;
            let actions = [];

            actions.push({ type: 'title', label: getLiteral('label_customize') });

            actions.push({
                label: getLiteral('action_select_visible_columns'),
                icon: 'columns',
                id: 'columns',
                onClick: () => {
                    setShowModalFields(true);
                    logEvent({
                        event: SALESORDERSLINES.trueName,
                        functionality: 'visibleFields',
                        checkDuplicate: true,
                    });
                },
            });

            return {
                title: getLiteral('label_showHideColumns'),
                actions,
                groupedColumns: state.groupedFields,
            };
        }, [state.groupedFields]);

        const modalOptionsProps = useCallback(
            () => ({
                advice: getLiteral('label_visible_fields_explanation'),
                isOpen: showModalFields,
                confirmText: getLiteral('action_save'),
                cancelText: getLiteral('action_cancel'),
                placeholderSearch: getLiteral('label_search_field'),
                title: getLiteral('label_visible_fields'),
                onCancel: setShowModalFields,
            }),
            [showModalFields],
        );

        const onOrderProducts = useCallback(
            (event) => {
                //Here we use a promise because the table expects a promise
                return new Promise((resolve, reject) => {
                    let newProducts = [];
                    event.api.forEachNode((current) => {
                        let newProduct = current.data;
                        newProduct.intOrder = current.rowIndex;
                        newProduct.numLine = current.rowIndex + 1;
                        newProducts.push(newProduct);
                    });

                    changeField('orderedProducts')(newProducts);
                    resolve();
                });
            },
            [changeField],
        );

        const onChangeCell = useCallback(
            ({ rowIndex, id, field, value }) => {
                changeField('product')({ rowIndex, id, field, value });
                if (!mappedFields.current) return;
                if (errorsRef?.current && Object.keys(errorsRef.current).length > 0) {
                    const newErrors = { ...errorsRef.current };
                    const colId = mappedFields.current[field].colId;
                    if (
                        errorsRef.current?.[colId]?.rows?.[rowIndex]?.error === 'mandatory' &&
                        value
                    ) {
                        delete newErrors[colId].rows[rowIndex];
                        updateTableErrors(newErrors);
                    }
                }
            },
            [changeField, updateTableErrors],
        );

        const columnsRefreshOnDragEnd = useMemo(() => ['numLine', 'rowNumber'], []);

        const { domLayout, extraClassName } = useMemo(() => {
            // 50px each row, 400px of max-height for the table
            // we have only the header, so we have 350px for rows -> 7 rows
            if (!finalData?.length) {
                return {
                    domLayout: 'normal',
                    extraClassName: 'sales-order-product-lines-table__empty',
                };
            } else if (finalData?.length <= 7)
                return { domLayout: 'autoHeight', extraClassName: '' };
            else {
                return { domLayout: 'normal', extraClassName: 'table-with-many-rows' };
            }
        }, [finalData?.length]);

        const getRowNodeId = useCallback((data) => data?.id, []);
        const rootClassName = classnames(['sales-order-product-lines-table', extraClassName]);

        return (
            <SectionListCrud
                id="sales-order-product-lines-table"
                className={rootClassName}
                getColumnDefs={getColumnDefs} //SectionListCrud uses getColumnDefs instead of columnDefs
                rowData={finalData}
                getRowNodeId={getRowNodeId}
                useSort={false}
                useDragRows={true}
                onDragEnd={onOrderProducts}
                columnsRefreshOnDragEnd={columnsRefreshOnDragEnd}
                fieldSelector
                fieldSelectorProps={fieldSelectorProps}
                saveVisibleColumns={saveVisibleColumns}
                saveColumnSize={saveColumnSize}
                errors={errors}
                domLayout={domLayout}
                onRef={handleOnRef}
                modalOptionsProps={modalOptionsProps()}
                customEmptyViewComponent={ProductLinesEmptyView}
                shouldRefreshColumnDefs={refreshColumnDefs}
            />
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductLinesTable);
