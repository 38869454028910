import { memo } from 'react';
import { Badge } from 'hoi-poi-ui';
import { BaseReactCell } from '@web/web5';

import './styles.scss';

const BadgeCell = memo(({ data, value, ...props }) => {
    return (
        <BaseReactCell data={data} {...props}>
            <div className="fm-badge-cell">
                <Badge type={value?.type}>{value?.text}</Badge>
            </div>
        </BaseReactCell>
    );
});

export default BadgeCell;
