import { getLiteral } from 'utils/getLiteral';
import {
    ACTIVITIES,
    COMPANIES,
    CONTACTS,
    OPPORTUNITIES,
    SALESORDERS,
    USERS,
} from 'constants/Entities';
import { PAGINATION_TABLE_ENTITY } from 'constants/Environment';
import { asTelUrl, asEmailUrl } from 'utils/url';
import Context from 'managers/Context';

export default (params) => ({
    pageSize: PAGINATION_TABLE_ENTITY,
    entity: COMPANIES,
    useFiltersDrawer: true,
    useViewsInFilters: true,
    columnSelectorSections: [
        {
            label: 'label_contact_information',
            fields: ['phone', 'phone2', 'cellphone', 'email1', 'fax', 'web'],
        },
        {
            label: 'label_info',
            fields: [
                'companytype',
                'companystate',
                'calificacion',
                'environment',
                'followingitem',
                'responsible',
                'responsible2',
                'responsible3',
                'responsible4',
                'responsible5',
                'tarifa',
                'nif',
                'notes',
                'fcreado',
                'modified',
            ],
        },
        {
            label: 'label_address',
            fields: [
                'address',
                'city',
                'province',
                'cp',
                'country',
                'isgeocoded',
                'longitude',
                'latitude',
            ],
        },
    ],
    useQuickView: true,
    quickFilters: ['companyType', 'companyState', 'owner1'],
    defaultFilters: [
        'onlySelected',
        'companyType',
        'companyState',
        'idcalificacion',
        'environment',
        'users',
        'activity',
        'followingItem',
    ],
    defaultCrossFilters: {
        [CONTACTS.entity]: ['companyType', 'companyState'],
        [ACTIVITIES.entity]: ['companyType', 'idcalificacion'],
        [OPPORTUNITIES.entity]: ['companyType', 'idcalificacion'],
        [SALESORDERS.entity]: ['companyType'],
    },
    filtersAddButtonSchema: [
        {
            label: getLiteral('label_standard_filters_section_title'),
            options: [
                'hasAccount',
                'onlySelected',
                'companyType',
                'companyState',
                'idcalificacion',
                'environment',
                'users',
                'activity',
                'followingItem',
            ],
        },
        {
            label: getLiteral('label_owners'),
            options: ['owner1', 'owner2', 'owner3', 'owner4', 'owner5'],
        },
        {
            label: getLiteral('label_contact_information'),
            options: ['phone', 'email'],
        },
        {
            label: getLiteral('label_info'),
            options: ['fcreado', 'fmodificado', 'rates', 'nif'],
        },
        {
            label: getLiteral('label_address'),
            options: ['strpoblacion', 'strprovincia', 'cp', 'idcountry'],
        },
        {
            label: getLiteral('title_campaigns'),
            options: ['idCampaign'],
        },
    ],
    columnDefs: [
        {
            colId: 'name',
            headerName: getLiteral('label_name'),
            field: 'name',
            sortField: 'name',
            fieldConfiguration: 'nombre',
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                showTotal: true,
                checkbox: {
                    onChange: params?.account?.onClickCheckbox || null,
                },
            },
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                withEmptyPlaceholder: true,
                bold: true,
                hoverLink: true,
                getUrl: params?.getUrl?.({ idKey: 'Id', entity: COMPANIES }) || null,
                innerMethods: {
                    getSvg: params?.account?.getSvg || null,
                },
                otherFields: {
                    src: 'srcAccount',
                    placeholder: 'placeholderAccount',
                },
                checkbox: {
                    onChange: params?.account?.onClickCheckbox || null,
                    isAlwaysVisible: true,
                    isDisabled: params?.account?.isDisabled || null,
                },
            },
            width: 250,
            pinned: 'left',
            lockPosition: true,
            suppressMovable: true,
            suppressSizeToFit: true,
            suppressFromFieldSelector: true,
        },
        {
            colId: 'followingitem',
            headerName: getLiteral('label_following'),
            field: 'isFollowing',
            sortField: 'followingitem',
            headerComponent: 'headerIconCell',
            headerComponentParams: {
                innerMethods: {
                    getSvg: params?.follow?.getHeaderSvg || null,
                },
            },
            cellRenderer: 'toggleCell',
            cellRendererParams: {
                innerMethods: {
                    getSvg: params?.follow?.getSvg || null,
                    onClick: params?.follow?.onClick || null,
                },
                highlightOnHover: true,
                useTooltip: true,
                tooltipValue: params?.follow?.getTooltip || null,
            },
            width: 120,
            suppressSizeToFit: true,
        },
        {
            colId: 'responsible',
            headerName: getLiteral('label_responsible_1'),
            field: 'Responsible',
            sortField: 'responsible',
            fieldConfiguration: 'idComercial',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl: params?.getUrl?.({ idKey: 'IdResponsable', entity: USERS }) || null,
                otherFields: {
                    src: 'srcResponsible',
                    placeholder: 'placeholderResponsible',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'companytype',
            headerName: getLiteral('label_account_type'),
            field: 'CompanyType',
            sortField: 'companytype',
            fieldConfiguration: 'idtipo',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'companystate',
            headerName: getLiteral('label_state'),
            field: 'CompanyState',
            sortField: 'companystate',
            fieldConfiguration: 'idestadoempresa',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'calificacion',
            headerName: getLiteral('label_account_calification'),
            field: 'Calificacion',
            sortField: 'calificacion',
            fieldConfiguration: 'idcalificacion',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'country',
            headerName: getLiteral('label_country'),
            field: 'Country',
            sortField: 'country',
            fieldConfiguration: 'idcountry',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'environment',
            headerName: getLiteral('label_environment'),
            field: 'Environment',
            sortField: 'environment',
            fieldConfiguration: 'idsucursal',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'phone',
            headerName: getLiteral('label_phone'),
            field: 'Phone',
            sortField: 'phone',
            fieldConfiguration: 'tel',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
            cellRendererParams: {
                getUrl: (_, value) => asTelUrl(value),
            },
        },
        {
            colId: 'email1',
            headerName: getLiteral('label_email'),
            field: 'Email1',
            sortField: 'email1',
            fieldConfiguration: 'email',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
            cellRendererParams: {
                getUrl: (_, value) => asEmailUrl(value),
                forceUrl: params?.email1?.forceUrl,
                onClick: params?.email1?.onClick,
            },
        },
        {
            colId: 'fcreado',
            headerName: getLiteral('label_created'),
            field: 'CreationDate',
            hide: true,
            sortField: 'fcreado',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.date?.getLocale() || '',
                offset: Context?.config?.userData?.userOffsetFromServer,
                outputFormat: 'P',
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'modified',
            headerName: getLiteral('label_modified'),
            field: 'Modified',
            hide: true,
            sortField: 'fmodificado',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.date?.getLocale() || '',
                offset: Context?.config?.userData?.userOffsetFromServer,
                outputFormat: 'P',
            },
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'responsible2',
            headerName: getLiteral('label_responsible_2'),
            field: 'Responsible2',
            hide: true,
            sortField: 'responsible2',
            fieldConfiguration: 'idComercial2',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                bold: true,
                getUrl: params?.getUrl?.({ idKey: 'IdResponsable2', entity: USERS }) || null,
                otherFields: {
                    src: 'srcResponsible2',
                    placeholder: 'placeholderResponsible2',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'responsible3',
            headerName: getLiteral('label_responsible_3'),
            field: 'Responsible3',
            hide: true,
            sortField: 'responsible3',
            fieldConfiguration: 'idComercial3',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                bold: true,
                getUrl: params?.getUrl?.({ idKey: 'IdResponsable3', entity: USERS }) || null,
                otherFields: {
                    src: 'srcResponsible3',
                    placeholder: 'placeholderResponsible3',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'responsible4',
            headerName: getLiteral('label_responsible_4'),
            field: 'Responsible4',
            hide: true,
            sortField: 'responsible4',
            fieldConfiguration: 'idComercial4',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                bold: true,
                getUrl: params?.getUrl?.({ idKey: 'IdResponsable4', entity: USERS }) || null,
                otherFields: {
                    src: 'srcResponsible4',
                    placeholder: 'placeholderResponsible4',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'responsible5',
            headerName: getLiteral('label_responsible_5'),
            field: 'Responsible5',
            hide: true,
            sortField: 'responsible5',
            fieldConfiguration: 'idComercial5',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                bold: true,
                getUrl: params?.getUrl?.({ idKey: 'IdResponsable5', entity: USERS }) || null,
                otherFields: {
                    src: 'srcResponsible5',
                    placeholder: 'placeholderResponsible5',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'notes',
            headerName: getLiteral('label_notes'),
            field: 'comments',
            fieldConfiguration: 'observaciones',
            suppressFromFieldSelector: true,
            forceExport: true,
        },
        {
            colId: 'province',
            headerName: getLiteral('label_provinceregion'),
            field: 'Province',
            hide: true,
            sortField: 'province',
            fieldConfiguration: 'strprovincia',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'nif',
            headerName: getLiteral('label_vat_number'),
            field: 'NIF',
            hide: true,
            sortField: 'nif',
            fieldConfiguration: 'nif',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'address',
            headerName: getLiteral('label_address'),
            field: 'Address',
            hide: true,
            sortField: 'address',
            fieldConfiguration: 'direccion',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'phone2',
            headerName: getLiteral('label_phone2'),
            field: 'Phone2',
            hide: true,
            sortField: 'tel2',
            fieldConfiguration: 'tel2',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
            cellRendererParams: {
                getUrl: (_, value) => asTelUrl(value),
            },
        },
        {
            colId: 'cellphone',
            headerName: getLiteral('label_phone3'),
            field: 'CellPhone',
            hide: true,
            sortField: 'cellphone',
            fieldConfiguration: 'strmovil',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
            cellRendererParams: {
                getUrl: (_, value) => asTelUrl(value),
            },
        },
        {
            colId: 'fax',
            headerName: getLiteral('label_fax'),
            field: 'Fax',
            hide: true,
            sortField: 'fax',
            fieldConfiguration: 'fax',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'web',
            headerName: getLiteral('label_web'),
            field: 'Web',
            hide: true,
            sortField: 'web',
            fieldConfiguration: 'web',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'latitude',
            headerName: getLiteral('label_latitude'),
            field: 'Latitude',
            hide: true,
            sortField: 'latitude',
            fieldConfiguration: 'geocodelat',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'longitude',
            headerName: getLiteral('label_longitude'),
            field: 'Longitude',
            hide: true,
            sortField: 'longitude',
            fieldConfiguration: 'geocodelon',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'cp',
            headerName: getLiteral('label_postal_code'),
            field: 'CP',
            hide: true,
            sortField: 'cp',
            fieldConfiguration: 'cp',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'city',
            headerName: getLiteral('label_city'),
            field: 'City',
            hide: true,
            sortField: 'city',
            fieldConfiguration: 'strpoblacion',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'tarifa',
            headerName: getLiteral('label_rate'),
            field: 'Tarifa',
            hide: true,
            sortField: 'tarifa',
            fieldConfiguration: 'idtarifa',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'isgeocoded',
            headerName: getLiteral('label_generic_geolocated'),
            field: 'IsGeoCoded',
            hide: true,
            sortField: 'isgeocoded',
            fieldConfiguration: 'blngeocoded',
            headerComponent: 'headerIconCell',
            headerComponentParams: {
                innerMethods: {
                    getSvg: params?.geolocation?.getHeaderSvg || null,
                },
            },
            cellRenderer: 'booleanIconCell',
            cellRendererParams: {
                innerMethods: {
                    getSvg: params?.geolocation?.getSvg || null,
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
    ],
    help: [
        {
            label: 'label_link_help_what_account_is',
            link: 'link_help_what_account_is',
            permissions: ['permission.empresas'],
        },
        {
            label: 'label_link_help_how_to_find_account',
            link: 'link_help_how_to_find_account',
            permissions: ['permission.empresas'],
        },
        {
            label: 'label_link_help_massive_update_account',
            link: 'link_help_massive_update_account',
            permissions: ['permission.companiesBulkActions'],
        },
        {
            label: 'label_link_help_export_account',
            link: 'link_help_export_account',
            permissions: ['permission.exportEmpresas'],
        },
        {
            label: 'label_link_help_related_account',
            link: 'link_help_related_account',
            permissions: ['permission.empresas_relacionadas'],
        },
        {
            label: 'label_link_help_create_view_account',
            link: 'link_help_create_view_account',
            permissions: ['permission.viewsPublicManagement', 'permission.viewsPrivateManagement'],
        },
    ],
});
