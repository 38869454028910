import colors from 'constants/colors';

export const FolderSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" fill="${colors.grey[500]}" fill-rule="evenodd"></path></svg>`;

export const ReportSvg = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="18" height="18" rx="4" fill="${colors.semantic.positiveCustom100}"/><g clip-path="url(#clip0_1231_34607)"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.6824 7.40037C13.6824 7.87107 13.2996 8.25387 12.8289 8.25387C12.8016 8.25387 12.776 8.24832 12.7495 8.24576L11.4708 10.0362C11.5196 10.1435 11.5486 10.2619 11.5486 10.3876C11.5486 10.8583 11.1659 11.2411 10.6952 11.2411C10.2244 11.2411 9.84166 10.8583 9.84166 10.3876C9.84166 10.3232 9.85019 10.2611 9.86385 10.2009L8.17158 8.63901C8.09178 8.66354 8.00899 8.68061 7.92129 8.68061C7.75635 8.68061 7.60336 8.63154 7.47257 8.55024L5.57098 9.56419C5.55476 10.0206 5.18114 10.3876 4.72068 10.3876C4.24998 10.3876 3.86719 10.0048 3.86719 9.53411C3.86719 9.06341 4.24998 8.68061 4.72068 8.68061C4.88562 8.68061 5.03861 8.72969 5.16941 8.8112L7.071 7.79703C7.08721 7.34063 7.46083 6.97362 7.92129 6.97362C8.39199 6.97362 8.77479 7.35642 8.77479 7.82712C8.77479 7.89156 8.76625 7.95365 8.7526 8.01382L10.4449 9.57572C10.5245 9.55118 10.6075 9.53411 10.6952 9.53411C10.7225 9.53411 10.7481 9.53966 10.7745 9.54222L12.0533 7.7518C12.0044 7.64447 11.9754 7.52605 11.9754 7.40037C11.9754 6.92967 12.3582 6.54688 12.8289 6.54688C13.2996 6.54688 13.6824 6.92967 13.6824 7.40037Z" fill="${colors.semantic.positive500}"/></g><defs><clipPath id="clip0_1231_34607"><rect width="10.8" height="10.8" fill="${colors.primary.white}" transform="translate(3.375 3.6001)"/></clipPath></defs></svg>`;

export const WidgetSvg = `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.7 4.5L19.5 7.3L16.7 10.1L13.9 7.3L16.7 4.5M9 5V9H5V5H9M19 15V19H15V15H19M16.7 1.7L11 7.3L16.7 13H13V21H21V13H16.7L22.3 7.3L16.7 1.7M11 3H3V11H11V3M9 15V19H5V15H9M11 13H3V21H11V13Z" fill="${colors.grey[500]}" /></svg>`;

export const FolderKeySvg = `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6H12L10 4H4ZM11 10C12.31 10 13.42 10.84 13.83 12H19V14H18V16H16V14H13.83C13.42 15.17 12.31 16 11 16C10.2044 16 9.44129 15.6839 8.87868 15.1213C8.31607 14.5587 8 13.7956 8 13C8 12.2044 8.31607 11.4413 8.87868 10.8787C9.44129 10.3161 10.2044 10 11 10V10ZM11 12C10.7348 12 10.4804 12.1054 10.2929 12.2929C10.1054 12.4804 10 12.7348 10 13C10 13.56 10.45 14 11 14C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13C12 12.7348 11.8946 12.4804 11.7071 12.2929C11.5196 12.1054 11.2652 12 11 12Z" fill="${colors.grey[500]}"/></svg>`;

export const SFMSvg = `<svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="18" height="18" rx="4" fill="${colors.semantic.infoCustom100}"/><g clip-path="url(#a)"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.257 5.266h-1.28a.427.427 0 0 0-.427.427v6.401c0 .236.191.427.426.427h1.28a.427.427 0 0 0 .427-.427v-6.4a.427.427 0 0 0-.426-.428Zm-2.56 2.988H9.415a.427.427 0 0 0-.427.426v3.414c0 .236.192.427.427.427h1.28a.427.427 0 0 0 .427-.427V8.68a.427.427 0 0 0-.427-.426Zm-2.561-1.28h-1.28a.427.427 0 0 0-.427.426v4.694c0 .236.191.427.426.427h1.28a.427.427 0 0 0 .427-.427V7.4a.427.427 0 0 0-.426-.427ZM6.002 9.96v2.133a.427.427 0 0 1-.427.427h-1.28a.427.427 0 0 1-.427-.427V9.961c0-.236.192-.427.427-.427h1.28c.236 0 .427.191.427.427Z" fill="${colors.semantic.info500}"/></g><defs><clipPath id="a"><path fill="${colors.primary.white}" transform="translate(3.375 3.6)" d="M0 0h10.8v10.8H0z"/></clipPath></defs></svg>`;

export const DashboardSvg = `<svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="18" height="18" rx="4" fill="${colors.semantic.focusCustom100}"/><g clip-path="url(#a)"><path d="M8.05 5.132v4.593h4.593A4.195 4.195 0 1 1 8.05 5.132Zm.84-.632a4.824 4.824 0 0 1 4.385 4.386H8.89V4.5Z" fill="${colors.semantic.focus500}"/></g><defs><clipPath id="a"><path fill="${colors.primary.white}" transform="translate(3.375 3.6)" d="M0 0h10.8v10.8H0z"/></clipPath></defs></svg>`;
