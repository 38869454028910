import {
    COMPANIES,
    CONTACTS,
    AGENDA,
    OPPORTUNITIES,
    KPIS,
    CAMPAIGNS,
    SALESORDERS,
    RATES,
    ACTIVITIES,
} from 'constants/Entities';
import Context from 'managers/Context';
import { IS_PRODUCTION } from 'constants/Environment';
import { isExternalRoute } from 'utils/routes';
import { PRODUCT_MODE_CODES } from 'constants/Constants';

const entities = {
    [COMPANIES.entity]: COMPANIES,
    [CONTACTS.entity]: CONTACTS,
    [AGENDA.entity]: AGENDA,
    [OPPORTUNITIES.entity]: OPPORTUNITIES,
    [KPIS.entity]: KPIS,
    [CAMPAIGNS.entity]: CAMPAIGNS,
    [RATES.entity]: RATES,
    'sales-orders': SALESORDERS,
    [ACTIVITIES.entity]: ACTIVITIES,
};

const observer = new MutationObserver(function (mutations) {
    mutations.forEach((current) => {
        current.addedNodes.forEach((node) => {
            if (node.classList?.length > 0 && node.classList.contains('intercom-lightweight-app')) {
                intercomHideAndShow(null, true);
            }
        });
    });
});

export function load(traits = { people: {}, company: {} }, hideByDefault, productModeCode) {
    const APP_ID = IS_PRODUCTION ? 'vw2hqdo6' : 'gj98e3oc';

    (function () {
        let w = window;
        let ic = w.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            let d = document;
            let i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            let l = function () {
                let s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/' + APP_ID;
                let x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
                l();
            } else if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    })();

    window.Intercom('boot', {
        app_id: APP_ID,
        ...traits.people,
        company: {
            ...traits.company,
        },
        hide_default_launcher: hideByDefault || false,
    });

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('openChat')) {
        window.Intercom('showSpace', 'home');
    }
}

export function intercomMutationObserver() {
    const target = document.querySelector('body');
    const config = {
        childList: true,
    };
    observer.observe(target, config);
}

export function intercomHideAndShow(action, isObserver) {
    let intercomElement = document.querySelector('.intercom-lightweight-app') || null;
    if (!intercomElement) intercomElement = document.getElementById('intercom-container') || null;

    //Case for Billboard, SalesOrders and Calendar Toast Notifications
    if (isExternalRoute()) {
        if (intercomElement) intercomElement.style.display = 'none';
        return;
    } else if (intercomElement && action) {
        if (action === 'hide' || isExternalRoute()) {
            intercomElement.style.display = 'none';
            return;
        }
        if (action === 'show') {
            intercomElement.style.display = 'block';
            return;
        }
    }

    // General case
    const href = window.location.href;
    let entity = '';
    Object.keys(entities).forEach((key) => {
        if (href.split('/').includes(key)) entity = entities[key];
    });

    if (!entity) return;

    const state = Context.store.getState();

    let shouldHide = false;

    const filters = state.entityFilters?.[entity.entity] || filters;
    const isFiltersOpen = filters?.show ? true : false;

    let isMobile =
        navigator.userAgent.match(/android/i) != null ||
        navigator.userAgent.match(/(iPad|iPhone|iPod)/i) != null;

    // RegEx to check if the current url requires hiding the intercom
    const mapViewRegEx = new RegExp(`${entity.route}\/map$`, 'g');
    const detailRegEx = new RegExp(`${entity.route}\/[0-9]*$`, 'g');
    const createRegEx = new RegExp(`${entity.route}\/new$`, 'g');
    const editRegEx = new RegExp(`${entity.route}\/[0-9]*\/edit$`, 'g');
    const detailActivitiesRegEx = new RegExp(`${entity.route}\/[0-9]*\/[0-9]*(/timeline)?$`, 'g');
    const createActivitiesRegEx = new RegExp(`${entity.route}\/new\/[0-9]*$`, 'g');
    const editActivitiesRegEx = new RegExp(`${entity.route}\/[0-9]*\/[0-9]*\/edit$`, 'g');

    if (
        isMobile ||
        isFiltersOpen ||
        mapViewRegEx.test(href) ||
        detailRegEx.test(href) ||
        createRegEx.test(href) ||
        editRegEx.test(href) ||
        detailActivitiesRegEx.test(href) ||
        createActivitiesRegEx.test(href) ||
        editActivitiesRegEx.test(href) ||
        isExternalRoute()
    ) {
        shouldHide = true;
    }

    if (intercomElement && shouldHide) intercomElement.style.display = 'none';
    else if (intercomElement) intercomElement.style.display = 'block';

    // Disconnect the observer because it's only necessary after the first load to
    // check if the intercom has to be hiden
    if (isObserver) observer.disconnect();
}

export function trackEvent(name, metadata) {
    if (window?.Intercom && !IS_PRODUCTION) {
        console.info('INTERCOM_EVENT', name);
    }

    return window.Intercom?.('trackEvent', name, metadata);
}

export default {
    load,
};
