import {
    ACTIVITIES,
    COMPANIES,
    CONTACTS,
    OPPORTUNITIES,
    SALESORDERS,
    USERS,
} from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

export const AUTOMATION_TRIGGERS = {
    create: {
        icon: 'addCircle',
        name: 'label_automation_event_is_created',
    },
    update: {
        icon: 'edit',
        name: 'label_automation_event_is_modified',
    },
    delete: {
        icon: 'delete',
        name: 'label_automation_event_is_deleted',
    },
    create_update: {
        icon: 'addCircle',
        name: 'label_automation_event_is_created',
    },
};

export const AUTOMATION_ENTITIES = [
    COMPANIES,
    ACTIVITIES,
    OPPORTUNITIES,
    CONTACTS,
    SALESORDERS,
    USERS,
];

export const CONDITION_OPERATOR_LOCALES = {
    // General
    empty: 'label_automation_operator_no_data',
    not_empty: 'label_automation_operator_with_data',

    // Text
    text_contains: 'label_automation_operator_contains',
    text_not_contains: 'label_automation_operator_no_contains',
    text_equal: 'label_automation_operator_equal_to',
    text_equal_calculated: 'label_automation_operator_equal_to_another_field',
    text_not_equal: 'label_automation_operator_no_equal_to',
    text_not_equal_calculated: 'label_automation_operator_different_from_another_field',

    // bools
    bool_equal: 'label_automation_operator_equal_to',
    bool_not_equal: 'label_automation_operator_no_equal_to',
    bool_equal_calculated: 'label_automation_operator_equal_to_another_field',
    bool_not_equal_calculated: 'label_automation_operator_different_from_another_field',

    // numbers
    number_equal: 'label_automation_operator_equal_to',
    number_not_equal: 'label_automation_operator_no_equal_to',
    number_greater_than: 'label_automation_operator_greater_than',
    number_greater_equal_than: 'label_automation_operator_greater_equal_than',
    number_less_than: 'label_automation_operator_less_than',
    number_less_equal_than: 'label_automation_operator_less_equal_than',
    number_between: 'label_automation_operator_inside_range',
    number_not_between: 'label_automation_operator_not_inside_range',
    number_equal_calculated: 'label_automation_operator_equal_to_another_field',
    number_not_equal_calculated: 'label_automation_operator_different_from_another_field',

    // dates
    datetime_today: 'common_today',
    datetime_yesterday: 'common_yesterday',
    datetime_tomorrow: 'common_tomorrow',
    datetime_this_week: 'common_this_week',
    datetime_last_week: 'common_last_week',
    datetime_next_week: 'common_next_week',
    datetime_this_month: 'common_this_month',
    datetime_last_month: 'common_last_month',
    datetime_next_month: 'common_next_month',
    datetime_this_quarter: 'common_this_quarter',
    datetime_last_quarter: 'common_last_quarter',
    datetime_next_quarter: 'common_next_quarter',
    datetime_this_year: 'common_this_year',
    datetime_last_year: 'common_last_year',
    datetime_next_year: 'common_next_year',
    datetime_before: 'label_automation_operator_before_than',
    datetime_after: 'label_automation_operator_after_than',
    datetime_on_or_before: 'label_automation_operator_on_or_before_than',
    datetime_on_or_after: 'label_automation_operator_on_or_after_than',
    datetime_in_range: 'label_automation_operator_inside_range',
    datetime_not_in_range: 'label_automation_operator_not_inside_range',
    datetime_last_n_days: 'label_automation_operator_in_the_last_n_days',
    datetime_next_n_days: 'label_automation_operator_in_the_next_n_days',
    datetime_equal_calculated: 'label_automation_operator_equal_to_another_field',
    datetime_not_equal_calculated: 'label_automation_operator_different_from_another_field',
    datetime_day_of_week: 'label_automation_operator_select_day_of_week',
    datetime_month: 'label_automation_operator_select_month',
    datetime_this_weekend: 'common_this_week',

    // list
    list_contains: 'label_automation_operator_contains',
    list_not_contains: 'label_automation_operator_does_not_contain',
    list_equal_calculated: 'label_automation_operator_equal_to_another_field',
    list_not_equal_calculated: 'label_automation_operator_different_from_another_field',

    // multilist
    multilist_contains: 'label_automation_operator_contains',
    multilist_not_contains: 'label_automation_operator_multilist_not_contains',
    multilist_contains_any: 'label_automation_operator_contains_all_values',
    multilist_not_contains_any: 'label_automation_operator_does_not_contains_all_values',
    multilist_equal_calculated: 'label_automation_operator_equal_to_another_field',
    multilist_not_equal_calculated: 'label_automation_operator_different_from_another_field',
};

export const CONDITION_TYPE_DEFAULTS = {
    text: 'text_contains',
    int: 'number_equal',
    decimal: 'number_equal',
    currency: 'number_equal',
    percentage: 'number_equal',
    bit: 'bool_equal',
    list: 'list_contains',
    multivalue: 'multilist_contains',
    datetime: 'not_empty',
};

export const CONDITION_TYPE_NO_INPUT = [
    'empty',
    'not_empty',
    'datetime_today',
    'datetime_yesterday',
    'datetime_tomorrow',
    'datetime_this_weekend',
    'datetime_last_week',
    'datetime_next_week',
    'datetime_this_month',
    'datetime_last_month',
    'datetime_next_month',
    'datetime_this_quarter',
    'datetime_last_quarter',
    'datetime_next_quarter',
    'datetime_this_year',
    'datetime_last_year',
    'datetime_next_year',
];

export const CONDITION_BOOL_LOGIC_LOCALES = {
    and: 'cfm_label_condition_and',
    or: 'cfm_label_condition_or',
};

export const TRIGGER_ACTION_ICONS = {
    create: 'addCircle',
    update: 'edit',
    delete: 'delete',
};

export const ACTION_OPERATORS = {
    nullable: 'label_automation_operator_equal_to_null',
    calculated_boolean: 'label_automation_operator_calculated_value',
    calculated_datetime: 'label_automation_operator_calculated_value',
    calculated_calculator_int: 'label_automation_operator_calculated_value',
    calculated_calculator_float: 'label_automation_operator_calculated_value',
    calculated_string: 'label_automation_operator_calculated_value',
    specific_single_list: 'label_automation_operator_specif_value',
    specific_multi_list: 'label_automation_operator_specif_value',
    calculated_single_list: 'label_automation_operator_equal_to_another_field',
    calculated_multi_list: 'label_automation_operator_equal_to_another_field',
    days_after_datetime: 'label_automation_operator_days_after_datetime',
    week_after_datetime: 'label_automation_operator_week_after_datetime',
    month_after_datetime: 'label_automation_operator_month_after_datetime',
    next_day_week_datetime: 'label_automation_operator_next_day_week_datetime',
};

export const ACTION_OPERATORS_DEFAULTS = {
    bit: 'calculated_boolean',
    currency: 'calculated_calculator_float',
    datetime: 'calculated_datetime',
    decimal: 'calculated_calculator_float',
    int: 'calculated_calculator_int',
    list: 'specific_single_list',
    multivalue: 'specific_single_list',
    percentage: 'calculated_calculator_float',
    text: 'calculated_string',
};

export const ACTION_OPERATORS_NO_INPUT = ['nullable'];

export const ACTION_ICONS = {
    update_entity_related: 'edit',
};

export function findNodeByType(node, id) {
    if (node.name === id) return node;
    if (Array.isArray(node.next)) {
        for (const child of node.next) {
            const result = findNodeByType(child, id);
            if (result) return result;
        }
    }

    return null;
}

export function findParentById(tree, targetId) {
    function traverse(node, parent) {
        if (node.name === targetId) {
            return parent;
        }
        if (node.next) {
            for (const child of node.next) {
                const result = traverse(child, node);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    return traverse(tree, tree);
}

export function buildPathTree(obj, path) {
    const keys = path.split('.');
    function walk(current, index) {
        const isFinalNode = index >= keys.length;

        if (isFinalNode) {
            if (Array.isArray(current)) {
                return [...current];
            } else if (current !== null && typeof current === 'object') {
                const copyObj = { ...current };
                delete copyObj.next;
                return copyObj;
            } else {
                return current;
            }
        }
        const key = keys[index];
        if (Array.isArray(current)) {
            const idx = parseInt(key, 10);
            if (isNaN(idx) || idx < 0 || idx >= current.length) {
                return [];
            }
            const copyArr = [...current];
            copyArr[idx] = walk(current[idx], index + 1);
            return copyArr;
        }
        if (current !== null && typeof current === 'object') {
            if (!Object.prototype.hasOwnProperty.call(current, key)) {
                return {};
            }
            const copyObj = { ...current };
            copyObj[key] = walk(current[key], index + 1);
            return copyObj;
        }
        return current;
    }

    return walk(obj, 0);
}

export const getDayWeekOptions = () => {
    return [
        {
            label: getLiteral('common_monday'),
            value: 1,
        },
        {
            label: getLiteral('common_tuesday'),
            value: 2,
        },
        {
            label: getLiteral('common_wednesday'),
            value: 3,
        },
        {
            label: getLiteral('common_thursday'),
            value: 4,
        },
        {
            label: getLiteral('common_friday'),
            value: 5,
        },
        {
            label: getLiteral('common_saturday'),
            value: 6,
        },
        {
            label: getLiteral('common_sunday'),
            value: 0,
        },
    ];
};

export const getMonthOptions = () => {
    return [
        {
            label: getLiteral('common_january'),
            value: 1,
        },
        {
            label: getLiteral('common_february'),
            value: 2,
        },
        {
            label: getLiteral('common_march'),
            value: 3,
        },
        {
            label: getLiteral('common_april'),
            value: 4,
        },
        {
            label: getLiteral('common_may'),
            value: 5,
        },
        {
            label: getLiteral('common_june'),
            value: 6,
        },
        {
            label: getLiteral('common_july'),
            value: 7,
        },
        {
            label: getLiteral('common_august'),
            value: 8,
        },
        {
            label: getLiteral('common_september'),
            value: 9,
        },
        {
            label: getLiteral('common_october'),
            value: 10,
        },
        {
            label: getLiteral('common_november'),
            value: 11,
        },
        {
            label: getLiteral('common_december'),
            value: 12,
        },
    ];
};
