import { getLiteral } from 'utils/getLiteral';
import Context from 'managers/Context';

export default (params) => ({
    fieldSelectorProps: {
        title: getLiteral('label_showHideColumns'),
    },
    cacheId: 'metrics_list',
    columnDefs: [
        {
            colId: 'name',
            headerName: getLiteral('label_name'),
            field: 'name',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                bold: true,
                imageSize: 'small',
                isSquared: true,
                hoverLink: true,
                onlyIcon: true,
                innerMethods: {
                    getAvatarSvg: params?.name?.getSvg || null,
                },
                onClick: params?.name?.onClick || null,
                favorite: {
                    getShouldHide: params?.name?.getShouldHide || null,
                    onChange: params?.name?.onChangeFavorite || null,
                },
            },
            resizable: true,
            width: 350,
            suppressSizeToFit: true,
            suppressFromFieldSelector: true,
        },
        {
            colId: 'dateCreated',
            headerName: getLiteral('label_created'),
            field: 'dateCreated',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: () => Context?.config?.userData?.locale,
                inputFormat: "yyyy-MM-dd'T'HH:mm:ssXXX",
                outputFormat: 'P',
            },
            resizable: true,
            suppressSizeToFit: true,
        },

        {
            colId: 'dateUpdated',
            headerName: getLiteral('label_modified'),
            field: 'dateUpdated',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: () => Context?.config?.userData?.locale,
                inputFormat: "yyyy-MM-dd'T'HH:mm:ssXXX",
                outputFormat: 'P',
            },
            resizable: true,
            suppressSizeToFit: true,
        },
        {
            colId: 'creator',
            headerName: getLiteral('label_creator'),
            field: 'creator',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                hoverLink: true,
                getUrl: params.getUrlUser,
                otherFields: {
                    src: 'creatorSrc',
                    placeholder: 'creatorPlaceholder',
                },
            },
            resizable: true,
            suppressSizeToFit: true,
        },
        {
            colId: 'visibleIn',
            headerName: getLiteral('label_analytics_pro_report_visible_entities'),
            field: 'visibleIn',
            cellRenderer: 'multiValueCell',
            sortable: false,
            resizable: true,
            suppressSizeToFit: true,
        },
    ],
});
