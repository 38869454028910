import { SettingsIllustrations } from '@web/web5';
import SageCopilotIcon from 'components/icons/SageCopilot';
import { BILLBOARD, CAMPAIGNS, KPIS, OPPORTUNITIES, SALESORDERS } from 'constants/Entities';
import metadata from 'lib/metadata';

const {
    GoalManager,
    SalesOrders,
    OutlookPlugin,
    CallerIDiOS,
    MicrosoftTeams,
    CallerIDAndroid,
    CardManager,
    GeolocatedOpportunities,
    Campaigns,
    NewsManager,
    EmailTracking,
    DanaPlus,
} = SettingsIllustrations;

export const ADDON_GOALMANAGER = 'addon_goalmanager';
export const ADDON_SALESORDERS = 'addon_salesorders';
export const ADDON_OUTLOOK = 'addon_outlook';
export const ADDON_CALLERID = 'addon_callerid';
export const ADDON_MICROSOFT_TEAMS = 'addon_microsoft_teams';
export const ADDON_ANDROID_TRACKER = 'addon_android_tracker';
export const ADDON_SCAN_CARD = 'addon_scan_card';
export const ADDON_OPPS_GEOPOS = 'addon_opportunities_geolocation';
export const ADDON_SALES_CAMPAIGNS = 'addon_sales_campaigns';
export const ADDON_NEWSMANAGER = 'addon_newsmanager';
export const ADDON_EMAIL_TRACKING = 'addon_email_tracking';
export const ADDON_DANA_PLUS = 'addon_danaplus';

export const ICONS = {
    [ADDON_GOALMANAGER]: 'goals',
    [ADDON_SALESORDERS]: 'salesOrders',
    [ADDON_OUTLOOK]: 'outlook',
    [ADDON_CALLERID]: 'callerIdIos',
    [ADDON_MICROSOFT_TEAMS]: 'microsoftTeams',
    [ADDON_ANDROID_TRACKER]: 'callerIdAndroid',
    [ADDON_SCAN_CARD]: 'scanCard',
    [ADDON_OPPS_GEOPOS]: 'geolocatedOpportunity',
    [ADDON_SALES_CAMPAIGNS]: 'campaigns',
    [ADDON_NEWSMANAGER]: 'news',
    [ADDON_EMAIL_TRACKING]: 'emailTracking',
    [ADDON_DANA_PLUS]: { customIcon: metadata.SageCopilotIcon },
};

export const PICS = {
    [ADDON_GOALMANAGER]: GoalManager,
    [ADDON_SALESORDERS]: SalesOrders,
    [ADDON_OUTLOOK]: OutlookPlugin,
    [ADDON_CALLERID]: CallerIDiOS,
    [ADDON_MICROSOFT_TEAMS]: MicrosoftTeams,
    [ADDON_ANDROID_TRACKER]: CallerIDAndroid,
    [ADDON_SCAN_CARD]: CardManager,
    [ADDON_OPPS_GEOPOS]: GeolocatedOpportunities,
    [ADDON_SALES_CAMPAIGNS]: Campaigns,
    [ADDON_NEWSMANAGER]: NewsManager,
    [ADDON_EMAIL_TRACKING]: EmailTracking,
    [ADDON_DANA_PLUS]: DanaPlus,
};

export const STATUS = {
    UNDEFINED: 0, // Will be treated as INACTIVE
    ACTIVE: 1,
    INACTIVE: 2,
    EXPIRED: 3,
    PURCHASED: 4,
    FEATURE: 99,
};

export const DOWNLOAD_URLS = {
    [ADDON_OUTLOOK]: 'label_link_plugin_outlook',
    [ADDON_CALLERID]: 'label_link_install_ForceManger_App_Store',
    [ADDON_MICROSOFT_TEAMS]: 'label_link_plugin_microsoft_teams',
    [ADDON_ANDROID_TRACKER]: 'action_download_call_tracker',
};

export const ENTITIES = {
    [ADDON_GOALMANAGER]: KPIS,
    [ADDON_SALESORDERS]: SALESORDERS,
    [ADDON_OPPS_GEOPOS]: OPPORTUNITIES,
    [ADDON_SALES_CAMPAIGNS]: CAMPAIGNS,
    [ADDON_NEWSMANAGER]: BILLBOARD,
};

export const PERMISSIONS = {
    [ADDON_GOALMANAGER]: [
        {
            key: 'kpisPremium',
            value: true,
        },
    ],
    [ADDON_SALESORDERS]: [
        {
            key: 'tarifa',
            value: true,
        },
        {
            key: 'salesOrders',
            value: true,
        },
    ],
    [ADDON_OPPS_GEOPOS]: [
        {
            key: 'opportunitiesGeolocation',
            value: true,
        },
    ],
    [ADDON_SALES_CAMPAIGNS]: [
        {
            key: 'verCampaignsAdmin',
            value: true,
        },
    ],
    [ADDON_NEWSMANAGER]: [
        {
            key: 'billboard',
            value: true,
        },
    ],
};

export const INTERCOM_METADATA = {
    [ADDON_GOALMANAGER]: {
        value: 'goalManager',
    },
    [ADDON_SALESORDERS]: {
        value: 'quotes_orders',
    },
    [ADDON_OPPS_GEOPOS]: {
        value: 'opportunity_geolocalized',
    },
    [ADDON_SALES_CAMPAIGNS]: {
        value: 'campaigns',
    },
    [ADDON_NEWSMANAGER]: {
        value: 'news',
    },
};
