import React from 'react';
import { getLiteral } from 'utils/getLiteral';
import { PAGINATION_TABLE_ENTITY } from 'constants/Environment';
import { BILLBOARD, USERS } from 'constants/Entities';

export default (params) => ({
    pageSize: PAGINATION_TABLE_ENTITY,
    entity: BILLBOARD,
    columnDefs: [
        {
            colId: 'subject',
            headerName: getLiteral('title_billboard_title'),
            field: 'subject',
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                showTotal: true,
            },
            cellRenderer: 'textCell',
            cellRendererParams: {
                bold: true,
            },
            suppressSizeToFit: false,
        },
        {
            colId: 'attachments',
            field: 'attachments',
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                hideTitle: true,
                isSquared: true,
            },
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                align: 'center',
                imageSize: 'small',
                onlyIcon: true,
                innerMethods: {
                    getAvatarSvg: params?.attachments?.getAvatarSvg || null,
                },
            },
            width: 100,
            suppressSizeToFit: true,
        },
        {
            colId: 'userNameCreado',
            headerName: getLiteral('label_billboard_author'),
            field: 'userNameCreado',
            headerComponent: 'headerTextCell',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl: params?.getUrl?.({ idKey: 'userCreado', entity: USERS }) || null,
                otherFields: {
                    src: 'srcAccount',
                    placeholder: 'placeholderAccount',
                },
            },
            suppressSizeToFit: false,
        },
        {
            colId: 'environmentsDescription',
            headerName: getLiteral('label_billboard_environment'),
            field: 'environmentsDescription',
            headerComponent: 'headerTextCell',
            cellRenderer: 'multiValueCell',
            sortable: false,
            suppressSizeToFit: false,
        },
        {
            colId: 'startDate',
            headerName: getLiteral('label_billboard_date_sent'),
            field: 'startDate',
            headerComponent: 'headerTextCell',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.locale?.getLocale() || '',
                outputFormat: 'P',
            },
            width: 150,
            suppressSizeToFit: true,
        },
        {
            colId: 'sent',
            headerName: getLiteral('label_billboard_sent'),
            field: 'sent',
            headerComponent: 'headerTextCell',
            cellRenderer: 'stepCell',
            cellRendererParams: {
                isFirst: true,
                background: 'var(--semantic-infoCustom50)',
                backgroundNext: 'var(--semantic-infoCustom100)',
            },
            width: 100,
            suppressSizeToFit: true,
        },
        {
            colId: 'opened',
            headerName: getLiteral('label_billboard_opened'),
            field: 'opened',
            headerComponent: 'headerTextCell',
            cellRenderer: 'stepCell',
            cellRendererParams: {
                isMiddle: true,
                background: 'var(--semantic-infoCustom100)',
                backgroundNext: 'var(--semantic-positiveCustom100)',
                otherFields: {
                    valueTwo: 'openedPercentage',
                },
            },
            width: 100,
            suppressSizeToFit: true,
        },
        {
            colId: 'confirmed',
            headerName: getLiteral('label_billboard_confirmed'),
            field: 'confirmed',
            headerComponent: 'headerTextCell',
            cellRenderer: 'stepCell',
            cellRendererParams: {
                isLast: true,
                background: 'var(--semantic-positiveCustom100)',
                otherFields: {
                    valueTwo: 'confirmedPercentage',
                },
            },
            width: 100,
            suppressSizeToFit: true,
        },
    ],
});
