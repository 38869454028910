import { isEqual } from 'utils/objects';
import { isEqual as isEqualArray } from 'utils/arrays';

export default ({ data, originalData, field }) => {
    if (!data || !originalData) return false;

    const originalValue = originalData[field.id];
    const newValue = data[field.id];

    let isTouched;

    if (Array.isArray(originalValue) || Array.isArray(newValue)) {
        isTouched = !isEqualArray(originalValue, newValue);
    } else if (typeof originalValue === 'object' || typeof newValue === 'object') {
        isTouched = !isEqual(originalValue, newValue);
    } else {
        isTouched = originalValue !== newValue;
    }

    return isTouched;
};
