import React, { memo, useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { PRODUCTS } from 'constants/Entities';
import CONFIG from './TableConfig';
import { PATH_IMAGE } from 'constants/Environment';
import NewEntityList from 'containers/components/NewEntityList';
import NoData from 'components/SvgIcons/emptyScreen/NoData';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import Products from 'components/SvgIcons/emptyScreen/Products';

import { EntityListActions, EntityExtraFieldsActions } from 'actions';
import LinkFromWeb3Decorator from 'decorators/LinkFromWeb3Decorator';
import { getLiteral } from 'utils/getLiteral';
import { groupFieldsForFieldSelector, processExtraFieldsForList } from 'utils/fm';
import { logEvent } from 'utils/tracking';
import metadata from 'lib/metadata';

const mapStateToProps = (state) => {
    const permission = state.config.permission;

    return {
        canCreate: permission?.crud_permission?.[PRODUCTS.permission]?.create || false,
        locale: state?.config?.userData?.locale || '',
        enabledUserflow: state?.config?.userData?.userFlow,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getExtraFieldSchema: bindActionCreators(EntityExtraFieldsActions, dispatch)
            .getExtraFieldSchema,
        setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
        standardFieldsConfiguration: bindActionCreators(EntityListActions, dispatch)
            .standardFieldsConfiguration,
    };
};

const Content = memo(
    ({
        getExtraFieldSchema,
        standardFieldsConfiguration,
        locale,
        canCreate,
        setUseLazyLoad,
        enabledUserflow,
        onChangeFamily,
    }) => {
        const isFirstLoad = useRef(true);
        const [config, setConfig] = useState(null);

        useEffect(() => {
            setUseLazyLoad(PRODUCTS, true);
            if (!isFirstLoad?.current) return;

            let newConfig = CONFIG({
                onChangeFamily,
            });
            newConfig = {
                ...newConfig,
                withEntitySelection: false,
            };

            getExtraFieldSchema(newConfig.entity, (extraFieldTabs) => {
                let extraFields = [];
                const groupedExtraFields = extraFieldTabs.map((tab) => {
                    let group = {
                        label:
                            typeof tab.descripcion !== 'string'
                                ? 'label_customized_fields'
                                : tab.descripcion,
                        fields: [],
                    };

                    group.fields = processExtraFieldsForList(tab.tabFields);
                    extraFields = [...extraFields, ...group.fields];

                    return group;
                });

                standardFieldsConfiguration(PRODUCTS, newConfig.columnDefs)
                    .then((columnDefs) => {
                        const newColumnDefs = [...columnDefs, ...extraFields];
                        const groupedFields = [
                            ...groupFieldsForFieldSelector(newConfig, columnDefs, true),
                            ...groupedExtraFields,
                        ];

                        newConfig = {
                            ...newConfig,
                            columnDefs: newColumnDefs,
                            groupedFields,
                        };
                        setConfig(newConfig);
                    })
                    .catch((e) => {
                        console.error(e);
                        console.error('List configuration error');
                    });
            });

            isFirstLoad.current = false;
        }, [
            config,
            setConfig,
            getExtraFieldSchema,
            standardFieldsConfiguration,
            setUseLazyLoad,
            onChangeFamily,
        ]);

        const getCustomActiveRowId = useCallback((activeRowId) => {
            if (!activeRowId) return activeRowId;
            return String(activeRowId);
        }, []);

        const emptyIcon = useMemo(() => {
            if (locale.includes('es-'))
                return <img src={`${PATH_IMAGE}illustration_video_products_ES.png`} />;
            return <img src={`${PATH_IMAGE}illustration_video_products_EN.png`} />;
        }, [locale]);

        const emptyViewProps = useMemo(() => {
            // no userflow video yet
            if (!metadata.showEmptyScreenVideo || enabledUserflow === 'False')
                return {
                    canCreate,
                    icon: <NoData />,
                    iconFirstTime: <Products />,
                    iconType: 'icon',
                    titleFirstTime: getLiteral('label_empty_screen_products_title'),
                    subtitleFirstTime: getLiteral('label_empty_screen_products_desc'),
                    titleSearch: getLiteral('error_notresultfound'),
                    subtitle: getLiteral('helptext_search_filter_text'),
                    subtitleSearch: getLiteral('helptext_search_filter_text'),
                    customButtonLiteral: getLiteral('action_add_product'),
                };

            return {
                canCreate,
                className: 'fm-empty-screen__container-products',
                icon: <NoData />,
                iconFirstTime: emptyIcon,
                iconType: 'video',
                onTracking: () => {
                    logEvent({
                        event: PRODUCTS.trueName,
                        submodule: 'emptyScreen',
                        functionality: 'video',
                    });
                },
                titleFirstTime: getLiteral('label_empty_screen_products_title'),
                subtitleFirstTime: getLiteral('label_empty_screen_products_desc'),
                titleSearch: getLiteral('error_notresultfound'),
                subtitle: getLiteral('helptext_search_filter_text'),
                subtitleSearch: getLiteral('helptext_search_filter_text'),
                customButtonLiteral: getLiteral('action_add_product'),
            };
        }, [canCreate, emptyIcon, enabledUserflow]);

        const getRowNodeId = useCallback((data) => {
            return String(data.id);
        }, []);

        return (
            <div className="products-table">
                {config && (
                    <NewEntityList
                        id="fm-grid-products"
                        entity={PRODUCTS}
                        config={config}
                        initOnReady={true}
                        useLazyLoad={true}
                        useCache={true}
                        getRowNodeId={getRowNodeId}
                        emptyViewProps={emptyViewProps}
                        customEmptyViewComponent={CustomEmptyView}
                        getCustomActiveRowId={getCustomActiveRowId}
                        discardCount={true}
                        rowHeight={64}
                    />
                )}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(LinkFromWeb3Decorator(Content));
