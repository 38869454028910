import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Icon } from 'hoi-poi-ui';

import { DOCUMENTS } from 'constants/Entities';
import { EntityListActions, DocumentActions } from 'actions';
import { ENTITY_LIST_DRAG_DROP, TREE_NODE_DRAG_DROP } from 'constants/Constants';
import colors from 'constants/colors';
import TruncatedTooltip from 'components/TruncatedTooltip';

const propTypes = {
    node_id: PropTypes.string,
    childLength: PropTypes.number,
    selected: PropTypes.bool,
    currentNode: PropTypes.object,
    dataSource: PropTypes.object,
    entityType: PropTypes.object,
    isOpen: PropTypes.bool,
    depth: PropTypes.number,
    onClick: PropTypes.func,
    onArrowClick: PropTypes.func,
};

//Check if dragged is parent of drop receiver.
const checkIfCanDrop = (tree, draggedRow, currentNode) => {
    if (currentNode.id === '-1' && draggedRow.node_id !== '-1') {
        return true;
    } else if (tree[currentNode.node_id].folder.id === draggedRow.id) {
        return false;
    } else if (tree[currentNode.node_id].folder.id === '-1') {
        return true;
    } else {
        return checkIfCanDrop(tree, draggedRow, tree[currentNode.node_id].folder);
    }
};

const dragSource = {
    canDrag(props, monitor) {
        const { currentNode, canEditFolder } = props;

        if (currentNode.id === '-1' || !canEditFolder) return false;
        return true;
    },
    beginDrag(props) {
        const { entityType, currentNode, setDraggedRow } = props;

        setDraggedRow(entityType.entity, currentNode);

        return {
            draggedRow: currentNode,
        };
    },
    endDrag(props, monitor, component) {
        const { entityType, setDraggedRow, updateEntity } = props;

        setDraggedRow(entityType.entity);

        switch (entityType) {
            //Add cases if necessary
            case DOCUMENTS:
                if (monitor.getDropResult()) {
                    const targetRow = monitor.getDropResult().targetRow;
                    const draggedRow = monitor.getItem().draggedRow;
                    const updatedRow = {
                        ...draggedRow,
                        node_id: targetRow.id,
                    };

                    updateEntity(updatedRow, updatedRow.description, updatedRow.level, '', true);
                }
        }
    },
};

const dropTarget = {
    canDrop(props, monitor) {
        const { dataSource, currentNode } = props;

        const draggedRow = monitor.getItem().draggedRow;

        if (!monitor.isOver()) return false;
        if (draggedRow.id === '-1') return false;
        if (draggedRow.node_id === '-1' && !currentNode.node_id) return false;
        if (draggedRow.id === currentNode.id) return false;
        if (draggedRow.node_id === currentNode.id) return false;

        let canItDrop = checkIfCanDrop(dataSource, draggedRow, currentNode);

        if (!canItDrop) return false;

        return true;
    },
    drop(props) {
        const { currentNode } = props;
        return {
            targetRow: currentNode,
        };
    },
};

const mapStateToProps = (state, ownProps) => {
    const draggedRow =
        state.entityList[ownProps.entityType.entity] &&
        state.entityList[ownProps.entityType.entity].draggedRow
            ? state.entityList[ownProps.entityType.entity].draggedRow
            : null;
    const permission = state.config.permission;
    const folderPermission = permission.crud_permission.DOCUMENTLIBRARY;

    return {
        draggedRow,
        canEditFolder: permission.documentos_manager && folderPermission.update,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setDraggedRow: bindActionCreators(EntityListActions, dispatch).setDraggedRow,
        updateEntity: bindActionCreators(DocumentActions, dispatch).updateEntity,
    };
};

@connect(mapStateToProps, mapDispatchToProps)
@DragSource(TREE_NODE_DRAG_DROP.TREE_NODE, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
}))
@DropTarget(TREE_NODE_DRAG_DROP.TREE_NODE, dropTarget, (connect, monitor) => ({
    connectDropTargetTreeNode: connect.dropTarget(),
    isOverTreeNode: monitor.isOver(),
    canDropTreeNode: monitor.canDrop(),
}))
@DropTarget(ENTITY_LIST_DRAG_DROP.DOCUMENTS_ROW, dropTarget, (connect, monitor) => ({
    connectDropTargetEntityList: connect.dropTarget(),
    isOverEntityList: monitor.isOver(),
    canDropEntityList: monitor.canDrop(),
}))
class TreeRowDragAndDrop extends PureComponent {
    state = {};

    componentDidMount() {
        this.props.connectDragPreview(getEmptyImage(), {
            captureDraggingState: true,
        });
    }

    handleArrowClick = (event) => {
        const { onArrowClick } = this.props;
        event.stopPropagation();
        event.preventDefault();
        onArrowClick();
    };

    renderIcon = () => {
        const { iconType, isOpen } = this.props;

        const types = {
            folder: (
                <Icon
                    name={isOpen ? 'folderOpen' : 'folder'}
                    size="large"
                    color={colors['var(--grey-500)']}
                />
            ),
            //ADD CATEGORIES IF NECESSARY
        };

        return <span>{types[iconType]}</span>;
    };

    render() {
        const {
            node_id,
            childLength,
            selected,
            colorIconSelected,
            dataSource,
            isOpen,
            depth,
            onClick,
            connectDragSource,
            connectDropTargetEntityList,
            connectDropTargetTreeNode,
            draggedRow,
            canDropEntityList,
            isOverEntityList,
            canDropTreeNode,
            isOverTreeNode,
            currentNode,
            canEditFolder,
        } = this.props;

        let node = dataSource[node_id].folder;

        const isDragAllowed =
            (isOverEntityList || isOverTreeNode) && (canDropEntityList || canDropTreeNode);

        let colorSelected = '';
        if (selected && colorIconSelected) colorSelected = colorIconSelected;

        let classNameItem = ['fm-tree-navigator-item'];
        if (selected) classNameItem.push('selected');
        if (colorSelected) classNameItem.push(colorSelected);
        if (node_id === '-1') classNameItem.push('is-root');
        if (node_id !== '-1') classNameItem.push('not-root');
        if (childLength === 0) classNameItem.push('no-children');
        if (isOpen) classNameItem.push('is-open');

        if (isDragAllowed && !selected) classNameItem.push('drag-allowed');
        else if (isDragAllowed && selected) classNameItem.push('drag-allowed-selected');

        if (draggedRow && currentNode && draggedRow.id === currentNode.id)
            classNameItem.push('dragged-row');

        const classNameIcon = [
            'fm-tree-navigator-icon',
            currentNode.id !== '-1' && canEditFolder ? 'draggable' : '',
        ].join(' ');

        return connectDragSource(
            connectDropTargetTreeNode(
                connectDropTargetEntityList(
                    <div
                        className={classNameItem.join(' ')}
                        style={{ marginLeft: depth * (node_id === '-1' ? 10 : 20) }}
                        onClick={onClick}
                    >
                        <div
                            className="fm-tree-navigator-arrow-container"
                            onClick={(event) => this.handleArrowClick(event)}
                        >
                            <div className="fm-tree-navigator-arrow">
                                <Icon name="arrowDropDownRaw" size="raw" color="currentColor" />
                            </div>
                        </div>
                        <div className={classNameIcon}>{this.renderIcon()}</div>
                        <div className="fm-tree-navigator-text">
                            <TruncatedTooltip content={node.name}>{node.name}</TruncatedTooltip>
                        </div>
                    </div>,
                ),
            ),
        );
    }
}

TreeRowDragAndDrop.propTypes = propTypes;

export default TreeRowDragAndDrop;
