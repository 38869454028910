import { memo } from 'react';
import { Chip } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { CONDITION_BOOL_LOGIC_LOCALES } from '../../../../utils';

const AutomationBuilderConditionLogicSelector = memo(({ onChange, value }) => {
    return (
        <div className="fm-automation-builder__condition-logic-selector">
            <Chip
                className="fm-automation-builder__condition-logic-selector__chip"
                onClick={() => onChange('and')}
                isActive={value === 'and'}
                isFilled
            >
                {getLiteral(CONDITION_BOOL_LOGIC_LOCALES.and)}
            </Chip>
            <div className="fm-automation-builder__condition-logic-selector__divider"></div>
            <Chip
                className="fm-automation-builder__condition-logic-selector__chip"
                onClick={() => onChange('or')}
                isActive={value === 'or'}
                isFilled
            >
                {getLiteral(CONDITION_BOOL_LOGIC_LOCALES.or)}
            </Chip>
        </div>
    );
});

export default AutomationBuilderConditionLogicSelector;
