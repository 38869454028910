import { memo } from 'react';
import { Text, Icon, useTheme } from 'hoi-poi-ui';
import { BaseReactCell } from '@web/web5';

import './styles.scss';

const IconCell = memo(({ data, value, iconValue, ...props }) => {
    const theme = useTheme();

    return (
        <BaseReactCell data={data} {...props}>
            <div className="fm-icon-cell">
                <Icon name={data?.[iconValue]} color={theme.colors.grey[500]} />
                <Text>{value || '-'}</Text>
            </div>
        </BaseReactCell>
    );
});

export default IconCell;
