import React, { memo, useCallback, useState, useMemo, useRef, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { SectionList, SectionFilter, FiltersProvider } from '@web/web5';
import { Button, Spacer, Icon } from 'hoi-poi-ui';
import { default as dateFormat } from 'date-fns/format';

import {
    getAutomationsLog,
    getAutomationLog,
    getAutomationsLogCount,
} from 'services/AutomationsService';

import Context from 'managers/Context';

import * as ENTITIES from 'constants/Entities';
import { FuzzyMap } from 'utils/fuzzy';
import { FuzzyActions } from 'actions';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { getUserSfmUrl } from 'utils/getUrl';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { getNumberFilters, cleanFilters } from 'utils/filters';
import { isEqual, isEmptyObject } from 'utils/objects';

import { AUTOMATION_TRIGGERS, AUTOMATION_ENTITIES } from './utils';
import AutomationsListEmptyView from './components/AutomationsListEmptyView';
import { BadgeCell, TextPopoverCell, IconCell } from 'components/TableCells';

import './styles.scss';

const initialState = {
    isFiltersOpen: false,
    numberFilters: 1,
    haveFilters: false,
    filters: null,
    selected: {},
};

function reducer(state, action) {
    switch (action.type) {
        case 'setIsFiltersOpen':
            return {
                ...state,
                isFiltersOpen: action.value,
            };
        case 'setFilters':
            return {
                ...state,
                filters: action.filters,
                numberFilters: action.numberFilters,
                haveFilters: action.haveFilters,
            };
        default:
            throw new Error('No action provided');
    }
}

const AutomationsLog = memo(({ openBuilder }) => {
    const dispatchStore = useDispatch();
    const [state, dispatch] = useReducer(reducer, { ...initialState });
    const [error, setError] = useState(false);
    const [quickFiltersKeys, setQuickFiltersKeys] = useState([
        'date',
        'automation',
        'level',
        'triggerUser',
        'entity',
        'action',
        'entity',
    ]);
    const totalList = useRef([]);
    const tableRef = useRef(null);
    const filters = useRef({});

    const getRowNodeId = useCallback((data) => String(data.id), []);

    const refresh = useCallback(() => {
        totalList.current = [];
        tableRef.current?.refresh({ resetOffset: true });
    }, []);

    const get = useCallback((pageSize, startRow) => {
        if (startRow > totalList.current.length) {
            return Promise.reject();
        }

        return getAutomationsLog({
            count: pageSize,
            offset: startRow,
            ...filters.current,
        })
            .then((data) => {
                // Result processing
                function resultProcessing(d) {
                    let result = {};
                    let resultDetails = '';
                    switch (d?.result) {
                        case 'success':
                            result = {
                                text: getLiteral('label_automation_result_success'),
                                type: 'semanticPositive',
                            };
                            resultDetails = getLiteralWithParameters(
                                'label_automations_result_details_sucess',
                                [getLiteral(d?.action)],
                            );
                            break;
                        case 'filtered':
                            result = {
                                text: getLiteral('label_automation_result_filtered'),
                                type: 'default',
                            };
                            resultDetails = getLiteralWithParameters(
                                'label_automations_result_details_filtered',
                                [getLiteral(d?.action)],
                            );
                            break;
                        case 'error':
                            result = {
                                text: getLiteral('label_automation_result_error'),
                                type: 'semanticNegative',
                            };
                            resultDetails = getLiteralWithParameters(
                                'label_automations_result_details_error',
                                [getLiteral(d?.action), d?.error],
                            );
                            break;
                        default:
                            break;
                    }

                    return { result, resultDetails };
                }

                const entities = data.map((d) => {
                    const { src, placeholder } = getSrcUserCircleAvatar(d?.triggerUser?.id);
                    const entity = Object.values(ENTITIES).find((e) => e.trueName === d.entity);
                    let name = getLiteral(AUTOMATION_TRIGGERS[d.triggerEvent]?.name);
                    if (d.withCreation) {
                        name += ` (${getLiteral('label_automation_event_creation_included')})`;
                    }

                    return {
                        ...d,
                        automation: d?.automation?.description,
                        ...resultProcessing(d),
                        triggeringUser: d?.triggerUser?.description,
                        triggeringUserId: d?.triggerUser?.id,
                        triggeringUserSrc: src,
                        triggeringUserPlaceholder: placeholder,
                        triggerEventIcon: AUTOMATION_TRIGGERS[d.action]?.icon,
                        triggerEvent: name,
                        triggerEntityIcon: d.entity,
                        triggerEntity: entity?.labels?.singular
                            ? getLiteral(entity?.labels?.singular)
                            : d.entity,
                        triggeringRecord:
                            d?.triggerRecord?.description || getLiteral('label_no_data'),
                        triggeringRecordURL: entity?.route
                            ? `/#${entity?.route}/${d?.triggerRecord?.id}`
                            : '',
                    };
                });

                totalList.current.push(...entities);

                return {
                    entities,
                };
            })
            .catch((e) => {
                console.error(e);
                setError(true);
                errorToast({
                    title: getLiteral('error_error'),
                    text: getLiteral('error_generalerror'),
                });
            });
    }, []);

    const getTotal = useCallback(() => {
        return getAutomationsLogCount(filters.current)
            .then((data) => {
                return data?.total || 0;
            })
            .catch((e) => {
                console.error(e);
                setError(true);
                errorToast({
                    title: getLiteral('error_error'),
                    text: getLiteral('error_generalerror'),
                });
            });
    }, []);

    const downloadLog = useCallback((row) => {
        getAutomationLog(row.id)
            .then((data) => {
                const blob = new Blob([JSON.stringify(data.trace, null, 2)], {
                    type: 'application/json',
                });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `automation-log-${row.id}.json`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((e) => {
                console.error(e);
                errorToast({
                    title: getLiteral('error_error'),
                    text: getLiteral('error_generalerror'),
                });
            });
    }, []);

    const tableProps = useMemo(
        () => ({
            components: {
                BadgeCell,
                TextPopoverCell,
                IconCell,
            },
            columnDefs: [
                {
                    colId: 'id',
                    field: 'id',
                    headerName: getLiteral('label_run_id'),
                    headerComponent: 'headerTextCell',
                    headerComponentParams: {
                        showTotal: true,
                    },
                    cellRenderer: 'textCell',
                    cellRendererParams: {
                        bold: true,
                    },
                    width: 200,
                    suppressSizeToFit: true,
                    pinned: 'left',
                },
                {
                    colId: 'date',
                    field: 'date',
                    headerName: getLiteral('cfm_label_execution_date'),
                    cellRenderer: 'dateCell',
                    cellRendererParams: {
                        locale: () => Context?.config?.userData?.locale,
                        inputFormat: "yyyy-MM-dd'T'HH:mm:ss",
                        outputFormat: 'P p',
                    },
                    minWidth: 200,
                },
                {
                    colId: 'automation',
                    field: 'automation',
                    headerName: getLiteral('label_automation'),
                    cellRenderer: 'textCell',
                    minWidth: 600,
                },
                {
                    colId: 'result',
                    field: 'result',
                    headerName: getLiteral('cfm_label_result'),
                    cellRenderer: 'BadgeCell',
                    minWidth: 150,
                },
                {
                    colId: 'resultDetails',
                    field: 'resultDetails',
                    headerName: getLiteral('label_result_details'),
                    cellRenderer: 'TextPopoverCell',
                    minWidth: 350,
                },
                {
                    colId: 'triggeringUser',
                    field: 'triggeringUser',
                    headerName: getLiteral('label_triggering_user'),
                    cellRenderer: 'avatarCell',
                    cellRendererParams: {
                        getUrl: (data) => {
                            const id = data.triggeringUserId;
                            if (!id) return;
                            return getUserSfmUrl(id) || '';
                        },
                        otherFields: {
                            src: 'triggeringUserSrc',
                            placeholder: 'triggeringUserPlaceholder',
                        },
                    },
                    minWidth: 250,
                },
                {
                    colId: 'triggerEntity',
                    field: 'triggerEntity',
                    headerName: getLiteral('label_triggering_entity'),
                    cellRenderer: 'IconCell',
                    cellRendererParams: {
                        iconValue: 'triggerEntityIcon',
                    },
                    minWidth: 250,
                },
                {
                    colId: 'triggerEvent',
                    field: 'triggerEvent',
                    headerName: getLiteral('label_triggering_event'),
                    cellRenderer: 'IconCell',
                    cellRendererParams: {
                        iconValue: 'triggerEventIcon',
                    },
                    minWidth: 250,
                },
                {
                    colId: 'triggeringRecord',
                    field: 'triggeringRecord',
                    headerName: getLiteral('label_triggering_record'),
                    cellRenderer: 'avatarCell',
                    cellRendererParams: {
                        getUrl: (data) => {
                            return data.triggeringRecordURL || '';
                        },
                    },
                    minWidth: 250,
                },
            ],
            forceActionsRefresh: true,
            actions: [
                (row) => {
                    return {
                        icon: <Icon name="download" onClick={() => downloadLog(row)} />,
                        tooltip: { content: getLiteral('label_result_details_download') },
                    };
                },
            ],
            onRef: (ref) => {
                tableRef.current = ref;
            },
        }),
        [downloadLog],
    );

    const getErrorView = useCallback(() => {
        return {
            title: getLiteral('error_an_error_occurred'),
            subtitle: getLiteral('error_reload_table'),
            button: (
                <Button type="secondary" onClick={refresh}>
                    {getLiteral('action_reload_table')}
                </Button>
            ),
        };
    }, [refresh]);

    const onCreate = useCallback(() => {
        openBuilder();
    }, [openBuilder]);

    const onChangeFilters = useCallback(
        (values) => {
            const numberFilters = getNumberFilters(values);
            dispatch({
                type: 'setFilters',
                numberFilters,
                haveFilters: numberFilters > 0,
                filters: values,
            });

            let dateFrom, dateTo;

            if (values?.date?.[0]) {
                dateFrom = dateFormat(values?.date?.[0], "yyyy-MM-dd'T'00:00:00.000'Z'");
            }

            if (values?.date?.[1]) {
                dateTo = dateFormat(values?.date?.[1], "yyyy-MM-dd'T'23:59:59.000'Z'");
            }

            const newFilters = cleanFilters({
                dateFrom,
                dateTo,
                automation: values?.automation,
                level: values?.level?.value,
                triggerUser: values?.triggerUser?.value,
                entity: values?.entity?.value,
                action: values?.action?.value,
                entityId: parseInt(values?.entityId?.value, 10),
            });

            if (
                isEqual(newFilters, filters.current) ||
                (isEmptyObject(values) && isEmptyObject(filters.current))
            ) {
                filters.current = newFilters;
            } else {
                filters.current = newFilters;
                refresh();
            }
        },
        [refresh],
    );

    const openFilters = useCallback(() => {
        dispatch({
            type: 'setIsFiltersOpen',
            value: true,
        });
    }, []);

    const onCloseFilters = useCallback(
        () =>
            dispatch({
                type: 'setIsFiltersOpen',
                value: false,
            }),
        [],
    );

    const toggleQuickFilter = useCallback(
        (id) => {
            const alreadyExists = quickFiltersKeys.includes(id);
            const newQuickFilters = alreadyExists
                ? quickFiltersKeys.filter((i) => i !== id)
                : [...quickFiltersKeys, id];

            setQuickFiltersKeys(newQuickFilters);
        },
        [quickFiltersKeys],
    );

    const onReOrder = useCallback(
        (startIndex, endIndex) => {
            const result = [...quickFiltersKeys];
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            setQuickFiltersKeys(result);
        },
        [quickFiltersKeys],
    );

    const filterSchema = useMemo(() => {
        const showTriggeringEntityId = state.filters?.entity?.value;
        const schema = [
            {
                label: getLiteral('cfm_label_execution_date'),
                id: 'date',
                fields: [
                    {
                        id: 'date',
                        dataType: 'date',
                    },
                ],
            },
            {
                label: getLiteral('label_automation'),
                id: 'automation',
                fields: [
                    {
                        id: 'automation',
                        dataType: 'text',
                        inputProps: {
                            placeholder: getLiteral('cfm_placeholder_search'),
                        },
                    },
                ],
            },
            {
                label: getLiteral('cfm_label_result'),
                id: 'level',
                fields: [
                    {
                        id: 'level',
                        dataType: 'singleValueList',
                        inputProps: {
                            placeholder: getLiteral('cfm_placeholder_search'),
                            options: [
                                {
                                    label: getLiteral('label_automation_result_success'),
                                    value: 2,
                                },
                                {
                                    label: getLiteral('label_automation_result_filtered'),
                                    value: 0,
                                },
                                {
                                    label: getLiteral('label_automation_result_error'),
                                    value: -1,
                                },
                            ],
                        },
                    },
                ],
            },
            {
                label: getLiteral('label_triggering_user'),
                id: 'triggerUser',
                fields: [
                    {
                        id: 'triggerUser',
                        dataType: 'singleValueList',
                        inputProps: {
                            placeholder: getLiteral('cfm_placeholder_search'),
                            isFuzzy: true,
                            defaultOptions: true,
                            loadOptions: (text) =>
                                dispatchStore(
                                    FuzzyActions.getFuzzy({ list: 'usuarios', field: '', text }),
                                ),
                        },
                    },
                ],
            },
            {
                label: getLiteral('label_triggering_event'),
                id: 'action',
                fields: [
                    {
                        id: 'action',
                        dataType: 'singleValueList',
                        inputProps: {
                            placeholder: getLiteral('cfm_placeholder_search'),
                            options: Object.entries(AUTOMATION_TRIGGERS).map(([key, value]) => ({
                                value: key,
                                label: getLiteral(value.name),
                            })),
                        },
                    },
                ],
            },
            {
                label: getLiteral('label_triggering_entity'),
                id: 'entity',
                fields: [
                    {
                        id: 'entity',
                        dataType: 'singleValueList',
                        inputProps: {
                            placeholder: getLiteral('cfm_placeholder_search'),
                            options: AUTOMATION_ENTITIES.map((entity) => ({
                                label: getLiteral(entity.labels.singular),
                                value: entity.trueName,
                            })),
                        },
                    },
                ],
                subfilterSchema: showTriggeringEntityId
                    ? [
                          {
                              id: 'entityId',
                              isPinnable: false,
                              isRemovable: false,
                              label: getLiteral('label_triggering_record'),
                              fields: [
                                  {
                                      id: 'entityId',
                                      dataType: 'singleValueList',
                                      isSubFilter: true,
                                      inputProps: {
                                          placeholder: getLiteral('cfm_placeholder_search'),
                                          isFuzzy: true,
                                          defaultOptions: false,
                                          loadOptions: (text) => {
                                              let listName = '';
                                              switch (filters.current?.entity) {
                                                  case 'accounts':
                                                      listName = 'empresas';
                                                      break;
                                                  case 'contacts':
                                                      listName = 'contactos';
                                                      break;
                                                  case 'opportunities':
                                                      listName = 'expedientes';
                                                      break;
                                                  case 'activities':
                                                      listName = 'actividades';
                                                      break;
                                                  case 'salesorders':
                                                      listName = 'z_tblpedidos';
                                                      break;
                                                  case 'users':
                                                      listName = 'users';
                                                      break;
                                                  default:
                                                      listName = '';
                                              }

                                              if (!listName) return Promise.resolve([]);

                                              return dispatchStore(
                                                  FuzzyActions.getFuzzy({
                                                      text,
                                                      ...FuzzyMap[listName],
                                                  }),
                                              );
                                          },
                                      },
                                  },
                              ],
                          },
                      ]
                    : [],
            },
        ];
        return schema;
    }, [dispatchStore, state.filters?.entity?.value]);

    const emptyViewProps = useMemo(() => {
        return {
            onCreate: () => openBuilder(),
        };
    }, [openBuilder]);

    const quickFilterSchema = useMemo(() => {
        return filterSchema.filter((filter) => quickFiltersKeys.includes(filter.id));
    }, [filterSchema, quickFiltersKeys]);

    const generalFilterSchema = useMemo(() => {
        return filterSchema.filter((filter) => !quickFiltersKeys.includes(filter.id));
    }, [filterSchema, quickFiltersKeys]);

    const defaultOpenFilters = useMemo(
        () => ({
            entity: {
                open: false,
                subfilters: {
                    entityId: true,
                },
            },
        }),
        [],
    );

    const haveFilters = state.haveFilters;

    return (
        <FiltersProvider>
            <div className="fm-automations-settings__toolbar">
                <SectionFilter
                    useQuickFilters
                    quickFilters={quickFiltersKeys}
                    schema={filterSchema}
                    onChange={onChangeFilters}
                    values={state.filters}
                    noFiltersProvider={true}
                    defaultOpenFilters={defaultOpenFilters}
                />
                {!haveFilters && (
                    <Button type="terciary" size="medium" icon="filterList" onClick={openFilters}>
                        {getLiteral('action_filters')}
                    </Button>
                )}
                {haveFilters && (
                    <Button
                        type="secondary"
                        size="medium"
                        icon="filterListActive"
                        onClick={openFilters}
                    >
                        {getLiteralWithParameters('label_filters_button', [state.numberFilters])}
                    </Button>
                )}
                <Button onClick={onCreate}>{getLiteral('action_create')}</Button>
            </div>
            <Spacer y={2} />
            <SectionList
                id="automations-log"
                {...tableProps}
                useLoaderPlaceholder
                hasError={error}
                getRowNodeId={getRowNodeId}
                errorViewProps={getErrorView}
                emptyViewProps={emptyViewProps}
                customEmptyViewComponent={AutomationsListEmptyView}
                //Infinite scrolling props
                forceActionsRefresh
                initTable={get}
                getTotal={getTotal}
                pageSize={50}
                useLazyLoad
                initOnReady
            />
            <SectionFilter
                isOpen={state.isFiltersOpen}
                onClose={onCloseFilters}
                id="fm-automations-log__filter-section"
                quickSchema={quickFilterSchema}
                schema={generalFilterSchema}
                onChange={onChangeFilters}
                values={state.filters}
                toggleQuickFilter={toggleQuickFilter}
                onReOrder={onReOrder}
                isPinnable={true}
                noFiltersProvider={true}
                defaultOpenFilters={defaultOpenFilters}
            />
        </FiltersProvider>
    );
});

export default AutomationsLog;
