import React, { memo, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'hoi-poi-ui';

import { PRODUCTS } from 'constants/Entities';
import { PATH_IMAGE } from 'constants/Environment';

import metadata from 'lib/metadata';
import { isEmptyObject } from 'utils/objects';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { ensureRoute } from 'utils/routes';

import { EntityFiltersActions } from 'actions';
import NoData from 'components/SvgIcons/emptyScreen/NoData';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import Products from 'components/SvgIcons/emptyScreen/Products';

const hasSearchFilter = (filters) => {
    return filters && filters.matchingName && filters.matchingName.value;
};

const hasVisibleFilters = (filters) => {
    return (
        !isEmptyObject(filters) &&
        !Object.keys(filters).every((key) => filters[key].invisible || filters[key].isPreload)
    );
};

const ProductsGridEmptyView = ({ loading, count, filters }) => {
    const dispatch = useDispatch();

    const { canCreate, locale, enabledUserflow } = useSelector((state) => {
        return {
            canCreate:
                state.config.permission?.crud_permission?.[PRODUCTS.permission]?.create || false,
            locale: state?.config?.userData?.locale || '',
            enabledUserflow: state?.config?.userData?.userFlow,
        };
    });

    const showEmptyView = useMemo(() => {
        return !loading && !count;
    }, [loading, count]);

    const onClearFilters = useCallback(() => {
        logEvent({
            event: PRODUCTS.trueName,
            submodule: 'emptyScreen',
            functionality: 'clearFilter',
        });

        dispatch(EntityFiltersActions.clearFilters({ entity: PRODUCTS, isAPurge: false }));
        dispatch(EntityFiltersActions.changeAdvancedFilter({ entity: PRODUCTS, operators: {} }));
    }, [dispatch]);

    const onClickAdd = useCallback(() => {
        if (!canCreate) return;

        ensureRoute(`${PRODUCTS.route}/grid/new`);

        logEvent({
            event: PRODUCTS.trueName,
            functionality: 'create',
            submodule: 'emptyScreen',
        });
    }, [canCreate]);

    const emptyViewButton = useMemo(() => {
        if (hasVisibleFilters(filters) || hasSearchFilter(filters)) {
            return (
                <Button type="secondary" onClick={onClearFilters}>
                    <span className={`${PRODUCTS.entity}-content__empty-view-actions__text`}>
                        {getLiteral('action_clear_filters')}
                    </span>
                </Button>
            );
        }

        if (!canCreate) return null;

        return (
            <Button type="secondary" onClick={onClickAdd}>
                <span className={`${PRODUCTS.entity}-content__empty-view-actions__text`}>
                    {getLiteral('action_add_product')}
                </span>
            </Button>
        );
    }, [filters, canCreate, onClickAdd, onClearFilters]);

    const emptyViewPropsFn = useCallback(() => {
        let emptyViewProps = {};

        if (!metadata.showEmptyScreenVideo || enabledUserflow === 'False')
            emptyViewProps = {
                className: 'fm-empty-screen__grid--products',
                canCreate,
                icon: <NoData />,
                iconFirstTime: <Products />,
                iconType: 'icon',
                titleFirstTime: getLiteral('label_empty_screen_products_title'),
                subtitleFirstTime: getLiteral('label_empty_screen_products_desc'),
                titleSearch: getLiteral('error_notresultfound'),
                subtitle: getLiteral('helptext_search_filter_text'),
                subtitleSearch: getLiteral('helptext_search_filter_text'),
            };
        else {
            emptyViewProps = {
                canCreate,
                className: 'fm-empty-screen__grid--products fm-empty-screen__container-products',
                icon: <NoData />,
                iconFirstTime: locale.includes('es-') ? (
                    <img src={`${PATH_IMAGE}Products-ES.png`} />
                ) : (
                    <img src={`${PATH_IMAGE}Products-EN.png`} />
                ),
                iconType: 'video',
                onTracking: () => {
                    logEvent({
                        event: PRODUCTS.trueName,
                        submodule: 'emptyScreen',
                        functionality: 'video',
                    });
                },
                titleFirstTime: getLiteral('label_empty_screen_products_title'),
                subtitleFirstTime: getLiteral('label_empty_screen_products_desc'),
                titleSearch: getLiteral('error_notresultfound'),
                subtitle: getLiteral('helptext_search_filter_text'),
                subtitleSearch: getLiteral('helptext_search_filter_text'),
            };
        }

        const emptyTitle = getLiteral('error_notresultfound');
        const emptyTitleFirstTime = emptyViewProps?.titleFirstTime;
        const emptyTitleSearch = emptyViewProps?.titleSearch;
        const emptySubtitle = emptyViewProps?.subtitle;
        const emptySubtitleFirstTime = emptyViewProps?.subtitleFirstTime;
        const emptySubtitleSearch = emptyViewProps?.subtitleSearch;
        const emptyIconFirstTime = emptyViewProps?.iconFirstTime;

        let finalEmptyTitle = emptyTitle;
        let finalEmptySubtitle = emptySubtitle;
        let finalEmptyIcon = emptyViewProps?.icon;

        if (!hasVisibleFilters(filters) && !hasSearchFilter(filters)) {
            finalEmptyTitle = emptyTitleFirstTime;
            finalEmptySubtitle = emptySubtitleFirstTime;
            finalEmptyIcon = emptyIconFirstTime;
        } else if (hasSearchFilter(filters)) {
            finalEmptyTitle = emptyTitleSearch;
            finalEmptySubtitle = emptySubtitleSearch;
        }

        return {
            className: emptyViewProps?.className || null,
            title: finalEmptyTitle,
            subtitle: finalEmptySubtitle,
            icon: finalEmptyIcon || null,
            iconLast: false,
            iconType: emptyViewProps?.iconType || 'icon',
            onTracking: emptyViewProps?.onTracking || null,
            button: emptyViewButton || null,
        };
    }, [canCreate, emptyViewButton, enabledUserflow, filters, locale]);

    if (showEmptyView) return <CustomEmptyView emptyViewProps={emptyViewPropsFn} />;
    else return null;
};

export default memo(ProductsGridEmptyView);
