import React, { memo, useMemo, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Error as ErrorWeb5 } from '@web/web5';

import metadata from 'lib/metadata';

import Logo from 'components/Logo';
import useLoginLiterals from 'utils/literals/useLoginLiterals';
import { logEvent } from 'utils/tracking';

import './styles.scss';

const mapStateToProps = (state) => ({
    title: state.error?.title,
    description: state.error?.description,
});

const Error = memo(({ title, description }) => {
    const { code } = useParams();
    const { loading, getLiteral } = useLoginLiterals();
    const isFirstRender = useRef(true);

    const bottomImage = useMemo(
        () => <img className="fm-generic-error__logo" alt="error-logo" src={metadata.errorImage} />,
        [],
    );

    const errorProps = useMemo(() => {
        let literals = {
            title: title || 'label_invitation_expired_error_screen_title',
            description: description || 'label_invitation_expired_error_screen_explanation',
        };

        switch (code) {
            case '401':
                if (isFirstRender?.current) {
                    logEvent({
                        event: 'settings',
                        submodule: 'users',
                        functionality: 'exceededAvailableSeats',
                    });
                    isFirstRender.current = false;
                }
                literals = {
                    title: 'label_invitation_error_no_licenses_title',
                    description: 'label_invitation_error_no_licenses_explanation',
                };
                break;
            default:
                break;
        }
        let errorProps = {
            logo: <Logo width={metadata.mainLogoWidth} />,
            title: getLiteral(literals.title),
            description: getLiteral(literals.description),
        };

        if (metadata.errorImage) errorProps = { ...errorProps, image: () => bottomImage };

        return errorProps;
    }, [bottomImage, code, description, getLiteral, title]);

    if (loading) return null;

    return (
        <div className="fm-generic-error">
            <ErrorWeb5 {...errorProps} />
        </div>
    );
});

export default memo(connect(mapStateToProps)(Error));
