import moment from 'moment';
import { AGENDA } from 'constants/Entities';
import Context from 'managers/Context';
import {
    AGENDA_CHANGE_SECTION,
    AGENDA_SET_AVAILABLE_TIMES,
    AGENDA_RESET_AVAILABLE_TIMES,
    AGENDA_INIT_FILTER_COLOR,
    AGENDA_SET_FILTER_COLOR,
    AGENDA_RESET_ALL,
    AGENDA_CHANGE_TIMEZONE,
} from 'constants/ActionTypes';
import { formatDateToBackendUTC } from 'utils/dates';
import { getBackendBoolean } from 'utils/fm';
import { AGENDA_TASK, AGENDA_EVENT, CALENDAR_VIEWS } from 'constants/Constants';
import UtilFormat from 'utils/UtilFormat';

export const changeSection = (section) => {
    return (dispatch) => {
        dispatch({ type: AGENDA_CHANGE_SECTION, section });
    };
};

export const setAvailableTimes = (availableTimes) => {
    return (dispatch) => {
        dispatch({ type: AGENDA_SET_AVAILABLE_TIMES, availableTimes });
    };
};

export const resetAvailableTimes = () => {
    return (dispatch) => {
        dispatch({ type: AGENDA_RESET_AVAILABLE_TIMES });
    };
};

export const initFiltersColor = () => {
    return (dispatch) => {
        const state = Context.store.getState();
        const agendaFilters = state.entityFilters[AGENDA.entity]?.filters || {};

        if (!Object.keys(agendaFilters).length || !agendaFilters.idusuario) return;

        const values = agendaFilters.idusuario?.value || [];

        if (!values.length) return;

        const filtersColor = state.agenda.filtersColor;
        const { base, filters } = filtersColor;
        let filterColor = '';
        let newControl = base.reduce((obj, color) => {
            if (color.name) obj[color.name] = 0;
            return obj;
        }, {});
        let newFilters = {
            ...filters,
            idusuario: {
                control: newControl,
                colors: {},
            },
        };

        let counter = 0;
        values.forEach((current) => {
            filterColor = base[counter];

            newFilters.idusuario.colors[current] = {
                name: filterColor.name,
                default: filterColor.default,
                defaultText: filterColor.defaultText,
                hover: filterColor.hover,
                pastDefault: filterColor.pastDefault,
                pastHover: filterColor.pastHover,
                pastText: filterColor.pastText,
                marker: filterColor.marker,
            };
            newControl[filterColor.name]++;

            counter++;
            if (counter > Object.keys(newControl).length - 1) counter = 0;
        });

        dispatch({
            type: AGENDA_INIT_FILTER_COLOR,
            filters: newFilters,
        });
    };
};

export const setFiltersColor = (filter, id) => {
    return (dispatch) => {
        const filterId = filter.id;
        if (filterId !== 'idusuario') return;
        const state = Context.store.getState();
        const filtersColor = state.agenda.filtersColor;
        const { base, filters } = filtersColor;

        const { control, colors } = filters[filterId] || {};
        let newControl = { ...control };
        let newColors = { ...colors };

        if (colors[id]) {
            const decreaseColor = colors[id];
            newControl[decreaseColor.name]--;
            delete newColors[id];
        } else {
            let colorStr = Object.keys(control).reduce((str, key) => {
                if (!str) str = key;
                if (control[str] > control[key]) str = key;
                return str;
            }, '');

            const color = base.filter((current) => current.name === colorStr)[0];

            newControl[colorStr]++;
            newColors[id] = color;
        }

        let newFilters = {
            ...filters,
            idusuario: { control: { ...newControl }, colors: newColors },
        };

        dispatch({ type: AGENDA_SET_FILTER_COLOR, filters: newFilters });
    };
};

export const updateEventFromCalendar = (data) => {
    return () => {
        return new Promise((resolve, reject) => {
            const newData = { ...data };
            let customState = { data: newData, ignoreErrors: true };
            const eventType = getBackendBoolean(newData.isTarea) ? AGENDA_TASK : AGENDA_EVENT;
            Context.entityCrudManager
                .getSchema(AGENDA, true, false, eventType)
                .then((obj) => {
                    customState = { ...customState, ...obj };
                    Context.entityCrudManager
                        .save({
                            entity: AGENDA,
                            entityState: null,
                            persistCrudWorkFlow: false,
                            fromDetail: false,
                            fromWorkFlow: false,
                            customState,
                        })
                        .then(() => {
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch(() => {
                    console.error(`Couldn't get schema`);
                    reject();
                });
        });
    };
};

export const resetAll = () => {
    return (dispatch) => {
        dispatch({ type: AGENDA_RESET_ALL });
    };
};

const getDayRange = (date, isPrev, isNext, closeToToday) => {
    const today = moment();
    let startDate = date ? moment(date) : moment();

    if (closeToToday) {
        const isSameMonth = startDate.isSame(today, 'month');
        const isSameWeek = startDate.isSame(today, 'week');
        if (isSameMonth || isSameWeek) {
            startDate = today;
        }
    }

    startDate.hours(0).minutes(0).seconds(0).milliseconds(0);
    let endDate = moment(startDate);

    if (isPrev) {
        startDate.subtract(1, 'days');
    } else if (isNext) {
        startDate.add(1, 'days');
        endDate.add(2, 'days');
    } else {
        endDate.add(1, 'days');
    }

    return {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
    };
};

const getWeekRange = (date, isPrev, isNext, closeToToday, locale) => {
    if (locale) moment.locale(locale);
    let startDate = date ? moment(date) : moment();
    startDate.startOf('week');

    if (closeToToday) {
        const today = moment();
        const startMonth = startDate.clone().endOf('week').startOf('month');
        const endOfMonth = startDate.clone().endOf('week').endOf('month');
        const isTodayWithinMonthRange = today.isBetween(startMonth, endOfMonth);

        if (isTodayWithinMonthRange) {
            today.startOf('week');
            startDate = today;
        }
    }

    let endDate = moment(startDate);
    endDate.endOf('week').add(1, 'days').hours(0).minutes(0).seconds(0).milliseconds(0);
    if (isPrev) {
        startDate.subtract(1, 'week');
        endDate.subtract(1, 'week');
    } else if (isNext) {
        startDate.add(1, 'week');
        endDate.add(1, 'week');
    }

    return {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
    };
};

const getWorkweekRange = (date, isPrev, isNext, closeToToday, locale) => {
    if (locale) moment.locale(locale);
    let startDate = date ? moment(date) : moment();
    startDate.startOf('week');

    if (closeToToday) {
        const today = moment();
        const startMonth = startDate.clone().endOf('week').startOf('month');
        const endOfMonth = startDate.clone().endOf('week').endOf('month');
        const isTodayWithinMonthRange = today.isBetween(startMonth, endOfMonth);

        if (isTodayWithinMonthRange) {
            today.startOf('week');
            startDate = today;
        }
    }

    let endDate = moment(startDate);
    endDate.endOf('week').hours(0).minutes(0).seconds(0).milliseconds(0);

    if (isPrev) {
        startDate.subtract(1, 'week');
        endDate.subtract(1, 'week');
    } else if (isNext) {
        startDate.add(1, 'week');
        endDate.add(1, 'week');
    }

    return {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
    };
};

const getMonthRange = (date, isPrev, isNext) => {
    let startDate = date ? moment(date) : moment();
    startDate.startOf('month');
    let endDate = moment(startDate);
    endDate.endOf('month').add(1, 'days').hours(0).minutes(0).seconds(0).milliseconds(0);

    if (isPrev) {
        startDate.subtract(1, 'month');
        endDate.subtract(1, 'month');
    } else if (isNext) {
        startDate.add(1, 'month');
        endDate.add(1, 'month');
    }

    return {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
    };
};

export const changeDateRangeFilter = (dateRange, refresh) => {
    return (dispatch) => {
        if (!dateRange?.startDate || !dateRange?.endDate) return;

        const startDateFilter = { id: 'fini', hideForCount: true, excludeFromCache: true };
        const endDateFilter = { id: 'ffin', hideForCount: true, excludeFromCache: true };
        const startDate = formatDateToBackendUTC(dateRange.startDate);
        const endDate = formatDateToBackendUTC(dateRange.endDate);

        dispatch(
            Context.actions.EntityFiltersActions.changeFilter({
                entity: AGENDA,
                filter: startDateFilter,
                value: startDate,
                refresh: false,
            }),
        );
        dispatch(
            Context.actions.EntityFiltersActions.changeFilter({
                entity: AGENDA,
                filter: endDateFilter,
                value: endDate,
                refresh,
            }),
        );
    };
};

export const getDateRange = ({ date, isPrev, isNext, closeToToday }) => {
    return (dispatch, getState) => {
        const state = getState();
        const outlierFilters = state.entityFilters[AGENDA.entity]?.outlierFilters || {};
        const calendarView = outlierFilters?.view?.value || null;
        const locale = UtilFormat._localeDate;

        if (!calendarView?.value) return null;
        let dateRange = null;

        switch (calendarView.value) {
            case CALENDAR_VIEWS.day:
                dateRange = getDayRange(date, isPrev, isNext, closeToToday);
                break;
            case CALENDAR_VIEWS.week:
                dateRange = getWeekRange(date, isPrev, isNext, closeToToday, locale);
                break;
            case CALENDAR_VIEWS.workweek:
                dateRange = getWorkweekRange(date, isPrev, isNext, closeToToday, locale);
                break;
            case CALENDAR_VIEWS.month:
            default:
                dateRange = getMonthRange(date, isPrev, isNext);
                break;
        }

        return dateRange;
    };
};

export const changeTimezone = (timezone) => {
    return (dispatch) => {
        dispatch({ type: AGENDA_CHANGE_TIMEZONE, timezone });
    };
};
